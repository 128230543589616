// import {
// 	PUT_MAP_TRAVELS_SKIP,
// 	GET_MAP_TRAVELS,
// 	GET_MAP_SIGHTS,
// 	GET_MAP_SIGHT,
// 	GET_MAP_HASHTAGS,
// 	GET_TRAVELS_FOR_MAP,
// 	DELETE_TRAVELS_FOR_MAP,
// 	GET_SIGHT_FOR_MAP,
// 	GET_SIGHTS_FOR_MAP,
// } from '../../constants/ActionTypes';
import axios from 'axios';
import { URL_TRAVEL, URL_SIGHTS } from '../../config';
import { showLoader, showMessage, hideLoader } from './HelperActions';
import { ALLDAYS } from '../../constants/Duration';
import { getDays } from './../../Helper';
import travelService from './../../services/travel.service';
import sightService from './../../services/sight.service';
import {
	hashtagsReceived,
	hashtagsRequested,
	hashtagsRequestFailed,
	sightReceived,
	sightRequested,
	sightRequestFailed,
	sightsReceived,
	travelsRequested,
	putTravelsSkip,
	travelsRequestFailed,
	sightsRequested,
	sightsRequestFailed,
	travelsReceived,
} from '../reducers/MapReducer';

const paramsForTravels = ({ skip, limit, hash, difficulty, days, lat, long, sightIdentifier, sights }) => {
	const sightsIdentifiersArr = sights.map(item => item.identifier);

	const route = ((lat && long) || sightIdentifier) && {
		$elemMatch: {
			sights: {
				$in: sightIdentifier ? [sightIdentifier] : sightsIdentifiersArr,
			},
		},
	};
	console.log(days)
	const daysNumber =
		days !== 'All'
			? {
					$in: getDays(days).map(String),
			  }
			: undefined;

	const hashtag = hash ? { $all: hash && hash.slice(1).split('#') } : undefined;

	return { skip, limit, hashtag, daysNumber, difficulty, sort: { rating: -1, identifier: 1 }, route };
};

export const loadTravels =
	({ skip = 0, limit = 5, lat, long, duration = 'All', hash, difficulty = 'moderate', distance = 34400, sightIdentifier, getHashtag = false }) =>
	async (dispatch, getState) => {
		const { sights, sight } = getState().MapPage;
		console.log(
			'lat',
			lat,
			'long',
			long,
			'skip',
			skip,
			'sightIdentifier',
			sightIdentifier,
			'duration',
			duration,
			'hash',
			hash,
			'difficulty',
			difficulty,
			'distance',
			distance,
			'getHashtag',
			getHashtag
		);
		!skip && !getHashtag && dispatch(travelsRequested());
		// dispatch(hashtagsRequested());
		try {
			// !skip && !getHashtag && dispatch(showLoader('travels_MapPage'));
			// (!skip || getHashtag) && dispatch(showLoader('hashtags_MapPage'));
			if ((!skip && sightIdentifier) || (sight.hasOwnProperty('identifier') && !sightIdentifier)) {
				console.log('getSight')
				dispatch(loadSight(sightIdentifier));
			}

			const sightsArr = (!skip && lat && long && (await loadSights(lat, long, Number(distance), dispatch))) || [];
			console.log('sightsArr', sightsArr)
			// data?.sights && data?.sights.length && dispatch({type: GET_MAP_SIGHTS, payload: data?.sights});
			// if ((!(lat && long) && sights.length && !sightIdentifier) || sightsArr.length) {
			// 	// dispatch({ type: GET_MAP_SIGHTS, payload: sightsArr });
			// 	console.log('getSights')
			// 	dispatch(sightsReceived(sightsArr));
			// }
			// const sightsIdentifiersArr = skip && !sightIdentifier ? sights.map(item => item.identifier) : sightsArr.map(item => item.identifier);
			// console.log(sightsIdentifiersArr, sightIdentifier, sightsArr);

			// const params = { skip, limit, hashtag, daysNumber, difficulty, sort: { rating: -1, identifier: 1 }, route };
			const params = paramsForTravels({ skip, limit, hash, difficulty, days: duration, lat, long, sightIdentifier, sights: skip && !sightIdentifier ? sights : sightsArr });
			console.log(params, duration)
			const travels = await travelService.getTravels(params);
			console.log(travels)
			!skip && dispatch(getHashtags(travels, getHashtag));
			if (!getHashtag) {
				if (skip) {
					dispatch(putTravelsSkip({ travels, skip, hasMore: travels.length ? true : false }));
					// dispatch({ type: PUT_MAP_TRAVELS_SKIP, payload: { travels, skip, hasMore: travels.length ? true : false } });
				} else {
					dispatch(travelsReceived({ travels, skip, hasMore: true }));
					// dispatch({ type: GET_MAP_TRAVELS, payload: { travels, skip, hasMore: true } });
				}
				// !skip && dispatch(hideLoader('travels_MapPage'));
			}
		} catch ({ message }) {
			dispatch(travelsRequestFailed(message));
			// dispatch(showMessage({identifier: 'travels_MapPage', message: e.message }));
			// dispatch(hideLoader('travels_MapPage'));
		}
	};

const loadSights =  async (lat, long, distance, dispatch)  => {
	dispatch(sightsRequested());
	try {
		// console.log(distance)
		const sights = await sightService.getSightsNear({ lat, long, distance });
		console.log(sights)
		dispatch(sightsReceived(sights));
		// dispatch({type: GET_MAP_SIGHTS, payload: sights });
		return sights;
	} catch ({message}) {
		dispatch(sightsRequestFailed(message));
	}
};

export const loadSight = sightIdentifier => async dispatch => {
	dispatch(sightRequested());
	try {
		if (sightIdentifier) {
			const [sight] = await sightService.get({ filter: { identifier: sightIdentifier } });
			dispatch(sightReceived(sight));
			// dispatch({ type: GET_MAP_SIGHT, payload: sight });
		} else {
			dispatch(sightReceived({}));
			// dispatch({ type: GET_MAP_SIGHT, payload: {} });
		}
	} catch ({ message }) {
		dispatch(sightRequestFailed(message));
		// dispatch(showMessage({ identifier: 'sight_MapPage', message: e.message }));
	}
};

export const getHashtags = (travels, skip) => (dispatch, getState) => {
	dispatch(hashtagsRequested());
	// const { hashtags } = getState().MapPage;
	// try{
	let hashtagsArr = travels.map(travel => travel.hashtags).flat();
	// let hashtagsArr = !skip ? travels.map(travel => travel.hashtags).flat() : hashtags.concat(travels.map(travel => travel.hashtags).flat());
	let uniqueHashtags = [...new Set(hashtagsArr)];

	// hashtags.map(el => uniqueHashtags.filter((hashtag, index) => (hashtag == el ? uniqueHashtags.splice(index, 1) : false)));
	dispatch(hashtagsReceived(uniqueHashtags));

	// dispatch({ type: GET_MAP_HASHTAGS, payload: uniqueHashtags });
	// dispatch(hideLoader('hashtags_MapPage'));

	// } catch(e){

	// }
};

// const checkLoading = (skip, loading, show) => {
// 	return dispatch => {
// 		if (skip >= 0 && loading) {
// 			show ? dispatch(showLoader('trips_MapPage')) : dispatch(hideLoader('trips_MapPage'));
// 		} else {
// 			show ? dispatch(showLoader('exploreTrips_MapPage')) : dispatch(hideLoader('exploreTrips_MapPage'));
// 		}
// 	};
// };

// const getHashtagsInTravels = ({ travels = [], all = false }) => {
// 	return async dispatch => {
		

// 		let hashtagsInsideTravels = [];
// 		if (travels.length) {
// 			dispatch(hashtagsRequested());
// 			travels.map(travel => {
// 				hashtagsInsideTravels = hashtagsInsideTravels.concat(travel.hashtags);
// 			});
// 			let uniqueHashtags = [...new Set(hashtagsInsideTravels)];
// 			dispatch(hashtagsReceived(uniqueHashtags));
// 			// dispatch({ type: GET_MAP_HASHTAGS, payload: uniqueHashtags });
// 			// if (all) {
// 			// 	dispatch(hideLoader('hashtags_MapPage'));
// 			// }
// 		}
// 	};
// };

// /center/distance
// export const getSightsAndTravels = ({ center, filteredHashtags, getHashtags, duration, distance, limit, skip, loading }) => {
// 	return async dispatch => {
// 		dispatch(sightsRequested());
// 		try {
// 			!getHashtags ? dispatch(checkLoading(skip, loading, true)) : dispatch(showLoader('hashtags_MapPage'));

// 			let filterUrl = `{"location":{"$near":{"$geometry":{"type":"Point","coordinates":[${center}]},"$maxDistance":${distance}}}}`;

// 			const { data: sights } = await axios.get(`${URL_SIGHTS}${encodeURIComponent(filterUrl)}`);
// 			// dispatch({ type: GET_SIGHTS_FOR_MAP, payload: sights });
// 			dispatch(sightsReceived(sights));

// 			let identifierArr = [];
// 			sights.map(item => {
// 				if (item.identifier) {
// 					identifierArr.push(item.identifier);
// 				}
// 			});

// 			dispatch(getTravelsIncludesSights({ identifierArr, filteredHashtags, duration, getHashtags, skip, limit, loading }));
// 		} catch ({ message }) {
// 			dispatch(sightsRequestFailed(message));
// 			// dispatch(showMessage({identifier: 'exploreTrips_MapPage', message: e.message}));
// 			// dispatch(checkLoading(skip, loading, false));
// 		}
// 	};
// };

// export const getTravelsIncludesSights = ({ identifierArr, filteredHashtags, duration, skip, limit, loading, getHashtags }) => {
// 	return async dispatch => {
// 		try {
// 			let daysString =
// 				duration && duration !== 0 && duration !== 'All'
// 					? ALLDAYS[duration].map(item => {
// 							return item.toString();
// 					  })
// 					: '';
// 			let objString = `{"route": {$elemMatch: {"sights": {$in: ${JSON.stringify(identifierArr)}}}}, "difficulty": "moderate"${
// 				daysString && `, "daysNumber": {$in: ${JSON.stringify(daysString)}}`
// 			}${filteredHashtags.length ? `, "hashtags": {$all: ${JSON.stringify(filteredHashtags)}}` : ''}}`;

// 			let objStringFields = `&%24sort=${encodeURIComponent('{"rating": -1, "identifier": 1}')}&%24skip=${getHashtags ? 0 : skip}&%24limit=${getHashtags ? 1000 : limit}`;

// 			let { data: resTravels = [] } = await axios.get(`${URL_TRAVEL}${encodeURIComponent(objString)}${objStringFields}`);

// 			if (!resTravels.length) {
// 				// dispatch(showMessage({identifier: 'exploreTrips_MapPage', message: 'explore trips'}));
// 			}

// 			if (getHashtags) {
// 				dispatch(getHashtagsInTravels({ travels: resTravels, all: true }));
// 			} else {
// 				dispatch(getHashtagsInTravels({ travels: resTravels }));
// 				dispatch(travelsReceived({ travels: resTravels, skip, hasMore: resTravels.length ? true : false }));

// 				// dispatch({ type: GET_TRAVELS_FOR_MAP, payload: resTravels, skip });
// 				// dispatch(checkLoading(skip, loading, false));
// 			}
// 		} catch ({ message }) {
// 			dispatch(travelsRequestFailed(message));
// 			// dispatch(showMessage({identifier: 'exploreTrips_MapPage', message: e.message }));
// 			// dispatch(checkLoading(skip, loading, false));
// 		}
// 	};
// };

// export const getTravelsWithSight = ({ sight, duration, limit, skip, loading, filteredHashtags, getHashtags }) => {
// 	return async dispatch => {
// 		try {
// 			dispatch(checkLoading(skip, loading, true));
// 			let daysString =
// 				duration && duration !== 0 && duration !== 'All'
// 					? ALLDAYS[duration].map(item => {
// 							return item.toString();
// 					  })
// 					: '';
// 			let objString = `{"route": {$elemMatch: {"sights": {$in: ${JSON.stringify([sight])}}}}, "difficulty": "moderate"${daysString && `, "daysNumber": {$in: ${JSON.stringify(daysString)}}`}${
// 				filteredHashtags.length ? `, "hashtags": {$all: ${JSON.stringify(filteredHashtags)}}` : ''
// 			}}`;
// 			let objStringFields = `&%24sort=${encodeURIComponent('{"rating": -1, "identifier": 1}')}&%24skip=${getHashtags ? 0 : skip}&%24limit=${getHashtags ? 1000 : limit}`;

// 			let { data: resTravels = [] } = await axios.get(`${URL_TRAVEL}${encodeURIComponent(objString)}${objStringFields}`);

// 			if (!resTravels.length) {
// 				dispatch(showMessage({ identifier: 'exploreTrips_MapPage', message: e.message }));
// 			}

// 			if (getHashtags) {
// 				dispatch(getHashtagsInTravels({ travels: resTravels, all: true }));
// 			} else {
// 				dispatch(getHashtagsInTravels({ travels: resTravels }));
// 				// dispatch({ type: GET_TRAVELS_FOR_MAP, payload: resTravels, skip });
// 				dispatch(travelsReceived({ travels: resTravels, skip, hasMore: resTravels.length ? true : false }));

// 				dispatch(checkLoading(skip, loading, false));
// 			}
// 		} catch (e) {
// 			dispatch(showMessage({ identifier: 'exploreTrips_MapPage', message: e.message }));
// 			dispatch(checkLoading(skip, loading, false));
// 		}
// 	};
// };

export const getAllHashtags = hashtags => {
	return async dispatch => {
		dispatch(hashtagsRequested());
		try {
			// dispatch(showLoader('hashtags_MapPage'));
			const limit = 800;
			let url = encodeURIComponent('{}') + `&$limit=${limit}`;
			let hashtagsArr = [];

			let { data: res } = await axios.get(`${URL_TRAVEL}${url}`);
			res.map(travel => {
				hashtagsArr = hashtagsArr.concat(travel.hashtags);
			});
			let uniqueHashtags = [...new Set(hashtagsArr)];
			hashtags.map(el => uniqueHashtags.filter((hashtag, index) => (hashtag == el ? uniqueHashtags.splice(index, 1) : false)));
			dispatch(hashtagsReceived(uniqueHashtags));
			// dispatch({ type: GET_MAP_HASHTAGS, payload: uniqueHashtags });
			// dispatch(hideLoader('hashtags_MapPage'));
		} catch ({ message }) {
			dispatch(hashtagsRequestFailed(message));
			// dispatch(showMessage({identifier: 'hashtags_MapPage', message: e.message }));
			// dispatch(hideLoader('hashtags_MapPage'));
			// console.log(new Error(`Error in get hashtags ${e}`));
		}
	};
};

// export const deleteTravels = () => {
// 	return {
// 		type: DELETE_TRAVELS_FOR_MAP,
// 		payload: [],
// 	};
// };

// export const deleteSights = () => {
// 	return {
// 		type: GET_SIGHTS_FOR_MAP,
// 		payload: [],
// 	};
// };

// export const deleteSight = () => {
// 	return {
// 		type: GET_SIGHT_FOR_MAP,
// 		payload: {},
// 	};
// };
