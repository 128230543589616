// import {
// 	DELETE_SIGHT_TRAVELINFO,
// 	PUT_SEARCH_LOCATION,
// 	PUT_SEARCH_DURATION,
// 	SET_SEARCH_INTENSITY,
// 	PUT_SEARCH_CONTROL,
// 	DELETE_SEARCH_LOCATION,
// 	CLEAR_SEARCH_LOCATION,
// 	SET_SEARCH
// } from '../../constants/ActionTypes';
// import { deepClone } from '../../helpers/_Object';
// import { openNotification } from '../../components/common/Notification/Notification';
import { createSlice } from '@reduxjs/toolkit';
import { tripControls } from '../../constants/Information';
import { openNotification } from './../../components/common/Notification/Notification';

// INITIALIZE STATE
const initialState = {
	location: {},
	duration: 'All',
	difficulty: 'moderate',
	errors: {},
};

// REDUCER
// export const SearchReducer = (state = initialState, action) => {
// 	switch (action.type) {
// 		case PUT_SEARCH_LOCATION:
// 			return {
// 				...state,
// 				item: action.payload
// 			};

// 		case DELETE_SIGHT_TRAVELINFO: {
// 			const {
// 				type,
// 				travel,
// 				day
// 			} = action.payload;
// 			return {
// 				...state,
// 				[type]: state[type].filter(el => el.data.label !== travel.identifier && el.day !== day )
// 			};
// 		}
// 		case PUT_SEARCH_CONTROL: {
// 			const { searchType, identifier, day, data, url = true } = action.payload;
// 			let newState = deepClone(state);
// 			if (newState[searchType]) {
// 				const index = newState[searchType].findIndex(item => item.identifier === identifier && item.day === day);
// 				if (index !== -1) {
// 					newState[searchType].splice(index, 1);
// 				}
// 			}
// 			if (newState[searchType] && state[searchType].length !== 0) {
// 				newState[searchType].push({ data, identifier, day });
// 			} else {
// 				newState[searchType] = [{ data, identifier, day }];
// 			}
// 			!url && openNotification('You have successfully added your starting point to your itinerary and your map.');
// 			return {
// 				...newState,
// 			};
// 		}
// 		case PUT_SEARCH_DURATION:
// 			return {
// 				...state,
// 				duration: action.payload
// 			};
// 		case CLEAR_SEARCH_LOCATION:
// 			return {
// 				...initialState
// 			};
// 		case SET_SEARCH_INTENSITY:
// 			return {
// 				...state,
// 				intensity: action.payload
// 			};
// 		case DELETE_SEARCH_LOCATION: {
// 			return {
// 				...state,
// 				item: action.payload,
// 			};
// 		}
// 		case SET_SEARCH:
// 			return {
// 				...action.payload,
// 				item: {}
// 			};
// 		default:
// 			return state;
// 	}
// };

const searchSlice = createSlice({
	name: 'Search',
	initialState,
	reducers: {
		putSearchLocation: (state, action) => {
			state.location = action.payload;
		},
		putSearchDuration: (state, action) => {
			state.duration = action.payload;
		},
		searchLocationRequestFailed: (state, action) => {
			state.errors.location = action.payload;
		},

		//controls
		searchAdded: (state, action) => {
			// console.log(action.payload, state)
			tripControls.forEach(({ control }) => {
				state[control] = action.payload[control];
			});
		},

		putSearchDifficulty: (state, action) => {
			state.difficulty = action.payload;
		},
		searchControlAdded: (state, action) => {
			//need to optimize in customize trip
			console.log(action.payload);
			const { controlType, identifier, difficulty, day, data, url = true } = action.payload;
			if (state[controlType]) {
				const index = state[controlType].findIndex(item => item.identifier === identifier && item.day === day && item.difficulty === difficulty);
				if (index !== -1) {
					state[controlType].splice(index, 1);
				}
			}
			if (state[controlType] && state[controlType].length !== 0) {
				state[controlType].push({ data, identifier, day, difficulty });
			} else {
				state[controlType] = [{ data, identifier, day, difficulty }];
			}
			!url && openNotification('You have successfully added your starting point to your itinerary and your map.');
		},
		searchControlDeleted: (state, action) => {
			const { type, day, identifier, difficulty } = action.payload;
			console.log(action.payload)
			state[type] = state[type].filter(el => !(el.data.label === identifier && el.day === day && el.difficulty === difficulty));
		},
		searchLocationDeleted: state => {
			state.location = {};
		},
	},
});

const { actions, reducer: SearchReducer } = searchSlice;

export const { searchAdded, putSearchLocation, putSearchDuration, searchLocationRequestFailed, putSearchDifficulty, searchControlAdded, searchControlDeleted, searchLocationDeleted } = actions;

export { SearchReducer };

export const getSearchInfo = () => state => state.Search;
export const getControl = control => state => state.Search[control] || [];
