import React from 'react';
import {Tag} from 'antd';
import { Link } from 'react-router-dom';

const Badge = ({className, intensity, pathname = '', type, onClick}) => {
	return (
		<Link
			to={pathname} >
				<Tag
					color={type}
					onClick={onClick || null}
					className={className}
				>
					{intensity}
				</Tag>
        </Link>
	);
};
 
export default Badge;