import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthActions from '../../../state/actions/AuthActions';
import * as HelperActions from '../../../state/actions/HelperActions';
import { Form, Input, Button, Modal, Typography, Alert } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
const { Title } = Typography;

const ResetFormPage = ({  message = '', handleResetPassword, actions, helperActions }) => {
	// const { getFieldDecorator, validateFields } = form;
	const dispatch = useDispatch();
	const handleSubmit = values => {
		console.log(values)
		// e.preventDefault();
		// validateFields((err, values) => {
		// 	if (!err) {
		// 		console.log('Received values of form: ', values);
		// 		actions.resetPassword(values.email);
		// 	}
		// });
	};

	const handleReset = () => {
		handleResetPassword(false);
		// dispatch(hideMessage('reset'));
	}

	function info() {
		Modal.info({
			icon: null,
			title: <Title level={2}>Check your email</Title>,
			content: (
				<>
					<p>OK, we just sent you an email message. Inside that message is a link you can use to change your password.</p>
					<p>The message should show up within a few seconds. If it doesn't, check your spam or junk mail folder.</p>
				</>
			),
			onOk() {
				handleReset();
			},
		});
	}
	return (
		<>
			<Form onSubmit={handleSubmit} autoComplete className='reset-form'>
				<Form.Item className='reset-item'>
					{/* {getFieldDecorator('email', {
						rules: [{ required: true, message: 'Please input your username!' }],
					})( */}
					<Input prefix={<UserOutlined />} placeholder='Email' />
					{/* )} */}
				</Form.Item>
				<Button htmlType='submit' className='reset-form__btn'>
					Send link
				</Button>
				<div className='reset-info'>
					<Link onClick={handleReset}>Take me back to login</Link>
					<Link to='/registration'>I need to sign up</Link>
				</div>
			</Form>
			{message === 'success' && info()}
			{message && message !== 'success' && <Alert message={message} type='error' />}
		</>
	);
};

export default (ResetFormPage);
