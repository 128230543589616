import React, {memo} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import MapTripCard from './MapTripCard';
import { Loader } from '../../common/Loader/Loader';

const MapTrips = ({ travels, hasMore, fetchMore, filteredHashtags = [], ...rest }) => {
	return (
		<>
			<InfiniteScroll
				next={() => fetchMore()}
				hasMore={hasMore}
				loader={<Loader />}
				dataLength={travels.length}
				className='explore-infinite-scroll'
				style={{ overflowX: 'hidden' }}
				height={'64vh'}
				endMessage={
					<p style={{ textAlign: 'center', marginBottom: '3em' }}>
						<b>You have seen it all.</b>
					</p>
				}>
				{travels.map(travel => (
					<MapTripCard filteredHashtags={filteredHashtags} travel={travel} key={travel.identifier} {...rest} />
				))}
			</InfiniteScroll>
		</>
	);
};

export default memo(MapTrips);
