import { travelReceived, travelsReceived, travelRequested, sightsReceived, routesReceived, sightsRequestFailed, travelRequestFailed } from '../reducers/TravelReducer';
import travelService from './../../services/travel.service';
import sightService from './../../services/sight.service';
import { getRouteDay } from '../../helpers/_Functions';

export const loadTravel = (identifier, difficulty) => async (dispatch) => {
	dispatch(travelRequested());
	try{
		const params = {filter: {identifier}};
		console.log(params)
		const travels = await travelService.get(params);
		const travel = travels.find(travel => travel.identifier === identifier && travel.difficulty === difficulty);
		dispatch(travelReceived(travel));
		dispatch(travelsReceived(travels));
		dispatch(loadSights(travel.route));
	} catch({message}){
		dispatch(travelRequestFailed(message));
	}
};

const loadSights = route => async dispatch => {
	try{
		const sightsArr = route.map(elem => elem.sights).flat();
		const uniqueSights = [...new Set(sightsArr)];
		const params = { sights: uniqueSights, limit: uniqueSights.length };
		const sights = await sightService.getSightsForTravel(params);
		const routes = await getRouteDay(route, sights);
		dispatch(routesReceived(routes));
		dispatch(sightsReceived(sights));
	} catch(e){
		dispatch(sightsRequestFailed());
	}
};

