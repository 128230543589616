import React from 'react';
import { Checkbox, Button, Tooltip, Popover } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getUserTrip } from '../../../../state/reducers/UserReducer';
import { saveTrip } from './../../../../state/actions/UserActions';

const Settings = () => {
	const { identifier, difficulty, daysNumber } = useSelector(state => state.CustomizeTripPage.travel);
	const { travel, routes } = useSelector(state => state.CustomizeTripPage);
	const Search = useSelector(state => state.Search);
	const { settings } = useSelector(getUserTrip(identifier, difficulty, daysNumber));
	const dispatch = useDispatch();
	const onChangeSettingsSave = e => {
		dispatch(saveTrip({ travel, routes, Search, settings: { autoSave: e.target.checked } }, 'Trip Settings Saved!'));
	};

	const SettingsContentJSX = (
		<div>
			<Checkbox checked={settings?.autoSave} onChange={onChangeSettingsSave}>
				Auto save trip
			</Checkbox>
		</div>
	);

	return (
		<Tooltip title='Trip settings'>
			<Popover content={SettingsContentJSX} trigger='click'>
				<Button className='btn-settings ' icon={<SettingOutlined size={35} />} />
			</Popover>
		</Tooltip>
	);
};

export default Settings;
