import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { animateScroll as scroll } from 'react-scroll';
import './TravelsContainer.sass';
import Header from '../../components/ui/Header/Header';
import TravelsContent from '../../components/pages/TravelsPage/TravelsContent';
import PageHelmet from './../../components/ui/hoc/PageHelmet';
import { useParams, useLocation } from 'react-router-dom';
import { checkLatLong } from './../../helpers/_Functions';
import { getTravels } from './../../state/actions/TravelsActions';
import { loadSearchLocationMapBox } from './../../state/actions/SearchAction';
import { putSearchDuration, searchLocationDeleted } from '../../state/reducers/SearchReducer';

const TravelsContainer = () => {
	const dispatch = useDispatch();
	const { difficulty, duration, location: { label = '' } = {} } = useSelector(state => state.Search);
	const { days, identifier, location } = useParams();
	const params = useParams();
	const { hash } = useLocation();
	console.log(days, identifier, location, params)
	let { lat, long } = checkLatLong(identifier);

	useEffect(() => {
		if (identifier && identifier.includes(';')) {
			if (lat && long) {
				dispatch(getTravels({ lat, long, days, hash, difficulty }));
			}
		} else {
			dispatch(getTravels({ days, hash, difficulty }));
			if (label) {
				dispatch(searchLocationDeleted());
			}
		}
	}, [identifier, hash, days, difficulty]);

	useEffect(() => {
		if (label.replace(/ /g, '+').replace(/,\+/g, ';+') !== location) {
			if (identifier && identifier.includes(';')) {
				if (lat && long) {
					dispatch(loadSearchLocationMapBox(lat, long));
				}
			}
		}
	}, [identifier]);

	useEffect(() => {
		if (days !== duration) {
			dispatch(putSearchDuration(days));
		}
	}, [days]);

	useEffect(() => {
		scroll.scrollToTop(); //doesn't work, need to fix
	}, []);

	let title = label ? ` Best Trips Plans to ${label}` : 'Find the Best Recommended Trips - Browse 1000+ Itineraries.';
	let description = label ? `Explore Best Trips Plans to ${label}` : 'Explore the Best Itineraries to Popular Destinations - by Location, by Duration, by Recommendations, in a List of Trips.';

	return (
		<div className='travels'>
			<PageHelmet title={title} description={description}>
				<Header />
				<TravelsContent />
			</PageHelmet>
		</div>
	);
};

export default TravelsContainer;
