import { createSlice } from '@reduxjs/toolkit';

// INITIALIZE STATE
const initialState = {
	info: {},
	success: null,
    loading: true
};

//REDUCER
// export const ReviewReducer = (state = initialState, action) => {
//     switch(action.type) {
//         case GET_REVIEWS: {
//             return {
// 				...state,
//                 success: null,
// 				info: action.payload
// 			};
//         }

//         case SET_REVIEW: {
//             return {
//                 ...state,
//                 success: action.payload
//             };
//         }
//         default:
//             return state;
//     }
// };

const ReviewSlice = createSlice({
	name: 'Reviews',
	initialState,
	reducers: {
		reviewsRequested: state => {
			state.loading = true;
		},
		reviewsReceived: (state, action) => {
			state.info = action.payload;
			state.loading = false;
			state.error = null;
            state.success = null;
		},
		reviewsRequestFailed: (state, action) => {
			state.loading = false;
            state.success = null;
			state.error = action.payload;
		},
		setReview: (state, action) => {
            state.loading = true;
	    	state.success = action.payload;

		},
	},
});

const { actions, reducer: ReviewReducer } = ReviewSlice;

export const { reviewsRequested, reviewsReceived, reviewsRequestFailed, setReview } = actions;

export { ReviewReducer };
