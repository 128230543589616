import React, { Fragment, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Divider, Button, Popover } from 'antd';
import './Header.sass';
import BurgerMenu from './BurgerMenu';
import HeaderSearch from '../Search/HeaderSearch';
import Logo from '../../../img/logo.svg';
import { UserOutlined } from '@ant-design/icons';
import { logOut } from './../../../state/actions/AuthActions';

const Header = () => {
	const { auth, isLoggedIn } = useSelector(state => state.UserPage);
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const [menuOpen, setMenuOpen] = useState(false);

	const handleStateChange = state => {
		setMenuOpen(state.isOpen);
	};

	const toggleMenu = () => {
		setMenuOpen(prev => !prev);
	};

	const navigate = useNavigate();
	const handleLogOut = () => dispatch(logOut());

	const content = isLoggedIn ? (
		<div className='login-content'>
			<Link to={`/user/${auth.userId}`}>Profile</Link>
			<Link to={`/user/${auth.userId}/trips`}>Saved Trips</Link>
			<Link to={`/user/${auth.userId}/favorites`}>Wish List</Link>
			<Link to={`/user/${auth.userId}/hotels`}>Hotels</Link>
			<Divider  />
			<Link to={`/user/${auth.userId}/settings`}>Settings</Link>
			<Link to='/login' onClick={handleLogOut}>SIGN OUT</Link>
		</div>
	) : (
		<div className='login-content'>
			<Link to='/login'>SIGN UP</Link>
			<Link to='/registration'>CREATE AN ACCOUNT</Link>
		</div>
	);

	return (
		<>
			<Row className='header-container'>
				<Col className='header-content__logo' xs={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }} md={{ span: 6, order: 2 }} lg={{ span: 8, order: 2 }}>
					<Link to='/'>
						<img className='header-logo' src={Logo} alt='before.voyage' />
					</Link>
				</Col>
				<Col className='header-link_row' md={{ span: 8, order: 1 }} lg={{ span: 8, order: 1 }}>
					<div>
						<Link to='/'>Home</Link>
					</div>
					<div>
						<Link to='/trips/All/'>Trips</Link>
					</div>
					<div>
						<Link to='/map/itineraries/'>Map</Link>
					</div>
				</Col>
				<Col className='header-content__search' xs={{ span: 13, order: 2 }} sm={{ span: 7, order: 3 }} md={{ span: 8, order: 3 }} lg={{ span: 7, order: 3 }}>
					<HeaderSearch />
				</Col>
				<Col className='header-content__account' md={{ span: 2, order: 4 }} lg={{ span: 1, order: 4 }}>
					<Popover content={content} placement="topRight" >
						<Button shape='circle' icon={<UserOutlined />} onClick={() => (isLoggedIn ? navigate(`/user/${auth?.userId}/`) : navigate('/login'))} />
					</Popover>
					{/* <UserOutlined onClick={() => (isLoggedIn ? navigate(`/user/${auth?.userId}/`) : navigate('/login'))} /> */}
				</Col>
				<Col className='header-burger-menu' xs={{ span: 3, order: 3 }} sm={{ span: 3, order: 3 }}>
					<BurgerMenu
						pageWrapId='page-wrap'
						menuOpen={menuOpen}
						toggleMenu={toggleMenu}
						handleStateChange={handleStateChange}
						burgerMenuClass='burger-menu burger-menu__header'
						pathname={pathname}
						auth={auth}
						isLoggedIn={isLoggedIn}
					/>
				</Col>
			</Row>
		</>
	);
};

export default Header;
