import localStorageService from '../../services/localStorage.service';
// import {
import { createSlice } from '@reduxjs/toolkit';

// 	AUTH_REQUEST_SUCCESS,
// 	GET_USER_INFO,
// 	USER_LOG_OUT,
// 	GET_USER_FAVORITES,
// 	UPDATE_USER_TRIP,
// 	GET_USER_TRIPS,
// 	DELETE_USER_TRIP,
// 	ADD_USER_TRIP,
// 	ADD_USER_COUNTRY,
// 	ADD_USER_LOCATION
// } from './../../constants/ActionTypes';

// // INITIALIZE STATE
// const initialState = localStorageService.getAccessToken()
// 	? {
// 			user: null,
// 			auth: { userId: localStorageService.getUserId() },
// 			isLoggedIn: true,
// 			trips: [],
// 			favoritesList: [],
// 	  }
// 	: {
// 			user: null,
// 			auth: null,
// 			isLoggedIn: false,
// 	  };

// // REDUCER
// export const UserReducer = (state = initialState, action) => {
// 	switch (action.type) {
// 		case AUTH_REQUEST_SUCCESS:
// 			return {
// 				...state,
// 				auth: action.payload,
// 				isLoggedIn: true,
// 			};
// 		case USER_LOG_OUT:
// 			return {
// 				...state,
// 				user: null,
// 				isLoggedIn: false,
// 				auth: null,
// 			};
// 		case GET_USER_INFO:
// 			return {
// 				...state,
// 				user: action.payload,
// 			};

// 		case GET_USER_FAVORITES:
// 			return {
// 				...state,
// 				favoritesList: action.payload,
// 			};

// 		case GET_USER_TRIPS:
// 			return {
// 				...state,
// 				trips: action.payload,
// 			};
// 		case ADD_USER_TRIP:
// 			return {
// 				...state,
// 				trips: [...state.trips, action.payload],
// 			};
// 		case DELETE_USER_TRIP:
// 			return {
// 				...state,
// 				trips: action.payload,
// 			};
// 		case UPDATE_USER_TRIP:
// 			return {
// 				...state,
// 				trips: state.trips.map(content =>
// 					content.travel.identifier === action.payload.travel.identifier && content.travel.difficulty === action.payload.travel.difficulty ? action.payload : content
// 				),
// 			};
// 		case ADD_USER_COUNTRY:
// 			return {
// 				...state,
// 				user: { ...state.user, country: action.payload },
// 			};
// 		case ADD_USER_LOCATION:
// 			return {
// 				...state,
// 				user: {...state.user, location: action.payload}
// 			}
// 		default:
// 			return state;
// 	}
// };
// INITIALIZE STATE
const initialState = localStorageService.getAccessToken()
	? {
			user: null,
			auth: { userId: localStorageService.getUserId() },
			isLoggedIn: true,
			trips: [],
			hotels: [],
			favoritesList: [],
			loading: {},
			errors: {},
	  }
	: {
			user: null,
			auth: null,
			isLoggedIn: false,
			loading: {},
			errors: {},
			message: null,
	  };

const userSlice = createSlice({
	name: 'UserPage',
	initialState,
	reducers: {
		authRequested: state => {
			state.loading.auth = true;
		},
		authRequestSuccess: (state, action) => {
			state.auth = action.payload;
			state.loading.auth = false;
			state.isLoggedIn = true;
			state.message = null;
		},
		authRequestFailed: (state, action) => {
			state.loading.auth = false;
			state.message = action.payload;
		},

		createUserRequested: state => {
			state.loading.createUser = true;
		},
		userCreated: state => {
			state.loading.createUser = false;
		},
		createUserFailed: (state, action) => {
			state.errors.createUser = action.payload;
		},
		userRequested: state => {
			state.loading.user = true;
		},
		userReceived: (state, action) => {
			state.user = action.payload;
			// state.loading.user = false;
			state.errors.user = null;
		},
		userRequestFailed: (state, action) => {
			state.loading.user = false;
			state.errors.user = action.payload;
		},

		userTripsRequested: state => {
			state.loading.trips = true;
		},
		userTripsReceived: (state, action) => {
			state.trips = action.payload;
			state.loading.trips = false;
			state.loading.user = false;
		},
		userTripsRequestFailed: (state, action) => {
			state.loading.trips = false;
			state.errors.trips = action.payload;
		},
		userLocationAdded: (state, action) => {
			state.user.location = action.payload;
		},
		userCountryAdded: (state, action) => {
			state.user.country = action.payload;
		},
		userFavoritesReceived: (state, action) => {
			// state.favoritesList = action.payload;
			state.user = { ...state.user, favorites: action.payload };
		},
		userFavoritesFailed: (state, action) => {
			state.errors.favorites = action.payload;
		},
		userFavoriteTravelsReceived: (state, action) => {
			state.favoritesList = action.payload;
		},
		userTripAdded: (state, action) => {
			state.trips = [...state.trips, action.payload];
		},
		userTripUpdated: (state, action) => {
			const { travel: trip } = action.payload;
			// state.trips = state.trips.map(({travel}, content) => console.log(content, travel));

			state.trips = state.trips.map(content => (content.travel.identifier === trip.identifier && content.travel.difficulty === trip.difficulty ? action.payload : content));
		},
		userTripDeleted: (state, action) => {
			state.trips = state.trips.filter(trip => trip._id !== action.payload);
		},
		userHotelAdded: (state, action) => {
			state.user.hotels = action.payload;
		},
		passwordResetRequested: state => {
			state.loading.reset = true;
		},
		passwordReset: state => {
			state.reset = true;
			state.loading.reset = false;
			state.errors.reset = null;
		},
		passwordResetFailed: (state, action) => {
			state.loading.reset = false;
			state.errors.reset = action.payload;
		},
		userLoggedOut: state => {
			state.user = null;
			state.isLoggedIn = false;
			state.auth = null;
			state.loading = {};
			state.errors = {};
		},
		hideMessage: state => {
			state.message = null;
		},
		userInfoUpdated: (state, action) => {
			state.user = { ...state.user, ...action.payload };
		},
	},
});

const { reducer: UserReducer, actions } = userSlice;

export const {
	authRequested,
	authRequestSuccess,
	authRequestFailed,
	createUserRequested,
	userCreated,
	createUserFailed,
	userRequested,
	userReceived,
	userRequestFailed,
	userTripsRequested,
	userTripsReceived,
	userTripsRequestFailed,
	userFavoritesReceived,
	userFavoritesFailed,
	userFavoriteTravelsReceived,
	userTripAdded,
	userTripUpdated,
	userTripDeleted,
	userLocationAdded,
	userCountryAdded,
	userHotelAdded,
	passwordResetRequested,
	passwordReset,
	passwordResetFailed,
	userLoggedOut,
	hideMessage,
	userInfoUpdated,
} = actions;
export { UserReducer };

export const tripExist = (identifier, difficulty) => state => state.UserPage.trips.findIndex(({ travel }) => travel.identifier === identifier && travel.difficulty === difficulty);
export const getUserTrip = (identifier, difficulty, days) => state => state.UserPage.trips.find(({ travel }) => travel.identifier === identifier && travel.difficulty === difficulty && travel.daysNumber === days) || {};
export const getTripById = (id) => state => state.UserPage.trips.find(({userId}) => userId === id) || {};