import React, { Fragment, useState, memo } from 'react';
import { Button, Row, Col, Tooltip } from 'antd';
import { animateScroll as scroll, scroller } from 'react-scroll';

import { getDistance } from '../../../helpers/_Functions';
import { GetIcon } from './../../../Helper';
import { useDispatch } from 'react-redux';
// import { deleteMoreSightsNear, loadMoreSightsNear } from './../../../state/actions/TravelInfoActions';
import { loadMoreSightsNear, deleteMoreSightsNear } from './../../../state/actions/CustomizeTripActions';

const TravelControls = props => {
	const controls = ['hotel', 'airport', 'car', 'start'];
	const { day, identifier = '', travel = {}, routes = [], sightsNear = [], sights = [], travelControls = {}, width, updateTravelControls, handleChangeTab } = props;

	const dispatch = useDispatch();

	const toggleSightsInfo = () => {
		const index = sightsNear && sightsNear.findIndex(item => item.identifier === identifier && item.day === day) !== -1;
		if (index) {
			dispatch(deleteMoreSightsNear({ day, identifier }));
		} else {
			let first = {};
			let last = {};
			let distance = 0;
			routes[day - 1].sights.forEach((sightIdentifier, i, arr) => {
				if (!arr[i - 1]) {
					first = sights.find(sight => sight.identifier === sightIdentifier);
				} else {
					const sight = sights.find(item => item.identifier === sightIdentifier);
					if (sight) {
						const newDist = getDistance(sight.location[0], sight.location[1], first.location[0], first.location[1]);
						if (newDist > distance) {
							distance = newDist;
							last = sight;
						}
					}
				}
			});
			dispatch(loadMoreSightsNear({ day, identifier, long: (first.location[0] + last.location[0]) / 2, lat: (first.location[1] + last.location[1]) / 2, distance: distance / 2 + 10000 }));
			// actions.getMoreSightsNear({day, identifier, long: (first.location[0] + last.location[0]) / 2 , lat: (first.location[1] + last.location[1]) / 2, distance: (distance / 2 + 10000) });
		}
	};

	const scrollTo = control =>
		day === '1' && travel.daysNumber === day ? scroll.scrollToBottom({
			duration: 1500,
			smooth: true,
			containerId: 'travel-sights',
		}) : control !== 'hotel' && day === '1' ? scroll.scrollToTop({
			duration: 1500,
			smooth: true,
			containerId: 'travel-sights',
		}) : scroll.scrollToBottom({
			duration: 1500,
			smooth: true,
			containerId: 'travel-sights',
		});

	const handleClickControl = control => {
		!travelControls?.[control]?.includes(day) && updateTravelControls(control === 'start' && day === '1' ? { [control]: day } : { [control]: day });
		travel.daysNumber === day && !travelControls?.finish?.includes(day) && control === 'start' && updateTravelControls({ finish: travel.daysNumber });
		scrollTo(control);
	};
	return (
		<>
			{Number(day) === 1 || travel.daysNumber === day ? (
				<>
					{controls.map(control => (
						<Col key={control} className={travelControls[control] ? 'travel-controls__button active' : 'travel-controls__button'}>
							<Tooltip
								title={
									control === 'hotel' ? 'Add an accommodation to your itinerary.' : control === 'airport' ? 'Add an airport to your itinerary.' : control === 'car' ? 'Add a car rent office to your itinerary.' : 'Add a starting point of your ground trip to the itinerary.'
								}
								trigger={width < 769 ? 'click' : 'hover'}
							>
								<Button
									onClick={() => {
										handleClickControl(control);
										// !travelControls?.[control]?.includes(day) && updateTravelControls(control === 'start' && day == 1 ? { [control]: day } : { [control]: day });
										// travel.daysNumber === day && !travelControls?.finish?.includes(day) && control === 'start' && updateTravelControls({ finish: travel.daysNumber });
										// scrollTo();
										// scroll.scrollTo((day === '1' && travel.daysNumber === day) ? 'modify-btns--bottom' : control !== 'hotel' && day === '1'  ? 'modify-btns--top' : 'modify-btns--bottom', {
										// 	duration: 1500,
										// 	smooth: true,
										// 	containerId: 'travel-sights',
										// });

										// width < 769 ? (menuClick('List'), setTab('List'), showSights && (showSightsInfo(), setShowSights(false))) : false;
									}}>
									<GetIcon src={`${control === 'start' ? 'map-location' : control}.png`} className='travel-controls__button-img' />
									<span className='travel-controls__button-text'>
										{control === 'car' ? 'Car rent' : control === 'start' ? 'Start/Finish' : control[0].toUpperCase() + control.slice(1)}{' '}
									</span>
								</Button>
							</Tooltip>
						</Col>
					))}
					<Col className='travel-controls__button'>
						<Tooltip title='Add more sights to your itinerary.' trigger={width < 769 ? 'click' : 'hover'}>
							<Button
								onClick={() => {
									toggleSightsInfo();
									width < 769 && handleChangeTab('Map');
								}}>
								<GetIcon src='meeting-point.png' className='travel-controls__button-img' />
								<span className='travel-controls__button-text'>More sights</span>
							</Button>
						</Tooltip>
					</Col>
				</>
			) : (
				<>
					<Col className={travelControls.hotel ? 'travel-controls__button active' : 'travel-controls__button'}>
						<Tooltip title='Add an accommodation to your itinerary.' trigger={width < 769 ? 'click' : 'hover'}>
							<Button
								onClick={() => {
									scroll.scrollToBottom({
										duration: 1500,
										smooth: true,
										containerId: 'travel-sights',
									});
									!travelControls?.hotel?.includes(day) && updateTravelControls({ hotel: day });
								}}>
								<GetIcon src='hotel.png' className='travel-controls__button-img' />
								<span className='travel-controls__button-text'>Hotel</span>
							</Button>
						</Tooltip>
					</Col>
					<Col className='travel-controls__button'>
						<Tooltip title='Add more sights to your itinerary.' destroyTooltipOnHide={true} trigger={width < 769 ? 'click' : 'hover'}>
							<Button
								onClick={() => {
									toggleSightsInfo();
									width < 769 && handleChangeTab('Map');
								}}>
								<GetIcon src='meeting-point.png' className='travel-controls__button-img' />
								<span className='travel-controls__button-text'>More sights</span>
							</Button>
						</Tooltip>
					</Col>
				</>
			)}
		</>
	);
};

export default memo(TravelControls);
