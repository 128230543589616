import React from 'react';
import { Col, Tag, Icon } from 'antd';
import { StarFilled } from '@ant-design/icons';

const RatingFilter = ({ rating = 'all', handleChangeFilters }) => {
	const handleChange = value => {
		handleChangeFilters('rating', value);
	};

	const ratingValues = {
		2: '<2',
		3: '3',
		4: '4',
		5: '5',
	};

	return (
		<Col className='user-trips__filters-rating'>
			<span className='user-trips__filters-subtitle'>Rating</span>
			<Col className='user-trips__filters-rating__container'>
				{Object.keys(ratingValues).map(rate => (
					<Tag className={rate === rating ? 'user-trips__filters-rating__rate--active' : 'user-trips__filters-rating__rate'} key={rate} onClick={() => handleChange(rate)}>
						<StarFilled style={{ color: '#f7f152' }} /> {ratingValues[rate]}
					</Tag>
				))}
				<Tag className={rating === 'all' ? 'user-trips__filters-rating__rate--active' : 'user-trips__filters-rating__rate'} onClick={() => handleChange('all')}>
					All
				</Tag>
			</Col>
		</Col>
	);
};

export default RatingFilter;
