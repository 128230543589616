import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography } from 'antd';
import { GetIcon } from '../../../Helper';

const { Title, Text } = Typography;

const DayByDay = ({ travel }) => {
	return (
		<div className='overview-daysplan'>
			<Row>
				<Title level={2} className='overview-title'>
					A day-by-day itinerary + a day-by-day roadmap will help you to plan your trip in detail
				</Title>
			</Row>
			<Row className='overview-daysplan__content'>
				<Text className='overview-text'>Click on the link below and explore a detailed plan suggested by other travelers. It is a day-by-day itinerary including a day-by-day roadmap.</Text>
				<Row className='overview-daysplan__btn'>
					<Link to={`/trip/${travel.identifier}/${travel.difficulty}/day/1`} className='overview-btn__day'>
						<Text>Day-by-day itinerary</Text>
						<GetIcon src='arrow-right.png' className='arrow-right' alt='arrow-right' />
					</Link>
				</Row>
				<Col className='overview-text'>
					<GetIcon src='arrow-checked.png' className='arrow-checked' alt='arrow-checked' />
					<Text>Modify it to your liking if you wish. Add airports, hotels, car rents, more sights on the way from the airport, etc.</Text>
				</Col>
				<Col className='overview-text'>
					<GetIcon src='arrow-checked.png' className='arrow-checked' alt='arrow-checked' />
					<Text>Get your own personal travel plan.</Text>
				</Col>
			</Row>
		</div>
	);
};

export default DayByDay;
