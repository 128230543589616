import React from 'react';
import { Modal, Col, Row, Typography, Button } from 'antd';
import { openNotification } from '../../../common/Notification/Notification';

const TravelModal = ({ shareUrl, isModalVisible, onChangeVisible }) => {
	return (
		<Modal
			open={isModalVisible}
			onCancel={() => onChangeVisible(false)}
			footer={[
				<Button key='submit' type='primary' onClick={() => onChangeVisible(false)}>
					Done
				</Button>,
			]}>
			<Typography.Title level={4} className='link-title'>
				Get link
			</Typography.Title>
			<Row className='link-share'>
				<Col className='link-share__url'>{shareUrl}</Col>
				<Button
					onClick={() => {
						openNotification('Link copied', 'success', 'topRight');
						navigator.clipboard.writeText(shareUrl);
					}}>
					Copy link
				</Button>
			</Row>
		</Modal>
	);
};

export default TravelModal;
