import React, { useState, useEffect, memo, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import SearchLocation from '../../../ui/Search/SearchLocation';
import { GetIcon } from '../../../../Helper';
import { Row, Col, Button, Typography } from 'antd';
import { checkControlExisting, distanceMi } from '../../../../helpers/_Functions';
import { getControl, searchControlAdded } from '../../../../state/reducers/SearchReducer';
import { deleteSight } from '../../../../state/actions/CustomizeTripActions';
import { deleteSearchControl } from './../../../../state/actions/SearchAction';
import { addUserHotel } from './../../../../state/actions/UserActions';
import { getUserTrip } from '../../../../state/reducers/UserReducer';
const { Text } = Typography;
const TravelModifyHotel = props => {
	const { travel: { daysNumber, identifier, difficulty, stay, route, title } = {}, routes = [], sights = [], day = '', updateTravelControls, addToSights } = props;
	const dispatch = useDispatch();
	const trip = useSelector(getUserTrip(identifier, difficulty, daysNumber));
	console.log(trip);
	const { hotel = [] } = useSelector(state => state.Search);
	const Search = useSelector(state => state.Search);

	const [hotelInfo, setHotelInfo] = useState('');
	const [showHotel, setShowHotel] = useState(false);
	const [hotelsStay, setHotelsStay] = useState([]);

	const currHotel = useMemo(() => hotel.length && hotel.find(item => item.identifier === identifier && item.day === day && item.difficulty === difficulty), [hotel, day]);
	const index =
		day === daysNumber
			? checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'car', Search) && checkControlExisting(day, 'finish', Search)
				? routes[day - 1].sights.length - 3
				: (checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'finish', Search)) ||
				  (checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'car', Search)) ||
				  (checkControlExisting(day, 'car', Search) && checkControlExisting(day, 'finish', Search))
				? routes[day - 1].sights.length - 2
				: checkControlExisting(day, 'airport', Search) || checkControlExisting(day, 'finish', Search) || checkControlExisting(day, 'car', Search)
				? routes[day - 1].sights.length - 1
				: routes[day - 1].sights.length
			: routes[day - 1].sights.length;

	const lastSight = useMemo(() => sights.find(sight => sight.identifier === route[day - 1].sights[route[day - 1].sights.length - 1]), [sights, route]);

	const addHotel = hotel => {
		dispatch(searchControlAdded({ data: { label: hotel.label, text: hotel.text, x: hotel.x, y: hotel.y }, controlType: 'hotel', identifier, day, difficulty }));
	};

	const deleteHotel = () => {
		dispatch(deleteSight({ identifier: hotelInfo.label, day }));
		dispatch(deleteSearchControl({ type: 'hotel', identifier: hotelInfo.label, day, difficulty }));
		dispatch(addUserHotel({ hotel: { title: hotelInfo.label, day }, identifier, difficulty }));
		setHotelInfo('');
		setShowHotel(false);
	};

	useEffect(() => {
		if (hotel.length) {
			if (currHotel) {
				if (!sights.find(el => el.title === currHotel.data.label && el.day === day)) {
					setHotelInfo(currHotel.data);
					setShowHotel(true);
					addToSights({ obj: currHotel, type: 'hotel', icon: 'fa-hotel', index });

					dispatch(
						addUserHotel({
							identifier,
							difficulty,
							title,
							daysNumber,
							hotel: { title: currHotel.data.label, day: currHotel.day, location: [currHotel.data.x, currHotel.data.y] },
						}),
					);
				}
			}
		}
	}, [hotel]);

	useEffect(() => {
		if (hotel.length && !hotel.find(el => el.day === day)) {
			setHotelInfo('');
			setShowHotel(false);
		} else {
			if (currHotel) {
				setHotelInfo(currHotel.data);
				setShowHotel(true);
			}
		}

		if (day && stay.length) {
			setHotelsStay([]);
			stay.map(hotels => {
				hotels.map(hotel => {
					if (hotel.convenientForNights.includes('-')) {
						const dayFirst = hotel.convenientForNights.split('-')[0];
						const dayLast = hotel.convenientForNights.split('-')[1];
						if (dayFirst <= day && day <= dayLast) {
							setHotelsStay(hotelsStay => [...hotelsStay, hotel]);
						}
					} else if (day == hotel.convenientForNights) {
						setHotelsStay(hotelsStay => [...hotelsStay, hotel]);
					}
				});
			});
		}
	}, [day]);

	return (
		<>
			<Row className='modify-btns'>
				<Col lg={4} xs={6} className='modify-icon'>
					<GetIcon src='hotel.png' />
					<Text className='modify-title'>Hotel </Text>
				</Col>
				<Col lg={20} xs={18} className='modify-text'>
					{!showHotel &&
						hotelsStay.map((el, key) => (
							<Button className={'addOptionModify'} key={key} onClick={() => addHotel({ text: el.title, label: el.title, x: el.location[0], y: el.location[1] })}>
								{el.title}
							</Button>
						))}

					{!showHotel && (
						<div style={{ padding: '0' }}>
							<SearchLocation controlType='hotel' />
						</div>
					)}

					{showHotel && hotelInfo && (
						<Col lg={20} xs={20}>
							<h3 className={'modifyInfo'}>{hotelInfo.text}</h3>
							<a href={`https://www.google.com/maps/place/${hotelInfo.y},${hotelInfo.x}`} className='modify-link' target='_blank' rel='nofollow noopener noreferrer'>
								<span className='material-icons modify-link__marker'>place</span>
								<Text className='modify-link__text'>
									{hotelInfo.y}, {hotelInfo.x}
								</Text>
							</a>
							<span className='modify-distance'>
								<b>
									~ {distanceMi([hotelInfo.x, hotelInfo.y], lastSight.location)} mi/ ~ {Math.round(distanceMi([hotelInfo.x, hotelInfo.y], lastSight.location) * 1.609)} km
								</b>
								to {lastSight.title}, the last sight of Day {day}
							</span>
						</Col>
					)}

					{showHotel && hotelInfo && (
						<Col lg={4} xs={4} onClick={deleteHotel} aria-hidden='true' className={'closeModify'}>
							<CloseOutlined />
						</Col>
					)}

					{!showHotel && (
						<Col
							lg={4}
							xs={4}
							onClick={() => {
								updateTravelControls({ hotel: day });
							}}
							aria-hidden='true'
							className={'closeModify'}>
							<CloseOutlined />
						</Col>
					)}
				</Col>
			</Row>
		</>
	);
};

export default memo(TravelModifyHotel);
