import React from 'react';
import { Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../../state/actions/UserActions';

const Settings = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		user,
		auth: { userId },
	} = useSelector(state => state.UserPage);
	const handleSubmit = values => {
		console.log(values);
		navigate(`/user/${userId}/`);
		dispatch(updateUser(values));
	};

	return (
		<Form onFinish={handleSubmit} className='login-form'>
			<Form.Item label='Name' name='name' initialValue={user.name}>
				<Input initialvalue={user.name} />
			</Form.Item>
			<Form.Item
				label='Email'
				name='email'
				initialValue={user.email}
				rules={[
					{
						type: 'email',
						message: 'Invalid email address! If you do not need to send an email. Delete characters',
					},
					{
						required: true,
					},
				]}>
				<Input initialvalue={user.email} />
			</Form.Item>
			{user.country && (
				<Form.Item label='Country' name='country' initialValue={user.country}>
					<Input initialvalue={user.country} />
				</Form.Item>
			)}
			<Form.Item>
				<Button type='primary' htmlType='submit'>
					Submit
				</Button>
			</Form.Item>
		</Form>
	);
};

export default Settings;
