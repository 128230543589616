import React, { memo } from 'react';
import { Col, Button, Tooltip } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const settings = {
	freeMode: true,
	pagination: { clickable: true },
	initialSlide: 0,
	breakpoints: {
		320: {
			slidesPerView: 5,
		},
		480: {
			slidesPerView: 5,
		},
		767: {
			slidesPerView: 25,
			spaceBetween: 2,
		},
	},
};

const DaysTabs = ({ routes, changeRouteOnTab, date }) => {
	const { type: day = '' } = useParams();

	return (
		<>
			<Tooltip title='See all days route'>
				<Button className={`travel-button__all${day === 'All' ? '--active' : ''}`} size='small' onClick={() => changeRouteOnTab('All')}>
					All Days
				</Button>
			</Tooltip>
			{routes.map((route) => (
				<Button
					key={`day ${route.daysOrder}`}
					size='small'
					type={route.daysOrder === day ? 'primary' : 'default'}
					onClick={() => changeRouteOnTab(route.daysOrder)}
					className={route.daysOrder === day ? 'travel-button__primary' : 'travel-button__default'}>
					<span>{`Day ${route.daysOrder}`}</span>
					<span className='travel-button__day'>{route.daysOrder === '1' ? moment(date).format('YYYY-MM-DD') : moment(date).add(route.daysOrder-1, 'days').format('YYYY-MM-DD')}</span>
				</Button>
			))}
		</>
	);
};

export default DaysTabs;
