import {
	sightReceived,
	sightRequested,
	sightRequestFailed,
	sightsNearReceived,
	sightsNearRequested,
	sightsNearRequestFailed,
	travelsForSightReceived,
	travelsForSightRequested,
	travelsForSightRequestFailed,
	travelSightReceived,
	travelSightRequested,
	travelSightRequestFailed,
} from '../reducers/SightReducer';
import sightService from './../../services/sight.service';
import travelService from './../../services/travel.service';

export const loadSight = identifier => async dispatch => {
	dispatch(sightRequested());
	try {
		const params = { filter: { identifier } };
		const [sight] = await sightService.get(params);
		dispatch(sightReceived(sight));
		// dispatch({ type: GET_SIGHT_INFO, payload: sight });
		// dispatch(hideLoader('sight_SightPage'));
	} catch ({ message }) {
		dispatch(sightRequestFailed(message));
		// dispatch(showMessage({ identifier: 'sight_SightPage', message: error.message }));
		// dispatch(hideLoader('sight_SightPage'));
	}
};

export const loadTravelSight = identifier => async dispatch => {
	dispatch(travelSightRequested());
	try {
		// dispatch(showLoader('travel_SightPage'));
		const params = { filter: { identifier, difficulty: 'moderate' } };
		const [travel] = await travelService.get(params);
		dispatch(travelSightReceived(travel));
		// dispatch({ type: GET_SIGHT_TRAVEL, payload: travel });
		// dispatch(hideLoader('travel_SightPage'));
	} catch ({ message }) {
		dispatch(travelSightRequestFailed(message));
		// dispatch(showMessage({ identifier: 'travel_SightPage', message: error.message }));
		// dispatch(hideLoader('travel_SightPage'));
	}
};

export const loadTravelsForSight =
	(sight, identifier = '') =>
	async dispatch => {
		dispatch(travelsForSightRequested());
		try {
			// dispatch(showLoader('travels_SightPage'));
			let params = { identifier, sight, difficulty: 'moderate', sort: { rating: -1 }, limit: 6 };
			let travels = await travelService.getTravelsForSight(params);
			// dispatch({ type: GET_TRAVELS_FOR_SIGHT, payload: travels });
			// dispatch(hideLoader('travels_SightPage'));
			dispatch(travelsForSightReceived(travels));
		} catch ({ message }) {
			dispatch(travelsForSightRequestFailed(message));
			// dispatch(showMessage({ identifier: 'travels_SightPage', message: error.message }));
			// dispatch(hideLoader('travels_SightPage'));
		}
	};

export const loadSightsNear =
	({ lat, long, sight }) =>
	async dispatch => {
		dispatch(sightsNearRequested());
		try {
			const params = {
				lat,
				long,
				limit: 6,
				identifier: {
					$ne: sight,
				},
				distance: 50000,
			};
			// dispatch(showLoader('sights_SightPage'));

			const sights = await sightService.getSightsNear(params);
			dispatch(sightsNearReceived(sights));
			// dispatch({ type: GET_SIGHTS_NEAR, payload: sights });
			// dispatch(hideLoader('sights_SightPage'));
		} catch ({ message }) {
			dispatch(sightsNearRequestFailed(message));
			// dispatch(showMessage({ identifier: 'sights_SightPage', message: error.message }));
			// dispatch(hideLoader('sights_SightPage'));
		}
	};
