import React, {memo} from 'react';
import {  useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { scroller } from 'react-scroll';
import { Button, Drawer, Typography, Row, Tooltip, Col } from 'antd';

import { openNotification } from '../../common/Notification/Notification';
import { GetIcon, ImageFinder } from '../../../Helper';
import { addSight } from '../../../state/actions/CustomizeTripActions';

const { Title, Text } = Typography;

const TravelAddSights = ({ visibleDrawer, currSight, add, day, onClose, setAdd }) => {
	const dispatch = useDispatch();
	const addToSights = obj => {
		dispatch(
		addSight({
			day: Number(day),
			sight: obj,
			index: -1,
		}));
	};

	return (
		<Drawer placement='right' closable={false} onClose={onClose} getContainer={false} open={visibleDrawer} className='travel-drawer'>
			{currSight.hasOwnProperty('images') && (
				<Row className='travel-drawer__container'>
					<Title level={3} className='travel-drawer__title'>
						{currSight.title}
					</Title>
					<Link to={'/sight/' + currSight.identifier}>
						<ImageFinder className='travel-drawer__img' alt='gallery-img' src={currSight.images[0].name} />
					</Link>
					<Text className='travel-drawer__description'>{currSight.description}</Text>
					{currSight.hints && (
						<Row className='travel-drawer__hints'>
							<GetIcon src='hint.svg' />
							<Text>{currSight.hints}</Text>
						</Row>
					)}

					{add && (
						<Tooltip title='Add this sight to my trip'>
							<Button
								className='travel-drawer__btn'
								onClick={() => {
									addToSights(currSight);
									setAdd(false);
									onClose();
									scroller.scrollTo(currSight.identifier, {
										duration: 1500,
										smooth: true,
										offset: 450,
										containerId: 'travel-sights',
									});

									openNotification(
										currSight.title +
											' has been added to Day ' +
											day +
											' of your itinerary. Move it to the desired position (earlier in the day or to the other day) using control buttons.'
									);
								}}>
								<PlusOutlined />
							</Button>
						</Tooltip>
					)}
				</Row>
			)}

			{currSight.hasOwnProperty('day') && (
				<div>
					<Title level={3} className='travel-drawer__title'>
						{currSight.title}
					</Title>
					<Col className='travel-drawer__description'>
						<Text className='travel-drawer__description-text'>{currSight.subtitle}</Text>
					</Col>

					<a href={`https://www.google.com/maps/place/${currSight.location[1]},${currSight.location[0]}`} className='travel-drawer_link' target='_blank' rel='nofollow noopener noreferrer'>
						<span className='material-icons travel-drawer__marker'>place</span>
						<Text className='travel-drawer__text-link'>
							{currSight.location[0]}, {currSight.location[1]}
						</Text>
					</a>
				</div>
			)}
		</Drawer>
	);
};


export default memo(TravelAddSights);
