import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Row, Col, Card, Tag } from 'antd';
import { Link } from 'react-router-dom';

import { ImageFinder } from '../../../../Helper';
import { getColor } from '../../../../helpers/_Functions';
import { addToFavorites } from '../../../../state/actions/UserActions';
import { HeartFilled } from '@ant-design/icons';

const { Meta } = Card;

const FavoritesContent = () => {
	const {favorites = []} = useSelector(state => state.UserPage.user);
	const dispatch = useDispatch();

	const addToFavorite = data => {
		dispatch(addToFavorites(data));
	};

	return (
		<Layout>
			<h1>Favorites</h1>
			{favorites ? (
				<Row gutter={[16, 16]}>
					{favorites.map(travel => (
						<Col span={8} key={travel.identifier}>
							<Card
								hoverable
								className='zeropage-recommended__carousel-card'
								cover={<ImageFinder alt={travel.imageTitle} title={travel.imageTitle} src={travel.imageTitle} className='zeropage-recommended__carousel-img' />}>
								<Link to={`/trip/${travel.identifier}/moderate/`}>
									<Tag className='mar-l-5' color={getColor(travel.difficulty)}>
										{travel.difficulty}
									</Tag>
									<Meta title={travel.title} description={travel.subtitle} />
								</Link>
								<HeartFilled
									style={{ fontSize: '16px', color: 'ff0000' }}
									onClick={() => addToFavorite({ title: travel.title, subtitle: travel.subtitle, identifier: travel.identifier, difficulty: travel.difficulty })}
								/>
							</Card>
						</Col>
					))}
				</Row>
			) : (
				<h1>Favorites list is empty</h1>
			)}
		</Layout>
	);
};

export default FavoritesContent;
