import React from 'react';
import { Row } from 'antd';
import {Link} from 'react-router-dom';

const HashtagsList = ({ hashtags, pathname = '/trips/All', className = '' }) => {
	return (
		<>
			{hashtags.length && (
				<Row className='hashtags'>
					{hashtags.map((item, key) => (
						<Link to={{ pathname: pathname, hash: `${item}` }} className={className} key={key}>
							#{item}
						</Link>
					))}
				</Row>
			)}
		</>
	);
};

export default HashtagsList;
