import React, { useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { MapContainer, Marker, Popup, useMapEvents, TileLayer } from 'react-leaflet';
import { Row, Col, Typography, Tabs } from 'antd';
import { useParams } from 'react-router';

import { loadTravelsForSight, loadSightsNear } from './../../../state/actions/SightActions';
import { GetIcon } from '../../../Helper';
import SightHeader from './SightHeader';
import { OtherSightsAndTrips } from '../../ui/OtherSightsAndTrips';
import ReviewsInfo from './../../ui/Reviews/ReviewsInfo';

const { Title, Text } = Typography;

const iconBlue = L.divIcon({
	className: 'custom-div-icon',
	html: '<div class="marker-pin-blue"></div>',
});

const SightPageContent = () => {
	const { identifier = '', sight } = useParams();
	const { sight: sightInfo = {}, sights, travels, loading, errors } = useSelector(state => state.SightPage);
	const dispatch = useDispatch();
	// const { SightPage: { sight: sightInfo = {}, sights = [], travels = [] } = {}, loading = {}, message = {}, actions } = props;
	const OpenGoogle = () => {
		useMapEvents({
			click() {
				window.open(`https://www.google.com/maps/place/${sightInfo.location[1]},${sightInfo.location[0]}`, '_blank');
			},
		});
		return null;
	};

	const [activeKey, setActiveKey] = useState('moreSights');

	const changeTabs = key => {
		setActiveKey(key);
		if (key == 'otherTrips') {
			dispatch(loadTravelsForSight(sight, identifier));
		} else if (key == 'moreSights' && !sights.length) {
			dispatch(loadSightsNear({ lat: sightInfo.location[1], long: sightInfo.location[0], identifier: sight }));
		}
	};

	const items = [
		{
			label: (
				<Title level={2} className='sight-trips__title'>
					More sights
				</Title>
			),
			key: 'moreSights',
			children: <OtherSightsAndTrips sights={sights} sight={sightInfo} loading={loading.sights} message={errors.sights} />,
		},
		{
			label: (
				<Title level={2} className='sight-trips__title'>
					{identifier?.length ? 'Other trips' : 'Trips'}
				</Title>
			),
			key: 'otherTrips',
			children: <OtherSightsAndTrips travels={travels} loading={loading.travels} message={errors.travels} sight={sightInfo} />,
		},
	];

	return (
		<main className='sight-content'>
			<SightHeader />

			<Row className='sight-about'>
				<Col className='sight-about__info' lg={12} md={12}>
					<Text>{sightInfo.description}</Text>
					{sightInfo.hints && (
						<Row className='sight-about__hints'>
							<GetIcon src='hint.svg' className='sight-header__icon' />
							<Text>{sightInfo.hints}</Text>
						</Row>
					)}
				</Col>
				<Col lg={12} md={12}>
					<MapContainer
						center={[sightInfo.location[1], sightInfo.location[0]]}
						zoom={'18'}
						style={{ height: '40vh', width: '100%' }}>
						<OpenGoogle />
						<TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
						<Marker position={[sightInfo.location[1], sightInfo.location[0]]} key={`${sightInfo.identifier}_marker`} title={sightInfo.identifier} riseOnHover='true' icon={iconBlue}>
							<Popup>{sightInfo.identifier}</Popup>
						</Marker>
					</MapContainer>
				</Col>
			</Row>

			<Row className='sight-trips'>
				<Tabs items={items} onChange={changeTabs} activeKey={activeKey} />
			</Row>

			<ReviewsInfo type='SightPage' sights={sights} sightInfo={sightInfo} />
		</main>
	);
};

export default memo(SightPageContent);
