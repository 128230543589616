import {
    INIT_LOCATION,
} from './../../constants/ActionTypes';

// INITIALIZE STATE
const initialState = {
    paths: ['/'],
    pathname: '/',
    match: {}
};

// REDUCER
export const LocationReducer = (state = initialState, action) => {
    switch(action.type) {
        case INIT_LOCATION: {
            let {
                paths = []
            } = state;

            let currentPath = action.payload;
            let newPaths = paths;
            let pathname = currentPath;
            if(currentPath == '/'){
                paths = ['/'];
            }else{
                newPaths.push(currentPath);
                paths = newPaths;
            }
            return {
                ...state,
                pathname,
                paths
            };
        }
        default:
            return state;
    }
};