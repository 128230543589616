import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, OAuthProvider, FacebookAuthProvider } from 'firebase/auth';
const firebaseConfig = {
	apiKey: 'AIzaSyDpml--qqfI9CYBTj9krUvvqTwKdYhehlc',
	authDomain: 'beforevoyage-be6c7.firebaseapp.com',
	databaseURL: 'https://beforevoyage-be6c7-default-rtdb.firebaseio.com',
	projectId: 'beforevoyage-be6c7',
	storageBucket: 'beforevoyage-be6c7.appspot.com',
	messagingSenderId: '1052557636531',
	appId: '1:1052557636531:web:e5ad75be7a849026b0d1c6',
	measurementId: 'G-66XXQZJLP1',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const auth2 = getAuth();

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export const signInWithGoogle = () => signInWithPopup(auth, googleProvider);

export const signInWithFacebook = () => signInWithPopup(auth, facebookProvider);