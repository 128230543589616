import React, { useEffect } from 'react';
import { Form, Icon, Input, Button, Checkbox, Alert, Divider, Col } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined, GoogleOutlined, FacebookFilled } from '@ant-design/icons';

const LoginFormPage = props => {
	const {
		handleResetPassword,
		googleSignIn,
		facebookSignIn,
		handleLogin,
	} = props;
	const { message, loading } = useSelector(state => state.UserPage);
	// const history = useNavigate();
	
	const handleSubmit = values => {
		console.log(values);
		// e.preventDefault();
		// validateFields((err, values) => {
		// 	if (!err) {
		// 		console.log('Received values of form: ', values);
		handleLogin({ ...values });
		// 	}
		// });
	};

	const forgotPassword = () => {
		handleResetPassword(true);
	};

	// useEffect(() => {
	// 	console.log(history.location.state.from.pathname, auth)
	// 	if (auth?.userId) {
	// 		console.log(history.location.state.from.pathname)
	// 		history.push(`/user/${auth?.userId}/`);
	// 	}
	// }, [auth]);

	return (
		<Form className='login-form' onFinish={handleSubmit} disabled={loading.auth}>
			<Form.Item
				className='auth-item'
				name='email'
				rules={[
					{
						type: 'email',
						message: 'Invalid email address! If you do not need to send an email. Delete characters',
					},
					{
						required: true,
					},
				]}>
				<Input name='email' placeholder='Email' type='email' maxLength={50} prefix={<UserOutlined />} />
			</Form.Item>
			<Form.Item
				className='auth-item'
				name='password'
				rules={[
					{
						required: true,
						message: 'Please input your password!',
					},
				]}>
				<Input.Password prefix={<LockOutlined />} placeholder='Password' />
			</Form.Item>
			<Form.Item name='remember' valuePropName='checked'>
				<Checkbox>Remember me</Checkbox>
			</Form.Item>

			{message && <Alert message={message} type='error' />}

			<Form.Item>
				<Button className='auth-form__btn' htmlType='submit' loading={loading.auth}>
					Log in
				</Button>
				<div>
					<a className='login-form-forgot' onClick={forgotPassword}>
						Forgot password
					</a>
				</div>
			</Form.Item>
			<Divider>Or</Divider>
			<Col>
				<Button onClick={googleSignIn} className='google-btn' shape='round' icon={<GoogleOutlined />}>
					Continue with Google
				</Button>
				<Button onClick={facebookSignIn} className='facebook-btn' shape='round' icon={<FacebookFilled />}>
					Continue with Facebook
				</Button>
			</Col>
		</Form>
	);
};

export default LoginFormPage;
