import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Input, Button, Col, Checkbox, Alert, Divider, Row } from 'antd';
import { validator } from '../../../helpers/ validator';
import { FacebookOutlined, GoogleOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
const validatorConfig = {
	password: {
		isCapitalSymbol: {
			message: 'Passwords must contain a minimum of 1 upper case letter',
		},
		isContainDigit: {
			message: 'Passwords must contain a minimum of 1 numeric character',
		},
		min: {
			message: 'Passwords must contain at least 8 characters',
			value: 8,
		},
	},
};
const RegisterFormPage = props => {
	const { googleSignIn, facebookSignIn, handleSignUp } = props;

	const { message } = useSelector(state => state.UserPage);
	const [form] = Form.useForm();
	const handleSubmit = ({ email, password, agreement }) => {
			handleSignUp({ email, password, agreement });
	};

	const validatePassword = (rule, value, callback) => {
		const messageError = validator(value, validatorConfig.password);
		if (value && Object.keys(messageError).length) {
			callback(Object.values(messageError)[0]);
		} else {
			callback();
		}
	};

	useEffect(() => {
		console.log(form.getFieldValue('agreement'));
	}, [form.getFieldValue('agreement')]);

	useEffect(() => {
		form.setFieldValue({
			agreement: false
		})
	}, [])
	return (
		<Form className='registration-form' form={form} onFinish={handleSubmit}>
			<Row className='social-items'>
				<span className='auth-text'>Sign up with social</span>
				<Col className='social-btns'>
					<Button className='social-item social-item__google' onClick={googleSignIn} shape='circle' icon={<GoogleOutlined />} />
					<Button className='social-item social-item__facebook' onClick={facebookSignIn} shape='circle' icon={<FacebookOutlined />} />
				</Col>
			</Row>

			<Divider>Or</Divider>
			<span className='auth-text'>Sign up with email</span>
			<Form.Item
				className='auth-item'
				name='email'
				rules={[
					{
						type: 'email',
						message: 'Invalid email address! If you do not need to send an email. Delete characters',
					},
					{
						required: true,
					},
				]}>
				<Input placeholder='Email' prefix={<UserOutlined />} />
			</Form.Item>
			<Form.Item
				className='auth-item'
				name='password'
				rules={[
					{
						required: true,
						message: 'Please input your password!',
					},
					{
						validator: validatePassword,
					},
				]}>
				<Input.Password prefix={<LockOutlined />} placeholder='Password' />
			</Form.Item>

			<Form.Item
				className='auth-item'
				name='agreement'
				valuePropName='checked'
				help='By creating an account you agree with our terms. This site is protected by before.voyage Privacy Policy and Terms of Service apply.'>
				<Checkbox>I want inspiring road trip stories, extraordinary destinations, and special offers sent right to my inbox.</Checkbox>
			</Form.Item>
			{message && <Alert message={message} type='error' />}

			<Form.Item>
				<Button
					className='auth-form__btn'
					htmlType='submit'
				>
					Register
				</Button>
			</Form.Item>
		</Form>
	);
};

export default RegisterFormPage;
