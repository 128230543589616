import { routing } from '../libs/mapbox';

export const checkControlExisting = (day, control, Search) => !!Search[control]?.find(el => el.day === day);

export const checkLatLong = identifier => {
	if (identifier && identifier.includes(';')) {
		let location = identifier.split(';');
		let lat = parseFloat(location[0]);
		let long = parseFloat(location[1]);
		if (location.length === 2) {
			if (Number.isFinite(lat) && Number.isFinite(long)) {
				return { lat, long };
			}
		}
	}
	return {};
};

export const checkDay = day => (day == 1 ? `${day} day` : `${day} days`);

export const changeTitle = title =>
	title
		.split(',')
		.filter(el => el !== title.split(',').pop())
		.join();

export const distanceMi = (locationSight, locationTravel) => {
	let deg2rad = 0.017453292519943295,
		cos = Math.cos,
		lat1 = deg2rad * locationSight[1],
		lng1 = deg2rad * locationSight[0],
		lat2 = deg2rad * locationTravel[1],
		lng2 = deg2rad * locationTravel[0],
		R = 12742,
		dLat = lat2 - lat1,
		dLon = lng2 - lng1;
	return Math.ceil(R * Math.asin(Math.sqrt((1 - cos(dLat) + (1 - cos(dLon)) * cos(lat1) * cos(lat2)) / 2)) * 0.62137);
};

export function secondsToHms(d) {
	d = Number(d);
	let h = Math.floor(d / 3600);
	let m = Math.floor((d % 3600) / 60);

	let hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
	let mDisplay = m > 0 ? m + (m == 1 ? ' minute' : ' minutes') : '';
	return hDisplay + mDisplay;
}

export function getDistance(lat1, lon1, lat2, lon2) {
	const R = 6371e3;
	const phi1 = (lat1 * Math.PI) / 180;
	const phi2 = (lat2 * Math.PI) / 180;
	const deltaPhi = ((lat2 - lat1) * Math.PI) / 180;
	const deltaLamb = ((lon2 - lon1) * Math.PI) / 180;

	const a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLamb / 2) * Math.sin(deltaLamb / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	return R * c; // in metres
}

export const convertMeasure = (value, measure) => (measure === 'km' ? value / 1000 : value / 1609.344);

export const getZoom = maxDistance => {
	const earthLength = 4e7; // in metres
	let count = earthLength / maxDistance;
	let normalSize = Math.log2(count);
	let result = Math.round(normalSize) - 0.75;

	return result;
};

export const getColor = difficulty => {
	let color = 'green';
	switch (difficulty) {
		case 'moderate':
			color = 'green';
			break;
		case 'relaxed':
			color = 'orange';
			break;
		case 'active':
			color = 'red';
			break;
		default:
			color = 'green';
	}
	return color;
};

export const sortByField = field => (a, b) => a[field] > b[field] ? 1 : -1;

export function slicedRoute(route) {
	const slicedRoutes = [];
	for (let i = 0; i < Math.ceil(route.length / 24); i++) {
		slicedRoutes[i] = route.slice(i * 24, i * 24 + 24);
		if (i !== 0) {
			slicedRoutes[i].unshift(slicedRoutes[i - 1][slicedRoutes[i - 1].length - 1]);
		}
	}

	return slicedRoutes;
}

export const getRouteDay = async (route, sights) => {
	try {
		const filteredRoute = route.filter(route => route.daysOrder !== 'All');
		const daysSights = filteredRoute
			.map(r => {
				let newRoute = [];
				r.sights.forEach(sight => {
					let si = sights.find(s => s.identifier === sight);
					if (si) {
						newRoute.push(si.location);
					}
				});

				return newRoute;
			});
		// console.log(daysSights, 'day sights', route);
		let daysRoute = await Promise.all(
			daysSights.map(async (route, index, array) => {
				if (index !== 0) {
					route.unshift(array[index - 1][array[index - 1].length - 1]);
				}
				let newRoute = await Promise.all(
					slicedRoute(route).map(async r => {
						let { distance, legs, duration, code } = await routing({ coordinates: r.join(';') });
						// console.log(code, r)
						// if (code === 'NoRoute' && type === 'start') {
						// 	let { distance, legs, duration, code } = await routing({ coordinates: r.slice(1).join(';') });
						// 	return { distance, legs: [{identifier: 'NoRoute', type, distance: 0, duration: 0}, ...legs], duration, code};
						// } else
						if (code === 'NoRoute') {
							let { distance, legs, duration } = await routing({ profile: 'walking', coordinates: r.join(';') });
							return { distance, duration, legs, type: 'walking' };
						} else {
							return { distance, duration, legs, type: 'driving' };
						}
					})
				);

				return {
					distance: newRoute.reduce(function (acc, obj) {
						return acc + obj.distance;
					}, 0),
					duration: newRoute.reduce(function (acc, obj) {
						return acc + obj.duration;
					}, 0),
					legs: newRoute.reduce(function (acc, obj) {
						return acc.concat(obj.legs);
					}, []),
				};
			})
		);

		let newRoute = [
			...filteredRoute.map(day => ({ ...day, route: daysRoute[Number(day.daysOrder) - 1] })),
			{
				daysOrder: 'All',
				sights: filteredRoute.reduce(function (acc, obj) {
					return acc.concat(obj.sights);
				}, []),
				route: {
					distance: daysRoute.reduce(function (acc, obj) {
						return acc + obj.distance;
					}, 0),
					duration: daysRoute.reduce(function (acc, obj) {
						return acc + obj.duration;
					}, 0),
					legs: daysRoute.reduce(function (acc, obj) {
						return acc.concat(obj.legs);
					}, []),
				},
			},
		];
		// console.log(newRoute);
		return newRoute;
	} catch (error) {
		console.log(error);
	}
};

export const getDate = data => {
	return new Date(data).getFullYear();
};

export const getMonthName = month => {
	const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	return monthNames[month];
};

export const autoSaveTrip = () => {
	const message = 'Trip auto-saved.';
	const saveData = useCallback(newData => {
		
		dispatch(saveTrip(newData, message));
	}, []);

	const debouncedSave = useCallback(
		debounce(async newData => {
			saveData(newData);
		}, DEBOUNCE_SAVE_DELAY_MS),
		[],
	);
}