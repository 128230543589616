import {
  PUT_SEARCH_LOCATION, 
  PUT_SEARCH_DURATION,
  SET_SEARCH_INTENSITY,
  DELETE_SEARCH_LOCATION,
  PUT_SEARCH_CONTROL,
  CLEAR_SEARCH_LOCATION,
  SET_SEARCH
} from './../../constants/ActionTypes';
import * as Mapbox from '../../libs/mapbox';
import {searchControlDeleted, searchControlAdded, searchAdded, searchLocationRequestFailed, putSearchLocation} from '../reducers/SearchReducer';

export const deleteSearchControl = (props) => dispatch => {
  dispatch(searchControlDeleted(props));
};

export const putSearchControl = (props) => dispatch => {
  dispatch(searchControlAdded(props));
}

export const getSearchLocation = (lat, long, searchType, identifier, day) => async dispatch => {
  try{
    const [data] = await Mapbox.getSearchFromMapBox(lat, long);
    dispatch({type: PUT_SEARCH_CONTROL, payload: {data: { label: data.label, text: data.text, x: data.x, y: data.y }, searchType, identifier, day}});
  }catch(e){
    console.log('Error on search mapbox', e);
  }
};

export function setSearchIntensity(intensity = '') {
  return {
    type: SET_SEARCH_INTENSITY,
    payload: intensity
  };
}

// export const putSearchLocation = (payload) => {
//   return {
//     type: PUT_SEARCH_LOCATION,
//     payload
//   };
// };

export const putSearchDuration = (payload) => {
  return {
    type: PUT_SEARCH_DURATION,
    payload
  };
};

// export const deleteLocation = () => {
//   return{
//     type: DELETE_SEARCH_LOCATION,
//     payload: {}
//   };
// };

// export function putSearchControl({data, searchType, identifier, day, url}) {
//   return {
//     type: PUT_SEARCH_CONTROL,
//     payload: {data, searchType, identifier, day, url}
//   };
// }

export const loadSearchLocationMapBox = (lat, long) => async dispatch => {
  try{
    const [payload] = await Mapbox.getSearchFromMapBox(lat, long);
    dispatch(putSearchLocation(payload));
  }catch({message}){
    dispatch(searchLocationRequestFailed(`Error on search mapbox ${message}`));
  }
};

export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH_LOCATION,
    payload: {}
  };
};

export const setSearch = payload => {
  return {
    type: SET_SEARCH,
    payload
  };
};

export const putSearch = (props) => dispatch => {
  // console.log(props)
  dispatch(searchAdded(props))
}