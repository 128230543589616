import React, { useState, useEffect, memo } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Button } from 'antd';

import SearchLocation from '../../../ui/Search/SearchLocation';
// import * as SearchActions from '../../../../state/actions/SearchAction';
import * as TravelInfoActions from '../../../../state/actions/TravelInfoActions';
import { GetIcon } from '../../../../Helper';
import { getControl } from '../../../../state/reducers/SearchReducer';
import { deleteSearchControl } from '../../../../state/actions/SearchAction';
import { deleteSight } from '../../../../state/actions/CustomizeTripActions';
const { Text } = Typography;

const TravelModifyStart = props => {
	const {
		identifier = '', 
		day = '',
		sights = [], 
		addToSights,
		updateTravelControls
	} = props;
	const dispatch = useDispatch();
	const start = useSelector(getControl('start'));

	const [showStart, setShowStart] = useState(false);
	const [startInfo, setStartInfo] = useState('');

	const deleteStart = () => {
		dispatch(deleteSight({identifier: startInfo.label, day}));
		dispatch(deleteSearchControl({type: 'start', identifier: startInfo.label, day}));
		setStartInfo('');
		setShowStart(false);
	};

	const success = ({coords}) => {
		console.log(coords.latitude, coords.longitude);
		// dispatch(getSearchLocation('49.0749679', '-125.767622', 'start', identifier, day));
		// SearchActions.setSearchLocation(coords.latitude, coords.longitude, 'start', identifier, day);
	};
	const findMe = () => {
		navigator.geolocation.getCurrentPosition(success);
	};

	useEffect(() => {
		if (start.length) {
			let currStart = start.find(item => item.identifier === identifier && item.day === day);

			if (currStart) {
				if (!sights.find(el => el.title === currStart.data.label && el.day === day)) {
					setStartInfo(currStart.data);
					setShowStart(true);
					addToSights({obj: currStart, type: 'start', icon: 'fa-home', index: 0, });
					// if(routes[day-1].sights.find(sight => sight === currStart.data.label)){
					// 	addToSights({obj: currStart, type: 'start', icon: 'fa-home', index: 0})
					// }
				}
			}
		}
	}, [start]);
	
	useEffect(() => {
		if (start.length && !start.find(el => el.day === day)) {
			setStartInfo('');
			setShowStart(false);
		} else {
			let currStart = start.find(item => item.identifier === identifier && item.day === day);
			if (currStart) {
				setStartInfo(currStart.data);
				setShowStart(true);
			}
		}
	}, [day]);

	return (
		<Row className='modify-btns'>
			<Col lg={4} xs={4} className='modify-icon'>
				<GetIcon src='map-location.png' />
				<Text className='modify-title'>Start</Text>
			</Col>
			<Col lg={20} xs={20} className='modify-text'>
				{!showStart && (
					<div style={{ padding: '0' }}>
						<SearchLocation controlType='start' />
						<Button onClick={findMe}>Your location</Button>
					</div>
				)}

				{showStart && startInfo && (
					<Col lg={20} xs={20}>
						<h3 className={'modifyInfo'}>{startInfo.text}</h3>
						<a href={`https://www.google.com/maps/place/${startInfo.y},${startInfo.x}`} className='modify-link' target='_blank' rel='nofollow noopener noreferrer'>
							<span className='material-icons modify-link__marker'>place</span>
							<Text className='modify-link__text'>
								{startInfo.y}, {startInfo.x}
							</Text>
						</a>
					</Col>
				)}

				{showStart && startInfo && (
					<Col lg={4} xs={4} onClick={deleteStart} className={'closeModify'}>
						<CloseOutlined />
					</Col>
				)}

				{!showStart && (
					<Col lg={4} xs={4} onClick={() => updateTravelControls({ start: day })} aria-hidden='true' className={'closeModify'}>
						<CloseOutlined />
					</Col>
				)}
			</Col>
		</Row>
	);
};

export default memo(TravelModifyStart);
