import React from 'react';
import { Link } from 'react-router-dom';
import { Carousel, Row, Typography, Col } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { GetIcon, ImageFinder } from '../../Helper';
import { Loader } from '../common/Loader/Loader';
import ErrorMessage from '../common/Message/Message';
import { checkDay, changeTitle } from '../../helpers/_Functions';

const { Title, Text } = Typography;

export const OtherSightsAndTrips = props => {
	let { sights = [], travels = [], loading, message = false } = props;

	let settings = {
		slidesPerRow: 3,
		draggable: true,
		infinite: true,
		lazyLoad: true,
		dots: true,
		arrows: true,
		swipeToSlide: true,
		nextArrow: <RightOutlined />,
		prevArrow: <LeftOutlined />,
		appendDots(dots) {
			return (
				<div>
					<ul className='sight-dots'> {dots} </ul>
				</div>
			);
		},
		responsive: [
			{
				breakpoint: 480,
				settings: {
					slidesPerRow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<>
			{!loading ? (
				<Carousel {...settings}>
					{travels.length &&
						travels.map((item, key) => {
							let count = 0;
							item.route.map(el => (count += el.sights.length));
							return (
								<Link to={`/trip/${item.identifier}/${item.difficulty}/`} className={'sight-carousel__item'} key={key}>
									<ImageFinder alt={item.title} title={item.title} src={item.imageTitle} className='sight-carousel__img' />
									<div className='sight-carousel__card'>
										<Row className='sight-carousel__card-titles'>
											<Title level={3} className='sight-carousel__card-title'>
												{changeTitle(item.title)}
											</Title>
											{item.rating && <span className='sight-carousel__card-rate'>{item.rating / 20 < 4.5 ? item.rating / 20 + 0.5 : item.rating / 20}</span>}
											<Text className='sight-carousel__card-subtitle'>{item.subtitle}</Text>
										</Row>

										<Row className='sight-carousel__card-info'>
											<Col lg={12} md={12} className='sight-carousel__card-days'>
												<GetIcon src='days.svg' className='sight-carousel__card-icon' />
												<Text>{checkDay(item.daysNumber)}</Text>
											</Col>
											<Col lg={12} md={12} className='sight-carousel__card-sight'>
												<GetIcon src='marker.svg' className='sight-carousel__card-icon' />
												<Text>{count} sights</Text>
											</Col>
										</Row>
									</div>
								</Link>
							);
						})}

					{sights.length &&
						sights.map((item, key) => (
							<Link
								to={`/sight/${item.identifier}/`}
								className={'sight-carousel__item'}
								key={key}>
								<ImageFinder alt={item.title} title={item.title} src={item.images[0].name} className='sight-carousel__img' />
								<div className='sight-carousel__card'>
									<Row className='sight-carousel__card-titles'>
										<Title level={3} className='sight-carousel__card-title'>
											{item.title}
										</Title>
										{item.trailDetails && <Text className='sight-carousel__card-rate'>{item.trailDetails.scenery}.0</Text>}
										<Text className='sight-carousel__card-subtitle'>{item.subtitle}</Text>
									</Row>

									<div className='sight-carousel__card-address'>
										<GetIcon src='marker.svg' className='sight-carousel__card-icon' />
										<Text>{item.address}</Text>
									</div>
								</div>
							</Link>
						))}
				</Carousel>
			) : (
				<Loader />
			)}
			{message ? <ErrorMessage text={`Sorry, no trips found`} subtext='' className='message-info' /> : false}
		</>
	);
};
