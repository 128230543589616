import {options} from '../config';
import axios from 'axios';
import searchMapboxService from '../services/search.mapbox.service';
const KEY = options.API_KEY_MAPBOX;

export async function search({ query = ''}) {
    try{
        const {features} = await searchMapboxService.get(query);
        console.log(features, query)
        if(features.length){
            return features.map((place)=>{
                let{
                    text = '',
                    place_name = '',
                    center = [],
                    bbox = [],
                    geometry = {},
                    properties = {}
                } = place;

                return {
                    label: place_name,
                    text,
                    x: center.length ? center[0] : 0,
                    y: center.length ? center[1] : 0,
                    raw: {
                        bbox,
                        geometry
                    },
                    properties
                };
            });
        }
    }catch(e){
        console.log('ERROR MAPBOX', e);
    }
}

export const searchCategory = async (query = '', category = '') => {
    try{
        const features = await searchService.getCategory(query, category);
        if(features.length){
            return features.map((place)=>{
                let{
                    text = '',
                    place_name = '',
                    center = [],
                    bbox = [],
                    geometry = {},
                    properties = {}
                } = place;

                return {
                    label: place_name,
                    text,
                    x: center.length ? center[0] : 0,
                    y: center.length ? center[1] : 0,
                    raw: {
                        bbox,
                        geometry
                    },
                    properties
                };
            });
        }
    } catch (e) {
console.log('ERROR MAPBOX', e);
    }
};


export async function routing({profile = 'driving', coordinates}){
    try {
        const {data} = await axios.get(`https://api.mapbox.com/directions/v5/mapbox/${profile}/${coordinates}?access_token=${KEY}`);
        return {...data.routes[0], code: data.code};
    } catch (error) {
        console.log('ERROR MAPBOX ROUTING', error);
    }
}

export async function getSearchFromMapBox(lat, long){
    let results = [];
    try{
      let shortLat = Math.trunc(lat * 100) / 100;
      let shortLong = Math.trunc(long * 100) / 100;
      results = await search({query: `${shortLong}, ${shortLat}`, type: 'place,region'});
    }catch(e){
      console.log('Error on search mapbox', e);
    }
    return results;
}