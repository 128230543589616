import React, { useState, useEffect, memo } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router';
import { Row, Col, Breadcrumb, Spin } from 'antd';

import Map from './Map';
import flightCanceled from '../../../img/flight.png';
import MapTrips from './MapTrips';
import { checkLatLong, getDistance } from '../../../helpers/_Functions';
import { openNotification } from '../../common/Notification/Notification';
import MapHeader from './MapHeader';
import { WindowSize } from '../../../helpers/WindowSize';
import ErrorBoundaryTravelMap from './../../ErrorBoundary/ErrorBoundaryTravelMap';
import BreadcrumbComponent from './../../ui/Breadcrumb/Breadcrumb';
import { loadTravels } from './../../../state/actions/MapActions';

const MapContent = () => {
	// const { travels = [], sights = [], sight = {}, loading = true, skip, Search, actions, SearchActions, MapPage } = props;
	const { travels, sights, sight, hashtags, skip, hasMore, loading } = useSelector(state => state.MapPage);
	const Search = useSelector(state => state.Search);
	const { identifier, distance = 34400, duration = 'All' } = useParams();
	const params = useParams();

	const { hash } = useLocation();
	const { width } = WindowSize();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [mapSights, setMapSights] = useState([]);
	// const [sight, setSight] = useState({});
	const [selectedHashtags, setSelectedHashtags] = useState([]);
	const [center, setCenter] = useState([37.09024, -95.712891]);
	const [circleParams, setCircleParams] = useState({ center: [], radius: 0 });
	const [zoom, setZoom] = useState(4);
	const [openedTooltip, setOpenedTooltip] = useState('');

	const fetchMore = () => {
		console.log(params, identifier, distance, duration)
		if (identifier) {
			if (identifier.includes(';')) {
				const { lat, long } = checkLatLong(identifier);
				if (lat && long) {
					dispatch(loadTravels({ skip: skip + 5, lat, long, duration, hash, distance }));
				}
			} else {
				dispatch(loadTravels({ skip: skip + 5, sightIdentifier: identifier, duration, hash }));
			}
		} else {
			dispatch(loadTravels({ skip: skip + 5, duration, hash, distance }));
		}
	};

	const openTooltip = identifier => setOpenedTooltip(identifier);

	const checkRadius = (center, distance) => {
		if (distance > 220000) {
			openNotification('Please zoom in to see sights.', 'warning');
		} else {
			navigate(`/map${duration ? `/${duration}/` : '/'}itineraries/${center[1]};${center[0]}/${distance}/`);
		}
	};

	const updateRadius = width => {
		let zoomLevel = 11;
		let scale = distance / 500;
		let pos = width < 769 ? 15 : 16;

		zoomLevel = pos - Math.log(scale) / Math.log(2);
		setZoom(zoomLevel);
	};

	const findNear = pos => {
		// setSight({});
		navigate(`/map${duration ? `/${duration}/` : '/'}itineraries/${pos.coords.latitude};${pos.coords.longitude}/${hash && `${hash}`}`);
		// SearchActions.getSearchLocationMapBox(pos.coords.latitude, pos.coords.longitude);
	};

	const findInArea = area => {
		let center = area.getCenter();
		let nw = area.getNorthWest();
		const radius = Math.floor(getDistance(center.lat, center.lng, nw.lat, nw.lng));
		const distance = Math.round(Number(radius) / 1.4);

		// setSight({});
		checkRadius([center.lng, center.lat], distance);
	};

	// console.log(center, zoom, circleParams, sight);

	const handleSetSight = (sight, type) => {
		// setSight(sight);
		console.log(type);
		navigate(`/map${duration ? `/${duration}/` : '/'}itineraries/${sight.identifier}/${hash && `${hash}`}`);

		// if (Search.item?.label) {
		// 	setMapSights([
		// 		...MapPage.sights.map(el => {
		// 			if (el.identifier === sight.identifier) {
		// 				return { ...el, active: true };
		// 			}
		// 			return el;
		// 		}),
		// 		{ location: [Search.item.x, Search.item.y], title: Search.item.label, search: true },
		// 	]);
		// } else {
		// 	setMapSights(
		// 		MapPage.sights.map(el => {
		// 			if (el.identifier === sight.identifier) {
		// 				return { ...el, active: true };
		// 			}
		// 			return el;
		// 		})
		// 	);
		// }
	};

	const mouseOver = route => {
		let allSights = [];
		if (route.length) {
			route.map(route => {
				allSights.push(...route.sights);
			});
			if (Search.item?.label || identifier?.includes(';')) {
				setMapSights(
					sights.map(sight => {
						if (allSights.includes(sight.identifier)) {
							return { ...sight, hovered: true };
						}
						return sight;
					})
				);
				Search.item?.label && setMapSights(prevSights => [...prevSights, { location: [Search.item.x, Search.item.y], title: Search.item.label, search: true }]);
			}
		}
	};

	useEffect(() => {
		if (!hash) {
			setSelectedHashtags([]);
		} else {
			setSelectedHashtags(hash.slice(1).split('#'));
		}
	}, [hash]);

	// useEffect(() => {
	// 	if (Search.item?.label && width) {
	// 		setZoom(width < 769 ? 9 : 10);
	// 		setSight({});
	// 		setCenter([Search.item.y, Search.item.x]);
	// 		setCircleParams({ center: [Search.item.x, Search.item.y], radius: 34400 });
	// 	}
	// }, [Search, width]);

	useEffect(() => {
		console.log('identifier', identifier);
		if (identifier ) {
			updateRadius(width);
			const { lat, long } = checkLatLong(identifier);
			if (lat && long) {
				setCenter([lat, long]);
				setCircleParams({ center: [long, lat], radius: Number(distance) });
			} else {
				console.log('identifier', identifier, sight);
				if (sights.length) {
					setMapSights(
						sights.map(el => {
							if (el.identifier === sight.identifier) {
								return { ...el, active: true };
							}
							return el;
						})
					);
				} else if(sight.hasOwnProperty(location)){
					setZoom(width < 769 ? 9 : 10);
					setCenter([sight.location[1], sight.location[0]]);
					setMapSights([sight]);
				}
			}
		} else {
			setMapSights([]);
			setZoom(4);
			setCenter([37.09024, -95.712891]);
			setCircleParams({ center: [], radius: 0 });
		}
	}, [identifier, sight]);

	// useEffect(() => {
	// if (Search.item?.label) {
	// 	setMapSights([
	// ...sights.map(el => {
	// 	if (el.identifier === sight.identifier) {
	// 		return { ...el, active: true };
	// 	}
	// 	return el;
	// }),
	// { location: [Search.item.x, Search.item.y], title: Search.item.label, search: true },
	// 	]);
	// } else {
	// 	setMapSights(
	// sights.map(el => {
	// 	if (el.identifier === sight.identifier) {
	// 		return { ...el, active: true };
	// 	}
	// 	return el;
	// })
	// 	);
	// }

	// }, [Search]);

	useEffect(() => {
		console.log('sights changed', sights, Search);
		// if (sights.length) {
		// 	if (Search.item?.label && identifier) {
		// 		const {lat, long} = checkLatLong(identifier);
		// 		if (lat && long) {
		// 				setSights([...MapPage.sights, { location: [Search.item.x, Search.item.y], title: Search.item.label, search: true }]);
		// 			}

		// 	} else {
		// 		setSights(MapPage.sights);
		// 	}
		// } else if (Search.item?.label && identifier) {
		// 	setSights([{ location: [Search.item.x, Search.item.y], title: Search.item.label, search: true }]);
		// } else if (!identifier) {
		// 	setSights([]);
		// 	setSight({});
		// 	setCircleParams({ center: [], radius: 0 });
		// 	setZoom(4);
		// 	setCenter([37.09024, -95.712891]);
		// } else {
		// 	setSights([]);
		// 	setSight({});
		// }
	}, [sights]);

	useEffect(() => {
		if (sights.length) {
			setMapSights(sights);
			if (Search.item?.label) {
				console.log('search item', Search.item, Search);
				setMapSights(prev => [...prev, { location: [Search.item.x, Search.item.y], title: Search.item.label, search: true }]);
			}
		}
	}, [Search, sights]);

	// console.log(mapSights, center, zoom, circleParams, sight);
	const SorryMessageJSX = (
		<div className='spin-centr'>
			<img src={flightCanceled} alt='We have no travels' /> Sorry, we have no trips in this area yet. Please zoom out or try a different destination.
		</div>
	);
	const LoadingJSX = (
		<div className='spin-centr'>
			<Spin size='large' />
		</div>
	);
	const LoadingContentJSX = loading ? LoadingJSX : SorryMessageJSX;
	return (
		<>
			<BreadcrumbComponent text='Map' home='beforevoyage.com' />

			<main className='explore-content main'>
				<Row type='flex'>
					<Col className='explore-sidebar' xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 10, order: 1 }} lg={{ span: 8, order: 1 }}>
						<MapHeader selectedHashtags={selectedHashtags} sight={sight} hashtags={hashtags} loading={loading} />
						{!loading.travels && travels.length ? (
							<MapTrips
								selectedHashtags={selectedHashtags}
								duration={duration}
								travels={travels}
								hasMore={hasMore}
								mouseOver={mouseOver}
								sight={sight}
								center={circleParams.center}
								distance={circleParams.radius}
								fetchMore={fetchMore}
							/>
						) : (
							LoadingContentJSX
						)}
					</Col>
					<Col className='explore-map' xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 14, order: 2 }} lg={{ span: 16, order: 2 }}>
						<ErrorBoundaryTravelMap>
							<Map
								openedTooltip={openedTooltip}
								openTooltip={openTooltip}
								updateRadius={updateRadius}
								handleSetSight={handleSetSight}
								findNear={findNear}
								findInArea={findInArea}
								sights={mapSights}
								zoom={zoom}
								center={center}
								circleParams={circleParams}
							/>
						</ErrorBoundaryTravelMap>
					</Col>
				</Row>
			</main>
		</>
	);
};

export default memo(MapContent);
