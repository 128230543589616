import React, { useState, useEffect, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { StickyContainer, Sticky } from 'react-sticky';
import { Row, Col, Typography, Button } from 'antd';
import L from 'leaflet';
import { Marker, Popup, Tooltip } from 'react-leaflet';

import { ImageFinder } from '../../../Helper';
import { distanceMi } from '../../../helpers/_Functions';
import Map from '../../ui/hoc/Map/Map';

const { Title, Text } = Typography;
let settingsSwiper = {
	freeMode: true,
	pagination: { clickable: true },
	className: 'btns overview-day_btns',
	breakpoints: {
		320: {
			slidesPerView: 4,
		},
		480: {
			slidesPerView: 4,
		},
		767: {
			slidesPerView: 25,
			spaceBetween: 2,
		},
	},
};
const iconRedSmall = L.divIcon({
	className: 'custom-div-icon',
	html: '<div class="overview-marker--red-small"></div>',
});

const OverviewStay = () => {
	const {
		travel: { title, stay, route, identifier, difficulty },
		sights,
	} = useSelector(state => state.TravelOverviewPage);
	const [hotel, setHotel] = useState({ title: '', location: [] });
	const [lastSight, setLastSight] = useState({});
	const [daySights, setDaySights] = useState([]);
	const [day, setDay] = useState(1);
	const [activeDay, setActiveDay] = useState(0);
	const [activeOption, setActiveOption] = useState(0);

	const findLastSight = name => {
		setLastSight(sights.find(sight => sight.identifier === name) || {});
	};

	let daySightsMap = [];
	daySights?.map(item => {
		if (sights.find(sight => sight.identifier == item)) {
			daySightsMap.push(sights.find(sight => sight.identifier == item));
		}
	});

	const daySights2 = useMemo(
		() =>
			route[day - 1].sights
				.map(sight => sights.find(el => el.identifier === sight))
				.filter(Boolean)
				.concat(stay[activeOption][activeDay]),
		[day, route, sights, activeOption, activeDay]
	);
	const bounds = daySights2.map(sight => [sight.location[1], sight.location[0]]);

	let markersSightsPositions = [{ title: hotel.title, location: [hotel.location[1], hotel.location[0]] }],
		markerBoundsPosition = [[stay[0][0].location[1], stay[0][0].location[0]]];

	daySightsMap.map(item => {
		markerBoundsPosition.push([item.location[1], item.location[0]]);
		markersSightsPositions.push({ title: item.title, subtitle: item.subtitle, images: item.images, location: [item.location[1], item.location[0]] });
	});

	let ButtonDays = (
		<Swiper {...settingsSwiper}>
			{stay[activeOption].map((el, key) => {
				return (
					<SwiperSlide key={key}>
						<Button
							key={key}
							size='small'
							className={`btn btn${key == activeDay ? '--primary' : '--default'}`}
							type={key == activeDay ? 'primary' : 'default'}
							onClick={() => {
								setActiveDay(key);
								setDay(el.convenientForNights.includes('-') ? Number(el.convenientForNights.split('-')[1]) : Number(el.convenientForNights));
								setHotel({ title: el.title, location: el.location });
								setDaySights(el.convenientForNights.includes('-') ? route[el.convenientForNights.split('-')[1] - 1].sights : route[el.convenientForNights - 1].sights);
								findLastSight(
									el.convenientForNights.includes('-')
										? route[el.convenientForNights.split('-')[1] - 1].sights[route[el.convenientForNights.split('-')[1] - 1].sights.length - 1]
										: route[el.convenientForNights - 1].sights[route[el.convenientForNights - 1].sights.length - 1]
								);
							}}>
							<span>Day {el.convenientForNights}</span>
						</Button>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);

	const findHotel = ({ title, location }) => {
		setHotel({ title, location });
	};

	let ButtonOptions = (
		<Swiper {...settingsSwiper}>
			{stay.map((item, key) => {
				return (
					<SwiperSlide key={key}>
						<Button
							size='small'
							key={key}
							type={key == activeOption ? 'primary' : 'default'}
							className={`btn btn${key == activeOption ? '--primary' : '--default'}`}
							onClick={() => {
								setActiveOption(key);
								setActiveDay(item.findIndex(el => el.convenientForNights.includes(day)));
								setDay(prev =>
									item.find(el => el.convenientForNights.includes(day)).convenientForNights.includes('-')
										? item.find(el => el.convenientForNights.includes(day)).convenientForNights.split('-')[1]
										: prev
								);
								findHotel(item.find(el => el.convenientForNights.includes(day)));
								setDaySights(prev =>
									item.find(el => el.convenientForNights.includes(day)).convenientForNights.includes('-')
										? route[item.find(el => el.convenientForNights.includes(day)).convenientForNights.split('-')[1] - 1].sights
										: prev
								);
								findLastSight(
									item.find(el => el.convenientForNights.includes(day)).convenientForNights.includes('-')
										? route[item.find(el => el.convenientForNights.includes(day)).convenientForNights.split('-')[1] - 1].sights[
												route[item.find(el => el.convenientForNights.includes(day)).convenientForNights.split('-')[1] - 1].sights.length - 1
										  ]
										: lastSight.identifier
								);
							}}>
							<span>Option {key + 1}</span>
						</Button>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);

	useEffect(() => {
		if (sights.length) {
			findLastSight(route[0].sights[route[0].sights.length - 1]);
		}
	}, [sights]);

	useEffect(() => {
		setActiveOption(0);
		setActiveDay(0);
		setDay(1);
		setDaySights(route[0].sights);
		setHotel({ title: stay[0][0].title, location: stay[0][0].location });
	}, [stay]);

	return (
		<>
			<div className='overview-stay' id='overview-stay'>
				<StickyContainer>
					<Row className='overview-stay__header'>
						<Sticky>
							{({ style }) => (
								<div style={style} className='overview-stay__header-sticky'>
									<Title level={2} className='overview-title'>
										Where to stay
									</Title>
								</div>
							)}
						</Sticky>
						<Col span={20}>
							<Text className='overview-stay__subtitle'>During this trip, people usually stay in:</Text>
						</Col>
					</Row>
					<Row type='flex' justify='space-around' className='overview-stay__content'>
						<Col lg={16} md={16} sm={24} xs={24}>
							<Map bounds={bounds} className='overview-stay__map'>
								{markersSightsPositions.map((item, key) => {
									if (lastSight.hasOwnProperty('title')) {
										if (hotel.title == item.title) {
											return (
												<Marker
													position={[item.location[0], item.location[1]]}
													key={key}
													riseOnHover='true'
													icon={L.ExtraMarkers.icon({
														innerHTML: '<div class="overview-marker--blue"><i class="material-icons">weekend</i></div>',
														markerColor: 'blue',
													})}>
													<Tooltip offset={[0, -35,]} direction='top' opacity={1} permanent>
														<div>{item.title}</div>
														{hotel.title == item.title ? (
															<div>
																~ {distanceMi(hotel.location, lastSight.location)} mi/ ~ {Math.round(distanceMi(hotel.location, lastSight.location) * 1.609)} km
															</div>
														) : (
															''
														)}
													</Tooltip>
													<Popup>
														<div>{item.title}</div>
														{hotel.title == item.title ? (
															<div>
																~ {distanceMi(hotel.location, lastSight.location)} mi/ ~ {Math.round(distanceMi(hotel.location, lastSight.location) * 1.609)} km
															</div>
														) : (
															''
														)}
														<div>{item.images ? <ImageFinder src={item.images[0].name} className='overview-stay__img' /> : false}</div>
													</Popup>
												</Marker>
											);
										}
										return (
											<Marker
												position={[item.location[0], item.location[1]]}
												key={key}
												riseOnHover='true'
												icon={
													lastSight.title == item.title
														? L.ExtraMarkers.icon({
																innerHTML: '<div class="overview-marker--red"><i class="material-icons">follow_the_signs</i></div>',
																markerColor: 'red',
														  })
														: iconRedSmall
												}>
												<Tooltip>
													<div>{item.title}</div>
													{lastSight.title == item.title ? <div>{item.subtitle}</div> : ''}
													{lastSight.title == item.title ? (
														<div>
															~ {distanceMi(hotel.location, lastSight.location)} mi/ ~ {Math.round(distanceMi(hotel.location, lastSight.location) * 1.609)} km
														</div>
													) : (
														''
													)}
												</Tooltip>
												<Popup>
													<div>{item.title}</div>
													{lastSight.title == item.title ? <div>{item.subtitle}</div> : ''}
													{lastSight.title == item.title ? (
														<div>
															~ {distanceMi(hotel.location, lastSight.location)} mi/ ~ {Math.round(distanceMi(hotel.location, lastSight.location) * 1.609)} km
														</div>
													) : (
														''
													)}
													<div>{item.images ? <ImageFinder src={item.images[0].name} className='overview-stay__img' /> : false}</div>
												</Popup>
											</Marker>
										);
									}
								})}
							</Map>
						</Col>
						<Col lg={8} md={8} sm={24} xs={24} className='overview-sidebar'>
							<div>{ButtonDays}</div>
							<div>{ButtonOptions}</div>
							<div className='overview-info'>
								{hotel.location.length && lastSight.hasOwnProperty('title') ? (
									<>
										<p>
											<span className='overview-info__text'>{hotel.title}</span> is located:
											<span className='overview-info__text-stay'>
												<span className='overview-info__text'>
													~ {distanceMi(hotel.location, lastSight.location)} mi/ ~ {Math.round(distanceMi(hotel.location, lastSight.location) * 1.609)} km
												</span>
												to {lastSight.title}, the last sight of Day {`${day}`} of the trip {`"${title}"`}
											</span>
										</p>
										<p className='overview-info__text-about'>
											{hotel.title} is one of the popular options to stay for the night during the trip {`"${title}"`}.
										</p>
										<a href={`/trip/${identifier}/${difficulty}/day/1`} className='overview-info__link'>
											Add an accommodation to the trip itinerary
										</a>
									</>
								) : (
									false
								)}
							</div>
						</Col>
					</Row>
				</StickyContainer>
			</div>
		</>
	);
};

// function mapStateToProps(state) {
// 	return {
// 		travel: state.TravelOverview.travel,
// 		sights: state.TravelOverview.sights,
// 	};
// }

export default memo(OverviewStay);
