import React, {useState} from 'react';
import { Calendar, Col } from 'antd';

const CalendarFilter = () => {
	const [range, setRange] = useState([]);
	function onPanelChange(value, mode) {
		console.log(value, mode);
	  }
	const onSelect = (value) => {
		console.log(value)
		setRange(prev => [prev, value])
	}
	return ( 
		<Col>
		<Calendar onSelect={onSelect} fullscreen={false} onPanelChange={onPanelChange} />
		</Col>
	 );
}
 
export default CalendarFilter;