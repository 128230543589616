import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { animateScroll as scroll, scroller } from 'react-scroll';

import './SightContainer.sass';
import '../Travels/TravelsContainer.sass';
import Header from '../../components/ui/Header/Header';
import SightPageContent from '../../components/pages/SightPage/SightPageContent';
import Footer from './../../components/ui/Footer/Footer';
import { Loader } from '../../components/common/Loader/Loader';
import PageHelmet from './../../components/ui/hoc/PageHelmet';
import { useParams, useLocation } from 'react-router';
import ErrorMessage from './../../components/common/Message/Message';
import { loadSight, loadTravelSight, loadSightsNear } from './../../state/actions/SightActions';

const SightContainer = () => {
	// const {
	// 	sightInfo = {},
	// 	loading = true,
	// 	message = '',
	// 	actions,
	// } = props;
	const { sight: sightInfo = {}, loading, errors } = useSelector(state => state.SightPage);
	const dispatch = useDispatch();
	const { identifier, sight } = useParams();
	const { pathname } = useLocation();

	const title = sightInfo.title ? `${sightInfo.title}. Useful information.` : 'Useful information.',
		description = sightInfo.title ? `${sightInfo.title} - Description, Location, Photos, Hints, Recommendations.` : 'Description, Location, Photos, Hints, Recommendations.';

	useEffect(() => {
		// scroll.scrollToTop();
		dispatch(loadSight(sight));
		if (identifier) {
			dispatch(loadTravelSight(identifier));
		}
	}, [sight, identifier]);

	useEffect(() => {
		if (sightInfo.hasOwnProperty('title')) {
			// scroll.scrollToTop();
			dispatch(loadSightsNear({ lat: sightInfo.location[1], long: sightInfo.location[0], sight }));
			// scroll.scrollToTop();
		}
	}, [sightInfo]);

	// const loadingJSX = loading.sight ? <Loader /> : message.sight ?
	const LoadingContentJSX = loading.sight ? <Loader /> : errors.sight ? <ErrorMessage text={errors.sight} /> : false;
	console.log(loading, sightInfo, )
	return (
		<PageHelmet title={title} description={description} canonicalUrl={!identifier && pathname}>
			<Header />
			{!loading.sight && sightInfo.hasOwnProperty('identifier') ? <SightPageContent /> : LoadingContentJSX}
			<Footer />
		</PageHelmet>
	);
};

export default SightContainer;
