import { SHOW_LOADER, HIDE_LOADER, SHOW_MESSAGE, HIDE_MESSAGE, RESET_HELPER } from './../../constants/ActionTypes';

export function showLoader(type) {
	return {
		type: SHOW_LOADER,
		payload: type,
	};
}

export function hideLoader(type) {
	return {
		type: HIDE_LOADER,
		payload: type,
	};
}

export function showMessage(payload) {
	return {
		type: SHOW_MESSAGE,
		payload: payload,
	};
}

export function hideMessage(type) {
	return {
		type: HIDE_MESSAGE,
		payload: type,
	};
}

export function resetHelper() {
	return {
		type: RESET_HELPER,
	};
}
