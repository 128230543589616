import { notification } from 'antd';

export const openNotification = (message, type = 'success', placement = 'bottomRight', className = 'notification', duration = 5) => {
	notification[type]({
		message,
		placement,
		className,
		duration,
	});
};
