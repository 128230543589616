import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Select } from 'antd';

import * as Mapbox from '../../../libs/mapbox';
import { putSearchLocation, searchControlAdded } from '../../../state/reducers/SearchReducer';
import { useSelector } from 'react-redux';
import { CloseCircleTwoTone, FileSearchOutlined } from '@ant-design/icons';
const { Option } = Select;
const NOTFOUND = (
	<span>
		<CloseCircleTwoTone twoToneColor='#eb2f96' />
		No results found
	</span>
);

const FIND = (
	<span>
		<FileSearchOutlined style={{ color: '#1890ff' }}/>
		Find location
	</span>
);

const SearchLocation = props => {
	const { searchCategory = 'place', controlType, className = 'location-select' } = props;
	const {error} = useSelector(state => state.Search);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { days, type: day, identifier } = useParams();
	const { pathname } = useLocation();

	const [data, setData] = useState([]);
	const [value, setValue] = useState();
	const [fetching, setFetching] = useState(FIND);
	const [loading, setLoading] = useState(false);

	const handleSearch = async newValue => {
		if (newValue && newValue.length >= 2) {
			console.log(newValue);
			setLoading(true);
			const data = searchCategory === 'place' ? (await Mapbox.search({ query: newValue })) || [] : (await Mapbox.searchCategory(newValue, controlType)) || [];
			console.log(data);
			setData(data);
			if (!data.length) {
				setFetching(NOTFOUND);
			}
			setLoading(false);
		} else {
			setFetching(FIND);
			setData([]);
		}
	};

	const handleSelect = value => {
		console.log(value);
		let { result } = JSON.parse(value);
		let urlLabel = result.label && result.label.trim().replace(/ /g, '+').replace(/,\+/g, ';+').replace(/,\t\t/g, ';+');
		console.log(urlLabel);
		console.log('days, day, identifier', days, day, identifier);
		// if (pathname === '/') {
		dispatch(putSearchLocation(result));
		// }

		//travels page
		if(days){
			navigate(`/trips/${days}/${result.y};${result.x}/${urlLabel}`);
		}

		if (pathname.includes('/map')) {
			let duration = !pathname.split('/map/')[1].split('/itineraries/')[0].includes('itineraries') ? pathname.split('/map/')[1].split('/itineraries/')[0] : '';
			navigate(`/map/${duration && `${duration}/`}itineraries/${result.y};${result.x}/`);
		}

		if (identifier && day) {
			// travel info page
			dispatch(searchControlAdded({ data: result, controlType, identifier, day }));
		}
	};

	const options = data.map(({ id, text, label, properties, x, y }) => (
		<Option key={id} value={JSON.stringify({ result: { label, text, x, y } })}>
			<span className='material-icons place'>
				{properties?.category?.includes('park') && 'park'}
				{properties?.category?.includes('airport') && 'flight'}
				{properties?.category?.includes('shop') && 'store'}
				{properties?.category?.includes('restaurant') && 'restaurant'}
				{!properties?.category && 'place'}
			</span>
			<span className='location-select-link'>{label}</span>
		</Option>
	));

	return (
		<>
			<Select
				showSearch
				className={className}
				placeholder='Find location'
				defaultActiveFirstOption={false}
				showArrow={false}
				filterOption={false}
				onSearch={handleSearch}
				onSelect={handleSelect}
				loading={loading}
				notFoundContent={fetching}>
				{options}
			</Select>

			{/* <Select
				showSearch
				className={className}
				placeholder='Find location'
				defaultActiveFirstOption={false}
				showArrow={false}
				filterOption={false}
				onSearch={handleSearch}
				onSelect={handleSelect}
				loading={loading}
				notFoundContent={fetching}>
				{Results_JSX}
			</Select> */}
			{error ? <div className="ant-form-explain on-error" >Please select duration</div> : false}
		</>
	);
};

// class SearchLocation extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             inputValue: undefined,
//             searchResult: {},
//             loadingSearch: false,
//             fetching: null,
//             placeholder: 'Find location'
//         };

//         this.onClickLink = this.onClickLink.bind(this);
//         this.handleChange = this.handleChange.bind(this);
//         this.handleSearch = this.handleSearch.bind(this);
//     }

//     componentDidUpdate(prevProps){
//         const {
//             match: {
//                 url = ''
//             } = {},
//             Search: {
//                 item = '',
//             } = {}
//         } = this.props;

//         const {
//             match: {
//                 url: urlPrev = ''
//             } = {}
//         } = prevProps;
//         if(!item?.label && this.state.inputValue && url !== urlPrev && urlPrev !== ''){
//             this.setState({inputValue: undefined, placeholder: 'Find location'});
//         }
//     }

//     onClickLink(e, lat, long){
//         e.preventDefault();
//         let {
//             router: {
//                 location: {
//                     pathname = ''
//                 } = {}
//             } = {},
//         } = this.props;
//         if(pathname.includes('/map')) {
//             let duration = !pathname.split('/map/')[1].split('/itineraries/')[0].includes('itineraries') ? pathname.split('/map/')[1].split('/itineraries/')[0] : '';
//             this.props.history.push(`/map/${duration && `${duration}/`}itineraries/${lat};${long}/`);
//         }
//     }

//     handleChange (e) {
//         let {
//             label = '',
//             link = '',
//             result
//         } = JSON.parse(e);
//         let {
//             router: {
//                 location: {
//                     pathname = ''
//                 } = {}
//             } = {},
//             TravelsAndSightsActions
//         } = this.props;

//         if(pathname.includes('/trips/')){
//             TravelsAndSightsActions.deleteSightsAndTravels();
//         }

//         if(pathname === '/' || pathname.includes('/map')){
//             this.setState({inputValue: label, searchResult: result});
//             this.props.SearchAction.SearchLocation(result);
//         }else{
//             this.setState({inputValue: label, searchResult: result}, ()=> {
//                 if (this.props.hasOwnProperty('searchType') && this.props.searchType) {

//                     this.props.SearchAction.SearchLocation(result, this.props.searchType, this.props.identifier, this.props.day, this.props.type);
//                 } else {
//                     this.props.SearchAction.SearchLocation(result);
//                     this.props.history.push(link);
//                 }
//             });
//         }
//     }

//     async handleSearch (value) {
//         console.log(value);
//         // searchService.get(value)
//         let results = [];
//         let {
//             router: {
//                 location: {
//                     pathname = ''
//                 } = {}
//             } = {}
//         } = this.props;
//         this.setState({inputValue: value});

//         if(value && value.length >= 2){
//             this.setState({loadingSearch: true, placeholder: value});

//             if (this.props.searchType === 'Airport') {
//                 results = await Mapbox.search({query: value, type: 'poi'});
//             } else if (this.props.searchType) {
//                 results = await Mapbox.search({query: value, type: 'address'});
//             } else {
//                 results = await Mapbox.search({query: value, type: 'place,poi,address'});
//             }

//             if(results.length){
//                 results.map((result) =>{
//                     if(result.label == value && !pathname.includes('/trips/')){
//                         if (this.props.hasOwnProperty('identifier') && this.props.identifier) {
//                             this.props.SearchAction.SearchLocation(result, this.props.searchType, this.props.identifier, this.props.day);
//                         } else if (this.props.hasOwnProperty('searchType') && this.props.searchType) {
//                             this.props.SearchAction.SearchLocation(result, this.props.searchType);
//                         } else {
//                             this.props.SearchAction.SearchLocation(result);
//                         }
//                     }
//                 });
//                 this.setState({ results, loadingSearch: false });
//             }else{
//                 this.setState({ results: [], loadingSearch: false, fetching: NOTFOUND });
//             }
//         }else if(value) {
//             this.setState({ placeholder: 'search', loadingSearch: false, fetching: FIND });
//         }
//     }

//     render(){
//         let {
//             inputValue = undefined,
//             results = [],
//             fetching = null,
//             loadingSearch = false,
//             placeholder = 'Find location'
//         } = this.state;
//         let {
//             Search: {
//                 error = false,
//                 duration = ''
//             } = {},
//             className = 'location-select'
//         } = this.props;
//         let urlDay = DAYS.includes(duration) ? duration : 'All';

//         let Results_JSX = results.length ? results.map((result, i)=>{
//             let {
//                 x: long = '',
//                 y: lat = '',
//                 label = ''
//             } = result;
//             let urlLabel = label;
//             if(label.length){
//                 urlLabel = label.trim();
//                 urlLabel = urlLabel.replace(/ /g, '+');
//                 urlLabel = urlLabel.replace(/,\+/g, ';+');
//                 urlLabel = urlLabel.replace(/,\t\t/g, ';+');
//             }

//             return(
//                 <Select.Option key={`${i}-select-res`} value={JSON.stringify({label: label, link: `/trips/${urlDay}/${lat};${long}/${urlLabel}/`, result: result})}>
//                     {result?.properties?.category?.includes('park') && <span className="material-icons place">park</span>}
//                     {result?.properties?.category?.includes('airport') && <span className="material-icons place">flight</span>}
//                     {result?.properties?.category?.includes('camp') && <span className="material-icons place">cabin</span>}
//                     {result?.properties?.category?.includes('shop') && <span className="material-icons place">store</span>}
//                     {result?.properties?.category?.includes('marina') && <span className="material-icons place">directions_boat</span>}
//                     {result?.properties?.category?.includes('restaurant') && <span className="material-icons place">restaurant</span>}
//                     {result?.properties?.category?.includes('doctor') && <span className="material-icons place">medication</span>}
//                     {!result?.properties?.category && <span className="material-icons place">place</span>}
//                     <Link className="location-select-link" to={`/trips/${urlDay}/${lat};${long}/${urlLabel}/`} onClick={(e) => this.onClickLink(e, lat, long)} title={label}>{label}</Link>
//                 </Select.Option>
//             );
//         }) : false;

//         return (
//             <Fragment>
//                 <Select
//                     showSearch
//                     className={className}
//                     placeholder={placeholder}
//                     value={inputValue !== '' ? inputValue : 'Find location'}
//                     defaultActiveFirstOption={false}
//                     showArrow={false}
//                     filterOption={false}
//                     onSearch={this.handleSearch}
//                     onSelect={this.handleChange}
//                     loading={loadingSearch}
//                     notFoundContent={fetching}
//                 >
//                     {Results_JSX}
//                 </Select>
//                 {error ? <div className="ant-form-explain on-error" >Please select duration</div> : false}
//             </Fragment>
//         );
//     }
// }
// function mapStateToProps(state) {
// 	return {
// 		Search: state.Search,
// 		router: state.router,
// 	};
// }
// function mapDispatchToProps(dispatch) {
// 	return {
// 		// actions: bindActionCreators(SearchAction, dispatch),
// 		TravelsAndSightsActions: bindActionCreators(TravelsAndSightsActions, dispatch),
// 		MapPageActions: bindActionCreators(MapPageActions, dispatch),
// 	};
// }

export default memo(SearchLocation);
