import React from 'react';
import { Col, Skeleton, Button } from 'antd';

const MapHashtags = ({ hashtags, selectedHashtags, hashtagsOpen, loading, handleSelectHashtag, onHashtagsToggle, getAllHashtags }) => {
	console.log(loading)
	return (
		<Col className='explore-filters__hashtags explore-hashtags'>
			<Skeleton loading={loading} active paragraph={{ rows: 1, width: '100%' }}>
				{hashtags.map(hashtag => (
					<Button
						className={`explore-hashtag${selectedHashtags.includes(hashtag) ? '--active' : ''}`}
						key={hashtag}
						onClick={() => {
							handleSelectHashtag(hashtag);
							onHashtagsToggle(false);
						}}>
						{hashtag}
					</Button>
				))}
			</Skeleton>

			{(!hashtagsOpen || loading) && (
				<Button
					className='explore-hashtags__btn'
					loading={loading}
					onClick={() => {
						getAllHashtags();
						onHashtagsToggle(true);
					}}>
					See all
				</Button>
			)}
			{hashtagsOpen && !loading && (
				<Button
					onClick={() => {
						onHashtagsToggle(false);
					}}
					className='explore-hashtags__btn'>
					See less
				</Button>
			)}
		</Col>
	);
};

export default MapHashtags;
