import React, { useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import { Row, Affix, Button, Tooltip, Typography, Col } from 'antd';
import { scroller } from 'react-scroll';
import { StickyContainer, Sticky } from 'react-sticky';
import ButtonTabs from './../TravelPage/ButtonTabs';
import ButtonIntensity from '../../ui/ButtonIntensity';
import { getTravel, getTravels, getRoutes, getSights } from '../../../state/reducers/TravelReducer';
import BreadcrumbComponent from './../../ui/Breadcrumb/Breadcrumb';
import { changeTitle, checkDay, secondsToHms } from '../../../helpers/_Functions';
import TravelModal from './../TravelPage/TravelShare/TravelModal';
import ErrorBoundaryTravelMap from './../../ErrorBoundary/ErrorBoundaryTravelMap';
import { WindowSize } from './../../../helpers/WindowSize';
import TravelTab from './../TravelPage/TravelTab/TravelTab';
import { Link } from 'react-router-dom';
import DaysTabs from '../../ui/DaysTabs/DaysTabs';
import TravelCards from '../../ui/TravelCards/TravelCards';
import { GetIcon } from '../../../Helper';
import { StarFilled, UnorderedListOutlined, EnvironmentOutlined } from '@ant-design/icons';
import TravelMap from '../CustomizeTripPage/TravelMap';

const ButtonGroup = Button.Group;
const { Title, Text } = Typography;

const TravelInfoContent = () => {
	const { identifier, difficulty, type: day = '' } = useParams();
	const navigate = useNavigate();
	const { width } = WindowSize();

	const travel = useSelector(getTravel());
	const travels = useSelector(getTravels());
	const routes = useSelector(getRoutes());
	const sights = useSelector(getSights());

	const [tab, setTab] = useState('List');
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [tripRoute, setTripRoute] = useState({});

	const route = useMemo(() => (day === 'All' ? routes.find(route => route.daysOrder === day) : travel.route.find(route => route.daysOrder === day)), [day, routes, travel]);
	const difficulties = useMemo(() => travels.map(travel => travel.difficulty), [travels]);
	const routeSights = useMemo(() => routes.find(route => route.daysOrder === day).sights, [routes, day]);
	console.log(identifier, difficulty, routes);
	//need optimization
	const objSights = [];
	sights.map(sight => {
		objSights[sight.identifier] = sight;
	});
	//need optimization

	const changeRouteOnTab = activeKey => {
		console.log(activeKey);
		// scroller.scrollTo('travel-sights__cards', {
		// 	duration: 1500,
		// 	smooth: true,
		// 	containerId: 'travel-sights',
		// });
		navigate(`/trip/${travel.identifier}/${travel.difficulty}/day/${activeKey}/`);
	};

	const getLinkToGoogle = (prev, curr) => {
		prev = sights.find(sight => sight.identifier === prev);
		curr = sights.find(sight => sight.identifier === curr);
		if (prev === undefined) {
			let prevDaySight = travel.route[day - 2].sights[travel.route[day - 2].sights.length - 1];
			prev = sights.find(sight => sight.identifier === prevDaySight);
		}
		if (prev && curr) {
			const route = `${prev.location[1]},+${prev.location[0]}/${curr.location[1]},+${curr.location[0]}`;
			window.open('https://www.google.com.ua/maps/dir/' + route, '_blank');
		}
	};

	const getRoute = () => {
		let route = [];
		travel.route[Number(day) - 1].sights.map((el, index) => {
			sights.filter(sight => {
				if (sight.identifier === el && sight.hasOwnProperty('images')) {
					if (index === 1) {
						route.push(`${sight.location[1]},${sight.location[0]}/`);
					} else if (index < 10 || index === travel.route[Number(day) - 1].sights.length - 1) {
						route.push(`${sight.location[1]},+${sight.location[0]}/`);
					}
				}
			});
		});

		window.open('https://www.google.com.ua/maps/dir/' + route.join(''), '_blank');
	};

	const handleChangeVisible = visibility => setIsModalVisible(visibility);
	const handleChangeTab = type => setTab(type);
	const handleChangeTripRoute = route => setTripRoute(route);

	const travelDistance = `${Math.floor(travel.distance / 1000)} km | ${Math.floor(travel.distance / 1609.344)} mi`,
		dayDistance = day !== 'All' && `~${(tripRoute.totalDistance / 1000).toFixed(2)} km | ${(tripRoute.totalDistance / 1609.344).toFixed(2)} mi ~${secondsToHms(tripRoute.totalTime)}`;
	const allRoutesSights = routes.find(route => route.daysOrder === 'All').sights;
	console.log(day);
	return (
		<>
			<Row className='travel-header'>
				<BreadcrumbComponent travel={travel} day={day} />
				<Row>
					<Col lg={14} md={14} sm={24} xs={24}>
						<Col className='travel-header__subinfo'>
							<Text>Day-by-day itinerary. Details and routes.</Text>
							<ButtonIntensity difficulties={difficulties} difficulty={difficulty} identifier={travel.identifier} day={day} />
						</Col>
						<Col className='travel-header__titles'>
							<Title level={1} className='travel-header__title'>
								{changeTitle(travel.title)}
							</Title>
							<Text className='travel-header__day'>{checkDay(travel.daysNumber)}</Text>
						</Col>

						<Text className='travel-header__subtitle'>{travel.subtitle}</Text>
					</Col>

					<Col lg={10} md={10} sm={24} xs={24} className='travel-header__info'>
						<Col className='travel-header__info-distance'>
							<Col className='travel-header__info-distance__trip'>
								<Text className='travel-header__info-title'>
									<i className='material-icons'>route</i> Trip Distance:
								</Text>
								<Text className='travel-header__info-subtitle'>{travelDistance}</Text>
							</Col>
							{day !== 'All' && (
								<Col className='travel-header__info-distance__day'>
									<Text className='travel-header__info-title'>
										<i className='material-icons'>drive_eta</i> Day {day} Distance:
									</Text>
									<Text className='travel-header__info-subtitle'>{dayDistance}</Text>
								</Col>
							)}
						</Col>
					</Col>
				</Row>
			</Row>

			<main className='travel-content'>
				{width < 769 && (
					<ButtonGroup className='days-menu--mobile'>
						<Col xs={12}>
							<Button className='days-menu__button' onClick={() => handleChangeTab('List')}>
								<UnorderedListOutlined className={tab === 'List' ? 'primary' : 'default'} />
								<span className={`days-menu__button-text ${tab === 'List' ? 'primary' : 'default'}`}>List</span>
							</Button>
						</Col>
						<Col xs={12}>
							<Button className='days-menu__button' onClick={() => handleChangeTab('Map')}>
								<EnvironmentOutlined className={tab === 'Map' ? 'primary' : 'default'} />
								<span className={`days-menu__button-text ${tab === 'Map' ? 'primary' : 'default'}`}>Map</span>
							</Button>
						</Col>
					</ButtonGroup>
				)}

				{(tab === 'List' || width > 769) && (
					<Row className='travel-sights'>
						<Row className='travel-sights__content' id='travel-sights'>
							{tab !== 'Map' && (
								<Col className='travel-sights__days' xxl={2} lg={4} md={4} sm={24} xs={24}>
									<DaysTabs routes={travel.route} day={day} changeRouteOnTab={changeRouteOnTab} />
								</Col>
							)}

							<Col className='travel-sights__cards' id='travel-sights__cards' xxl={22} lg={20} md={20} sm={24} xs={24}>
								{day !== 'All' ? (
									<Col className='travel-sights__edit'>
										<Tooltip title='Routes on google.maps'>
											<Button
												shape='circle'
												className='travel-sights__btn-map'
												onClick={getRoute}
												icon={<GetIcon src='google-map.svg' className='travel-header__btn-map__icon' />}
											/>
										</Tooltip>
										<Button className='travel-button__edit' onClick={() => navigate(`/trip/edit/${identifier}/${difficulty}/day/${day}`)}>
											Edit
										</Button>
									</Col>
								) : (
									false
								)}

								{day === 'All' ? (
									routes.slice(0, -1).map(route => (
										<StickyContainer key={route.daysOrder} className='travel-sights__cards-day'>
											<Sticky>{({ style }) => <h5 style={style}>Day {route.daysOrder}</h5>}</Sticky>

											<TravelCards routes={routes} objSights={objSights} day={route.daysOrder} route={route} identifier={identifier} getLinkToGoogle={getLinkToGoogle} />
										</StickyContainer>
										// <StickyContainer key={route.daysOrder} className='travel-sights__cards-day'>
										// 		<Sticky>{({ style }) => <h5 style={style}>Day {route.daysOrder}</h5>}</Sticky>

										// 		<TravelCards routes={routes} objSights={objSights} day={route.daysOrder} route={route} identifier={identifier} getLinkToGoogle={getLinkToGoogle} />
										// 	</StickyContainer>
									))
								) : (
									<TravelCards routes={routes} objSights={objSights} day={day} route={route} identifier={identifier} getLinkToGoogle={getLinkToGoogle} />
								)}

								<Row className='pics-contribution' name='pics-contribution'>
									<Title level={3}>Photos Info</Title>
									{route.sights.map((sight, key) => {
										if (objSights[sight] && objSights[sight].hasOwnProperty('images') && objSights[sight].images[0] !== undefined && objSights[sight].images[0].author) {
											return (
												<Col className='pics-contribution__info' key={key}>
													{objSights[sight].images[0].author && <p>Author: {objSights[sight].images[0].author}</p>}
													{objSights[sight].images[0].url && (
														<Text>
															<a href={objSights[sight].images[0].url}>Source</a>
														</Text>
													)}
													{objSights[sight].images[0].license !== '' && objSights[sight].images[0].license && (
														<Text>
															<a href={objSights[sight].images[0].license}>License</a>
														</Text>
													)}
												</Col>
											);
										}
									})}
								</Row>
							</Col>
						</Row>
					</Row>
				)}

				{(tab === 'Map' || width > 769) && routeSights && (
					<ErrorBoundaryTravelMap page='TravelMap'>
						<TravelMap
							className='travel-map'
							allRoutesSights={allRoutesSights}
							handleChangeTripRoute={handleChangeTripRoute}
							sights={sights}
							routeSights={routeSights}
							day={day}
							identifier={identifier}
						/>
					</ErrorBoundaryTravelMap>
				)}

				<TravelModal isModalVisible={isModalVisible} onChangeVisible={handleChangeVisible} />
			</main>
		</>
	);
};

export default TravelInfoContent;
