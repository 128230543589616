export function generateAuthError(message) {
	switch (message) {
		case 'EMAIL_NOT_FOUND':
			return 'User not found, please sign up';
		case 'INVALID_PASSWORD':
			return 'Email or password is incorrect';
		case 'EMAIL_EXISTS':
			return 'User already exists! ';
		default:
			return '';
	}
}
