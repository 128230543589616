import React, { memo } from 'react';
import { Tooltip, Steps, Typography, List, Skeleton } from 'antd';
import { useSelector } from 'react-redux';

import { checkControlExisting, convertMeasure } from '../../../helpers/_Functions';
import TravelCard from './TravelCard';

import { getSearchInfo } from '../../../state/reducers/SearchReducer';

const { Text } = Typography;
const { Step } = Steps;

const TravelCards = ({ route: { sights = [] }, title = '', day, routes, routeLoading, getLinkToGoogle, objSights, ...rest }) => {
	const Search = useSelector(state => state.Search);
	const firstDay = Number(day) === 1 && !(checkControlExisting(day, 'airport', Search) || checkControlExisting(day, 'car', Search) || checkControlExisting(day, 'start', Search));

	const getLeg = index => {
		return routes[Number(day) - 1].route.legs[
			Number(day) === 1
				? checkControlExisting(day, 'car', Search) && checkControlExisting(day, 'start', Search) && checkControlExisting(day, 'airport', Search)
					? index + 2
					: (checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'start', Search)) ||
					  (checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'car', Search)) ||
					  (checkControlExisting(day, 'car', Search) && checkControlExisting(day, 'start', Search))
					? index + 1
					: checkControlExisting(day, 'car', Search) || checkControlExisting(day, 'start', Search) || checkControlExisting(day, 'airport', Search)
					? index
					: index - 1
				: index
		];
	};

	return (
		<Skeleton loading={routeLoading} active>
			<List
				dataSource={sights}>
					<Steps direction='vertical' status='finish'>
						{sights.map((sight, index, arr) => {
							if (objSights[sight])
								return (
									<Step
										key={index}
										title={
											firstDay && index === 0 ? (
												<Text>{title}</Text>
											) : (
												<>
													{getLeg(index)?.identifier ? (
														<div>{getLeg(index).identifier}</div>
													) : (
														<Tooltip title='view on google'>
															<Text
																className='travel-sights__step-title'
																onClick={() => getLinkToGoogle(arr[day === 1 && !(Search?.airport || Search?.car || Search?.start) ? index - 1 : index], sight)}>
																{getLeg(index)
																	? `${convertMeasure(getLeg(index).distance, 'km').toFixed(2)} km | ${convertMeasure(getLeg(index).distance, 'mi').toFixed(2)} mi`
																	: ''}
															</Text>
														</Tooltip>
													)}
													{Number(day) !== 1 && index === 0 && <Text className='travel-sights__step-subtitle'>from last sight of day {Number(day) - 1}</Text>}
												</>
											)
										}
										status='finish'
										name={sight}
										icon={<i className='material-icons'>drive_eta</i>}
										description={<TravelCard sights={sights} objSights={objSights} Search={Search} day={day} sight={sight} {...rest} />}
									/>
								);
						})}
						<Step className='modify-btns__step' />
					</Steps>
				</List>
		</Skeleton>
	);
};

export default memo(TravelCards);
