import React, { useRef, memo } from 'react';
import { Button, Tooltip, Typography, Col, Row } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, ZoomControl, Marker, Circle, Tooltip as TooltipMarker, ScaleControl } from 'react-leaflet';
import L from 'leaflet';

import * as ActionsPage from '../../../state/actions/MapActions';
import * as SearchActions from '../../../state/actions/SearchAction';
import SearchLocation from '../../ui/Search/SearchLocation';
import { ImageFinder } from '../../../Helper';
import { Link } from 'react-router-dom';
import { MapScale } from './../../common/MapScale';

const Map = ({ center, openedTooltip, openTooltip, zoom, handleSetSight, circleParams, findNear, sights, findInArea, Search, SearchActions }) => {
	const mapRef = useRef();
	const findNearMe = () => {
		navigator.geolocation.getCurrentPosition(findNear);
	};

	const findButtons = (
		<>
			<Tooltip title='Find in this area' defaultOpen={true} overlayClassName='toltip-find toltip'>
				<Button
					className='explore-map__btn'
					onClick={() => {
						findInArea(mapRef.current.leafletElement.getBounds());
						// Search.item?.label && SearchActions.deleteLocation();
					}}>
					<span className='material-icons explore-map__icon'>open_in_full</span>
				</Button>
			</Tooltip>

			<Tooltip title='Find near me' defaultOpen={true} overlayClassName='toltip-near toltip'>
				<Button
					className='explore-map__btn explore-map__icon'
					onClick={() => {
						findNearMe();
					}}>
					<span className='material-icons'>accessibility_new</span>
				</Button>
			</Tooltip>
		</>
	);

	const getMarker = sight => {
		if (sight?.images) {
			return L.divIcon({
				className: 'custom-div-icon',
				html: `<div class="marker--blue${sight?.active ? '-active' : sight?.hovered ? '-hovered' : ''}"></div>`,
			});
		} else {
			return L.divIcon({
				className: 'custom-div-icon',
				html: '<div class="explore-marker__search"><i class="fas fa-location-arrow"></i></div>',
			});
		}
	};

	return (
		<>
			<Typography.Title level={3} className='explore-map__title'>
				All sights in this area:
			</Typography.Title>
			<Row gutter={10} className='explore-map__btns'>
				<SearchLocation className='explore-map__search' />
				{findButtons}
			</Row>
			<MapContainer ref={mapRef} center={center} zoom={zoom} zoomControl={false} className='explore-map__map'>
				<TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
				<ZoomControl position='bottomright' />
				<ScaleControl position='bottomleft' />
				{/* <MapScale options={{ imperial: false }} /> */}
				{sights.length
					? sights.map((sight, index) => {
							return (
								<Marker
									key={sight.identifier + index}
									position={[sight.location[1], sight.location[0]]}
									riseOnHover='true'
									title={sight.title}
									onMouseOver={() => {
										openTooltip(sight.identifier);
									}}
									onFocus={() => {
										openTooltip(sight.identifier);
									}}
									onBlur={() => 0}
									onClick={e => {
										console.log(e.target.options, openedTooltip);
										!sight?.search && handleSetSight(sight, 'marker');
									}}
									icon={getMarker(sight)}>
									{openedTooltip === sight.identifier ? (
										<div
											onMouseLeave={() => {
												openTooltip('');
											}}>
											{sight?.images ? (
												<Link to={`/sight/${sight.identifier}/`}>
													<Tooltip onClick={() => handleSetSight(sight, 'tooltip')} permanent interactive={true} opacity={1} className='explore-tooltip'>
														<ImageFinder src={sight.images[0].name} className='explore-tooltip__img' />
														<Col>
															<Typography.Text className='explore-tooltip__title'>{sight.title}</Typography.Text>
															<Typography.Text className='explore-tooltip__text'>{sight.subtitle}</Typography.Text>
														</Col>
													</Tooltip>
												</Link>
											) : (
												<Tooltip permanent interactive={true} opacity={1} className='explore-tooltip'>
													<Col>
														<Typography.Title level={3} className='explore-tooltip__title'>
															{sight.title}
														</Typography.Title>
													</Col>
												</Tooltip>
											)}
										</div>
									) : (
										false
									)}
								</Marker>
							);
					  })
					: false}
				{circleParams.center.length ? <Circle center={[circleParams.center[1], circleParams.center[0]]} radius={circleParams.radius} /> : false}
			</MapContainer>
		</>
	);
};

export default memo(Map);
