import React, { useState, useEffect, memo } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { Row, Col, Typography } from 'antd';

import { GetIcon } from '../../../../Helper';
import SearchLocation from '../../../ui/Search/SearchLocation';
import * as SearchActions from '../../../../state/actions/SearchAction';
import * as TravelInfoActions from '../../../../state/actions/TravelInfoActions';
import { deleteSight } from '../../../../state/actions/CustomizeTripActions';
import { deleteSearchControl } from './../../../../state/actions/SearchAction';
import { getControl } from '../../../../state/reducers/SearchReducer';

const { Text } = Typography;

const TravelModifyFinish = props => {
	const { 
		routes = [],
		identifier = '', 
		day = '', 
		sights = [],
		updateTravelControls,
		addToSights,
	} = props;
	const dispatch = useDispatch();
	const finish = useSelector(getControl('finish'));
	const [showFinish, setShowFinish] = useState(false);
	const [finishInfo, setFinishInfo] = useState('');

	const index = routes[Number(day) - 1].sights.length;

	const deleteFinish = () => {
		dispatch(deleteSight({ identifier: finishInfo.label, day}));
		dispatch(deleteSearchControl({type: 'finish', identifier: finishInfo.label, day}));
		setShowFinish(false);
		setFinishInfo('');
	};

	useEffect(() => {
		if (finish.length) {
			let currFinish = finish.find(item => item.identifier === identifier && item.day === day);
			if (currFinish) {
				if (!sights.find(el => el.title === currFinish.data.label && el.day === day)) {
					addToSights({obj: currFinish, type: 'finish', icon: 'fa-calendar-check', index});
					setFinishInfo(currFinish.data);
					setShowFinish(true);
				}
			}
		}
	}, [finish]);

	useEffect(() => {
		if (finish.length && !finish.find(el => el.day === day)) {
			setFinishInfo('');
			setShowFinish(false);
		} else {
			let currStart = finish.find(item => item.identifier === identifier && item.day === day);
			if (currStart) {
				setFinishInfo(currStart.data);
				setShowFinish(true);
			}
		}
	}, [day]);

	return (
		<Row className='modify-btns'>
			<Col lg={4} xs={4} className='modify-icon'>
				<GetIcon src='map-location.png' />
				<Text className='modify-title'>Finish</Text>
			</Col>
			<Col lg={20} xs={20} className='modify-text'>
				{!showFinish && (
					<div style={{ padding: '0' }}>
						<SearchLocation controlType='finish' />
					</div>
				)}

				{showFinish && finishInfo && (
					<Col lg={20} xs={20}>
						<h3 className={'modifyInfo'}>{finishInfo.text}</h3>
						<a href={`https://www.google.com/maps/place/${finishInfo.y},${finishInfo.x}`} className='modify-link' target='_blank' rel='nofollow noopener noreferrer'>
							<span className='material-icons modify-link__marker'>place</span>
							<Text className='modify-link__text'>
								{finishInfo.y}, {finishInfo.x}
							</Text>
						</a>
					</Col>
				)}

				{showFinish && finishInfo && (
					<Col lg={4} xs={4} onClick={deleteFinish} className={'closeModify'}>
						<CloseOutlined />
					</Col>
				)}

				{!showFinish && (
					<Col lg={4} xs={4} onClick={() => updateTravelControls({ finish: day })} aria-hidden='true' className={'closeModify'}>
						<CloseOutlined />
					</Col>
				)}
			</Col>
		</Row>
	);
};

export default memo(TravelModifyFinish);
