import React, { memo, useEffect, useState, useCallback, useMemo } from 'react';
import { ZoomControl, MapContainer, ScaleControl, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
function DisplayPosition({ map }) {
	const [position, setPosition] = useState(() => map.getCenter());

	const onMove = useCallback(() => {
		setPosition(map.getCenter());
	}, [map]);

	useEffect(() => {
		map.on('move', onMove);
		map.flyTo(L.latLng(52.45473919037913, -118.00277709960939))
		return () => {
			map.off('move', onMove);
		};
	}, [map, onMove]);
	console.log(position);
	return null;
}
const Map = ({ className, children, visibility, center, zoom = true, bounds = [], scale = true, zoomPosition = 'bottomright', scalePosition = 'bottomleft' }) => {
	function SetBoundsComponent() {
		const map = useMap();
		map.fitBounds(bounds);
		console.log(map.getBounds())
		// map.flyTo(L.latLng(map.getCenter().lat-1, map.getCenter().lng-1), map.getZoom())
		return null;
	}



	const [map, setMap] = useState(null);

	useEffect(() => {
		if(visibility !== undefined && map){
		console.log('visibility', visibility, bounds, map)
		map.invalidateSize();
		}
		console.log('visibility', visibility)
	}, [visibility]);

	return (
		<MapContainer className={className} zoomControl={false} ref={setMap}>
			{zoom && <ZoomControl position={zoomPosition} />}
			{scale && <ScaleControl position={scalePosition} />}
			<TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
			<SetBoundsComponent />
			{/* {map ? <DisplayPosition map={map} /> : null} */}
			{children}
		</MapContainer>
	);
};

export default memo(Map);
