import { useRef } from 'react';

export default function useIsFirstRender() {
	const isFirst = useRef(true);

	if (isFirst.current) {
		isFirst.current = false;
		return true;
	}
	return isFirst.current;
}
