import React, { useState, memo } from 'react';
import { Layout, Card, Col, Row, Tag, Divider, Tooltip, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ImageFinder } from './../../../../Helper';
import { changeTitle, getColor, checkDay } from '../../../../helpers/_Functions';
import { Link, useNavigate } from 'react-router-dom';
import DayFilter from './../TripsFilters/DayFilter';
import IntensityFilter from './../TripsFilters/IntensityFilter';
import DistanceFilter from './../TripsFilters/DistanceFilter';
import RatingFilter from './../TripsFilters/RatingFilter';
import CalendarFilter from './../TripsFilters/CalendarFilter';
import { ALLDAYS } from '../../../../constants/Duration';
import { DeleteOutlined, StarFilled } from '@ant-design/icons';
import { deleteTrip } from '../../../../state/actions/UserActions';

const TripsContent = () => {
	const [filters, setFilters] = useState({});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const deleteFromTrips = (id, identifier, difficulty, days) => dispatch(deleteTrip(id, identifier, difficulty, days));
	const { trips } = useSelector(state => state.UserPage);
	const handleChangeFilters = (filter, value) => {
		setFilters(prev => ({ ...prev, [filter]: value }));
	};

	const filterTrips = data => {
		const filteredTrips = Object.keys(filters).length
			? data.filter(({ travel }) =>
					Object.keys(filters).every(filter => {
						if (filter === 'day') {
							return filters[filter] !== 'All' ? ALLDAYS[filters[filter]].includes(Number(travel.daysNumber)) : data;
						} else if (filter === 'rating') {
							if (filters[filter] === '2') {
								return travel[filter] < Number(filters[filter]) * 20;
							} else if (filters[filter] === 'all') {
								return data;
							} else {
								return travel[filter] < Number(filters[filter]) * 20 && travel[filter] > Number(filters[filter]) * 20 - 20;
							}
						} else if (filter === 'distance') {
							return travel[filter] / 1000 > filters[filter][0] && travel[filter] / 1000 < filters[filter][1];
						} else {
							if (filters[filter] === 'all') {
								return data;
							} else {
								return travel[filter] === filters[filter];
							}
						}
					}),
			  )
			: data;
		return filteredTrips;
	};

	const clearFilters = () => setFilters({});

	const filteredTrips = filterTrips(trips);

	return (
		<Layout>
			<Row gutter={[16, 16]}>
				<Col span={18}>
					{filteredTrips.length ? (
						<Row gutter={[16, 16]}>
							{filteredTrips.map(el => (
								<Col span={8} key={el.travel.identifier + el.travel.difficulty}>
									<Card
										hoverable
										className='user-trips__card'
										cover={
											<Col className='user-trips__card-img__container'>
												<Tag className='user-trips__card-img__rating'>
													<StarFilled style={{ color: '#f7f152' }} />
													{el.travel.rating / 20}
												</Tag>
												<ImageFinder alt={el.travel.imageTitle} title={el.travel.imageTitle} src={el.travel.imageTitle} className='user-trips__card-img' />
												<DeleteOutlined className='user-trips__card-img__delete' style={{ fontSize: '16px' }} onClick={() => deleteFromTrips(el._id, el.travel.identifier, el.travel.difficulty, el.travel.daysNumber)} />
											</Col>
										}>
										{/* <Link to={`/trip/${el.travel.identifier}/moderate/day/1`}> */}
										<Row>
											<Col className='user-trips__card-header'>
												<Col className='user-trips__card-header__titles'>
													<span className='user-trips__card-header__title'>{changeTitle(el.travel.title)}</span>
													<span className='user-trips__card-header__subtitle'>{el.travel.subtitle}</span>
												</Col>
												<Button className='user-trips__btn-edit' onClick={() => navigate(`/trip/edit/${el.travel.identifier}/${el.travel.difficulty}/day/1`)}>
													Edit
												</Button>
											</Col>

											<Col className='user-trips__card-info'>
												{checkDay(el.travel.daysNumber)}
												<Tag className='mar-l-5' color={getColor(el.travel.difficulty)}>
													{el.travel.difficulty}
												</Tag>
											</Col>
										</Row>

										{/* <Meta title={changeTitle(el.travel.title)} description={el.travel.subtitle} /> */}
										{/* </Link> */}
									</Card>
								</Col>
							))}
						</Row>
					) : (
						<h2>Trips list is empty</h2>
					)}
				</Col>

				<Col span={6}>
					<Col className='user-trips__filters'>
						<div className='user-trips__filters-header'>
							<span className='user-trips__filters-title'>Filter by</span>
							<Tooltip title='Clear Filters'>
								<span className='btn-clear' onClick={clearFilters} role='button' onKeyPress={clearFilters} tabIndex='0'>
									Clear
								</span>
							</Tooltip>
						</div>

						<Divider className='user-trips__filters-divider' />
						<DayFilter day={filters.day} handleChangeFilters={handleChangeFilters} />
						<IntensityFilter difficulty={filters.difficulty} handleChangeFilters={handleChangeFilters} />
						<RatingFilter rating={filters.rating} handleChangeFilters={handleChangeFilters} />
						<DistanceFilter distance={filters.distance} handleChangeFilters={handleChangeFilters} />
					</Col>
					<CalendarFilter />
				</Col>
			</Row>
		</Layout>
	);
};

export default memo(TripsContent);
