import React from 'react';
import { Col, Row, Typography } from 'antd';
import { ImageFinder } from './../../../../Helper';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

const TravelCard = ({identifier, sight, objSights}) => {
	return (
		<Row className='days-card' key={sight} name={sight}>

			<Col>
				<Col>
					<Link to={`/trip/${identifier}/sight/${objSights[sight].identifier}/`} className='days-card_img'>
						<ImageFinder
							alt={sight}
							src={objSights[sight].images && objSights[sight].images.length && objSights[sight].images[0].hasOwnProperty('name') ? objSights[sight].images[0].name : 'default-img.jpg'}
							className='days-card_img'
						/>
					</Link>
				</Col>

				<Col className='days-card_info'>
					<Link to={`/trip/${identifier}/sight/${objSights[sight].identifier}/`}>
						<Title level={2} className='days-card_info-title'>
							{objSights[sight].title}
						</Title>
					</Link>
					<Text className='days-card_info-subtitle'>{objSights[sight].subtitle}</Text>
					<a href={`https://www.google.com/maps/place/${objSights[sight].location[1]},${objSights[sight].location[0]}`} target='_blank' rel='nofollow noopener noreferrer'>
						<span className='material-icons days-card_marker'>place</span>
						<Text className='days-card_info-subtitle_link'>
							{objSights[sight].location[1]}, {objSights[sight].location[0]}
						</Text>
					</a>
					<Text className='days-card_info-description'>{objSights[sight].description}</Text>
				</Col>
			</Col>
		</Row>
	);
};

export default TravelCard;
