import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	travel: {},
	travels: [],
	sights: [],
	routes: [],
	isLoading: true,
	error: null
};

const travelSlice = createSlice({
	name: 'TravelPage',
	initialState,
	reducers: {
		travelRequested: state => {
			state.isLoading = true;
		},
		travelReceived: (state, action) => {
			state.travel = action.payload;
		},
		travelsReceived: (state, action) => {
			state.travels = action.payload;
		},
		routesReceived: (state, action) => {
			state.routes = action.payload;
		},
		sightsReceived: (state, action) => {
			state.sights = action.payload;
			state.isLoading = false;
		},
		travelRequestFailed: (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		},
		sightsRequestFailed: (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

const { actions, reducer: TravelReducer } = travelSlice;

export const { travelRequested, travelReceived, travelsReceived, routesReceived, sightsReceived, travelRequestFailed, sightsRequestFailed } = actions;

export { TravelReducer };

export const getTravel = () => state => state.TravelPage.travel;
export const getTravels = () => state => state.TravelPage.travels;
export const getRoutes = () => state => state.TravelPage.routes;
export const getSights = () => state => state.TravelPage.sights;
export const getLoadingStatus = () => state => state.TravelPage.isLoading;