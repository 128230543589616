import axios from 'axios';
import configFile from '../config.json';

const httpSearch = axios.create({
	baseURL: configFile.apiMapboxEndpoint,
	params: {
		access_token: configFile.API_KEY_MAPBOX,
		autocomplete: true,
		country: 'us,ca',
		proximity: 'ip'
	}
});

const searchEndpoint = 'mapbox.places/';

const searchMapboxService = {
	get: async (query) => {
		const { data } = await httpSearch.get(`${searchEndpoint}${query}.json`);
		return data;
	},
	getCategory: async(query, category) => {
		const {data: { features }} = await httpSearch.get(`${searchEndpoint}${query}.json`, {
			params: {
				types: 'poi',
				limit: 10,
			}
		});
		return features.filter(location => location.properties.category.includes(category));
	}

};
export default searchMapboxService;