import React from 'react';
import { Layout, Typography } from 'antd';
import { GetIcon } from '../../../Helper';
import { MailFilled } from '@ant-design/icons';
import './Message.sass';

const { Title, Text } = Typography;

export default function ErrorMessage(props) {
    const {
        text = 'Sorry, service isn\'t available now. Please try again later.',
        subtext = 'Or contact us.', 
        className = 'error-message'
    } = props;

    return (
        <Layout className={className}>
            <Title level={1}>Oops!</Title>
            <Text>{text}</Text>
            <GetIcon src="error_img.png" className="error-message_img" />
            <Text>{subtext}</Text>
            <Text><MailFilled /> before.voyage@gmail.com</Text>
        </Layout>
    );
}
