import React from 'react';
import { Row, Typography, Col } from 'antd';

const PicsContribution = ({ objSights, route }) => {
	return (
		<Row className='pics-contribution' name='pics-contribution'>
			<Typography.Title level={3}>Photos Info</Typography.Title>
			{route.sights.map((sight, key) => {
				if (objSights[sight] && objSights[sight].hasOwnProperty('images') && objSights[sight].images[0] !== undefined && objSights[sight].images[0].author) {
					return (
						<Col className='pics-contribution__info' key={key}>
							{objSights[sight].images[0].author && <p>Author: {objSights[sight].images[0].author}</p>}
							{objSights[sight].images[0].url && (
								<Typography.Text>
									<a href={objSights[sight].images[0].url}>Source</a>
								</Typography.Text>
							)}
							{objSights[sight].images[0].license !== '' && objSights[sight].images[0].license && (
								<Typography.Text>
									<a href={objSights[sight].images[0].license}>License</a>
								</Typography.Text>
							)}
						</Col>
					);
				}
			})}
		</Row>
	);
};

export default PicsContribution;
