import React from 'react';
import { Col, Slider } from 'antd';

const DistanceFilter = ({ distance = [200, 900], handleChangeFilters }) => {
	const handleChange = value => {
		handleChangeFilters('distance', value);
	};
	return (
		<Col className='user-trips__filters-distance'>
			<span className='user-trips__filters-subtitle'>Trip Distance (km)</span>
			<Slider className='user-trips__filters-distance__slider' range value={distance} max={1500} min={200} marks={{ 200: '200', 1500: '1500' }} onChange={handleChange} />
		</Col>
	);
};

export default DistanceFilter;
