import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Tabs, Col, Carousel, Typography } from 'antd';

import { popularCities } from './../../../constants/Information';
import { ImageFinder } from './../../../Helper';
import { Loader } from './../../common/Loader/Loader';
import ErrorMessage from './../../common/Message/Message';

const { Title, Text } = Typography;

const CitiesTravels = ({ travels, message, settings, loading, changeCitiesTravels }) => {
	const items = popularCities.map((label, key) => ({
		label,
		key,
		children: !loading ? (
			<Carousel {...settings}>
				{travels.map((travel, index) => (
					<Col lg={8} xs={24} sm={24} md={8} className='zeropage-popular__card' key={`popular-card-${index}`}>
						<ImageFinder src={travel.imageTitle} className='zeropage-popular__card-img' alt={travel.imageTitle} />
						<span className='zeropage-popular__card-rate'>{travel.rating / 20 < 4.5 ? travel.rating / 20 + 0.5 : travel.rating / 20} </span>
						<div className='zeropage-popular__card-titles'>
							<a href={`/trip/${travel.identifier}/${travel.difficulty}/`}>
								<Title level={4} className='zeropage-popular__card-title'>
									{travel.title}
								</Title>
							</a>
							<div className='zeropage-popular__card-subtitle'>
								{travel.hashtags.slice(0, 5).map((el, key) => (
									<Link key={`hashtag-${key}-${index}`} to={{ pathname: '/trips/All', hash: `${el}` }}>
										#{el}
									</Link>
								))}
							</div>
						</div>
					</Col>
				))}
				<Col className='zeropage-popular__info'>
					<Link to={{ pathname: '/trips/All', hash: `${label}` }} className='zeropage-arrow--find'>
						Show More <ArrowRightOutlined />
					</Link>
					<Text>#{label}</Text>
				</Col>
			</Carousel>
		) : (
			<Loader />
		),
	}));
	return (
		<>
			<Tabs className='zeropage-popular__content' tabBarGutter={45} onChange={key => changeCitiesTravels(key)} items={items} />
			{message ? <ErrorMessage /> : false}
		</>
	);
};

export default CitiesTravels;
