import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Link as AnimatedLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel, Row, Col, Typography } from 'antd';
import { GetIcon } from '../../../Helper';

const { Text } = Typography;
let settings = {
	draggable: false,
	infinite: false,
	slidesToShow: 8,
	lazyLoad: true,
	dots: false,
	arrows: false,
	className: 'center',
	responsive: [
		{
			breakpoint: 480,
			settings: {
				arrows: true,
				className: 'center',
				speed: 500,
				swipeToSlide: true,
				centerMode: true,
				initialSlide: 2,
				infinite: true,
				focusOnSelect: true,
				slidesToShow: 3,
				nextArrow: <RightOutlined />,
				prevArrow: <LeftOutlined />,
			},
		},
	],
};

const images = ['photos.svg', 'map.svg', 'day-by-day.svg', 'stay.svg', 'flights.svg', 'tips.svg', 'reviews.svg', 'alternatives.svg'];
const titles = ['Photos', 'Roadmap', 'Day-by-day', 'Stay', 'Flights', 'Tips', 'Reviews', 'Alternatives'];

const QuickMenu = () => {
	const { identifier = '', difficulty = 'moderate' } = useSelector(state => state.TravelOverviewPage.travel);

	return (
		<Row className='overview-quick-menu'>
			<Carousel {...settings}>
				{images.map((item, index) => {
					if (titles[index] == 'Day-by-day') {
						return (
							<Link to={`/trip/${identifier}/${difficulty}/day/1`} key={titles[index]}>
								<Col className='overview-quick-menu__link'>
									<GetIcon src={item} alt={'icon'} className='overview-quick-menu__icon' />
									<Text className='overview-quick-menu__title'>{titles[index]}</Text>
								</Col>
							</Link>
						);
					} else {
						return (
							<AnimatedLink to={`overview-${titles[index].replace(/\s+/g, '').toLowerCase()}`} spy={true} smooth={true} duration={500} key={titles[index]}>
								<Col className='overview-quick-menu__link'>
									<GetIcon src={item} alt={'icon'} className='overview-quick-menu__icon' />
									<Text className='overview-quick-menu__title'>{titles[index]}</Text>
								</Col>
							</AnimatedLink>
						);
					}
				})}
			</Carousel>
		</Row>
	);
};

export default memo(QuickMenu);
