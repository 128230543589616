import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link, useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { Col } from 'antd';

const BurgerMenu = props => {
	let { menuOpen, handleStateChange, toggleMenu, burgerMenuClass = 'burger-menu', pathname, isLoggedIn, auth } = props;
	const navigate = useNavigate();

	return (
		<div className={burgerMenuClass}>
			<Menu {...props} right isOpen={menuOpen} onStateChange={state => handleStateChange(state)}>
				{pathname !== '/' ? (
					<Link to='/' title='www.before.voyage' onClick={toggleMenu}>
						Home
					</Link>
				) : (
					false
				)}

				<Link to='/trips/All/' title='Trips' onClick={toggleMenu}>
					Trips
				</Link>
				<Link to='/map/itineraries/' title='Map' onClick={toggleMenu}>
					Map
				</Link>
				<Col className='header-content__account-burgermenu'>
					<UserOutlined onClick={() => (isLoggedIn ? navigate(`/user/${auth?.userId}/`) : navigate('/login'))} />
				</Col>
			</Menu>
		</div>
	);
};

export default BurgerMenu;
