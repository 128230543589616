import React  from 'react';
import {ImageFinder} from '../../../Helper';
import { Typography, Col } from 'antd';
const { Title, Text } = Typography;

function ImageSights({imageObj={}, className='catalog-img'}){
    return(
        <>
            <ImageFinder className={className} alt={imageObj.title} title={imageObj.title} src={imageObj.image} />
            <div className="travels-catalog__card">
                <Title level={4} className="travels-catalog__card-title">{imageObj.title}</Title>
                <Text className="travels-catalog__card-subtitle">{imageObj.subtitle}</Text>
            </div>
        </>
    );
}

export default ImageSights;