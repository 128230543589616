import React from 'react';
import { Row, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { ImageFinder } from '../../../../Helper';
import ChangeSightsButtons from './ChangeSightsButtons';
import TravelModifyAirport from './../TravelModify/ModifyAirport';
import TravelModifyStart from './../TravelModify/ModifyStart';
import TravelModifyCar from './../TravelModify/ModifyCar';
import TravelModifyHotel from './../TravelModify/ModifyHotel';

const TravelCard = props => {
	const { Title, Text } = Typography;
	const { objSights, day, Search, identifier, sight } = props;

	if(day == 1){
		if(Search?.start?.find(start => start.day === day && start.data.label === sight)){
			return <TravelModifyStart day={day}  type={'startDay'}/>;
		}
		if(Search?.airport?.find(airport => airport.day === day && airport.data.label === sight)){
			return <TravelModifyAirport day={day} type={'startDay'} />;
		}
		if(Search?.car?.find(car => car.day === day && car.data.label === sight)){
			return <TravelModifyCar day={day} type={'startDay'}/>;
		}
	}

	if(Search?.hotel?.find(hotel => hotel.day === day && hotel.data.label === sight)){
		return <TravelModifyHotel day={day}  />;
	}

	return (
		<Row className='days-card' key={sight} name={sight}>
			<ChangeSightsButtons sight={sight} day={day} identifier={identifier} allDays={props.days} />

			<Col>
				<Col>
					<Link to={`/trip/${identifier}/sight/${objSights[sight].identifier}/`} className='days-card_img'>
						<ImageFinder
							alt={sight}
							src={objSights[sight].images && objSights[sight].images.length && objSights[sight].images[0].hasOwnProperty('name') ? objSights[sight].images[0].name : 'default-img.jpg'}
							className='days-card_img'
						/>
					</Link>
				</Col>

				<Col className='days-card_info'>
					<Link to={`/trip/${identifier}/sight/${objSights[sight].identifier}/`}>
						<Title level={2} className='days-card_info-title'>
							{objSights[sight].title}
						</Title>
					</Link>
					<Text className='days-card_info-subtitle'>{objSights[sight].subtitle}</Text>
					<a href={`https://www.google.com/maps/place/${objSights[sight].location[1]},${objSights[sight].location[0]}`} target='_blank' rel='nofollow noopener noreferrer'>
						<span className='material-icons days-card_marker'>place</span>
						<Text className='days-card_info-subtitle_link'>
							{objSights[sight].location[1]}, {objSights[sight].location[0]}
						</Text>
					</a>
					<Text className='days-card_info-description'>{objSights[sight].description}</Text>
				</Col>
			</Col>
		</Row>
	);
};

export default TravelCard;
