import { routesReceived, sightsNearReceived, sightsNearRequestFailed, travelReceived, travelRequested, sightsReceived, travelsReceived, sightsNearDeleted, sightAdded, sightDeleted, sightMoved, travelRequestFailed } from '../reducers/CustomizeTripReducer';
import sightService from './../../services/sight.service';
import travelService from './../../services/travel.service';
import { getRouteDay } from './../../helpers/_Functions';
import { putSearch } from './SearchAction';

export const loadSightsNear = ({lat, long, day, identifier, distance = 5000}) => async dispatch => {
	try{
		const params = {lat, long, distance};
		const sightsNear = await sightService.getSightsNear(params);
		dispatch(sightsNearReceived(sightsNear));
	}catch(e){
		console.log(e)
		dispatch(sightsNearRequestFailed(e))
	}
};

export const loadUserTravel = (identifier, difficulty) => async (dispatch, getState) => {
	const {trips} = getState().UserPage;
	dispatch(travelRequested());
	try{
		const existingTrip = trips.find(({travel}) => travel.identifier === identifier && travel.difficulty === difficulty) || false;

		const params = {filter: {identifier}};
		const travels = await travelService.get(params);
		dispatch(travelsReceived(travels));
		if(existingTrip){
			dispatch(travelReceived(existingTrip.travel));
			existingTrip.Search && dispatch(putSearch(existingTrip.Search));
			dispatch(routesReceived(existingTrip.routes));
			dispatch(loadSights(existingTrip.routes, existingTrip.Search));
		} else {
			const travel = travels.find(travel => travel.identifier === identifier && travel.difficulty === difficulty);
			dispatch(travelReceived(travel));
			dispatch(loadSightsAndRoutes(travel.route));
		}
	} catch({message}) {
		dispatch(travelRequestFailed(message))
	}
	
};

const loadSightsAndRoutes = route => async (dispatch, getState) => {
		try{ 
		console.log(route);
		// dispatch(loadSights(route))
		const sightsArr = route.map(elem => elem.sights).flat();
		const uniqueSights = [...new Set(sightsArr)];
		const params = { sights: uniqueSights, limit: uniqueSights.length };
		const sights = await sightService.getSightsForTravel(params);
		const routesTrips = await getRouteDay(route, sights);
		console.log(sights)
		dispatch(routesReceived(routesTrips));
		dispatch(sightsReceived({sights}));
	} catch(error){
		console.log(error)

		// dispatch(sightsRequestFailed());
	}
};


const loadSights = (route, search) => async (dispatch) =>  {
	try{
		const sightsArr = route.map(elem => elem.sights).flat();
		const uniqueSights = [...new Set(sightsArr)];
		const params = { sights: uniqueSights, limit: uniqueSights.length };
		const sights = await sightService.getSightsForTravel(params);
		dispatch(sightsReceived({sights, search}));
	} catch(error){
		console.log(error)
	}
	
};


export const loadMoreSightsNear = ({lat, long, day, identifier, distance = 5000}) => async dispatch => {
	try{
		const params = {lat, long, distance};
		const sightsNear = await sightService.getSightsNear(params);
		// console.log(sightsNear)
		dispatch(sightsNearReceived( {data: sightsNear, identifier, day }))
		// dispatch({type: GET_MORE_SIGHTS_NEAR_TRAVELINFO, payload: {data: sightsNear, identifier, day }});
	}catch (e) {
		dispatch(sightsNearRequestFailed(e.message));
		// dispatch(showMessage({ identifier: 'sightNear_TravelInfoPage', message: e.message }));
	}
};

export const deleteMoreSightsNear = (props) => dispatch => {
	dispatch(sightsNearDeleted(props));
};

export const addSight = ({day, sight, index}) => (dispatch) =>{
	// const {routes, sights, travel} = getState().CustomizeTripPage;
	// const newTravel = deepClone(travel), newRoutes = deepClone(routes);
	dispatch(sightAdded({day, sight, index}));
	// if(index === -1){
	// 	// dispatch(sightAdded({day, sight, index}))
	// 	newTravel.route[day-1].sights.push(sight.identifier);
	// 	// let currPos = newTravel.route[day-1].sights.length-1;
	// 	const lastSight = newTravel.route[day-1].sights[newTravel.route[day-1].sights.length-2];
		
	// 	const indexLastSight = newRoutes[day-1].sights.findIndex(s => s === lastSight);
	// 	// console.log(lastSight, indexLastSight);
	// 	newRoutes[day-1].sights.splice(indexLastSight + 1, 0, sight.identifier);
	// } else {
	// 	newRoutes[day-1].sights.splice(index, 0, sight.identifier);
	// }
	dispatch(getRoute())

	// // dispatch({ type: ADD_SIGHT_TRAVELINFO, payload: {sight, travel: newTravel, routes: newRoutes} });

	// dispatch(getRoute(newRoutes.filter(route => route.daysOrder !== 'All'), [...sights, sight], type));
	// type !== 'start' &&  dispatch(getRoute(newRoutes.filter(route => route.daysOrder !== 'All'), [...sights, sight]));
};


// export const addToSights = (props) => dispatch => {
// 	dispatch(sightsAdded(props));
	
// }

export const deleteSight = (props) => (dispatch) => {
	// console.log(identifier, day, type)
	dispatch(sightDeleted(props));
	// const {routes, sights, travel} = getState().TravelInfo;
	// const newRoutes = routes.map(route => ({...route, sights: route.sights.filter(sight => sight !== identifier)}));
	// const newTravel = {...travel, route: travel.route.map(route => ({...route, sights: route.sights.filter(sight => sight !== identifier)}))}
	// dispatch({ type: DELETE_SIGHT_TRAVELINFO, payload: {type, routes: newRoutes, travel: newTravel, day, identifier}});
	// console.log(routes, newRoutes, sights, travel, newTravel)
	dispatch(getRoute());
	// dispatch(getRoute(newRoutes.filter(route => route.daysOrder !== 'All'), sights));
};

export const getRoute = () => async (dispatch, getState) => {
	const {sights, routes} = getState().CustomizeTripPage;

	try{
		// dispatch(showLoader({ type: 'TravelInfoPage', identifier: 'routes' }));
		const newRoute = await getRouteDay(routes, sights);
		dispatch(routesReceived(newRoute));
		// dispatch({type: GET_ROUTE_TRAVELINFO, payload: newRoute});
		// dispatch(hideLoader({ type: 'TravelInfoPage', identifier: 'routes' }));
	} catch(e){
		// dispatch(showMessage({ identifier: 'routes_TravelInfoPage', message: e.message }));
		// dispatch(hideLoader('routes_TravelInfoPage' ));
	}
};

export const moveSight = (props) => (dispatch, getState) => {
	// const travel
	// let changeSightIndex = state.travel.route[day - 1].sights.findIndex(item => item === identifier);
	dispatch(sightMoved(props));
	dispatch(getRoute())
}