import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
	const location = useLocation();
	const { isLoggedIn: isUserLoggedIn } = useSelector(state => state.UserPage);
	
	if (!isUserLoggedIn) {
		return <Navigate to='/login' state={{ from: location }} />;
	}

	return children;
};

export default ProtectedRoute;
