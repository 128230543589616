import React, { useState } from 'react';
import { Layout, Col, Row, Typography, Button, Tooltip, Tag, Drawer } from 'antd';
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FullscreenExitOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';

import { WindowSize } from '../../../../helpers/WindowSize';
import CollapseSights from './CollapseSights';
import { getColor } from '../../../../helpers/_Functions';
import MenuDrawer from './MenuDrawer';
import ErrorBoundaryTravelMap from './../../../ErrorBoundary/ErrorBoundaryTravelMap';
import TravelMap from '../TravelMap';
const FullScreenCustomizeTripContent = ({
	children,
	travelDistance,
	dayDistance,
	handleChangeScreen,
	date,
	route,
	objSights,
	getLinkToGoogle,
	routeSights,
	travelControls,
	updateTravelControls,
	handleChangeTab,
	changeRouteOnTab,
	handleChangeTripRoute,
	allRoutesSights,
}) => {
	const { identifier, type: day = '' } = useParams();
	const { pathname } = useLocation();
	const { travel, routes, sights, sightsNear } = useSelector(state => state.CustomizeTripPage);
	const { width } = WindowSize();
	const { auth, isLoggedIn } = useSelector(state => state.UserPage);

	const [openDrawer, setOpenDrawer] = useState(false);
	const [visible, setVisible] = useState(false);
	const handleChangeVisible = () => {
		setVisible(prev => !prev);
	};
	const showDrawer = () => setOpenDrawer(prev => !prev);
	return (
		<Layout>
			<Row className='moderate-header'>
				<Row className='moderate-header__title'>
					<Typography.Title level={1} className='travel-header__title'>
						{travel.title}
					</Typography.Title>
					<Tag className='moderate-header__tag' color={getColor(travel.difficulty)}>
						{travel.difficulty}
					</Tag>
				</Row>
				<Row className='moderate-header__info'>
					<Col className='moderate-header__trip-distance'>
						<Typography.Text className='travel-header__info-title'>
							<i className='material-icons'>route</i> Trip Distance:
						</Typography.Text>
						<Typography.Text className='travel-header__info-subtitle'>{travelDistance}</Typography.Text>
					</Col>
					<Col className='moderate-header__day-distance'>
						<Typography.Text className='travel-header__info-title'>
							<i className='material-icons'>drive_eta</i> Day {day} Distance:
						</Typography.Text>
						<Typography.Text className='travel-header__info-subtitle'>{dayDistance}</Typography.Text>
					</Col>
				</Row>
				<Col className='moderate-header__icons'>
					<Tooltip title='Menu'>
						<Button className='moderate-header__icon-menu' onClick={showDrawer} icon={<MenuOutlined />} />
					</Tooltip>
					<Tooltip title='Back to Moderate Trip'>
						<Button className='moderate-header__icon-fullscreen' icon={<FullscreenExitOutlined />} onClick={handleChangeScreen} />
					</Tooltip>
				</Col>
			</Row>
			<Row className='moderate-content'>
				<CollapseSights
					day={day}
					visible={visible}
					handleChangeVisible={handleChangeVisible}
					identifier={identifier}
					travel={travel}
					routes={routes}
					route={route}
					sightsNear={sightsNear}
					objSights={objSights}
					getLinkToGoogle={getLinkToGoogle}
					routeSights={routeSights}
					sights={sights}
					travelControls={travelControls}
					width={width}
					updateTravelControls={updateTravelControls}
					handleChangeTab={handleChangeTab}
					changeRouteOnTab={changeRouteOnTab}
					date={date}
				/>
				<ErrorBoundaryTravelMap page='TravelMap'>
					<TravelMap
						handleChangeTripRoute={handleChangeTripRoute}
						className='moderate-map'
						sights={sights}
						sightsNear={sightsNear}
						routeSights={routeSights}
						day={day}
						identifier={identifier}
						allRoutesSights={allRoutesSights}
						visibility={visible}
					/>
				</ErrorBoundaryTravelMap>
			</Row>
			<MenuDrawer showDrawer={showDrawer} openDrawer={openDrawer} isLoggedIn={isLoggedIn} auth={auth} pathname={pathname} />
		</Layout>
	);
};

export default FullScreenCustomizeTripContent;
