import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import * as TravelInfoActions from '../../../../state/actions/TravelInfoActions';
import { connect } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import SearchLocation from '../../../ui/Search/SearchLocation';
import * as SearchActions from '../../../../state/actions/SearchAction';
import { GetIcon } from '../../../../Helper';
import { Row, Col, Button, Typography } from 'antd';
import { checkControlExisting, distanceMi } from '../../../../helpers/_Functions';
const { Text } = Typography;
const TravelModifyHotel = props => {
	let {
		travel:  {daysNumber, identifier, stay, route} = {},
		hotel = [],
		routes = [],
		sights = [],
		day = '',
		updateTravelControls,
		addToSights,
		Search,
		actions,
		SearchActions,
	} = props;

	const [hotelInfo, setHotelInfo] = useState('');
	const [showHotel, setShowHotel] = useState(false);
	const [hotelsStay, setHotelsStay] = useState([]);
	console.log(Search)
	const index = 
		day === daysNumber
			? checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'car', Search) && checkControlExisting(day, 'finish', Search)
				? routes[day - 1].sights.length - 3
				: (checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'finish', Search)) || (checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'car', Search)) || (checkControlExisting(day, 'car', Search) && checkControlExisting(day, 'finish', Search))
				? routes[day - 1].sights.length - 2
				: checkControlExisting(day, 'airport', Search) || checkControlExisting(day, 'finish', Search) || checkControlExisting(day, 'car', Search)
				? routes[day - 1].sights.length - 1
				: routes[day - 1].sights.length
			: routes[day - 1].sights.length;
	
	const lastSight = sights.find(sight => sight.identifier === route[day - 1].sights[route[day - 1].sights.length - 1]);

	const addHotel = hotel => {
		SearchActions.putSearchControl({data: { label: hotel.label, text: hotel.text, x: hotel.x, y: hotel.y }, searchType: 'hotel', identifier, day});
	};

	const deleteHotel = () => {
		actions.deleteSight({type: 'hotel', identifier: hotelInfo.label, day });
		setHotelInfo('');
		setShowHotel(false);
	};

	useEffect(() => {
		if (hotel.length) {
			let currHotel = hotel.find(item => item.identifier === identifier && item.day === day);
			if (currHotel) {
				if (!sights.find(el => el.title === currHotel.data.label && el.day === day)) {
					setHotelInfo(currHotel.data);
					setShowHotel(true);
					addToSights({obj: currHotel, type: 'hotel', icon: 'fa-hotel', index});
				}
			}
		}
	}, [hotel]);

	useEffect(() => {
		if (hotel.length && !hotel.find(el => el.day === day)) {
			setHotelInfo('');
			setShowHotel(false);
		} else {
			let currHotel = hotel.find(item => item.identifier === identifier && item.day === day);
			if (currHotel) {
				setHotelInfo(currHotel.data);
				setShowHotel(true);
			}
		}


		if (day && stay.length) {
			setHotelsStay([]);
			stay.map(hotels => {
				hotels.map(hotel => {
					if (hotel.convenientForNights.includes('-')) {
						let dayFirst = hotel.convenientForNights.split('-')[0],
							dayLast = hotel.convenientForNights.split('-')[1];
						if (dayFirst <= day && day <= dayLast) {
							setHotelsStay(hotelsStay => [...hotelsStay, hotel]);
						}
					} else if (day == hotel.convenientForNights) {
						setHotelsStay(hotelsStay => [...hotelsStay, hotel]);
					}
				});
			});
		}
	}, [day]);

	return (
		<>
			<Row className='modify-btns'>
				<Col lg={4} xs={6} className='modify-icon'>
					<GetIcon src='hotel.png' />
					<Text className='modify-title'>Hotel </Text>
				</Col>
				<Col lg={20} xs={18} className='modify-text'>
					{!showHotel &&
						hotelsStay.map((el, key) => (
							<Button className={'addOptionModify'} key={key} onClick={() => addHotel({ text: el.title, label: el.title, x: el.location[0], y: el.location[1] })}>
								{el.title}
							</Button>
						))}

					{!showHotel && (
						<div style={{ padding: '0' }}>
							<SearchLocation searchCategory='hotel' />
						</div>
					)}

					{showHotel && hotelInfo && (
						<Col lg={20} xs={20}>
							<h3 className={'modifyInfo'}>{hotelInfo.text}</h3>
							<a href={`https://www.google.com/maps/place/${hotelInfo.y},${hotelInfo.x}`} className='modify-link' target='_blank' rel='nofollow noopener noreferrer'>
								<span className='material-icons modify-link__marker'>place</span>
								<Text className='modify-link__text'>
									{hotelInfo.y}, {hotelInfo.x}
								</Text>
							</a>
							<span className='modify-distance'>
								<b>
									~ {distanceMi([hotelInfo.x, hotelInfo.y], lastSight.location)} mi/ ~ {Math.round(distanceMi([hotelInfo.x, hotelInfo.y], lastSight.location) * 1.609)} km
								</b>
								to {lastSight.title}, the last sight of Day {day}
							</span>
						</Col>
					)}

					{showHotel && hotelInfo && (
						<Col lg={4} xs={4} onClick={deleteHotel} aria-hidden='true' className={'closeModify'}>
							<CloseOutlined />
						</Col>
					)}

					{!showHotel && (
						<Col
							lg={4}
							xs={4}
							onClick={() => {
								updateTravelControls({hotel: day});
							}}
							aria-hidden='true'
							className={'closeModify'}>
							<CloseOutlined />
						</Col>
					)}
				</Col>
			</Row>
		</>
	);
};

function mapStateToProps(state) {
	return {
		travel: state.TravelInfo.travel,
		hotel: state.Search.hotel,
		routes: state.TravelInfo.routes,
		sights: state.TravelInfo.sights,
		Search: state.Search
	};
}

function mapDispatchToProps(dispatch) {
	return {
		SearchActions: bindActionCreators(SearchActions, dispatch),
		actions: bindActionCreators(TravelInfoActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelModifyHotel);
