import React from 'react';
import { Col, Select } from 'antd';
import { checkDay } from './../../../../helpers/_Functions';
import { CalendarOutlined } from '@ant-design/icons';
import { DAYS } from './../../../../constants/Duration';

const { Option } = Select;

const DayFilter = ({ day = '', handleChangeFilters }) => {
	const handleChange = value => {
		handleChangeFilters('day', value);
	};
	return (
		<Col className='user-trips__filters-duration'>
			<span className='user-trips__filters-subtitle'>Duration</span>
			<Select
				className='user-trips__filters-duration__select'
				value={
					day ? (
						checkDay(day)
					) : (
						<span className='user-trips__filters-duration__select-placeholder'>
							<CalendarOutlined style={{ textAlign: 'left' }} />
							<p>All days</p>
						</span>
					)
				}
				onChange={handleChange}>
				{DAYS.map((day, index) => {
					return (
						<Option key={`day-${index}`} value={day}>
							{checkDay(day)}
						</Option>
					);
				})}
			</Select>
		</Col>
	);
};

export default DayFilter;
