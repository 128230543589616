import React from 'react';
import { connect } from 'react-redux';
import {Col, Row } from 'antd';

import { ImageFinder } from '../../../Helper';
import SightInfo from './SightInfo';

const SightHeader = (props) => {
    let {
        SightPage: {
            sight = {}
        } = {}
    } = props;

    return(
        <Row className="sight-header">
            {/* <Row className="sight-breadcrumb">
                <BreadcrumbComponent travel={travel} day={getDay(travels, sight.identifier)} sight={sightInfo}/>
            </Row> */}
            <Col lg={12} md={12} sm={24} xs={24}>
                <ImageFinder alt={sight.title} title={sight.title} src={sight.images[0].name} className="sight-header__img"/>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24} className="sight-header__info">
                <SightInfo />
            </Col>
        </Row>
    );
};

function mapStateToProps(state){
    return {
        SightPage: state.SightPage
    };
}

export default connect(mapStateToProps, {})(SightHeader);