import axios from 'axios';
import configFile from '../config.json';

const httpReview = axios.create({
	baseURL: configFile.apiReviewEndpoint,
});

const reviewEndpoint = 'test/';

const reviewService = {
	get: async (identifier) => {
		const { data } = await httpReview.get(reviewEndpoint, {
			params: {
				$filter: {
					project: 'beforevoyage',
					objectId: identifier,
				}
			},
		});
		return data;
	},
	setReview: async (data) => {
		const result = await httpReview.post(reviewEndpoint, {
			...data,
			project: 'beforevoyage'
		});
		return result;
	}
};
export default reviewService;
