import React, { useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Tabs } from 'antd';
import { useParams } from 'react-router';

import { OtherSightsAndTrips } from '../../ui/OtherSightsAndTrips';
import { loadTravelsWithSightsNear } from './../../../state/actions/TravelOverviewActions';

const { Title } = Typography;

const OverviewAlternatives = () => {
	const { intensity, identifier } = useParams();
	const { sightsNear, travelsWithSightsNear, loading, errors } = useSelector(state => state.TravelOverviewPage);
	const dispatch = useDispatch();

	const [activeKey, setActiveKey] = useState('otherSights');

	const changeTabs = key => {
		setActiveKey(key);
		if (key == 'otherTrips') {
			dispatch(loadTravelsWithSightsNear(sightsNear, identifier, intensity));
		}
	};

	const items = [
		{
			label: (
				<Title level={2} className='overview-alternatives__title'>
					More sights
				</Title>
			),
			key: 'otherSights',
			children: <OtherSightsAndTrips sights={sightsNear} loading={loading.sightsNear} message={errors.sightsNear} />,
		},
		{
			label: (
				<Title level={2} className='overview-alternatives__title'>
					Other trips
				</Title>
			),
			key: 'otherTrips',
			children: <OtherSightsAndTrips travels={travelsWithSightsNear} loading={loading.travelsWithSightsNear} message={errors.travelsWithSightsNear} />,
		},
	];

	return <Tabs items={items} onChange={changeTabs} activeKey={activeKey} className='overview-alternatives' />;
};

export default memo(OverviewAlternatives);
