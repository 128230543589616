import {GET_TRAVEL_FOR_TRAVELINFO, GET_TRAVELS_FOR_TRAVELINFO, GET_SIGHTS_FOR_TRAVELINFO, GET_ROUTE_TRAVELINFO, GET_MORE_SIGHTS_NEAR_TRAVELINFO, DELETE_MORE_SIGHTS_NEAR_TRAVELINFO, ADD_SIGHT_TRAVELINFO, DELETE_SIGHT_TRAVELINFO } from '../../constants/ActionTypes';
import travelService from './../../services/travel.service';
import { hideLoader, showLoader, showMessage } from './HelperActions';
import sightService from './../../services/sight.service';
import { getRouteDay } from '../../helpers/_Functions';
import { deepClone } from '../../helpers/_Object';
import { setSearch } from './SearchAction';

export const getTravels = (identifier) => async (dispatch) => {
	try{
		dispatch(showLoader('travels_TravelInfoPage'));
		const params = {filter: {identifier}};
		console.log(params)
		const travels = await travelService.get(params);
		
		dispatch({ type: GET_TRAVELS_FOR_TRAVELINFO, payload: travels });
		// dispatch(setTravel(difficulty));
		
		dispatch(hideLoader('travels_TravelInfoPage' ));
	} catch(e){
		dispatch(showMessage({ identifier: 'travels_TravelInfoPage', message: e.message }));
		dispatch(hideLoader('travels_TravelInfoPage' ));
	}
}

export const setTravel = (difficulty, identifier) => (dispatch, getState) => {
	const {travels} = getState().TravelInfo;
	const {isLoggedIn, trips,} = getState().UserPage;
	const trip = trips?.find(el => el.travel.identifier === identifier && el.travel.difficulty === difficulty) || null;
	
	const travel = isLoggedIn && trip ? trip.travel : travels.find(t => t.difficulty === difficulty);
	if(isLoggedIn && trip){
		dispatch(setSearch(trip.Search));
	}

	dispatch(getSights(travel.route));
	dispatch({ type: GET_TRAVEL_FOR_TRAVELINFO, payload: travel});
};

export const getSights = (route) => async (dispatch) => {
	
	try{
		console.log(route)
		dispatch(showLoader('sights_TravelInfoPage' ));
		const sightsArr = route.map(elem => elem.sights).flat();
		const uniqueSights = [...new Set(sightsArr)];
		const params = { sights: uniqueSights, limit: 1000 };
		const sights = await sightService.getSightsForTravel(params);
		const routes = await getRouteDay(route, sights);

		// dispatch(getRoute(route, sights));
		dispatch({type: GET_SIGHTS_FOR_TRAVELINFO, payload: {sights, routes}});
		dispatch(hideLoader('sights_TravelInfoPage' ));
	} catch(e) {
		dispatch(showMessage({ identifier: 'sights_TravelInfoPage', message: e.message }));
		dispatch(hideLoader('sights_TravelInfoPage'));
	}

};

export const getRoute = (route, sights, type) => async dispatch => {
	try{
		// dispatch(showLoader({ type: 'TravelInfoPage', identifier: 'routes' }));
		const newRoute = await getRouteDay(route, sights, type);
		dispatch({type: GET_ROUTE_TRAVELINFO, payload: newRoute});
		// dispatch(hideLoader({ type: 'TravelInfoPage', identifier: 'routes' }));
	} catch(e){
		dispatch(showMessage({ identifier: 'routes_TravelInfoPage', message: e.message }));
		dispatch(hideLoader('routes_TravelInfoPage' ));
	}
};

export const getMoreSightsNear = ({lat, long, day, identifier, distance = 5000}) => async dispatch => {
	try{
		const params = {lat, long, distance};
		const sightsNear = await sightService.getSightsNear(params);
		// console.log(sightsNear)
		dispatch({type: GET_MORE_SIGHTS_NEAR_TRAVELINFO, payload: {data: sightsNear, identifier, day }});
	}catch (e) {
		dispatch(showMessage({ identifier: 'sightNear_TravelInfoPage', message: e.message }));
	}
};

export const deleteMoreSightsNear = (props) => {
	return {
		type: DELETE_MORE_SIGHTS_NEAR_TRAVELINFO,
		payload: props
	};
};

export const addSight = ({sight, index, day, type}) => (dispatch, getState) =>{
	const {routes, sights, travel} = getState().TravelInfo;
	const newTravel = deepClone(travel), newRoutes = deepClone(routes);

	if(index === -1){
		newTravel.route[day-1].sights.push(sight.identifier);
		// let currPos = newTravel.route[day-1].sights.length-1;
		const lastSight = newTravel.route[day-1].sights[newTravel.route[day-1].sights.length-2];
		
		const indexLastSight = newRoutes[day-1].sights.findIndex(s => s === lastSight);
		// console.log(lastSight, indexLastSight);
		newRoutes[day-1].sights.splice(indexLastSight + 1, 0, sight.identifier);
	} else {
		newRoutes[day-1].sights.splice(index, 0, sight.identifier);
	}

	dispatch({ type: ADD_SIGHT_TRAVELINFO, payload: {sight, travel: newTravel, routes: newRoutes} });

	dispatch(getRoute(newRoutes.filter(route => route.daysOrder !== 'All'), [...sights, sight], type));
	// type !== 'start' &&  dispatch(getRoute(newRoutes.filter(route => route.daysOrder !== 'All'), [...sights, sight]));
};

export const deleteSight = ({identifier, day, type}) => (dispatch, getState) => {
	console.log(identifier, day, type)
	const {routes, sights, travel} = getState().TravelInfo;
	const newRoutes = routes.map(route => ({...route, sights: route.sights.filter(sight => sight !== identifier)}));
	const newTravel = {...travel, route: travel.route.map(route => ({...route, sights: route.sights.filter(sight => sight !== identifier)}))}
	dispatch({ type: DELETE_SIGHT_TRAVELINFO, payload: {type, routes: newRoutes, travel: newTravel, day, identifier}});
	// console.log(routes, newRoutes, sights, travel, newTravel)

	dispatch(getRoute(newRoutes.filter(route => route.daysOrder !== 'All'), sights));
}