import React from 'react';
import AppLoader from '../../ui/hoc/AppLoader';
import { Outlet } from 'react-router-dom';

const Layout = () => {
	return <AppLoader>
		<Outlet />
	</AppLoader>;
};

export default Layout;
