import sightService from '../../services/sight.service';
import travelService from '../../services/travel.service';
import { getDays } from '../../Helper';
import { putTravelsSkip, putTravelsSkipRequested, putTravelsSkipRequestFailed, travelsReceived, travelsRequested, travelsRequestFailed } from '../reducers/TravelsReducer';

export const getTravels =
	({ skip = 0, limit = 15, difficulty = 'moderate', hash, days, lat, long }) =>
	async dispatch => {
		!skip ? dispatch(travelsRequested()) : dispatch(putTravelsSkipRequested());

		try {
			const sights = (lat && long && (await getSights(lat, long))) || undefined;
			console.log(days)
			const daysNumber =
				days !== 'All'
					? {
							$in: getDays(days).map(String),
					  }
					: undefined;
			const route =
				sights && sights.length
					? {
							$elemMatch: {
								sights: {
									$in: sights,
								},
							},
					  }
					: undefined;
			const hashtag = (hash && hash.split('#').pop().trim()) || undefined;
			const params = { skip, limit, hashtag, daysNumber, difficulty, sort: { rating: -1, identifier: 1 }, route };
					  console.log(params, daysNumber)
			const travels = (!(lat && long) || (sights.length && lat && long)) && (await travelService.getTravels(params));
			if (skip) {
				dispatch(putTravelsSkip({ travels, skip, hasMore: travels.length ? true : false }));
			} else {
				dispatch(travelsReceived({ travels, skip, hasMore: true }));
			}
		} catch ({ message }) {
			!skip ? dispatch(travelsRequestFailed(message)) : dispatch(putTravelsSkipRequestFailed(message));
		}
	};

const getSights = async (lat, long) => {
	try {
		const data = await sightService.getSightsNear({ lat, long, distance: 50000 });
		let identifierArr = [];
		data.map(item => {
			if (item.identifier) {
				identifierArr.push(item.identifier);
			}
		});
		return identifierArr;
	} catch (e) {
		console.log(e);
	}
};
