import React from 'react';
import { Link } from 'react-scroll';
import axios from 'axios';
import { connect } from 'react-redux';


const Fragment = React.Fragment;
class ErrorBoundaryTravelMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        console.log('error on page', error);
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        let {
            page = '',
            router: {
                location: {
                    pathname = ''
                } = {}
            } = {}
        } = this.props,
        dataTime = '';
        try{
            dataTime = Date(Date.now()); //new Date().toLocaleString()
        }catch(e){
            console.log('error on time', e);
        }
        
        try{
            let body = {
                site: 'beforevoyage',
                page: pathname,
                log: `Data: ${dataTime}. Error on ${page} info: ${error.toLocaleString()}.`
            };
            if(process.env.NODE_ENV === 'production'){
                axios.put('https://yxixvsdaqc.execute-api.us-east-1.amazonaws.com/test/', body);
            }else{
                console.log('Error', error.toLocaleString());
                console.log('ErrorInfo', info.componentStack);
            }
        }catch(e){
            console.log('NOT SAVE ERROR');
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <Fragment>
                    <div>
                        <div className="notfound_travel-map">
                            <div className="notfound-404"></div>
                            <h1>404</h1>
                            <h2>Oops. Something went wrong.</h2>
                            <p>We`re working on getting it fixed.</p>
                            <Link to='/'>Back to homepage</Link>
                        </div>
                    </div>
                </Fragment>
            );
        }

        return this.props.children;
    }
}

function mapStateToProps(state){
    return {
        router: state.router
    };
}

export default connect(mapStateToProps, {})(ErrorBoundaryTravelMap);