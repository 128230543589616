import React, { Fragment } from 'react';
import { Row, Col, Carousel } from 'antd';
import ImageSights from './ImageSights';
import { WindowSize } from './../../../helpers/WindowSize';

export const TravelsContentSlider = ({travel}) => {
    const width = WindowSize();
    
    let settings = {
        draggable: true,
        dots: false,
        lazyLoad: true,
        infinite: false,
        responsive: [
            {
              breakpoint: 800,
              settings: {
                  slidesToShow: 2,
              }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                }
              }
      ]
    };

    return(
        travel && +width.width < 800 ? <Fragment>
            <Carousel {...settings} className="travels-catalog__mob">
                {travel.imagesToShow.map((imageObj, index) => {
                    return(
                        <Col key={`${imageObj.title}-${index}`} className='travels-catalog__mob-img'>
                            <ImageSights className={`catalog-img--${index}`} imageObj={imageObj}/>
                        </Col>
                        
                    );
                })}
            </Carousel>
            
        </Fragment> : <Row className="travels-catalog__desktop">
            {travel.imagesToShow.map((imageObj, index) => {
                return(
                    <Col key={`${imageObj.title}-${index}`} className={`travels-catalog__desktop-card card-${index}`}>
                        <ImageSights className={`travels-catalog__desktop-img img-${index}`} imageObj={imageObj} identifier={travel.identifier}/>
                    </Col>
                );
            })}
        </Row>
    );
};


export default TravelsContentSlider;