import React from 'react';
// import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import App from './App';
// import 'antd/dist/reset.css'
import './sass/App.sass';
// const root = createRoot(document.getElementById('app'));

// root.render(<React.StrictMode><App /></React.StrictMode>);
ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('app'),
);
