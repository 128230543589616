import React from 'react';
import { Col, Typography } from 'antd';

const MapTitle = ({sight, distance, location}) => {
	return (
		<Col className='explore-header__container'>
			{sight.hasOwnProperty('identifier') ? (
				<Typography.Title level={1} className='explore-card__title'>
					Trips with {sight.title}:
				</Typography.Title>
			) : distance ? (
				<Typography.Title level={1} className='explore-card__title'>
					Trips in this area:
				</Typography.Title>
			) : location ? (
				<Typography.Title level={1} className='explore-card__title'>
					Trips near: {location}
				</Typography.Title>
			) : (
				<Typography.Title level={1} className='explore-card__title'>
					Most popular trips:
				</Typography.Title>
			)}
		</Col>
	);
};

export default MapTitle;
