import React, { useEffect, memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Tabs, List, Avatar, Divider, Typography, Rate, Col, Comment } from 'antd';
import { CommentOutlined, EditOutlined, UserOutlined, RightCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import ReviewsForm from './ReviewsForm';
import { Loader } from './../../common/Loader/Loader';
import { loadReviews } from './../../../state/actions/ReviewActions';

const { Title, Text } = Typography;

const ReviewsInfo = props => {
	const { info, loading } = useSelector(state => state.reviews);
	const { sights = [], type = '', sightInfo = {} } = props;
	const { sight, identifier } = useParams();
	const dispatch = useDispatch();

	const typePage = sight ? `sight_${sight}` : `travel_${identifier}`;
	const avatarImg = <Avatar style={{ backgroundColor: '#d2f4f7' }} icon={<UserOutlined />} />;

	let sightsAuthors = useMemo(() => sights.map(item => item.images).flat(), [sights]);

	sightInfo.hasOwnProperty('images') && sightsAuthors.push(...sightInfo.images);

	sightsAuthors = useMemo(() => sightsAuthors.filter((value, index, self) => index === self.findIndex(s => value.author && s.author === value.author)), [sightsAuthors]);

	const reviewContentJSX = (
		<>
			<Title level={4} className='reviews-default__title'>
				No reviews yet
			</Title>
			<Text className='reviews-default__text'>
				You can add your review in the next tab <RightCircleTwoTone />
			</Text>
		</>
	);
	const reviewsContentJSX = (
		<>
			<List
				className='reviews-comment__list'
				itemLayout='horizontal'
				dataSource={info.reviews}
				renderItem={(item, index) => (
					<li key={`reviews-comment__list-${index}`}>
						{info.count > 1 && index != 0 ? <Divider /> : false}
						<Comment
							actions={item.actions}
							author={<Text strong>{item.username}</Text>}
							avatar={avatarImg}
							content={
								<>
									<Rate disabled defaultValue={item.rating} className='overview-reviews-rate' />
									<div>{item.comment}</div>
								</>
							}
							datetime={item.timestamp}
						/>
					</li>
				)}
			/>
		</>
	);
	const loadingContentJSX = loading ? <Loader /> : reviewContentJSX;

	const items = [
		{
			label: (
				<Text className='reviews-card__text'>
					Reviews <CommentOutlined />
					<span className='card-number'>{info.count}</span>
				</Text>
			),
			key: 'Reviews',
			children: !loading && info.count ? reviewsContentJSX : loadingContentJSX,
		},
		{
			label: (
				<Text className='reviews-card__text'>
					Leave a review <EditOutlined />
				</Text>
			),
			key: 'Comments',
			children: <ReviewsForm typePage={typePage} type={type} />,
		},
		{
			label: (
				<Text className='reviews-card__text'>
					Photos Info <InfoCircleOutlined />
				</Text>
			),
			key: 'Info',
			children: (
				<>
					{sightsAuthors.map((item, key) => (
						<Col className='photos-info' key={key}>
							{item.author ? <p>Author: {item.author}</p> : false}
							{item.url ? (
								<Text>
									<a href={item.url}>Source</a>
								</Text>
							) : (
								false
							)}
							{item.license !== '' && item.license ? (
								<Text>
									<a href={item.license}>License</a>
								</Text>
							) : (
								false
							)}
						</Col>
					))}
					<p className='partner-link'>
						<a href='https://jooble.org/jobs-remote-travel-writer'>Jobs for Remote Travel Writer </a>
					</p>
				</>
			),
		},
	];

	useEffect(() => {
		if (sight) {
			dispatch(loadReviews(`sight_${sight}`, type));
		} else {
			dispatch(loadReviews(`travel_${identifier}`, type));
		}
	}, []);
	return (
		<Row className='reviews'>
			<Tabs items={items} type='card' className='reviews-card' />
			{/* <TabPane
					tab={
						<Text className='reviews-card__text'>
							Reviews <CommentOutlined />
							<span className='card-number'>{reviews.length}</span>
						</Text>
					}
					key='Reviews'
					className='reviews-tab'>
					{loading.reviews ? (
						<Loader />
					) : reviews.length ? (
						<>
							<List
								className='reviews-comment__list'
								itemLayout='horizontal'
								dataSource={reviews}
								renderItem={(item, index) => (
									<li key={`reviews-comment__list-${index}`}>
										{reviews.length > 1 && index != 0 ? <Divider /> : false}
										<Comment
											actions={item.actions}
											author={<Text strong>{item.username}</Text>}
											avatar={avatarImg}
											content={
												<>
													<Rate disabled defaultValue={item.rating} className='overview-reviews-rate' />
													<div>{item.comment}</div>
												</>
											}
											datetime={item.timestamp}
										/>
									</li>
								)}
							/>
						</>
					) : (
						<>
							<Title level={4} className='reviews-default__title'>
								No reviews yet
							</Title>
							<Text className='reviews-default__text'>
								You can add your review in the next tab <RightCircleTwoTone />
							</Text>
						</>
					)}
				</TabPane> */}

			{/* <TabPane
					tab={
						<Text className='reviews-card__text'>
							Leave a review <EditOutlined />{' '}
						</Text>
					}
					key='Comments'
					className='reviews-comments'>
					<ReviewsForm typePage={typePage} type={type} />
				</TabPane> */}

			{/* <TabPane
					tab={
						<Text className='reviews-card__text'>
							Photos Info <InfoCircleOutlined />
						</Text>
					}
					key='Info'
					className='reviews-info'>
					{sightsAuthors.map((item, key) => (
						<Col className='photos-info' key={key}>
							{item.author ? <p>Author: {item.author}</p> : false}
							{item.url ? (
								<Text>
									<a href={item.url}>Source</a>
								</Text>
							) : (
								false
							)}
							{item.license !== '' && item.license ? (
								<Text>
									<a href={item.license}>License</a>
								</Text>
							) : (
								false
							)}
						</Col>
					))}
					<p className='partner-link'>
						<a href='https://jooble.org/jobs-remote-travel-writer'>Jobs for Remote Travel Writer </a>
					</p>
				</TabPane>
			</Tabs> */}
		</Row>
	);
};

export default memo(ReviewsInfo);
