import searchService from '../services/search.service';
import { sortByField } from './_Functions';

export const dataSearch = async value => {
	try {
		const { travels, sights, travelByTags } = await searchService.get(value);
		const hashtags = [];
		travelByTags.filter(travel => travel.difficulty === 'moderate').sort(sortByField('identifier')).forEach(travelTag => {
			if (travelTag.hashtags && travelTag.hashtags.length && value && value !== '') {
				travelTag.hashtags.forEach(tag => {
					let searchInput = value.toLowerCase();
					if (tag.toLowerCase().includes(searchInput)) {
						if (!hashtags[tag]) hashtags[tag] = [];
						hashtags[tag].push(travelTag);
					}
				});
			}
		})
		return {
			trips: travels.filter(travel => travel.difficulty === 'moderate').sort(sortByField('identifier')),
			sights: sights.sort(sortByField('identifier')),
			hashtags
		};
	} catch (e) {
		console.log(e);
	}
};
