import React, { Fragment } from 'react';
import { Row, Typography } from 'antd';

const { Title } = Typography;

const TipsRecommendations = ({ travel }) => {
	return (
		<div className='overview-tips'>
			<Row>
				<Title level={2} className='overview-title'>
					Tips & recommendations
				</Title>
			</Row>
			{travel.tips ? <Row className='overview-description' dangerouslySetInnerHTML={{ __html: travel.tips }} /> : false}
			<Row className='overview-recomendation'>
				<Title level={2}>Useful resources: </Title>
				{travel.usefulResources &&
					travel.usefulResources.map((element, key) => {
						const url = element.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];
						return (
							<Fragment key={key}>
								<a href={element} key={key} rel='nofollow noopener noreferrer' target='_blank'>
									{url}
								</a>
								{travel.usefulResources.length - 1 !== key && ', '}
							</Fragment>
						);
					})}
			</Row>
		</div>
	);
};

export default (TipsRecommendations);
