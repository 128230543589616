import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Typography, Form, Input, Button, Rate } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import configFile from '../../../config.json';
import { setReviewInfo } from '../../../state/actions/ReviewActions';
import { openNotification } from './../../common/Notification/Notification';

const { Text } = Typography;
const { TextArea } = Input;
const layout = { labelCol: { span: 4 }, wrapperCol: { span: 20 } };

const ReviewsForm = props => {
	const { typePage = '', type } = props;
	const { success, loading, error } = useSelector(state => state.reviews);
	const [form] = Form.useForm();

	const dispatch = useDispatch();

	const onSubmit = ({ comment = '', captcha = '', rating = 0, email = '', username = '' }) => {
		if (comment && captcha && rating && username) {
			dispatch(
				setReviewInfo(
					{
						objectId: typePage,
						comment,
						rating,
						username,
						email,
					},
					type
				)
			);
		}
	};

	const onChangeRecaptcha = value => {
		console.log(value, form);
		if (value !== null) {
			form.setFieldsValue({
				captcha: value,
			});
		} else {
			form.setFieldsValue({
				captcha: {
					errors: [new Error('Invalid captcha')],
				},
			});
		}
	};

	useEffect(() => {
		if (success) {
			form.setFieldsValue({
				captcha: '',
				comment: '',
				rating: 0,
				email: '',
				username: '',
			});
			openNotification('Successfully submitted review!');
			window.grecaptcha.reset();
		}

		if (error) {
			openNotification('Please try again!', 'warning');
		}
	}, [success, error]);

	console.log(success, type);

	let sitekey = process.env.NODE_ENV && process.env.NODE_ENV === 'development' ? configFile.sitekeyCaptchaDev : configFile.sitekeyCaptcha;

	return (
		<>
			<Card className='form-review' style={{ borderTop: 0 }}>
				<Form form={form} onFinish={onSubmit} {...layout} name='review_form'>
					<Form.Item name='rating' label={<Text strong>Overall rating </Text>} rules={[{ required: true, message: 'Please click to rate!', type: 'number' }]}>
						<Rate name='rating' className='form-review__rate' tooltips={['Click to rate!', 'Click to rate!', 'Click to rate!', 'Click to rate!', 'Click to rate!']} />
					</Form.Item>

					<Form.Item
						name='comment'
						label={<Text strong>About</Text>}
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please input comment here!',
							},
						]}>
						<TextArea name='comment' placeholder='Share your own experience' maxLength={300} minLength={3} rows={4} />
					</Form.Item>

					<Row className='form-review__fields'>
						<Col span={12} xs={24} md={24}>
							<Form.Item
								name='username'
								label={<Text strong>Name</Text>}
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Please input name here!',
									},
									{
										min: 3,
										message: 'Enter more than three characters!',
									},
								]}>
								<Input name='username' placeholder='Name' maxLength={40} />
							</Form.Item>
						</Col>
						<Col span={12} xs={24} md={24}>
							<Form.Item
								name='email'
								label={<Text strong>Email</Text>}
								rules={[
									{
										type: 'email',
										message: 'Invalid email address! If you do not need to send an email. Delete characters',
									},
									{
										required: false,
									},
								]}>
								<Input name='email' placeholder='Email' type='email' maxLength={50} />
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						name='captcha'
						style={{ marginBottom: 0, width: '100%' }}
						label={<Text strong>Recaptcha</Text>}
						rules={[
							{
								required: true,
								message: 'We must make sure that your are a human.',
								initialValue: '',
							},
						]}>
						<Input name='captcha' type='hidden' />
						<ReCAPTCHA sitekey={sitekey} onChange={onChangeRecaptcha} />
					</Form.Item>

					<Form.Item>
						<Button htmlType='submit' loading={loading} type='primary'>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</>
	);
};

export default memo(ReviewsForm);
