import React from 'react';
import { Img } from 'react-image';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { ALLDAYS } from './constants/Duration';

export function ImageFinder(props) {
	let { src = '', title = '', alt = '', className = '' } = props;

	const arrImages = [`/external/img/${src}`, `./external/img/${src}`, `./img/${src}`, `/img/${src}`],
		defaultImg = '/img/default-img.webp',
		defaultSvg = '/img/default.svg',
		antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

	src = src != '' ? src.trim().toLowerCase() : src;

	let ImageJSX = <div />;

	if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
		let srcDev = `./img/${src}`;
		try {
			srcDev = require(`./img/${src}`);
		} catch (e) {
			try {
				srcDev = require(`./external/img/${src}`);
			} catch (er) {
				srcDev = require('./img/default-img.webp');
			}
		}

		ImageJSX = <img src={srcDev} alt={alt} title={title} className={className} />;
	} else {
		ImageJSX = (
			<Img
				src={arrImages}
				alt={alt}
				title={title}
				className={className}
				unloader={<img alt={alt} src={defaultImg} className={className} title={title} />}
				loader={<img alt={alt} src={defaultSvg} className={className} title={title} />}
			/>
		);
	}

	return ImageJSX;
}

export function GetIcon(props) {
	let { src = '', alt = '', className = '' } = props;
	src = src != '' ? src.trim().toLowerCase() : src;

	const arrImages = [`/img/icon/${src}`];

	const ImageJSX = (
		<img
			src={arrImages}
			className={className}
			alt={alt}
			// unloader={<ReloadOutlined/>}
		/>
	);

	return ImageJSX;
}

export function getDays(text) {
	if (ALLDAYS.hasOwnProperty(text)) {
		return ALLDAYS[text];
	} else {
		return [];
	}
}

export const SvgFinder = ({ name, color, size, src }) => {
    const Icon = (`/img/icon/${src}`);
    console.log(Icon)
    return <svg className={`icon icon-${name}`} fill={color} width={size} height={size}>
      <use xlinkHref={Icon} />
    </svg>
  };
