// IMPORT PACKAGE REFERENCES
import React, { lazy, Suspense } from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';

// IMPORT PROJECT REFERENCES
import ZeroPage from '../components/pages/ZeroPage/ZeroPage';
import TravelInfoContainer from '../containers/TravelInfo/TravelInfoContainer';
import SightContainer from '../containers/Sight/SightPageContainer';
import TravelsContainer from '../containers/Travels/TravelsContainer';
import TravelOverviewContainer from '../containers/TravelOverview/TravelOverviewContainer';
import TermsAndConditions from './../components/TermsAndConditions/TermsAndConditions';
import MapContainer from '../containers/Map/MapContainer';
import UserContainer from './../containers/User/UserContainer';
import AuthContainer from '../containers/Auth/AuthContainer';
import ProtectedRouter from './ProtectedRouter';
import CustomizeTripContainer from '../containers/CustomizeTrip/CustomizeTripContainer';
import Layout from '../components/common/Layout/RouterLayout';
import { Loader } from './../components/common/Loader/Loader';

import TripsContent from '../components/pages/UserPage/Trips/TripsContent';
import FavoritesContent from './../components/pages/UserPage/Favorites/FavoritesContent';
import HotelsContent from './../components/pages/UserPage/Hotels/HotelsContent';
import AccountContent from '../components/pages/UserPage/Account/AccountContent';

// const ZeroPage = lazy(() => import('../components/pages/ZeroPage/ZeroPage'));
// const TravelInfoContainer = lazy(() => import('../containers/TravelInfo/TravelInfoContainer'));
// const SightContainer = lazy(() => import('../containers/Sight/SightPageContainer'));
// const TravelsContainer = lazy(() => import('../containers/Travels/TravelsContainer'));
// const TravelOverviewContainer = lazy(() => import('../containers/TravelOverview/TravelOverviewContainer'));
// const TermsAndConditions = lazy(() => import('./../components/TermsAndConditions/TermsAndConditions'));
// const MapContainer = lazy(() => import('../containers/Map/MapContainer'));
// const UserContainer = lazy(() => import('./../containers/User/UserContainer'));
// const AuthContainer = lazy(() => import('../containers/Auth/AuthContainer'));
// const CustomizeTripContainer = lazy(() => import('../containers/CustomizeTrip/CustomizeTripContainer'));
// const TripsContent = lazy(() => import('../components/pages/UserPage/TripsContent'));
// const FavoritesContent = lazy(() => import('./../components/pages/UserPage/FavoritesContent'));
// const HotelsContent = lazy(() => import('./../components/pages/UserPage/HotelsContent'));
// const AccountContent = lazy(() => import('../components/pages/UserPage/AccountContent'));

const AppRouter = () => {
	return (
		<BrowserRouter>
			{/* <Suspense fallback={<Loader />}> */}
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<ZeroPage />} />

					<Route path='trips/:days' element={<TravelsContainer />}>
						<Route path=':identifier/:location' />
					</Route>

					<Route path='map/itineraries' element={<MapContainer />}>
						<Route path=':identifier'>
							<Route path=':distance' />
						</Route>
					</Route>
					<Route path='map/:duration/itineraries' element={<MapContainer />}>
						<Route path=':identifier'>
							<Route path=':distance' />
						</Route>
					</Route>

					<Route path='trip/:identifier/:difficulty' element={<TravelOverviewContainer />} />

					<Route path='trip/:identifier/:difficulty/day/:type/' element={<TravelInfoContainer />} />

					<Route path='sight/:sight' element={<SightContainer />} />
					<Route path='trip/:identifier/sight/:sight/' element={<SightContainer />} />

					<Route path='login' element={<AuthContainer />} />
					<Route path='registration' element={<AuthContainer />} />

					<Route
						path='user/:id'
						element={
							<ProtectedRouter>
								<UserContainer />
							</ProtectedRouter>
						}>
						<Route index element={<AccountContent />} />
						<Route path='settings' element={<AccountContent />} />
						<Route path='trips' element={<TripsContent />} />
						<Route path='favorites' element={<FavoritesContent />} />
						<Route path='hotels' element={<HotelsContent />} />
					</Route>

					<Route
						path='/trip/edit/:identifier/:difficulty/day/:type'
						element={
							<ProtectedRouter>
								<CustomizeTripContainer />
							</ProtectedRouter>
						}></Route>

					<Route path='Terms-And-Conditions' element={<TermsAndConditions />} />
					<Route path='*' element={<Navigate to='/' replace />} />
				</Route>
			</Routes>
			{/* </Suspense> */}
		</BrowserRouter>
	);
};

export default AppRouter;
