import { createSlice } from '@reduxjs/toolkit';
import { tripControls } from '../../constants/Information';
import { openNotification } from './../../components/common/Notification/Notification';

const initialState = {
	travel: {},
	routes: [],
	sights: [],
	travels: [],
	sightsNear: [],
	isLoading: true,
	errors: {},
	message: null,
	shareURL: 'https://www.before.voyage'
};

const customizeTripSlice = createSlice({
	name: 'CustomizeTripPage',
	initialState,
	reducers: {
		travelReset: () => initialState,
		travelRequested: state => {
			state.isLoading = true;
		},
		travelReceived: (state, action) => {
			state.travel = action.payload;
		},
		travelRequestFailed: (state, action) => {
			state.isLoading = false;
			state.errors.travel = action.payload;
		},
		travelsReceived: (state, action) => {
			state.travels = action.payload;
		},
		sightsNearReceived: (state, action) => {
			state.sightsNear = [...state.sightsNear, action.payload];
		},
		sightsNearRequestFailed: (state, action) => {
			state.errors.sightsNear = action.payload;
		},
		sightsNearDeleted: (state, action) => {
			const { day, identifier } = action.payload;
			state.sightsNear = state.sightsNear.filter(item => item.day !== day && item.identifier === identifier);
		},
		routesReceived: (state, action) => {
			state.routes = action.payload;
		},
		sightsReceived: (state, action) => {
			const { sights, search } = action.payload;
			state.sights = sights;
			// console.log(action.payload, sights)

			tripControls.forEach(({ control, icon }) => {
				if (search && search[control]) {
					search[control].forEach(item => {
						state.sights.push({
							title: item.data.label,
							subtitle: control,
							identifier: item.data.label,
							day: item.day,
							location: [item.data.x, item.data.y],
							icon: icon,
						});
					});
				}
			});
			state.isLoading = false;
		},
		sightsRequestFailed: (state, action) => {
			state.isLoading = false;
			state.errors.sights = action.payload;
		},
		travelSaved: (state, action) => {
			state.message = action.payload;
		},
		sightAdded: (state, action) => {
			const { day, sight, index } = action.payload;
			// console.log(action.payload)
			state.sights.push(sight);

			if (index === -1) {
				state.travel.route[day - 1].sights.push(sight.identifier);
				const lastSight = state.travel.route[day - 1].sights[state.travel.route[day - 1].sights.length - 2];

				const indexLastSight = state.routes[day - 1].sights.findIndex(s => s === lastSight);
				state.routes[day - 1].sights.splice(indexLastSight + 1, 0, sight.identifier);
				// state.sights.push(sight);
			} else {
				// const existingSight = state.routes[day-1].sights.find(el => el === sight.identifier);
				// console.log(existingSight)
				state.routes[day - 1].sights.splice(index, 0, sight.identifier);
			}
		},
		sightDeleted: (state, action) => {
			const { identifier, day, index } = action.payload;
			console.log(action.payload, state.travel)
			if (index !== -1) state.sights = state.sights.filter(sight => !(sight.day === day && sight.identifier === identifier));
			state.routes = state.routes.map(route => ({ ...route, sights: route.sights.filter(sight => sight !== identifier) }));
			state.travel = { ...state.travel, route: state.travel.route.map(route => ({ ...route, sights: route.sights.filter(sight => sight !== identifier) })) };
		},
		sightMoved: (state, action) => {
			const { move = '', sight = '', day = -1, identifier = '' } = action.payload;
			// console.log(action.payload);
			const changeSightIndex = state.travel.route[day - 1].sights.findIndex(item => item === sight);
			// console.log(changeSightIndex, day - 1, sight)
			state.travel.route[day - 1].sights.splice(changeSightIndex, 1);
			state.routes[day - 1].sights.splice(changeSightIndex, 1);
			if (move === 'up') {
				if (changeSightIndex === 0) {
					state.travel.route[day - 2].sights.push(sight);
					state.routes[day - 2].sights.push(sight);
				} else {
					state.travel.route[day - 1].sights.splice(changeSightIndex - 1, 0, sight);
					state.routes[day - 1].sights.splice(changeSightIndex - 1, 0, sight);
				}
			} else if (move === 'down') {
				if (changeSightIndex === state.travel.route[day - 1].sights.length) {
					state.travel.route[day].sights.splice(0, 0, sight);
					state.routes[day].sights.splice(0, 0, sight);
				} else {
					state.travel.route[day - 1].sights.splice(changeSightIndex + 1, 0, sight);
					state.routes[day - 1].sights.splice(changeSightIndex + 1, 0, sight);
				}
			} else if (move === 'next') {
				state.travel.route[day].sights.push(sight);
				state.routes[day].sights.push(sight);
			} else if (move === 'prev') {
				state.travel.route[day - 2].sights.push(sight);
				state.routes[day - 2].sights.push(sight);
			}
			// if (move === 'up' || move === 'down') {
			// 	let changeSightIndex = state.travel.route[day - 1].sights.findIndex(item => item === sight);
			// 	console.log(changeSightIndex, day-1, state.travel.route[day - 1])
			// 	if (changeSightIndex !== 0 && move === 'up') {
			//         [state.travel.route[day - 1].sights[changeSightIndex - 1], state.travel.route[day - 1].sights[changeSightIndex]] =
			//             [state.travel.route[day - 1].sights[changeSightIndex], state.travel.route[day - 1].sights[changeSightIndex - 1]];
			//         // openNotification(<span><span style={{color: '#00008B'}}>{state.travel.route[day - 1].sights[changeSightIndex - 1]}</span> was moved earlier in the day.</span>);
			//     }else if (changeSightIndex !== state.travel.route[day - 1].sights.length - 1 && move === 'down') {
			//         [state.travel.route[day - 1].sights[changeSightIndex], state.travel.route[day - 1].sights[changeSightIndex + 1]] =
			//             [state.travel.route[day - 1].sights[changeSightIndex + 1], state.travel.route[day - 1].sights[changeSightIndex]];
			//         // openNotification(<span><span style={{color: '#00008B'}}>{state.travel.route[day - 1].sights[changeSightIndex + 1]}</span> was moved later in the day.</span>);
			//     } else if( move === 'up' && changeSightIndex === 0){
			// 		state.travel.route[day - 1].push(sight)
			// 	} else {
			//         openNotification('Sorry. Not possible.', 'error');
			//     }
			// }
		},
		shareURLChanged: (state, action) => {
			state.shareURL = action.payload;
		}
	},
});

const { actions, reducer: CustomizeTripReducer } = customizeTripSlice;

export const {
	travelReset,
	travelRequested,
	travelReceived,
	travelRequestFailed,
	travelsReceived,
	sightsNearReceived,
	sightsNearRequestFailed,
	sightsNearDeleted,
	routesReceived,
	sightsReceived,
	sightsRequestFailed,
	travelSaved,
	sightAdded,
	sightDeleted,
	sightMoved,
	shareURLChanged
} = actions;

export { CustomizeTripReducer };
