import { getRouteDay } from './../../helpers/_Functions';
import travelService from './../../services/travel.service';
import sightService from './../../services/sight.service';
import {
	sightsNearReceived,
	sightsNearRequested,
	sightsNearRequestFailed,
	sightsReceived,
	sightsRequested,
	sightsRequestFailed,
	travelReceived,
	travelRequested,
	travelsReceived,
	travelsRequested,
	travelsRequestFailed,
	travelsWithSightsNearReceived,
	travelsWithSightsNearRequested,
	travelsWithSightsNearRequestFailed,
} from '../reducers/TravelOverviewReducer';

export const loadTravels = identifier => async dispatch => {
	dispatch(travelsRequested());
	dispatch(travelRequested());
	try {
		const params = { filter: { identifier } };
		const travels = await travelService.get(params);
		dispatch(travelsReceived(travels));
	} catch ({ message }) {
		dispatch(travelsRequestFailed(message));
	}
};

export const setTravel =
	(difficulty = 'moderate') =>
	(dispatch, getState) => {
		const { travels } = getState().TravelOverviewPage;

		const travel = travels.find(t => t.difficulty === difficulty);
		dispatch(travelReceived(travel));
	};

export const loadSights = route => async dispatch => {
	dispatch(sightsRequested());
	try {
		const sightsArr = route.map(elem => elem.sights).flat();

		const uniqueSights = [...new Set(sightsArr)];
		const params = { sights: uniqueSights, limit: 1000 };

		const sights = await sightService.getSightsForTravel(params);
		const routes = await getRouteDay(route, sights);
		dispatch(sightsReceived({ sights, routes }));
	} catch ({ message }) {
		dispatch(sightsRequestFailed(message));
	}
};

export const loadSightsNear = route => async dispatch => {
	dispatch(sightsNearRequested());
	try {
		const firstSights = route[0].sights.length > 5 ? route[0].sights.slice(0, 5) : route[0].sights;
		const params = { sights: firstSights };
		let sights = await sightService.getSightsForTravel(params);

		let allSightsNear = [];

		for (const i in sights) {
			const lat = sights[i].location[1],
				long = sights[i].location[0];
			const params = {
				lat,
				long,
				limit: 1,
				identifier: {
					$ne: sights[i],
				},
				distance: 50000,
			};
			const sightsNear = await sightService.getSightsNear(params);
			allSightsNear.push(...sightsNear);
		}

		const unique = [...new Map(allSightsNear.map(item => [item['identifier'], item])).values()];
		dispatch(sightsNearReceived(unique));
	} catch ({ message }) {
		dispatch(sightsNearRequestFailed(message));
	}
};

export const loadTravelsWithSightsNear =
	(sights, identifier, difficulty = 'moderate') =>
	async dispatch => {
		dispatch(travelsWithSightsNearRequested());
		try {
			const sightsInTravels = sights.map(item => item.identifier);
			const params = { sights: sightsInTravels, difficulty, limit: 5, identifier, sort: { rating: -1 } };

			let travelsWithSightsNear = await travelService.getTravelsWithSights(params);
			dispatch(travelsWithSightsNearReceived(travelsWithSightsNear));
		} catch ({ message }) {
			dispatch(travelsWithSightsNearRequestFailed(message));
		}
	};
