import axios from 'axios';
import configFile from '../config.json';

const httpError = axios.create({
	baseURL: configFile.apiEndpoint_FireBase,
});

httpError.interceptors.request.use(
	async function (config) {
		if (configFile.isFireBase) {
			const containSlash = /\/$/gi.test(config.url);
			config.url = (containSlash ? config.url.slice(0, -1) : config.url) + '.json';
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

const errorsEndpoint = 'errors/';

const errorsService = {
	putError: async (payload, page) => {
		const { data } = await httpError.patch(errorsEndpoint+page.slice(1), payload);
		return data;
	},
};

export default errorsService;
