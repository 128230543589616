import React, { memo } from 'react';
import { useParams, useLocation } from 'react-router';

import { Typography, Row, Space, Col } from 'antd';
import TravelsFilter from './TravelsFilter';
import TravelsList from './TravelsList';
import BreadcrumbComponent from '../../ui/Breadcrumb/Breadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import { getTravels } from './../../../state/actions/TravelsActions';
import { addToFavorites } from '../../../state/actions/UserActions';
import { Loader } from './../../common/Loader/Loader';
import { ImageFinder } from './../../../Helper';
const { Title } = Typography;

const TravelsContent = () => {
	const { travels = [], skip = 0, hasMore = true, loading, errors } = useSelector(state => state.TravelsPage);
	const { isLoggedIn } = useSelector(state => state.UserPage);
	const { duration = '', difficulty = 'moderate', location } = useSelector(state => state.Search);
	const dispatch = useDispatch();
	const { days, identifier } = useParams();
	const { hash } = useLocation();

	const fetchMore = () => {
		if (identifier && identifier.includes(';')) {
			let location = identifier.split(';');
			let lat = parseFloat(location[0]);
			let long = parseFloat(location[1]);
			if (location.length === 2) {
				if (Number.isFinite(lat) && Number.isFinite(long)) {
					if (lat && long) {
						dispatch(getTravels({ skip: skip + 15, lat, long, days, hash, difficulty }));
					}
				}
			}
		} else {
			dispatch(getTravels({ skip: skip + 15, days, hash, difficulty }));
		}
	};

	const addToFavorite = travel => dispatch(addToFavorites(travel));

	const SorryMessageJSX = (
		<Col className='travels-message' align='center'>
			<ImageFinder src='flight.png' alt='We have no travels' />
			<span>Sorry, we have no trips for this location yet.</span>
		</Col>
	);
	const LoadingJSX = (
		<Space className='travels-loader' align='center'>
			<Loader />
		</Space>
	);

	const ErrorMessageJSX = (
		<Space className='travels-error' align='center'>
			{errors.travels}
		</Space>
	);
	const LoadingContentJSX = loading.travels ? LoadingJSX : errors.travels ? ErrorMessageJSX : !travels.length && SorryMessageJSX;

	return (
		<main className='travels-content main'>
			<BreadcrumbComponent text='Trips' home='beforevoyage.com' />
			{location.label ? (
				<Row className='travels-header'>
					<Title level={1}>Find trips near: {location.label}</Title>
					<Title level={2}>Search the most popular travel plans from other travelers</Title>
				</Row>
			) : (
				false
			)}
			<TravelsFilter />

			{!loading.travels && travels.length ? (
				<TravelsList travels={travels} duration={duration} difficulty={difficulty} hash={hash} hasMore={hasMore} isLoggedIn={isLoggedIn} fetchMore={fetchMore} addToFavorite={addToFavorite} />
			) : (
				LoadingContentJSX
			)}
		</main>
	);
};

export default memo(TravelsContent);
