import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, Steps, Typography } from 'antd';

import { getRoutes } from './../../../../state/reducers/TravelReducer';
import { checkControlExisting, convertMeasure } from './../../../../helpers/_Functions';
import TravelCard from './TravelCard';

const { Text } = Typography;
const { Step } = Steps;

const TravelCards = ({ route, day, objSights, getLinkToGoogle, Search, ...rest }) => {
	console.log(route, day)
	return (
		<Steps direction='vertical' status='finish'>
			{route.sights.map((sight, index, arr) => {
				if (objSights[sight])
					return (
						<Step
							key={index}
							
							status='finish'
							name={sight}
							icon={<i className='material-icons'>drive_eta</i>}
							description={<TravelCard objSights={objSights} Search={Search} day={day} sight={sight} {...rest} />}
						/>
					);
			})}
			<Step className='modify-btns__step' />
		</Steps>
	);
};

export default TravelCards;
