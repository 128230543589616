import React from 'react';
import { connect } from 'react-redux';
import { Tooltip, Steps, Typography } from 'antd';

import { checkControlExisting, convertMeasure } from '../../../../helpers/_Functions';
import TravelCard from './TravelCard';

const {Text} = Typography;
const { Step } = Steps;

const TravelTab = ({route, day, Search, routes, getLinkToGoogle, objSights, ...rest}) => {
	const firstDay = Number(day) === 1 && !(checkControlExisting(day, 'airport', Search) || checkControlExisting(day, 'car', Search) || checkControlExisting(day, 'start', Search));
	console.log(route, day, Search, routes, getLinkToGoogle, objSights, rest)
	const getLeg = (index) => {
		return routes[Number(day)-1].route.legs[
			Number(day) === 1
				? checkControlExisting(day, 'car', Search) && checkControlExisting(day, 'start', Search) && checkControlExisting(day, 'airport', Search)
					? index + 2
					: (checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'start', Search)) || (checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'car', Search)) || (checkControlExisting(day, 'car', Search) && checkControlExisting(day, 'start', Search))
					? index + 1
					: checkControlExisting(day, 'car', Search) || checkControlExisting(day, 'start', Search) || checkControlExisting(day, 'airport', Search)
					? index
					: index - 1
				: index
		];
	};

	return (
		<Steps direction='vertical' status='finish'>
			{route.sights.map((sight, index, arr) => {
				if(objSights[sight]) return (
					<Step
						key={index}
						title={
							firstDay && index === 0 ? (
								<Text>start from...</Text>
							) : (
								<>
									{getLeg(index)?.identifier ? <div>{getLeg(index).identifier}</div>
									: <Tooltip title='view on google'>
										<Text
											className='days-content__step-title'
											onClick={() => getLinkToGoogle(arr[day === 1 && !(Search?.airport || Search?.car || Search?.start) ? index - 1 : index], sight)}>
											{getLeg(index)
												? `${(
													convertMeasure(getLeg(index).distance, 'km')
												  ).toFixed(2)} km | ${(
													convertMeasure(getLeg(index).distance, 'mi')
												  ).toFixed(2)} mi`
												: ''}
										</Text>
									</Tooltip>}
									{Number(day) !== 1 && index === 0 && <Text className='days-content__step-subtitle'>from last sight of day {Number(day) - 1}</Text>}
								</>
							)
						}
						status='finish'
						name={sight}
						icon={<i className='material-icons'>drive_eta</i>}
						description={
						<TravelCard objSights={objSights} Search={Search} day={day} sight={sight} {...rest}/>}
					/>
				);
			})}
			<Step className='modify-btns__step' />
		</Steps>
	);
};
function mapStateToProps(state) {
	return {
		// routes: state.TravelPage.routes,
		// sights: state.TravelInfo.sights,
		travel: state.TravelPage.travel,
		Search: state.Search,
	};
}

export default connect(mapStateToProps, null)(TravelTab);
