import React from 'react';
import { Row, Col, Carousel, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { ImageFinder } from './../../../Helper';
import { Loader } from './../../common/Loader/Loader';
import ErrorMessage from './../../common/Message/Message';

const { Title, Text } = Typography;

const PopularTravels = ({ travels, loading, settings, message }) => {
	return (
		<>
			{!loading ? (
				<Carousel {...settings}>
					{Object.keys(travels).map(key => {
						const day = travels[key][0].daysNumber;
						return (
							<Row key={`daytravels-${day}`}>
								<Title level={3} className='zeropage-daytravels__card-title'>
									Popular {day}-Day Trips
								</Title>
								<Row className='zeropage-daytravels__card-first'>
									{travels[key].slice(0, 2).map((travel, index) => {
										return (
											<Col key={`card-first-${index}`} lg={12} md={12} xs={24} sm={24}>
												<ImageFinder src={travel.imageTitle} alt={travel.imageTitle} />
												<a href={`/trip/${travel.identifier}/${travel.difficulty}/`} className='zeropage-daytravels__card-link'>
													<Title level={4}>{travel.title}</Title>
													<Text>{travel.subtitle}</Text>
												</a>
												<Text className='zeropage-daytravels__card-distance'>
													Distance: {Math.floor(travel.distance / 1000)} km | {Math.floor(travel.distance / 1609.344)} mi
												</Text>
											</Col>
										);
									})}
								</Row>
								<Row className='zeropage-daytravels__card-second'>
									{travels[key].slice(2, 5).map((travel, index) => {
										return (
											<Col key={`card-second-${index}`} lg={8} md={8} xs={24} sm={24}>
												<ImageFinder src={travel.imageTitle} alt={travel.imageTitle} />
												<a href={`/trip/${travel.identifier}/${travel.difficulty}/`} className='zeropage-daytravels__card-link'>
													<Title level={4}>{travel.title}</Title>
													<Text>{travel.subtitle}</Text>
												</a>
												<Text className='zeropage-daytravels__card-distance'>
													Distance: {Math.floor(travel.distance / 1000)} km | {Math.floor(travel.distance / 1609.344)} mi
												</Text>
											</Col>
										);
									})}
								</Row>
								<a href={`/trips/${key}/`} className='zeropage-arrow--find'>
									<p>
										Find More <ArrowRightOutlined />
									</p>
								</a>
							</Row>
						);
					})}
				</Carousel>
			) : (
				<Loader />
			)}
			{message ? <ErrorMessage /> : false}
		</>
	);
};

export default PopularTravels;
