import { hashtagsWords, recommendedTravels, popularDayTrips } from '../../constants/Information';
import travelService from './../../services/travel.service';
import { ALLDAYS } from '../../constants/Duration';
import {
	citiesTripsFailed,
	citiesTripsReceived,
	citiesTripsRequested,
	hashtagsFailed,
	hashtagsReceived,
	hashtagsRequested,
	popularDayTripsFailed,
	popularDayTripsReceived,
	popularDayTripsRequested,
	recommendedTripsFailed,
	recommendedTripsReceived,
	recommendedTripsRequested,
} from '../reducers/ZeroPageReducer';

export const loadRecommendedTrips =
	(type = 'Recommended') =>
		async dispatch => {
			dispatch(recommendedTripsRequested());
			try {
				if (type === 'Recommended') {
					dispatch(recommendedTripsReceived(recommendedTravels));
				} else if (type === 'New') {
					const params = { filter: { difficulty: 'moderate' }, sort: { _id: -1 }, limit: 5 };
					const trips = await travelService.get(params);
					dispatch(recommendedTripsReceived(trips));
				}
			} catch ({ message }) {
				dispatch(recommendedTripsFailed(message));
			}
		};

export const loadPopularDayTrips = () => async dispatch => {
	dispatch(popularDayTripsRequested());
	try {
		const res = await Promise.all(
			popularDayTrips.map(async day => {
				const params = { filter: { difficulty: 'moderate', daysNumber: day.toString() }, limit: 5, sort: { rating: -1, identifier: 1 } };
				const data = await travelService.get(params);
				const days = Object.keys(ALLDAYS).find(key => ALLDAYS[key].includes(day));

				return { [`${days}`]: data };
			}),
		);
		dispatch(popularDayTripsReceived(res));
	} catch ({ message }) {
		dispatch(popularDayTripsFailed(message));
	}
};

export const loadCitiesTrips = subtitle => async dispatch => {
	dispatch(citiesTripsRequested());
	try {
		const params = { filter: { difficulty: 'moderate', hashtags: subtitle }, sort: { rating: -1 }, limit: 8 };
		const data = await travelService.get(params);

		dispatch(citiesTripsReceived(data));
	} catch ({ message }) {
		dispatch(citiesTripsFailed(message));
	}
};

export const loadHashtags = () => async dispatch => {
	dispatch(hashtagsRequested());
	try {
		const params = { filter: {}, limit: 800 };
		let hashtagsArr = [];

		const data = await travelService.get(params);
		data.map(travel => {
			hashtagsArr = hashtagsArr.concat(travel.hashtags);
		});
		const uniqueHashtags = [...new Set(hashtagsArr)];
		hashtagsWords.map(el => uniqueHashtags.filter((hashtag, index) => (hashtag == el ? uniqueHashtags.splice(index, 1) : false)));

		dispatch(hashtagsReceived(uniqueHashtags));
	} catch ({ message }) {
		dispatch(hashtagsFailed(message));
	}
};
