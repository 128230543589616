import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams, useLocation } from 'react-router';

import './MapContainer.sass';
import MapContent from '../../components/pages/MapPage/MapContent';
import Header from '../../components/ui/Header/Header';
// import * as SearchActions from '../../state/actions/SearchAction';
import { Loader } from './../../components/common/Loader/Loader';
import PageHelmet from './../../components/ui/hoc/PageHelmet';
import { checkLatLong } from '../../helpers/_Functions';
import ErrorMessage from './../../components/common/Message/Message';
import { loadTravels } from './../../state/actions/MapActions';

const MapContainer = () => {
	const {
		travels,
		loading: { travels: loading = true },
		errors: {travels: error = null},
	} = useSelector(state => state.MapPage);
	const { identifier, distance = 34400, duration = 'All' } = useParams();
	const params = useParams();
	const { hash } = useLocation();
	const dispatch = useDispatch();
	const [loadingPage, setLoadingPage] = useState(true);

	useEffect(() => {
		console.log('first loading + params changed', params, identifier, distance, duration, hash);
		if (identifier) {
			const { lat, long } = checkLatLong(identifier);
			if (lat && long) {
				dispatch(loadTravels({ lat, long, duration, hash, distance }));
			} else {
				dispatch(loadTravels({ sightIdentifier: identifier, duration, hash }));
			}
		} else {
			//map/itineraries/
			dispatch(loadTravels({ duration, hash, distance }));
		}
	}, [hash, duration, identifier, distance]);

	// useEffect(() => {
	// 	if (identifier && distance === 34400) {
	// 		const {lat, long} = checkLatLong(identifier);
	// 		if (lat && long) {
	// 			dispatch(getSearchLocationMapBox(lat, long));
	// 		}
	// 	}
	// if (label) {
	// 	SearchActions.deleteLocation();
	// }
	// }, [identifier]);

	useEffect(() => {
		if (!loading && loadingPage) {
			setLoadingPage(false);
		}
	}, [loading]);

	let title = 'Route Map: Best Trips, Itineraries and Destinations.';
	let description = 'Use the interactive route map to find the best trips, explore the most popular destinations and find the recommended itineraries. View travel guides and ideas of where to go.';
	const loadingContentJSX = loading && loadingPage ? <Loader /> : error ? <ErrorMessage text='Sorry, no trips found.' /> : false;
	console.log('loading', loading, 'loadingPage', loadingPage, travels.length);
	return (
		<div className='explore'>
			<PageHelmet title={title} description={description}>
				<Header />
				{!loadingPage ? <MapContent /> : loadingContentJSX}
				{/* {!loadingPage ? message ? <ErrorMessage text='Sorry, no trips found.' />  : <MapContent /> : <Loader />} */}
			</PageHelmet>
		</div>
	);
};

export default MapContainer;
