import React from 'react';
import { Col, Button, Tag } from 'antd';
import { INTENSITY } from '../../../../constants/Information';
const ButtonGroup = Button.Group;

const IntensityFilter = ({ difficulty = 'all', handleChangeFilters }) => {
	const handleSelect = value => {
		handleChangeFilters('difficulty', value);
	};
	return (
		<Col className='user-trips__filters-intensity'>
			<span className='user-trips__filters-subtitle'>Intensity</span>
			<ButtonGroup className='user-trips__filters-intensity__header'>
				{INTENSITY.map(d => (
					<Tag key={d} color={d === difficulty ? 'green' : 'grey'} onClick={() => handleSelect(d)} className={d === difficulty ? 'header-intensity_primary' : 'header-intensity_default'}>
						{d}
					</Tag>
				))}
				<Tag color={difficulty === 'all' ? 'green' : 'grey'} onClick={() => handleSelect('all')} className={difficulty === 'all' ? 'header-intensity_primary' : 'header-intensity_default'}>
					All
				</Tag>
			</ButtonGroup>
		</Col>
	);
};

export default IntensityFilter;
