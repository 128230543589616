import React from 'react';
import { Helmet } from 'react-helmet';

const PageHelmet = ({ children, title, description, image, canonicalUrl }) => {
	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name='keywords' content='Beforevoyage' />
				<meta name='description' content={description} />
				<meta name='robots' content='index, follow' />
				<meta property='twitter:title' content={title} />
				<meta property='twitter:description' content={description} />
				<meta property='twitter:card' content='summary' />
				{image && <meta property="twitter:image" content={image} />}
				<meta property="twitter:image:alt" content={title} />
				<meta property='og:url' content='https://www.beforevoyage.info/' />
				<meta property='og:type' content='article' />
				<meta property='og:title' content={title} />
				<meta property='og:description' content={description} />
				{image && <meta property="og:image" content={image} />}
				<link href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined" rel="stylesheet"/>
				<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossOrigin="anonymous" />
				{canonicalUrl && <link rel='canonical' href={`https://www.before.voyage${canonicalUrl}`} />}
			</Helmet>
			{children}
		</>
	);
};

export default PageHelmet;
