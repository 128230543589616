import React, { useState, useEffect, memo } from 'react';
import { Button, Checkbox, Steps, Tooltip, Typography, Divider } from 'antd';
import { useSelector } from 'react-redux';
import TravelModifyControls from './TravelModify/TravelModifyControls';
import TravelCard from './../../ui/TravelCards/TravelCard';
import { convertMeasure, checkControlExisting } from '../../../helpers/_Functions';
import { GetIcon } from '../../../Helper';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
const { Step } = Steps;

const TripPlanner = ({ objSights, updateTravelControls, travelControls, day, getLinkToGoogle }) => {
	const {identifier} = useParams();
	const [checked, setChecked] = useState(false);
	const { country } = useSelector(state => state.UserPage.user);
	const { travel, routes, sights } = useSelector(state => state.CustomizeTripPage);
	const { airport = [], car = [] } = useSelector(state => state.Search);
	const Search = useSelector(state => state.Search);
	const firstDay = Number(day) === 1 && !(checkControlExisting(day, 'airport', Search) || checkControlExisting(day, 'car', Search) || checkControlExisting(day, 'start', Search));

	const onChange = e => {
		setChecked(e.target.checked);
	};

	const getLeg = index => {
		return routes[Number(day) - 1].route.legs[
			Number(day) === 1 ? checkControlExisting(day, 'car', Search) && checkControlExisting(day, 'start', Search) && checkControlExisting(day, 'airport', Search) ? index + 2 : (checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'start', Search)) ||
					(checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'car', Search)) ||
					(checkControlExisting(day, 'car', Search) && checkControlExisting(day, 'start', Search)) ? index + 1 : checkControlExisting(day, 'car', Search) || checkControlExisting(day, 'start', Search) || checkControlExisting(day, 'airport', Search) ? index : index - 1 : index
		];
	};

	useEffect(() => {
		if (airport.length) {
			console.log('search for airports....');
			// get airport search
		}
	}, [airport]);

	return (
		<>
			<Steps direction='vertical' status='finish'>
				{day === '1' && (
					<Step
						icon={
							<div>
								<GetIcon src={checked ? 'planner-marker.svg' : 'planner-marker-default.svg'} />
								<i className={`material-icons planner-icon${!checked ? '__default' : ''}`}>home</i>
								<div className='planner-icon__shadow'></div>
							</div>
						}
						title={<Checkbox onChange={onChange}>Start: {country}</Checkbox>}
					/>
				)}

				{checked && day === '1' && (
					<Step
						status={'wait'}
						icon={<PlusCircleOutlined />}
						title={
							airport.length ? (
								<div>Coming soon...</div>
							) : (
								<div>
									<span>Search for:</span>
									<Button onClick={() => updateTravelControls({ airport: day })}>Airport</Button> <Button>Car Rent</Button>
								</div>
							)
						}
					/>
				)}
			</Steps>

			<TravelModifyControls
				travel={travel}
				routes={routes}
				sights={sights}
				travelControls={travelControls}
				day={day}
				startDay={true}
				isStart={checked}
				updateTravelControls={updateTravelControls}
				getLinkToGoogle={getLinkToGoogle}>
				{!car.length && !!airport.length && (
					<Step
						status={'wait'}
						icon={<PlusCircleOutlined />}
						title={
							<div>
								<span>Search for:</span>
								<Button onClick={() => updateTravelControls({ car: day })}>Car Rent</Button>
							</div>
						}
					/>
				)}
			</TravelModifyControls>

			<Steps direction='vertical' status='finish'>
				{travel.route[day - 1].sights.map((sight, index, arr) => {
					if (objSights[sight]) {
						return (
							<Step
								key={index}
								title={
									!(firstDay && index === 0) && (
										<>
											{getLeg(index)?.identifier ? (
												<div>{getLeg(index).identifier}</div>
											) : (
												<Divider orientation='right'>
													<Tooltip title='view on google'>
														<span className='material-icons travel-sights__step-icon'>drive_eta</span>
														<Typography.Text
															className='travel-sights__step-title'
															onClick={() => getLinkToGoogle(arr[day === 1 && !(Search?.airport || Search?.car || Search?.start) ? index - 1 : index], sight)}>
															{getLeg(index) ? `${convertMeasure(getLeg(index).distance, 'km').toFixed(2)} km | ${convertMeasure(getLeg(index).distance, 'mi').toFixed(2)} mi` : ''}
														</Typography.Text>
													</Tooltip>
												</Divider>
											)}
											{Number(day) !== 1 && index === 0 && <Typography.Text className='travel-sights__step-subtitle'>from last sight of day {Number(day) - 1}</Typography.Text>}
										</>
									)
								}
								status='finish'
								name={sight}
								icon={<span className='material-icons-outlined planner-icon__sight'>filter_hdr</span>}
								description={<TravelCard identifier={identifier} sights={sights} objSights={objSights} Search={Search} day={day} sight={sight} />}
							/>
						);
					}
					return null;
				})}

				<Step className='modify-btns__step' />
			</Steps>
			<TravelModifyControls
				travel={travel}
				routes={routes}
				sights={sights}
				travelControls={travelControls}
				day={day}
				endDay={true}
				updateTravelControls={updateTravelControls}
				getLinkToGoogle={getLinkToGoogle}
			/>
		</>
	);
};

export default memo(TripPlanner);
