import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography } from 'antd';

import BreadcrumbComponent from '../../ui/Breadcrumb/Breadcrumb';
import { checkDay, changeTitle } from '../../../helpers/_Functions';
import ButtonIntensity from '../../ui/ButtonIntensity';

const { Text, Title } = Typography;

const OverviewHeader = ({ travels = [], travel = {} }) => {
	const difficulties = (travels.length && travels.map(travel => travel.difficulty)) || [];

	return (
		<>
			<BreadcrumbComponent travel={travel} />
			<Row className='overview-header'>
				<Col lg={18} md={18} sm={24} xs={24} className='overview-header__content'>
					<Text className='overview-header__text'>Trip itinerary. Recommendations and useful information.</Text>
					<Col className='overview-header__titles'>
						<Title level={1} className='overview-header__title'>
							{changeTitle(travel.title)}
						</Title>
						<Text className='overview-header__day'>{checkDay(travel.daysNumber)}</Text>
					</Col>
					<Text className='overview-header__subtitle'>{travel.subtitle}</Text>
				</Col>
				<Col lg={6} md={6} sm={24} xs={24}>
					<ButtonIntensity difficulties={difficulties} difficulty={travel.difficulty} identifier={travel.identifier} />
				</Col>
			</Row>
		</>
	);
};

export default memo(OverviewHeader);
