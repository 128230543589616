import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Steps, Typography, Tooltip } from 'antd';

import TravelModifyStart from './ModifyStart';
import TravelModifyAirport from './ModifyAirport';
import TravelModifyCar from './ModifyCar';
import TravelModifyFinish from './ModifyFinish';
import TravelModifyHotel from './ModifyHotel';
import { checkControlExisting } from '../../../../helpers/_Functions';
import { getSearchInfo } from '../../../../state/reducers/SearchReducer';
import { addSight } from '../../../../state/actions/CustomizeTripActions';
import { addUserHotel } from './../../../../state/actions/UserActions';
import { tripExist } from './../../../../state/reducers/UserReducer';
import { GetIcon } from '../../../../Helper';

const { Step } = Steps;
const { Text } = Typography;
const TravelModifyControls = props => {
	const { travel = {}, isStart, children, sights = [], travelControls = {}, day = '', routes = [], startDay = false, endDay = false, updateTravelControls, getLinkToGoogle } = props;
	// const isTripExisted = useSelector(tripExist(travel.identifier, travel.difficulty));
	const dispatch = useDispatch();
	const Search = useSelector(state => state.Search);

	const addToSights = ({ obj, type, icon, index }) => {
		// console.log('added to sight');
		// const sightExist = routes[day - 1].sights.find(sight => sight === obj.data.label);
		dispatch(
			addSight({
				day: Number(obj.day),
				sight: {
					title: obj.data.label,
					subtitle: type,
					identifier: obj.data.label,
					day: obj.day,
					location: [obj.data.x, obj.data.y],
					icon,
				},
				index,
			}),
		);
	};

	const currIndexHotel =
		day === travel.daysNumber ? checkControlExisting(day, 'hotel', Search) ? checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'finish', Search) && checkControlExisting(day, 'car', Search) ? routes[day - 1].route.legs.length - 4 : (checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'finish', Search)) ||
					  (checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'car', Search)) ||
					  (checkControlExisting(day, 'car', Search) && checkControlExisting(day, 'finish', Search)) ? routes[day - 1].route.legs.length - 3 : checkControlExisting(day, 'airport', Search) || checkControlExisting(day, 'finish', Search) || checkControlExisting(day, 'car', Search) ? routes[day - 1].route.legs.length - 2 : routes[day - 1].route.legs.length - 1 : undefined : checkControlExisting(day, 'hotel', Search) ? routes[day - 1].route.legs.length - 1 : undefined;

	const currIndexCar = startDay ? checkControlExisting(day, 'car', Search) ? checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'start', Search) ? 1 : checkControlExisting(day, 'airport', Search) || checkControlExisting(day, 'start', Search) ? 0 : undefined : undefined : checkControlExisting(day, 'car', Search) ? checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'finish', Search) ? routes[day - 1].route.legs.length - 3 : checkControlExisting(day, 'airport', Search) || checkControlExisting(day, 'finish', Search) ? routes[day - 1].route.legs.length - 2 : routes[day - 1].route.legs.length - 1 : undefined;

	const currIndexAirport = startDay ? checkControlExisting(day, 'start', Search) && checkControlExisting(day, 'airport', Search) ? 0 : undefined : checkControlExisting(day, 'airport', Search) ? checkControlExisting(day, 'finish', Search) ? routes[day - 1].route.legs.length - 2 : routes[day - 1].route.legs.length - 1 : undefined;

	const currIndexFinish = Search?.finish?.length && Search?.finish.filter(s => s.day === day) && routes[day - 1].route.legs.length - 1;

	const controlIcon = (icon, control) => (
		<div>
			<GetIcon src={checkControlExisting(day, control, Search) ? 'planner-marker.svg' : 'planner-marker-default.svg'} />
			<i className={`material-icons planner-icon${!checkControlExisting(day, control, Search) ? '__default' : ''}`}>{icon}</i>
			<div className='planner-icon__shadow'></div>
		</div>
	);

	const stepsControls = {
		start: {
			title: <Text className='days-content__step-title'>Start</Text>,
			icon: controlIcon('flag', 'start'),
			description: <TravelModifyStart identifier={travel.identifier} day={day} sights={sights} addToSights={addToSights} updateTravelControls={updateTravelControls} />,
		},
		airport: {
			title:
				currIndexAirport === 0 || currIndexAirport ? (
					<Tooltip title='view on google'>
						<Text className='days-content__step-title' onClick={() => getLinkToGoogle()}>
							{`${(routes[day - 1].route.legs[currIndexAirport].distance / 1000).toFixed(2)} km | ${(routes[day - 1].route.legs[currIndexAirport].distance / 1609.344).toFixed(2)} mi`}
						</Text>
					</Tooltip>
				) : (
					<Text className='days-content__step-title'>add airport to trip start day</Text>
				),
			icon: controlIcon('flight', 'airport'),
			description: <TravelModifyAirport travel={travel} sights={sights} routes={routes} type='startDay' day={day} updateTravelControls={updateTravelControls} addToSights={addToSights} />,
		},
		car: {
			title:
				currIndexCar === 0 || currIndexCar ? (
					<Tooltip title='view on google'>
						<Text className='days-content__step-title' onClick={() => getLinkToGoogle()}>
							{`${(routes[day - 1].route.legs[currIndexCar].distance / 1000).toFixed(2)} km | ${(routes[day - 1].route.legs[currIndexCar].distance / 1609.344).toFixed(2)} mi`}
						</Text>
					</Tooltip>
				) : (
					<Text className='days-content__step-title'>add car to trip</Text>
				),
			icon: controlIcon('directions_car', 'car'),
			description: (
				<TravelModifyCar identifier={travel.identifier} sights={sights} routes={routes} type='startDay' day={day} updateTravelControls={updateTravelControls} addToSights={addToSights} />
			),
		},
		hotel: {
			title: currIndexHotel ? (
				<Tooltip title='view on google'>
					<Text className='days-content__step-title' onClick={() => getLinkToGoogle()}>
						{`${(routes[day - 1].route.legs[currIndexHotel].distance / 1000).toFixed(2)} km | ${(routes[day - 1].route.legs[currIndexHotel].distance / 1609.344).toFixed(2)} mi`}
					</Text>
				</Tooltip>
			) : (
				<Text className='days-content__step-title'>add hotel to trip</Text>
			),
			icon: controlIcon('king_bed', 'hotel'),
			description: <TravelModifyHotel travel={travel} routes={routes} sights={sights} day={day} updateTravelControls={updateTravelControls} addToSights={addToSights} />,
		},
		finish: {
			title: currIndexFinish ? (
				<Tooltip title='view on google'>
					<Text className='days-content__step-title' onClick={() => getLinkToGoogle()}>
						{`${(routes[day - 1].route.legs[currIndexFinish].distance / 1000).toFixed(2)} km | ${(routes[day - 1].route.legs[currIndexFinish].distance / 1609.344).toFixed(2)} mi`}
					</Text>
				</Tooltip>
			) : (
				<Text className='days-content__step-title'>add finish...</Text>
			),
			icon: controlIcon('done', 'finish'),
			description: <TravelModifyFinish routes={routes} identifier={travel.identifier} sights={sights} day={day} updateTravelControls={updateTravelControls} addToSights={addToSights} />,
		},
	};

	const startDayControls = ['start', 'airport', 'car'];
	const endDayControls = ['hotel'];
	const endTripControls = ['car', 'airport', 'finish'];

	return (
		<>
			{day === '1' && startDay && (
				<Steps direction='vertical'>
					{startDayControls.map(control => (
						<>
							{((control === 'start' && travelControls.start?.includes(day) && !isStart) || (control !== 'start' && travelControls[control]?.includes(day))) && (
								<>
									<Step
										key={control}
										title={stepsControls[control].title}
										icon={stepsControls[control].icon}
										description={stepsControls[control].description}
									/>
									{control === 'airport' && children}
								</>
							)}
						</>
					))}
					<Step className='modify-btns__step' />
				</Steps>
			)}

			{travelControls.hotel?.includes(day) && endDay && (
				<Steps direction='vertical'>
					{endDayControls.map(control => (
						<Step
							key={control}
							icon={stepsControls[control].icon}
							title={stepsControls[control].title}
							description={stepsControls[control].description}
						/>
					))}
					<Step className='modify-btns__step' />
				</Steps>
			)}

			{day === travel.daysNumber && endDay && (
				<Steps direction='vertical'>
					{endTripControls.map(control => (
						<>{travelControls[control]?.includes(day) && <Step
							key={control}
							icon={stepsControls[control].icon}
							title={stepsControls[control].title}
							description={stepsControls[control].description}
						/>}</>
					))}
				</Steps>
			)}
		</>
	);
};

export default TravelModifyControls;
