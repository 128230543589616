import {
	GET_REVIEWS,
	GET_TRAVELCONTAINER_DATA,
	GET_TRAVELINFO,
	// GET_SIGHTINFO,
	GET_SIGHT_INFO,
	GET_SIGHTS_FOR_CORDS,
	PUT_SIGHTS_AND_TRAVELS,
	PUT_SIGHTS_AND_TRAVELS_SKIP,
	PUT_TRAVELS,
	DELETE_SIGHTS_AND_TRAVELS,
	ADD_SIGHTS,
	CHANGE_ROUTE,
	DELETE_SIGHT,
	DELETE_NEW_SIGHTS,
	// SET_REVIEW_FIELD,
	MOVE_SIGHT,
	GET_TRAVELS_FOR_SIGHT,
	GET_SIGHTS_NEAR
} from '../../constants/ActionTypes';
import axios from 'axios';
import { URL_TRAVEL, URL_SIGHTS } from '../../config';
import {  getRouteDay } from '../../helpers/_Functions';
import { hideLoader, showLoader } from './HelperActions';
import travelService from './../../services/travel.service';
import sightService from './../../services/sight.service';

async function getDataForContainer() {
	let travelParams = { filter: {}, limit: 1000};
	let sightsParams = { filter: {}};
	// let url_travel = `${URL_TRAVEL}%7B%7D&limit=1000`;
	
	// let sightsParams = `${URL_SIGHTS}%7B%7D`;

	let dataObj = {};
	try {
		let travels = await travelService.get(travelParams);
		let sights = await sightService.get(sightsParams);
		dataObj.travels = travels;
		dataObj.sights = sights;
	} catch (err) {
		console.log('Error getDataTravelContainer', err);
	}
	return dataObj;
}

export function getTravelsAndSights() {
	return {
		type: GET_TRAVELCONTAINER_DATA,
		payload: getDataForContainer(),
	};
}



export function putSightInformation(dataObj) {
	return {
		type: GET_SIGHT_INFO,
		payload: dataObj,
	};
}

export function putTravelInformation(dataObj) {
	return {
		type: GET_TRAVELINFO,
		payload: dataObj,
	};
}

export function putSightsAndTravels(obj = {}) {
	return {
		type: PUT_SIGHTS_AND_TRAVELS,
		payload: obj,
	};
}

export function putSightsAndTravelsSkip(obj = {}) {
	return {
		type: PUT_SIGHTS_AND_TRAVELS_SKIP,
		payload: obj,
	};
}

export function deleteSightsAndTravels(obj = {}) {
	return {
		type: DELETE_SIGHTS_AND_TRAVELS,
		payload: obj,
	};
}

export function putTravels(travels = []) {
	return {
		type: PUT_TRAVELS,
		payload: { travels: travels },
	};
}

export function addSights(sight, index, day) {
	return {
		type: ADD_SIGHTS, 
		payload: { add: sight, index, day }
	};
}

export function changeRoute (route, sights){
	return {
		type: CHANGE_ROUTE,
		payload: getRouteDay(route, sights)
	};
}

export function deleteSight(type, identifier, travel, day) {
	return {
		type: DELETE_SIGHT,
		payload: { type, identifier, travel, day },
	};
}

export function moveSight(identifier, travel, day, move) {
	return {
		type: MOVE_SIGHT,
		payload: { identifier, travel, day, move },
	};
}

export function deleteNewSights(identifier, day) {
	return {
		type: DELETE_NEW_SIGHTS,
		payload: { identifier: identifier, day: day },
	};
}

export function getReviews(identifier) {
	let filterUrl = `{"project": "beforevoyage", "objectId": ${identifier}}`;
	let encdURI = encodeURIComponent(filterUrl);
	let url = `https://j42zffbho3.execute-api.us-east-1.amazonaws.com/test?$filter=${encdURI}`;

	return {
		type: GET_REVIEWS,
		payload: axios.get(url),
	};
}

// export function setReviewField(obj) {
// 	return {
// 		type: SET_REVIEW_FIELD,
// 		payload: obj,
// 	};
// }

// export function getSightInfo(identifier) {
// 	return async dispatch => {
// 		try {
// 			let filtr = `{"identifier": ${identifier}}`;
// 			let urlSight = `${URL_SIGHTS}${encodeURIComponent(filtr)}`;
// 			let {
// 				data: [sight],
// 			} = await axios.get(urlSight);

// 			dispatch({ type: GET_SIGHT_INFO, payload: sight });
// 		} catch (e) {
// 			console.log(e);
// 		}
// 	};
// }

// export function getSightsNear(lat, lng) {
// 	return async dispatch => {
// 		try {
// 			let distance = '50000';
// 			let filterUrl = `{"location":{"$near":{"$geometry":{"type":"Point","coordinates":[${lng},${lat}]},"$maxDistance":${distance}}}}`;
// 			dispatch(showLoader('getSightsNear'));

// 			let { data: sights = [] } = await axios.get(`${URL_SIGHTS}${encodeURIComponent(filterUrl)}`);
// 			dispatch({ type: GET_SIGHTS_NEAR, payload: sights });
// 			dispatch(hideLoader('getSightsNear'));
// 		} catch (e) {
// 			console.log(e);
// 			dispatch(hideLoader('getSightsNear'));
// 		}
// 	};
// }

// export function getTravelsForSight(sight) {
// 	return async dispatch => {
// 		try {
// 			let urlDataTravels = `{"route.sights": "${sight}", "difficulty": "moderate"}`;
// 			let urlTravels = `${URL_TRAVEL}${encodeURIComponent(urlDataTravels)}&%24sort=${encodeURIComponent('{"rating": -1}')}`;
// 			dispatch(showLoader('getTravelsForSight'));

// 			let { data: travels = [] } = await axios.get(urlTravels);
// 			dispatch({ type: GET_TRAVELS_FOR_SIGHT, payload: travels });
// 			dispatch(hideLoader('getTravelsForSight'));
// 		} catch (e) {
// 			console.log(e);
// 			dispatch(hideLoader('getTravelsForSight'));
// 		}
// 	};
// }

// export function getSightsTravel(identifier, intensity = 'moderate') {
// 	return async dispatch => {
// 		try {
// 			let filtr = `{"identifier": ${identifier}, "difficulty": ${intensity}}`;
// 			let urlTravel = `${URL_TRAVEL}${encodeURIComponent(filtr)}`;
// 			dispatch(showLoader('getSightsTravel'));
// 			let { data: travel = [] } = await axios.get(urlTravel);

// 			let sightsArr = [];
// 			travel[0].route.map(elem => {
// 				sightsArr = sightsArr.concat(elem.sights);
// 			});
// 			let uniqeSights = [...new Set(sightsArr)];

// 			let urlDataSights = `{"identifier": {"$in": ${JSON.stringify(uniqeSights)}}}`;
// 			let urlSights = `${URL_SIGHTS}${encodeURIComponent(urlDataSights)}&$limit=5`;
// 			let { data: sights = [] } = await axios.get(urlSights);

// 			dispatch({ type: GET_SIGHTSTRAVEL, payload: { sights, travel: travel[0] } });
// 			dispatch(hideLoader('getSightsTravel'));
// 		} catch (e) {
// 			console.log(e);
// 			dispatch(hideLoader('getSightsTravel'));
// 		}
// 	};
// }

