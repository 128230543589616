import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Card, Tabs, Carousel } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { recommendedTab } from '../../../constants/Information';
import { Loader } from './../../common/Loader/Loader';
import { ImageFinder } from './../../../Helper';
import ErrorMessage from './../../common/Message/Message';

const { Meta } = Card;

const RecommendedTravels = ({ travels, loading, settings, message, changeRecommendedTravels }) => {
	const items = recommendedTab.map((tab, index) => ({
		label: tab,
		key: index,
		children: !loading ? (
			<Carousel {...settings} className='zeropage-recommended__carousel'>
				{travels.map((travel, key) => (
					<Col lg={8} xs={24} sm={24} md={8} key={`card-${key}`}>
						<Link to={`/trip/${travel.identifier}/moderate/`}>
							<Card
								hoverable
								className='zeropage-recommended__carousel-card'
								cover={<ImageFinder alt={travel.imageTitle} title={travel.imageTitle} src={travel.imageTitle} className='zeropage-recommended__carousel-img' />}>
								<Meta title={travel.title} description={travel.subtitle} />
							</Card>
						</Link>
					</Col>
				))}
				<Col className='zeropage-recommended__info'>
					<Link to='/trips/All' className='zeropage-arrow'>
						Show More <ArrowRightOutlined />
					</Link>
				</Col>
			</Carousel>
		) : (
			<Loader />
		),
	}));

	return (
		<>
			<Tabs className='zeropage-recommended__pane' onChange={key => changeRecommendedTravels(key)} items={items} />
			{message ? <ErrorMessage /> : false}
		</>
	);
};

export default RecommendedTravels;
