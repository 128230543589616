import { SHOW_LOADER, HIDE_LOADER, SHOW_MESSAGE, HIDE_MESSAGE } from '../../constants/ActionTypes';

// INITIALIZE STATE
const initialState = {
	loading: {},
	message: {},
	type: null,
};

const setHelper = (type, state, identifier, value, name) => {
	if (type !== state.type) {
		return {
			...state,
			type,
			[name]: { [identifier]: value },
		};
	} else {
		return {
			...state,
			[name]: { ...state[name], [identifier]: value },
		};
	}
};

// REDUCER
export const HelperReducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_LOADER:
			return {
				...state,
				loading: { ...state.loading, [action.payload]: true },
			};
		// return setHelper(action.payload.type, state, action.payload.identifier, true, 'loading');
		case HIDE_LOADER:
			return {
				...state,
				loading: { ...state.loading, [action.payload]: false },
			};
		// return setHelper(action.payload.type, state, action.payload.identifier, false, 'loading');
		case SHOW_MESSAGE:
			return {
				...state,
				message: { ...state.message, [action.payload.identifier]: action.payload.message },
			};
		// return setHelper(action.payload.type, state, action.payload.identifier, action.payload.message, 'message');
		case HIDE_MESSAGE: {
			return {
				...state,
				message: {... state.message, [action.payload]: null}
			};
			// setHelper(action.payload.type, state, action.payload.identifier, null, 'message');
		}
		default:
			return state;
	}
};
