import React from 'react';
import { Col, Typography } from 'antd';
import { StarFilled, ClockCircleOutlined } from '@ant-design/icons';

import { ImageFinder } from '../../../Helper';
import { checkDay, changeTitle } from '../../../helpers/_Functions';

const MapTripCard = ({ travel, mouseOver, filteredHashtags }) => {
	return (
		<Col className='explore-card' onMouseOver={() => mouseOver(travel.route)} onFocus = { () => mouseOver(travel.route) }>
			<ImageFinder src={travel.imageTitle} alt={travel.identifier} className='explore-card__img' />
			<a href={`/trip/${travel.identifier}/${travel.difficulty}/`} className='explore-card__titles'>
				<Typography.Title level={2}>
					{changeTitle(travel.title)}
				</Typography.Title>
				<Col className='explore-card__info'>
					<Typography.Text>
						<ClockCircleOutlined className='explore-card__info-icon' />
						{checkDay(travel.daysNumber)}
					</Typography.Text>
				</Col>
				<Col className='explore-card__rate'>
					<StarFilled className='explore-card__rate-icon' />
					<span>{travel.rating / 20}</span>
				</Col>
				<Col className='explore-card__hashtags'>
					{travel.hashtags.map(
						(hashtag, index) =>
							filteredHashtags.includes(hashtag) && (
								<span className='explore-card__hashtag' key={index}>
									#{hashtag}
								</span>
							)
					)}
				</Col>
			</a>
		</Col>
	);
};

export default MapTripCard;
