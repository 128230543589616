export const INTENSITY = ['relaxed', 'moderate', 'active'];
export const tripControls = [
	{ control: 'hotel', icon: 'fa-hotel' },
	{ control: 'airport', icon: 'fa-plane' },
	{ control: 'car', icon: 'fa-car-side' },
	{ control: 'start', icon: 'fa-home' },
	{ control: 'finish', icon: 'fa-calendar-check' },
];
export const popularDayTrips = [1, 3, 7];
export const popularCities = ['California', 'Arizona', 'Utah', 'Wyoming', 'Alberta', 'British Columbia', 'Ontario', 'Quebec'];
export const recommendedTab = ['Recommended', 'New'];
export const hashtagsWords = ['UniquePlace', 'BestInWinter', 'BestInSummer', 'BestInSpring', 'BestInAutumn', 'BestLateSpring', 'Mountains', 'Pacific', 'Atlantic', 'Wildlife'];

export const informationsTabs = [
	{
		number: '526',
		text: 'travel plans',
	},
	{
		number: '3',
		text: 'difficulty levels',
	},
	{
		number: '1526',
		text: 'photos',
	},
	{
		number: '765',
		text: 'sights',
	},
];

export const citiesOnMap = [
	{
		title: 'Yosemite Valley, CA',
		location: [-119.5797854, 37.7442767],
	},
	{
		title: 'Hurricane, UT',
		location: [-113.2910624, 37.1765942],
	},
	{
		title: 'Tropic, CA',
		location: [-112.0822481, 37.6251254],
	},
	{
		title: 'Las Vegas',
		location: [-115.1815778, 36.12957],
	},
	{
		title: 'Moab',
		location: [-109.7449774, 38.7599241],
	},
	{
		title: 'West Yellowstone, MT',
		location: [-111.106259, 44.661971],
	},
	{
		title: 'Jackson Hole, WY',
		location: [-110.7811181, 43.4734139],
	},
	{
		title: 'West Glacier, MT',
		location: [-113.9966968, 48.4996527],
	},
	{
		title: 'Grand Canyon Village, AZ',
		location: [-112.1408313, 36.0549062],
	},
	{
		title: 'Page, AZ',
		location: [-111.4583269, 36.913957],
	},
	{
		title: 'Banff, AB',
		location: [-115.5727501, 51.1774969],
	},
	{
		title: 'Tofino, BC',
		location: [-125.8943518, 49.1191856],
	},
	{
		title: 'Niagara Falls, ON',
		location: [-79.085688, 43.0882764],
	},
	{
		title: 'Toronto, ON',
		location: [-79.4186755, 43.6699474],
	},
	{
		title: 'Montreal, QC',
		location: [-73.5758465, 45.5258016],
	},
	{
		title: 'Ottawa, ON',
		location: [-75.7075767, 45.418349],
	},
	{
		title: 'Quebec City, QC',
		location: [-71.2082909, 46.8128903],
	},
	{
		title: 'Tobermory, ON',
		location: [-81.6654055, 45.2531655],
	},
];

export const recommendedTravels = [
	{
		identifier: 'ArizonaUtah7Days',
		title: 'Arizona, Utah, 7 days',
		subtitle: 'Arizona, Utah',
		imageTitle: 'monument-valley-visitor-center-1.jpg',
	},
	{
		identifier: 'NorCalOregon9Days',
		title: 'Northern California - Oregon, 9 days',
		subtitle: 'California, Oregon',
		imageTitle: 'crater-lake-from-rim-village-1.jpg',
	},
	{
		identifier: 'Yellowstone3Days',
		title: 'Yellowstone, 3 days',
		subtitle: 'Wyoming',
		imageTitle: 'grand-prismatic-spring-overlook-1.jpg',
	},
	{
		identifier: 'BanffYoho3Days',
		title: 'Banff, Yoho, 3 days',
		subtitle: 'Alberta, British Columbia',
		imageTitle: 'lake-louise-1.jpg',
	},
	{
		identifier: 'NovaScotia10Days',
		title: 'Nova Scotia, 10 days',
		subtitle: 'Nova Scotia',
		imageTitle: 'peggys-cove-1.jpg',
	},
];
