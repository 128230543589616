import React, { useCallback, memo } from 'react';
import { Typography, Col, Row, Tag, Space, Rate } from 'antd';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { getColor } from '../../../helpers/_Functions';
import TravelsContentSlider from './TravelsContentSlider';
import HashtagsList from './../../ui/HashtagsList';
import { ArrowRightOutlined, HeartFilled, HeartOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const TravelsCard = ({ travel: {title, identifier, difficulty, subtitle, imagesToShow, distance, rating, hashtags} = {}, travel, favorites, addToFavorite, isLoggedIn }) => {
	const { days } = useParams();
	const isFavorite = useCallback((identifier, difficulty) => (favorites.find(favorite => favorite.identifier === identifier && favorite.difficulty === difficulty) && true) || false, [favorites]);

	return (
		<Col xs={24}>
			<Row className='travels-catalog'>
				<Col className='travels-catalog__favorite'>
					{isLoggedIn &&
						(isFavorite(identifier, difficulty) ? (
							<HeartFilled
								style={{ color: '#9d1e1e' }}
								onClick={() => addToFavorite({ title, subtitle, identifier, difficulty })}
							/>
						) : (
							<HeartOutlined
								style={{ color: '#9d1e1e' }}
								onClick={() => addToFavorite({ title, subtitle, identifier, difficulty })}
							/>
						))}
				</Col>
				<Title level={3} className='travels-title'>
					<Link to={`/trip/${identifier}/${difficulty}/`}>{title ? title : false}</Link>
				</Title>
				<Space className='travels-catalog__space'>
					<Tag color={getColor(difficulty)}>{difficulty}</Tag>
					<Text className='travels-catalog__distance'>
						Distance: {Math.floor(distance / 1000)} km | {Math.floor(distance / 1609.344)} mi
					</Text>

					<Rate className='travels-catalog__rate' allowHalf disabled defaultValue={rating / 5} />
				</Space>

				<Col className='show-more'>
					<Link to={`/trip/${identifier}/${difficulty}/`} className='show-more__link'>
						Show more <ArrowRightOutlined />
					</Link>
				</Col>
			</Row>

			{imagesToShow && imagesToShow.length && <TravelsContentSlider travel={travel} />}

			<HashtagsList hashtags={hashtags} pathname={`/trips/${days}/`} />
		</Col>
	);
};

export default memo(TravelsCard);
