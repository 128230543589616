import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Button, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import * as TravelInfoActions from '../../../../state/actions/TravelInfoActions';
import * as SearchActions from '../../../../state/actions/SearchAction';
import SearchLocation from '../../../ui/Search/SearchLocation';
import { GetIcon } from '../../../../Helper';
import { checkControlExisting, distanceMi } from '../../../../helpers/_Functions';

const { Text } = Typography;

const TravelModifyAirport = props => {
	const {
		travel: { identifier, airports, route  } = {},
		sights = [],
		airport = [],
		routes = [],
		day = '',
		type = 'startDay',
		updateTravelControls,
		addToSights,
		Search,
		actions,
		SearchActions,
	} = props;
	
	const [airportInfo, setAirportInfo] = useState('');
	const [showAirport, setShowAirport] = useState(false);

	const index = 
			type === 'startDay' ?
				checkControlExisting(day, 'start', Search)
					? 1
					: 0
			: checkControlExisting(day, 'finish', Search)
				? routes[day - 1].sights.length - 1 
				: routes[day - 1].sights.length;
	const lastSight = sights.find(sight => sight.identifier === route[day - 1].sights[route[day - 1].sights.length - 1]);

	const addAirport = airport => {
		SearchActions.putSearchControl({data: { label: airport.label, text: airport.text, x: airport.x, y: airport.y }, searchType: 'airport', identifier, day});
	};

	const deleteAirport = () => {
		actions.deleteSight({type: 'airport', identifier: airportInfo.label, day});
		setAirportInfo('');
		setShowAirport(false);
	};

	useEffect(() => {
		if (airport.length) {
			let currAirport = airport.find(item =>   item.identifier === identifier && item.day === day);			
			if (currAirport) {
				if (!sights.find(el => el.title === currAirport.data.label && el.day === day)) {
					setAirportInfo(currAirport.data);
					setShowAirport(true);
					addToSights({obj: currAirport, type: 'airport', icon: 'fa-plane', index});
				}
			}
		}
	}, [airport]);

	useEffect(() => {
		if (airport.length && !airport.find(el => el.day === day)) {
			setAirportInfo('');
			setShowAirport(false);
		} else {
			let currAirport = airport.find(item => item.identifier === identifier && item.day === day);
			if (currAirport) {
				setAirportInfo(currAirport.data);
				setShowAirport(true);
			}
		}
	}, [day]);

	return (
		<Row className='modify-btns'>
			<Col lg={4} xs={4} className='modify-icon'>
				<GetIcon src='airport.png' />
				<Text className='modify-title'>Airport</Text>
			</Col>
			<Col lg={20} xs={20} className='modify-text'>
				{!showAirport &&
					airports.map((item, key) => (
						<Button className={'addOptionModify'} key={key} onClick={() => addAirport({ text: item.title, label: item.title, x: item.location[0], y: item.location[1] })}>
							{item.title}
						</Button>
					))}

				{!showAirport && (
					<div style={{ padding: '0' }}>
						<SearchLocation searchType='airport' searchCategory='airport'/>
					</div>
				)}

				{showAirport && airportInfo && (
					<Col lg={20} xs={20}>
						<h3 className={'modifyInfo'}>{airportInfo.text}</h3>
						<a href={`https://www.google.com/maps/place/${airportInfo.y},${airportInfo.x}`} className='modify-link' target='_blank' rel='nofollow noopener noreferrer'>
							<span className='material-icons modify-link__marker'>place</span>
							<Text className='modify-link__text'>
								{airportInfo.y}, {airportInfo.x}
							</Text>
						</a>
						<span className='modify-distance'>
							<b>
								~ {distanceMi([airportInfo.x, airportInfo.y], lastSight.location)} mi/ ~ {Math.round(distanceMi([airportInfo.x, airportInfo.y], lastSight.location) * 1.609)} km
							</b>{' '}
							to {lastSight.title}, the last sight of Day {day}
						</span>
					</Col>
				)}

				{showAirport && airportInfo && (
					<Col lg={4} xs={4} onClick={deleteAirport} className={'closeModify'}>
						<CloseOutlined />
					</Col>
				)}

				{!showAirport && (
					<Col lg={4} xs={4} onClick={() => updateTravelControls({ airport: day })} aria-hidden='true' className={'closeModify'}>
						<CloseOutlined />
					</Col>
				)}
			</Col>
		</Row>
	);
};

function mapStateToProps(state) {
	return {
		travel: state.TravelInfo.travel,
		airport: state.Search.airport,
		routes: state.TravelInfo.routes,
		sights: state.TravelInfo.sights,
		Search: state.Search,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		SearchActions: bindActionCreators(SearchActions, dispatch),
		actions: bindActionCreators(TravelInfoActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelModifyAirport);
