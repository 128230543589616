/**
 * Выполняет глубокое клонирование объекта
 * @param {Object} o - объект для клонирования
 * @returns {Object} - клон исходного объекта
 */
export function deepClone(o) {
    // "string", number, boolean
    if (typeof(o) != 'object') {
       return o;
    }
    
    // null
    if (!o) {
       return o; // null
    }
    
    var r = (o instanceof Array) ? [] : {};
    for (var i in o) {
       if (o.hasOwnProperty(i)) {
          r[i] = deepClone(o[i]);
       }
    }
    return r;
}