import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import { useParams, useLocation } from 'react-router';
import { Space } from 'antd';

import './TravelOverviewContainer.sass';
import Header from '../../components/ui/Header/Header';
import TravelOverviewContent from '../../components/pages/TravelOverviewPage/OverviewContent';
import Footer from '../../components/ui/Footer/Footer';
import { Loader } from '../../components/common/Loader/Loader';
import ErrorMessage from '../../components/common/Message/Message';
import PageHelmet from './../../components/ui/hoc/PageHelmet';
import { loadSightsNear, loadTravels, setTravel, loadSights } from '../../state/actions/TravelOverviewActions';

const TravelOverviewContainer = () => {
	const {
		travel,
		travels,
		loading: { travel: loading = true },
		errors,
	} = useSelector(state => state.TravelOverviewPage);

	const { identifier, difficulty } = useParams();
	const { pathname } = useLocation();

	const dispatch = useDispatch();

	useEffect(() => {
		console.log(travel, identifier, difficulty);
		if (!travel.hasOwnProperty('identifier') || !(travel.hasOwnProperty('identifier') && travel.identifier === identifier && travel.difficulty === difficulty)) {
			dispatch(loadTravels(identifier));
		}
	}, [identifier, difficulty]);

	useEffect(() => {
		if (travels.length) {
			dispatch(setTravel(difficulty));
		}
	}, [travels]);

	useEffect(() => {
		if (travel.hasOwnProperty('identifier')) {
			dispatch(loadSights(travel.route));
			dispatch(loadSightsNear(travel.route));
		}
	}, [travel]);

	const title = travel.title ? `${travel.title}. Itinerary.` : 'Itinerary.';
	const description = travel.title
		? `${travel.title} - Itinerary, Roadmap, Routes, Accommodation, Flights, Photos, Useful Information and Tips.`
		: 'Itinerary, Roadmap, Routes, Accommodation, Flights, Photos, Useful Information and Tips.';
	const canonical = difficulty ? pathname.replace(`/${difficulty}/`, '/') : pathname;

	const LoadingJSX = loading ? (
		<Space className='overview-loading' align='center'>
			<Loader />
		</Space>
	) : errors.travel ? (
		<ErrorMessage text={errors.travel} />
	) : (
		false
	);

	return (
		<div className='overview'>
			<PageHelmet title={title} description={description} canonicalUrl={canonical}>
				<Header />
				{LoadingJSX}
				{!loading ? <TravelOverviewContent /> : LoadingJSX}
				<Footer />
			</PageHelmet>
		</div>
	);
};

export default TravelOverviewContainer;
