import React, { useState, useEffect, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Typography, Button } from 'antd';
import L from 'leaflet';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import { Swiper, SwiperSlide } from 'swiper/react';
import { StickyContainer, Sticky } from 'react-sticky';
import { ImageFinder } from '../../../Helper';
import { distanceMi } from '../../../helpers/_Functions';
import Map from '../../ui/hoc/Map/Map';
const { Title, Text } = Typography;
const ButtonGroup = Button.Group;

const iconRedSmall = L.divIcon({
	className: 'custom-div-icon',
	html: '<div class="overview-marker--red-small"></div>',
});

const OverviewAirports = () => {
	const { travel: { title, airports, stay, identifier, difficulty, route } = {}, sights = [] } = useSelector(state => state.TravelOverviewPage);
	const [location, setLocation] = useState([]);
	const [airportTitle, setAirportTitle] = useState('');
	const [daySight, setDaySight] = useState('');
	const [activeTab, setActiveTab] = useState(1);
	const [activeTab1, setActiveTab1] = useState(0);
	const [day, setDay] = useState(0);

	const airport = airports[activeTab - 1];
	let sight = daySight && sights?.find(sight => sight.identifier == daySight);

	const daySights = useMemo(
		() =>
			route[day].sights
				.map(sight => sights.find(el => el.identifier === sight))
				.filter(Boolean)
				.concat(airports[activeTab - 1])
				.concat(
					stay.map(item => {
						if (item.length == 1) {
							return { title: item[0].title, location: item[0].location, hotel: true };
						} else if (day == 0) {
							return { title: item[0].title, location: item[0].location, hotel: true };
						} else {
							return { title: item[item.length - 1].title, location: item[item.length - 1].location, hotel: true };
						}
					})
				),
		[day, route, sights, airports, activeTab]
	);
	const bounds = useMemo(() => daySights.map(sight => [sight.location[1], sight.location[0]]), [daySights]);
	// console.log(day, activeTab, activeTab1, daySights2, daySight, airport2, sight);

	let hotels = [];
	stay.map(item => {
		if (item.length == 1) {
			hotels.push({ title: item[0].title, location: item[0].location });
		} else if (day == 0) {
			hotels.push({ title: item[0].title, location: item[0].location });
		} else {
			hotels.push({ title: item[item.length - 1].title, location: item[item.length - 1].location });
		}
	});

	let settingsSwiper = {
		freeMode: true,
		pagination: { clickable: true },
		className: 'btns overview-day_btns',
		breakpoints: {
			320: {
				slidesPerView: 4,
			},
			480: {
				slidesPerView: 4,
			},
			767: {
				slidesPerView: 25,
				spaceBetween: 2,
			},
		},
	};

	let ButtonDays =
		route.length == 1 ? (
			<ButtonGroup className='btns'>
				<Button
					size='small'
					type={activeTab1 == 0 ? 'primary' : 'default'}
					className={`btn btn${activeTab1 == 0 ? '--primary' : '--default'}`}
					onClick={() => {
						setLocation(airport.location);
						setActiveTab1(0);
						setAirportTitle(airport.title);
						setDaySight(route[0].sights[0]);
					}}>
					<span>Sight 1</span>
				</Button>
				<Button
					size='small'
					type={activeTab1 == 1 ? 'primary' : 'default'}
					className={`btn btn${activeTab1 == 1 ? '--primary' : '--default'}`}
					onClick={() => {
						setLocation(airport.location);
						setActiveTab1(1);
						setAirportTitle(airport.title);
						setDaySight(route[0].sights[route[0].sights.length - 1]);
					}}>
					<span>Last sight </span>
				</Button>
			</ButtonGroup>
		) : (
			<ButtonGroup className='btns'>
				<Button
					size='small'
					type={activeTab1 == 0 ? 'primary' : 'default'}
					className={`btn btn${activeTab1 == 0 ? '--primary' : '--default'}`}
					onClick={() => {
						setLocation(airport.location);
						setActiveTab1(0);
						setDay(0);
						setAirportTitle(airport.title);
						setDaySight(route[0].sights[0]);
					}}>
					<span>Day 1</span>
				</Button>
				<Button
					size='small'
					type={activeTab1 == 1 ? 'primary' : 'default'}
					className={`btn btn${activeTab1 == 1 ? '--primary' : '--default'}`}
					onClick={() => {
						setDay(route.length - 1);
						setLocation(airport.location);
						setActiveTab1(1);
						setAirportTitle(airport.title);
						setDaySight(route[route.length - 1].sights[route[route.length - 1].sights.length - 1]);
					}}>
					<span>Last day </span>
				</Button>
			</ButtonGroup>
		);

	let ButtonOptions = (
		<Swiper {...settingsSwiper}>
			{airports.map((item, key) => {
				key++;
				return (
					<SwiperSlide key={key}>
						<Button
							size='small'
							key={key}
							className={`btn btn${key == activeTab ? '--primary' : '--default'}`}
							type={key == activeTab ? 'primary' : 'default'}
							onClick={() => {
								setLocation(item.location);
								setActiveTab(key);
								setAirportTitle(item.title);
							}}>
							<span>Option {key}</span>
						</Button>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);

	useEffect(() => {
		setLocation(airports[0].location);
		setAirportTitle(airports[0].title);
		setDaySight(route[0].sights[0]);
		setActiveTab(1);
		setActiveTab1(0);
		setDay(0);
	}, [airports]);

	return (
		<>
			<div className='overview-flights' id='overview-flights'>
				<StickyContainer>
					<Row className='overview-flights__header'>
						<Sticky>
							{({ style }) => (
								<div style={style} className='overview-flights__header-sticky'>
									<Title level={2} className='overview-title'>
										Closest airports
									</Title>
								</div>
							)}
						</Sticky>
						<Col span={20}>
							<Text className='overview-stay__subtitle'>For this trip, people usually come to the airports:</Text>
						</Col>
					</Row>
					<Row type='flex' justify='space-around' className='overview-flights__content'>
						<Col lg={16} md={16} sm={24} xs={24}>
							<Map className='overview-flights__map' bounds={bounds}>
								{sight &&
									daySights.map((item, key) => {
										if (airport.title === item.title) {
											return (
												<Marker
													position={[item.location[1], item.location[0]]}
													key={key + item.title}
													riseOnHover='true'
													icon={L.ExtraMarkers.icon({
														innerHTML: '<div class="overview-marker--blue"><i class="material-icons">airplanemode_active</i></div>',
														markerColor: 'blue',
													})}>
													<Tooltip offset={[0, -35]} direction='top' opacity={1} permanent>
														<div>{item.title}</div>
														<div>
															~ {distanceMi(location, sight.location)} mi/ ~ {Math.round(distanceMi(location, sight.location) * 1.609)} km
														</div>
													</Tooltip>
													<Popup>
														<div>{item.title}</div>
														<div>
															~ {distanceMi(location, sight.location)} mi/ ~ {Math.round(distanceMi(location, sight.location) * 1.609)} km
														</div>
													</Popup>
												</Marker>
											);
										} else if (sight.title == item.title) {
											return (
												<Marker
													position={[item.location[1], item.location[0]]}
													key={key + item.title}
													riseOnHover='true'
													icon={L.ExtraMarkers.icon({
														innerHTML: '<div class="overview-marker--red"><i class="material-icons">follow_the_signs</i></div>',
														markerColor: 'red',
													})}>
													<Tooltip>
														<div>{item.title}</div>
														<div>{item.subtitle}</div>
														<div>
															~ {distanceMi(location, sight.location)} mi/ ~ {Math.round(distanceMi(location, sight.location) * 1.609)} km
														</div>
													</Tooltip>
													<Popup>
														<div>{item.title}</div>
														<div>{item.subtitle}</div>
														<div>
															~ {distanceMi(location, sight.location)} mi/ ~ {Math.round(distanceMi(location, sight.location) * 1.609)} km
														</div>
													</Popup>
												</Marker>
											);
										} else if (item.hotel) {
											return (
												<Marker
													position={[item.location[1], item.location[0]]}
													key={key + item.title}
													riseOnHover='true'
													icon={L.ExtraMarkers.icon({
														innerHTML: '<div class="overview-marker--blue"><i class="material-icons">weekend</i></div>',
														markerColor: 'blue',
													})}>
													<Tooltip>
														<div>{item.title}</div>
													</Tooltip>
													<Popup>
														<div>{item.title}</div>
													</Popup>
												</Marker>
											);
										} else {
											return (
												<Marker position={[item.location[1], item.location[0]]} key={key + item.title} icon={iconRedSmall} riseOnHover='true'>
													<Tooltip>
														<div>{item.title}</div>
													</Tooltip>
													<Popup>
														<div>{item.title}</div>
														<div>{item.images ? <ImageFinder src={item.images[0].name} className='overview-stay__img' /> : false}</div>
													</Popup>
												</Marker>
											);
										}
									})}
							</Map>
						</Col>
						<Col lg={8} md={8} sm={24} xs={24} className='overview-sidebar'>
							<div>{ButtonDays}</div>
							<div>{ButtonOptions}</div>
							<div className='overview-info'>
								{location && sight && (
									<p>
										<span className='overview-info__text'>{airportTitle}</span> is located:
										{hotels.map((item, index) => {
											return (
												<span key={index} className='overview-info__text-hotel'>
													<span className='overview-info__text'>
														~ {distanceMi(location, item.location)} mi/ ~ {Math.round(distanceMi(location, item.location) * 1.609)} km
													</span>
													to <span className='overview-airport-text'>{item.title}</span>, option #{++index} for accommodation during the trip {`"${title}"`}.
												</span>
											);
										})}
										<span>
											<span className='overview-info__text'>
												~ {distanceMi(location, sight.location)} mi/ ~ {Math.round(distanceMi(location, sight.location) * 1.609)} km
											</span>
											to {sight.title}, {activeTab1 == 0 ? 'the first sight of Day 1' : 'the last sight of the last day'} of the trip {`"${title}"`}
										</span>
									</p>
								)}
								<a href={`/trip/${identifier}/${difficulty}/day/1`} className='overview-info__link'>
									Add an airport to the trip itinerary
								</a>
							</div>
						</Col>
					</Row>
				</StickyContainer>
			</div>
		</>
	);
};

export default memo(OverviewAirports);
