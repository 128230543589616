import localStorageService from './../../services/localStorage.service';
import authService from './../../services/auth.service';
import { generateAuthError } from './../../helpers/generateAuthError';
import userService from './../../services/user.service';
import { signInWithFacebook, signInWithGoogle } from './../../libs/firebase';
import history from './../../helpers/history';
import { authRequestSuccess, userReceived, userRequested, authRequested, authRequestFailed, userRequestFailed, passwordResetFailed, passwordReset, userLoggedOut } from '../reducers/UserReducer';

export const login =
	({ email, password }) =>
		async dispatch => {
			dispatch(authRequested());
			try {
				const data = await authService.login({ email, password });
				dispatch(authRequestSuccess({ userId: data.localId }));
				localStorageService.setTokens(data);
			} catch (error) {
				const { code, message } = error.response.data.error;
				if (code === 400) {
					const errorMessage = generateAuthError(message);
					dispatch(authRequestFailed(errorMessage));
				} else {
					dispatch(authRequestFailed(error.message));
				}
			}
		};

export const loginGoogle = () => async dispatch => {
	dispatch(authRequested());
	try {
		const { user, _tokenResponse } = await signInWithGoogle();
		dispatch(authRequestSuccess({ userId: user.uid }));
		localStorageService.setTokens(_tokenResponse);
		const data = await userService.getCurrentUser();
		if (data === null) {
			const newUser = {
				email: user.email,
				name: user.displayName,
				_id: user.uid,
				createdAt: user.metadata.createdAt,
				google: true,
			};
			await dispatch(createUser(newUser));
		}
	} catch ({ message }) {
		dispatch(authRequestFailed(message));
	}
};

export const loginFacebook = () => async dispatch => {
	try {
		const data = await signInWithFacebook();
		console.log(data);
	} catch (error) {
		console.log(error);
	}
};

export const signUp =
	({ email, password, ...rest }) =>
		async dispatch => {
			dispatch(authRequested());
			try {
				const data = await authService.register({ email, password });
				localStorageService.setTokens(data);

				const newUser = {
					_id: data.localId,
					email,
					name: 'new user',
					password,
					...rest,
				};
				dispatch(createUser(newUser));
				dispatch(authRequestSuccess({ userId: data.localId }));
			} catch (error) {
				const { code, message } = error.response.data.error;
				if (code === 400) {
					console.log(message);
					const errorMessage = generateAuthError(message);
					dispatch(authRequestFailed(errorMessage));
				} else {
					dispatch(authRequestFailed(error.message));
				}
			}
		};

export const logOut = () => dispatch => {
	localStorageService.removeAuthData();
	dispatch(userLoggedOut());
	history.push('/');
};

function createUser (payload) {
	return async dispatch => {
		dispatch(userRequested());
		try {
			const data = await userService.create(payload);
			dispatch(userReceived(data));
		} catch (error) {
			const { message } = error.response.data.error;
			dispatch(userRequestFailed(message));
		}
	};
}

export const resetPassword = email => async dispatch => {
	dispatch(passwordResetRequested());
	try {
		const { data, status } = await authService.reset({ email });
		console.log(data);
		if (status === 200) {
			dispatch(passwordReset());
		}
	} catch (error) {
		const { code, message } = error.response.data.error;
		if (code === 400) {
			const errorMessage = generateAuthError(message);
			dispatch(passwordResetFailed(errorMessage));
		} else {
			dispatch(passwordResetFailed(error.message));
		}
	}
};
