import httpService from './http.service';

const sightEndpoint = 'sight/';

const sightService = {
	get: async ({ filter, sort, limit, skip }) => {
		const { data } = await httpService.get(sightEndpoint, {
			params: {
				$filter: filter,
				$sort: sort,
				$limit: limit,
				$skip: skip,
			},
		});
		return data;
	},
	getSightsNear: async ({ lat, long, distance, limit, identifier }) => {
		const { data } = await httpService.get(sightEndpoint, {
			params: {
				$filter: {
					location: {
						$near: {
							$geometry: {
								type: 'Point',
								coordinates: [long, lat],
							},
							$maxDistance: distance,
						},
					},
					identifier
				},
				$limit: limit,
			},
		});
		return data;
	},
	getSightsForTravel: async ({sights, limit}) => {
		const {data} = await httpService.get(sightEndpoint, {
			params:{
				$filter: {
					identifier: {
						$in: sights
					}
				},
				$limit: limit
			}
			
		});

		return data;
	}
};
export default sightService;
