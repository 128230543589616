import React from 'react';
import { Breadcrumb } from 'antd';
import { HomeFilled } from '@ant-design/icons';

const BreadcrumbComponent = props => {
	let { className = 'breadcrumb', home = <HomeFilled />, sight = {}, travel = {}, day = -1, text = null } = props;

	const cutTitle = title => {
		if (title) {
			return (
				<span className={'breadcrumb-text'}>
					<span className={'slice'}>{title.slice(0, 50) + (title.length > 50 ? '...' : ' ')}</span>
					<span className={'whole'}>{title}</span>
				</span>
			);
		}
	};

	return (
		<>
			<Breadcrumb separator='>' className={className}>
				<Breadcrumb.Item href='/' className='breadcrumb-sight-item'>
					{home}
				</Breadcrumb.Item>
				{text && <Breadcrumb.Item className='breadcrumb-sight-item'>{text}</Breadcrumb.Item>}
				{travel.hasOwnProperty('identifier') ? (
					<>
						<Breadcrumb.Item href={`/trip/${travel.identifier}/moderate`} className='breadcrumb-sight-item'>
							{cutTitle(travel.title)}
						</Breadcrumb.Item>
						{day !== -1 && (
							<Breadcrumb.Item href={`/trip/${travel.identifier}/moderate/day/${day}`} className='breadcrumb-sight-item'>
								{cutTitle('Day ' + day)}
							</Breadcrumb.Item>
						)}
						{sight.hasOwnProperty('identifier') && (
							<Breadcrumb.Item href={`/trip/${travel.identifier}/sight/${sight.identifier}`} className='breadcrumb-sight-item'>
								{cutTitle(sight.title)}
							</Breadcrumb.Item>
						)}
					</>
				) : (
					sight.hasOwnProperty('identifier') && (
						<Breadcrumb.Item href={`/sight/${sight.identifier}`} className='breadcrumb-sight-item'>
							{cutTitle(sight.title)}
						</Breadcrumb.Item>
					)
				)}
			</Breadcrumb>
		</>
	);
};

export default BreadcrumbComponent;
