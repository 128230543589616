import { createSlice } from '@reduxjs/toolkit';
// import {
// GET_TRAVEL_OVERVIEW_INFO,
// 	GET_TRAVELS_OVERVIEW,
// 	GET_SIGHTS_FOR_TRAVEL_OVERVIEW,
//     GET_SIGHTS_NEAR_TRAVEL_OVERVIEW,
//     GET_TRAVELS_INCLUDES_SIGHTS_NEAR_OVERVIEW
// } from '../../constants/ActionTypes';

// INITIALIZE STATE
const initialState = {
	travel: {},
	travels: [],
	sights: [],
	routes: [],
	sightsNear: [],
	travelsWithSightsNear: [],
	loading: {},
	errors: {},
};

// REDUCER
// export const TravelOverviewReducer = (state = initialState, action) => {
// 	switch (action.type) {
// 		case GET_TRAVEL_OVERVIEW_INFO:
// 			return {
// 				...state,
// 				travel: action.payload,
// 			};
// 		case GET_TRAVELS_OVERVIEW:
// 			return {
// 				...state,
// 				travels: action.payload,
// 			};
// 		case GET_SIGHTS_FOR_TRAVEL_OVERVIEW: {
// 			const { routes, sights } = action.payload;
// 			return {
// 				...state,
// 				routes,
// 				sights,
// 			};
// 		}
//         case GET_SIGHTS_NEAR_TRAVEL_OVERVIEW:
//             return {
//                 ...state,
// 				sightsNear: action.payload,
//             };
//         case GET_TRAVELS_INCLUDES_SIGHTS_NEAR_OVERVIEW:
//             return {
//                 ...state,
//                 travelsWithSightsNear: action.payload
//             };
// 		default:
// 			return state;
// 	}
// };

const TravelOverviewSlice = createSlice({
	name: 'TravelOverviewPage',
	initialState,
	reducers: {
		travelRequested: state => {
			state.loading.travel = true;
		},
		travelReceived: (state, action) => {
			state.travel = action.payload;
			state.loading.travel = false;
			state.errors.travel = null;
		},
		travelRequestFailed: (state, action) => {
			state.loading.travel = false;
			state.errors.travel = action.payload;
		},
		travelsRequested: state => {
			state.loading.travels = true;
			state = initialState;
		},
		travelsReceived: (state, action) => {
			state.travels = action.payload;
			state.loading.travels = false;
			state.errors.travels = null;
		},
		travelsRequestFailed: (state, action) => {
			state.loading.travels = false;
			state.errors.travels = action.payload;
		},
		sightsRequested: state => {
			state.loading.sights = true;
		},
		sightsReceived: (state, action) => {
			state.sights = action.payload.sights;
			state.routes = action.payload.routes;
			state.loading.sights = false;
			state.errors.sights = null;
		},
		sightsRequestFailed: (state, action) => {
			state.loading.sights = false;
			state.errors.sights = action.payload;
		},
		sightsNearRequested: state => {
			state.loading.sightsNear = true;
		},
		sightsNearReceived: (state, action) => {
			state.sightsNear = action.payload;
			state.loading.sightsNear = false;
			state.errors.sightsNear = null;
		},
		sightsNearRequestFailed: (state, action) => {
			state.loading.sightsNear = false;
			state.errors.sightsNear = action.payload;
		},
		travelsWithSightsNearRequested: state => {
			state.loading.travelsWithSightsNear = true;
		},
		travelsWithSightsNearReceived: (state, action) => {
			state.travelsWithSightsNear = action.payload;
			state.loading.travelsWithSightsNear = false;
			state.errors.travelsWithSightsNear = null;
		},
		travelsWithSightsNearRequestFailed: (state, action) => {
			state.loading.travelsWithSightsNear = false;
			state.errors.travelsWithSightsNear = action.payload;
		},
	},
});

const { actions, reducer: TravelOverviewReducer } = TravelOverviewSlice;

export const {
	travelRequested,
	travelReceived,
	travelRequestFailed,
	travelsRequested,
	travelsReceived,
	travelsRequestFailed,
	sightsRequested,
	sightsReceived,
	sightsRequestFailed,
	sightsNearRequested,
	sightsNearReceived,
	sightsNearRequestFailed,
	travelsWithSightsNearRequested,
	travelsWithSightsNearReceived,
	travelsWithSightsNearRequestFailed,
} = actions;

export { TravelOverviewReducer };
