import React from 'react';
import { Drawer, Col, Row, Divider, Button, Popover, Tooltip, Dropdown, Checkbox } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { UserOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from './../../../../state/actions/AuthActions';
import { ImageFinder } from './../../../../Helper';
import TravelShare from './../TravelShare/TravelShare';
import Settings from '../HeaderSettings/Settings';
import Share from './../HeaderSettings/Share';

const MenuDrawer = ({ showDrawer, openDrawer, isLoggedIn, auth, pathname }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const travel = useSelector(state => state.CustomizeTripPage.travel);
	const handleLogOut = () => dispatch(logOut());

	const menu = [
		{
			link: '/',
			title: 'Home',
		},
		{
			link: '/trips/All/',
			title: 'Trips',
		},
		{
			link: '/map/itineraries/',
			title: 'Map',
		},
	];

	const content = isLoggedIn ? (
		<div className='login-content'>
			<Link to={`/user/${auth.userId}`}>Profile</Link>
			<Link to={`/user/${auth.userId}/trips`}>Saved Trips</Link>
			<Link to={`/user/${auth.userId}/favorites`}>Wish List</Link>
			<Link to={`/user/${auth.userId}/hotels`}>Hotels</Link>
			<Divider />
			<Link to={`/user/${auth.userId}/settings`}>Settings</Link>
			<Link to='/login' onClick={handleLogOut}>
				SIGN OUT
			</Link>
		</div>
	) : (
		<div className='login-content'>
			<Link to='/login'>SIGN UP</Link>
			<Link to='/registration'>CREATE AN ACCOUNT</Link>
		</div>
	);

	const titleJSX = (
		<Col className='moderate-header__logo'>
			<Link to='/'>
				<ImageFinder src='logo.svg' />
			</Link>
		</Col>
	);

	return (
		<Drawer className='moderate-header__drawer' title={titleJSX} closeIcon={null} placement='right' onClose={showDrawer} open={openDrawer} width={178}>
			<Col className='header-content__account-burgermenu'>
				<Popover content={content} placement='topRight'>
					<Button shape='circle' icon={<UserOutlined />} onClick={() => (isLoggedIn ? navigate(`/user/${auth?.userId}/`) : navigate('/login'))} />
				</Popover>
			</Col>
			<Divider />
			<Col className='moderate-header__menu'>
				{menu.map(({ link, title }) => (
					<Link to={link} key={title}>
						{title}
					</Link>
				))}
			</Col>
			<Divider />
			<Row>
				<Share />
				<Settings />
			</Row>
		</Drawer>
	);
};

export default MenuDrawer;
