// Zero page
export const GET_ZEROPAGE_TRAVELS = 'GET_ZEROPAGE_TRAVELS';
export const GET_ZEROPAGE_CITIES_TRAVELS = 'GET_ZEROPAGE_CITIES_TRAVELS';
export const GET_ZEROPAGE_RECOMMENDED_TRAVELS = 'GET_ZEROPAGE_RECOMMENDED_TRAVELS';
export const GET_POPULAR_DAY_TRIPS = 'GET_POPULAR_DAY_TRIPS';

//Sight
export const GET_SIGHT_INFO = 'GET_SIGHT_INFO';
export const GET_TRAVELS_FOR_SIGHT = 'GET_TRAVELS_FOR_SIGHT';
export const GET_SIGHTS_NEAR = 'GET_SIGHTS_NEAR';
export const GET_SIGHT_TRAVEL = 'GET_SIGHT_TRAVEL';

//Reviews
export const GET_REVIEWS = 'GET_REVIEWS';
export const SET_REVIEW = 'SET_REVIEW';

//TravelOverview Page
export const GET_TRAVEL_OVERVIEW_INFO = 'GET_TRAVEL_OVERVIEW_INFO';
export const GET_TRAVELS_OVERVIEW = 'GET_TRAVELS_OVERVIEW';
export const GET_SIGHTS_FOR_TRAVEL_OVERVIEW = 'GET_SIGHTS_FOR_TRAVEL_OVERVIEW';
export const GET_SIGHTS_NEAR_TRAVEL_OVERVIEW = 'GET_SIGHTS_NEAR_TRAVEL_OVERVIEW';
export const GET_TRAVELS_INCLUDES_SIGHTS_NEAR_OVERVIEW = 'GET_TRAVELS_INCLUDES_SIGHTS_NEAR_OVERVIEW';

//Travels Page
export const GET_TRAVELS = 'GET_TRAVELS';
export const PUT_TRAVELS_SKIP = 'PUT_TRAVELS_SKIP';

//Map Page
export const PUT_MAP_TRAVELS_SKIP = 'PUT_MAP_TRAVELS_SKIP';
export const GET_MAP_TRAVELS = 'GET_MAP_TRAVELS';
export const GET_MAP_SIGHTS = 'GET_MAP_SIGHTS';
export const GET_MAP_SIGHT = 'GET_MAP_SIGHT';
export const GET_MAP_HASHTAGS = 'GET_MAP_HASHTAGS';

//TravelInfo Page
export const GET_TRAVELS_FOR_TRAVELINFO = 'GET_TRAVELS_FOR_TRAVELINFO';
export const GET_TRAVEL_FOR_TRAVELINFO = 'GET_TRAVEL_FOR_TRAVELINFO';
export const GET_SIGHTS_FOR_TRAVELINFO = 'GET_SIGHTS_FOR_TRAVELINFO';
export const GET_MORE_SIGHTS_NEAR_TRAVELINFO = 'GET_MORE_SIGHTS_NEAR_TRAVELINFO';
export const DELETE_MORE_SIGHTS_NEAR_TRAVELINFO = 'DELETE_MORE_SIGHTS_NEAR_TRAVELINFO';
export const ADD_SIGHT_TRAVELINFO = 'ADD_SIGHT_TRAVELINFO';
export const GET_ROUTE_TRAVELINFO = 'GET_ROUTE_TRAVELINFO';
export const DELETE_SIGHT_TRAVELINFO = 'DELETE_SIGHT_TRAVELINFO';

//search
export const PUT_SEARCH_CONTROL = 'PUT_SEARCH_CONTROL';
export const PUT_SEARCH_LOCATION = 'PUT_SEARCH_LOCATION';
export const PUT_SEARCH_DURATION = 'PUT_SEARCH_DURATION';
export const CLEAR_SEARCH_LOCATION = 'CLEAR_SEARCH_LOCATION';
export const SET_SEARCH_INTENSITY = 'SET_SEARCH_INTENSITY';
export const DELETE_SEARCH_LOCATION = 'DELETE_SEARCH_LOCATION';
export const SET_SEARCH = 'SET_SEARCH';

// TravelContainer
export const GET_TRAVELCONTAINER_DATA = 'GET_TRAVELCONTAINER_DATA';
export const GET_TRAVELCONTAINER_DATA_FULFILLED = 'GET_TRAVELCONTAINER_DATA_FULFILLED';
export const GET_TRAVELINFO = 'GET_TRAVELINFO';
export const GET_TRAVELINFO_FULFILLED = 'GET_TRAVELINFO_FULFILLED';
export const GET_ROUTE_INFO = 'GET_ROUTE_INFO';
export const CHANGE_ROUTE = 'CHANGE_ROUTE';
export const CHANGE_ROUTE_FULFILLED = 'CHANGE_ROUTE_FULFILLED';


// LOCATION
export const INIT_LOCATION = 'INIT_LOCATION';


//TravelsAndSights
export const GET_SIGHTS_FOR_CORDS = 'GET_SIGHTS_FOR_CORDS';
export const GET_SIGHTS_FOR_CORDS_FULFILLED = 'GET_SIGHTS_FOR_CORDS_FULFILLED';
export const GET_TRAVELS_FOR_SIGHTS_FULFILLED = 'GET_TRAVELS_FOR_SIGHTS_FULFILLED';
export const GET_TRAVELS_FOR_SIGHTS = 'GET_TRAVELS_FOR_SIGHTS';
export const PUT_SIGHTS_AND_TRAVELS = 'PUT_SIGHTS_AND_TRAVELS';
export const PUT_SIGHTS_AND_TRAVELS_FULFILLED = 'PUT_SIGHTS_AND_TRAVELS_FULFILLED';
export const PUT_SIGHTS_AND_TRAVELS_PENDING = 'PUT_SIGHTS_AND_TRAVELS_PENDING';
export const PUT_SIGHTS_AND_TRAVELS_SKIP = 'PUT_SIGHTS_AND_TRAVELS_SKIP';
export const DELETE_SIGHTS_AND_TRAVELS = 'DELETE_SIGHTS_AND_TRAVELS';
export const PUT_TRAVELS = 'PUT_TRAVELS';
export const ADD_SIGHTS = 'ADD_SIGHTS';
export const DELETE_SIGHT = 'DELETE_SIGHT';
export const MOVE_SIGHT = 'MOVE_SIGHT';
export const DELETE_NEW_SIGHTS = 'DELETE_NEW_SIGHTS';

//map page
export const GET_TRAVELS_FOR_MAP = 'GET_TRAVELS_FOR_MAP';
export const GET_SIGHTS_FOR_MAP = 'GET_SIGHTS_FOR_MAP';
export const DELETE_TRAVELS_FOR_MAP = 'DELETE_TRAVELS_FOR_MAP';
export const GET_SIGHT_FOR_MAP = 'GET_SIGHT_FOR_MAP';

//helper
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const GET_HASHTAGS = 'GET_HASHTAGS';
export const RESET_HELPER = 'RESET_HELPER';

//User page
export const AUTH_REQUEST_SUCCESS = 'AUTH_REQUEST_SUCCESS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const USER_LOG_OUT = 'USER_LOG_OUT';
export const GET_USER_FAVORITES = 'GET_USER_FAVORITES';
export const ADD_USER_TRIP = 'ADD_USER_TRIP';
export const DELETE_USER_TRIP = 'DELETE_USER_TRIP';
export const UPDATE_USER_TRIP = 'UPDATE_USER_TRIP';
export const GET_USER_TRIPS= 'GET_USER_TRIPS';
export const ADD_USER_COUNTRY = 'ADD_USER_COUNTRY';
export const ADD_USER_LOCATION = 'ADD_USER_LOCATION';