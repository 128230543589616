import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './TravelContainer.sass';
import { useParams } from 'react-router';
import { Layout } from 'antd';
// import * as SearchActions from '../../state/actions/SearchAction';
// import * as TravelInfoActions from '../../state/actions/TravelInfoActions';
// import TravelContent from '../../components/pages/TravelPage/TravelContent';
import Header from '../../components/ui/Header/Header';
import { Loader } from '../../components/common/Loader/Loader';
import PageHelmet from '../../components/ui/hoc/PageHelmet';
// import ErrorMessage from './../../components/common/Message/Message';
import TravelInfoContent from '../../components/pages/TravelInfoPage/TravelInfoContent';
import { loadTravel } from '../../state/actions/TravelActions';
import { getTravel, getLoadingStatus, } from '../../state/reducers/TravelReducer';


// const TravelContainer = (props) => {
//     const {
// 		travel = {},
//         travels = [],
//         message = false,
// 		isLoggedIn,
// 		loading: {
// 			travels_TravelInfoPage: travelLoading = true,
// 			sights_TravelInfoPage: sightsLoading = true
// 		} = {},
//         actions,
// 		trips = [],
// 		SearchActions,
//     } = props;

//     const {identifier, intensity} = useParams();

//     useEffect(() => {
// 		actions.getTravels(identifier, intensity);
		
//     }, [identifier]);

// 	useEffect(() => {
// 		if(travels.length){
// 			actions.setTravel(intensity, identifier);
// 			!trips.find(data => data.travel.identifier === identifier) && !isLoggedIn && SearchActions.clearSearch();
// 		}
// 	}, [intensity, travels]);

// 	let title = travel.title ? `${travel.title}. Detailed Travel Plan. Day-By-Day Planning.` : 'Detailed Travel Plan. Day-By-Day Planning.';
// 	let description = travel.title
// 		? `${travel.title}. Itinerary, Routes, Sights, Roadmaps, Trails, Useful Information, Recommendations, Tips, Photos`
// 		: 'Itinerary, Routes, Sights, Roadmaps, Trails, Useful Information, Recommendations, Tips, Photos';
// 	let imgTravel = travel.imageTitle ? `https://www.before.voyage/external/img/${travel.imageTitle}` : 'https://www.before.voyage/img/default.svg';
	
// 	return (
// 		<div className='days'>
// 			<PageHelmet title={title} description={description} image={imgTravel}>
// 				<Header />
// 				{
// 					!travelLoading && !sightsLoading ? message ? <ErrorMessage text='Sorry, no trips found.' /> :  <TravelContent /> : <Loader />  
// 				}
// 			</PageHelmet>
// 		</div>
// 	);
// };

// function mapStateToProps(state) {
// 	return {
// 		TravelInfo: state.TravelInfo,
// 		travel: state.TravelInfo.travel,
// 		travels: state.TravelInfo.travels,
//         message: state.Helper.message.travel_TravelInfoPage,
// 		loading: state.Helper.loading,
// 		trips: state.UserPage.trips,
// 	};
// }

// function mapDispatchToProps(dispatch) {
// 	return {
// 		actions: bindActionCreators(TravelInfoActions, dispatch),
// 		SearchActions: bindActionCreators(SearchActions, dispatch),
// 	};
// }

// export default connect(mapStateToProps, mapDispatchToProps)(TravelContainer);


//1. error message
//2. search

const TravelInfoContainer = () => {
	const dispatch = useDispatch();
	const {identifier, difficulty} = useParams();

	const travel = useSelector(getTravel());
	const loading = useSelector(getLoadingStatus());

	useEffect(() => {
		dispatch(loadTravel(identifier, difficulty));
	}, [identifier, difficulty, dispatch]);

	let title = travel.title ? `${travel.title}. Detailed Travel Plan. Day-By-Day Planning.` : 'Detailed Travel Plan. Day-By-Day Planning.';
	let description = travel.title
		? `${travel.title}. Itinerary, Routes, Sights, Roadmaps, Trails, Useful Information, Recommendations, Tips, Photos`
		: 'Itinerary, Routes, Sights, Roadmaps, Trails, Useful Information, Recommendations, Tips, Photos';
	let imgTravel = travel.imageTitle ? `https://www.before.voyage/external/img/${travel.imageTitle}` : 'https://www.before.voyage/img/default.svg';
	
	return (
		<Layout className='travel-page'>
			<PageHelmet title={title} description={description} image={imgTravel}>
				<Header />
				{ !loading ? 
					<TravelInfoContent /> 
					: <Loader />
				}
			</PageHelmet>
		</Layout>
	);
};

export default TravelInfoContainer;