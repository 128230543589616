import React, { useState, memo, useMemo } from 'react';
import L from 'leaflet';
import { scroller } from 'react-scroll';
import { MapContainer, TileLayer, Marker, ZoomControl, useMap, ScaleControl } from 'react-leaflet';
// import { MapContainer  } from 'react-leaflet/MapContainer';
import { getDistance, getZoom } from '../../../helpers/_Functions';
import TravelAddSights from '../../pages/CustomizeTripPage/TravelAddSights';
import { MapScale } from '../../common/MapScale';
import Map from '../../ui/hoc/Map/Map';
import Routing from './../../ui/TravelMap/RoutineMachine';

const TravelMap = props => {
	const {
		className = '',
		visibility,
		zoom = true,
		zoomPosition = 'bottomright',
		scalePosition = 'bottomleft',
		scale = true,
		allRoutesSights,
		sights = [],
		sightsNear = [],
		routeSights = [],
		day = '',
		identifier = '',
		handleChangeTripRoute,
	} = props;

	const [visibleDrawer, setVisibleDrawer] = useState(false);
	const [currSight, setCurrSight] = useState({});
	const [add, setAdd] = useState(false);

	const mapCenter = [0, 0];
	let maxDistance = 0;

	routeSights.forEach(sightIdentifier => {
		const sight = getSight(sightIdentifier);
		if (sight) {
			mapCenter[0] += sight.location[1];
			mapCenter[1] += sight.location[0];
		}
	});

	if (mapCenter[0] !== 0) {
		mapCenter[0] /= routeSights.length;
		mapCenter[1] /= routeSights.length;
	} else {
		/** Default location and send logs */
		//must be error checker for map
		// mapCenter = [40.712, -74.006];
		let dataTime = Date(Date.now());
		// try {
		let body = {
			site: 'beforevoyage',
			// page: pathname,
			log: `Data: ${dataTime}. Error on TravelMap info: error on mapCenter. Not found Latitude and Longitude for sights. Or not found sights.`,
		};
		// 	if (process.env.NODE_ENV === 'production') {
		// 		axios.put('https://yxixvsdaqc.execute-api.us-east-1.amazonaws.com/test/', body);
		// 	} else {
		// console.log('Error', body.log);
		// 	}
		// } catch (e) {
		// 	console.log('NOT SAVE ERROR');
		// }
	}

	routeSights.forEach(sightIdentifier => {
		const sight = getSight(sightIdentifier);
		if (sight) {
			let d = getDistance(mapCenter[0], mapCenter[1], sight.location[1], sight.location[0]);
			if (d > maxDistance) {
				maxDistance = d;
			}
		}
	});

	function getSight(sightIdentifier) {
		return sights.find(sight => sight.identifier === sightIdentifier);
	}

	const handleClick = (sight, newAdd) => {
		setVisibleDrawer(true);
		setCurrSight(sight);
		setAdd(newAdd);
	};

	const onClose = () => {
		setVisibleDrawer(false);
	};

	const changeAdd = value => setAdd(value);

	const currSightsNear = sightsNear
		?.find(item => item.day === day && item.identifier === identifier)
		?.data?.filter(sight => !routeSights.find(sightIdentifier => sightIdentifier === sight.identifier));

	// let newZoom = getZoom(maxDistance);
	// let zoom = 11;
	// if (zoom !== newZoom) zoom = newZoom;
	// console.log(routeSights, day);

	const waypoint = useMemo(
		() =>
			routeSights.map((sightIdentifier, index) => {
				const sight = getSight(sightIdentifier);
				if (sight) {
					return L.latLng(sight.location[1], sight.location[0]);
				}
			}),
		[routeSights],
	);

	const allRoutes = useMemo(
		() =>
			allRoutesSights.map((sightIdentifier, index) => {
				const sight = sights.find(el => el.identifier === sightIdentifier);
				if (sight) {
					return L.latLng(sight.location[1], sight.location[0]);
				}
			}),
		[allRoutesSights],
	);

	// console.log(allRoutes, waypoint, mapCenter);
	const mapSights = useMemo(() => routeSights.map(sight => sights.find(s => s.identifier === sight)).filter(Boolean), [day]);
	const bounds = useMemo(() => mapSights.map(s => [s.location[1], s.location[0]]), [mapSights]);

	return (
		<div className={className}>
			<MapContainer className={className} zoomControl={false}>
				{zoom && <ZoomControl position={zoomPosition} />}
				{scale && <ScaleControl position={scalePosition} />}
				<TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
				{/* {!!bounds && <SetBoundsComponent />} */}
				{/* {map ? <DisplayPosition map={map} /> : null} */}
				{/* {children} */}
				{/* <Map className='map' visibility={visibility} zoom={zoom}> */}
				{currSightsNear &&
					currSightsNear.map(sight => {
						const markerPosition = [sight.location[1], sight.location[0]];
						return (
							<Marker
								position={markerPosition}
								key={`${sight.identifier}_marker`}
								title={sight.title}
								riseOnHover='true'
								onClick={() => handleClick(sight, true)}
								icon={L.ExtraMarkers.icon({
									shape: 'circle',
									markerColor: 'cyan',
									prefix: 'fas',
									icon: 'fa-plus',
									iconColor: '#fff',
									extraClasses: 'add-sight',
								})}></Marker>
						);
					})}

				<Routing waypoint={waypoint} day={day} allRoutes={allRoutes} handleChangeTripRoute={handleChangeTripRoute} routeSights={routeSights} sights={sights} handleClick={handleClick} />
				<TravelAddSights visibleDrawer={visibleDrawer} setAdd={changeAdd} day={day} add={add} currSight={currSight} onClose={onClose} />
			</MapContainer>

			{/* <MapContainer center={mapCenter} zoom={zoom} className='days-content--right' zoomControl={false}>
				<ZoomControl position='bottomright' />
				{/* <MapScale options={{ imperial: false }} /> */}
			{/* <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' /> */}
			{/* {currSightsNear &&
					currSightsNear.map(sight => {
						const markerPosition = [sight.location[1], sight.location[0]];
						return (
							<Marker
								position={markerPosition}
								key={`${sight.identifier}_marker`}
								title={sight.title}
								riseOnHover='true'
								onClick={() => handleClick(sight, true)}
								icon={L.ExtraMarkers.icon({
									shape: 'circle',
									markerColor: 'cyan',
									prefix: 'fas',
									icon: 'fa-plus',
									iconColor: '#fff',
									extraClasses: 'add-sight',
								})}></Marker>
						);
					})} */}

			{/* {routeSights.map((sightIdentifier, index) => {
					const sight = sights.find(el => el.identifier === sightIdentifier);
					if (sight) {
						const markerPosition = [sight.location[1], sight.location[0]];
						const title = `${sight.title} ${sight?.day ? `Day ${sight.day}` : ''}`;
						return (
							<Marker
								position={markerPosition}
								key={`${sight.identifier}_marker`}
								title={title}
								riseOnHover='true'
								onClick={() => {
									handleClick(sight, false);
									scroller.scrollTo(`${sight.identifier}`, {
										duration: 1500,
										smooth: true,
										containerId: 'travel-sights',
									});
								}}
								icon={
									!sight?.icon
										? L.ExtraMarkers.icon({
												icon: 'fa-number',
												number: index + 1,
												markerColor: 'blue',
										  })
										: L.ExtraMarkers.icon({
												shape: 'circle',
												prefix: 'fas',
												iconColor: '#fff',
												extraClasses: 'add-marker',
												icon: sight.icon,
												markerColor: 'blue',
										  })
								}></Marker>
						);
					}
				})} */}
			{/* <Routing waypoint={waypoint} day={day} allRoutes={allRoutes} handleChangeTripRoute={handleChangeTripRoute} routeSights={routeSights} sights={sights} handleClick={handleClick} />
				<TravelAddSights visibleDrawer={visibleDrawer} setAdd={changeAdd} day={day} add={add} currSight={currSight} onClose={onClose} />
			</MapContainer> */}
		</div>
	);
};

export default memo(TravelMap);
