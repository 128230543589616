 
import { createSlice } from '@reduxjs/toolkit';

// INITIALIZE STATE
const initialState = {
	travels: [],
	sights: [],
	sight: {},

	loading: {},
	errors: {},
	// distance: 0,
	hashtags: [],
	skip: 0,
	hasMore: true,
};

// REDUCER
// export const MapReducer = (state = initialState, action) => {
// 	switch (action.type) {
// 		case GET_MAP_TRAVELS:
// 			return {
// 				...state,
// 				travels: action.payload.travels,
// 				skip: action.payload.skip,
// 				hasMore: action.payload.hasMore
// 			};
// 		case PUT_MAP_TRAVELS_SKIP:
// 			return {
// 				...state,
// 				travels: [ ...state.travels, ...action.payload.travels],
// 				skip: action.payload.skip,
// 				hasMore: action.payload.hasMore
// 			};
// 		case GET_MAP_SIGHTS:
// 			return {
// 				...state,
// 				sights: action.payload
// 			};
// 		case GET_MAP_SIGHT:
// 			return {
// 				...state,
// 				sight: action.payload
// 			};
// 		case GET_MAP_HASHTAGS:
// 			return {
// 				...state,
// 				hashtags: action.payload,
// 			};

// 		case GET_TRAVELS_FOR_MAP: {
// 			return {
// 				...state,
// 				travels: [...state.travels, ...action.payload],
// 				skip: action.skip,
// 			};
// 		}
// 		// case GET_SIGHTS_FOR_MAP: {
// 		// 	return {
// 		// 		...state,
// 		// 		sights: action.payload,
// 		// 	};
// 		// }
// 		// case GET_SIGHT_FOR_MAP: {
// 		// 	return {
// 		// 		...state,
// 		// 		sight: action.payload,
// 		// 	};
// 		// }
// 		case DELETE_TRAVELS_FOR_MAP: {
// 			return {
// 				...state,
// 				travels: action.payload,
// 				skip: 0,
// 			};
// 		}

// 		default:
// 			return state;
// 	}
// };

const MapSlice = createSlice({
	name: 'MapPage',
	initialState,
	reducers: {
		travelsRequested: state => {
			state.loading.travels = true;
		},
		travelsReceived: (state, action) => {
			console.log(action.payload)
			state.travels = action.payload.travels;
			state.skip = action.payload.skip;
			state.hasMore = action.payload.hasMore;
			state.loading.travels = false;
			state.errors.travels = null;
		},
		travelsRequestFailed: (state, action) => {
			state.loading.travels = false;
			state.errors.travels = action.payload;
		},
		putTravelsSkipRequested: state => {
			state.loading.skip = true;
		},
		putTravelsSkip: (state, action) => {
			state.loading.skip = false;
			state.travels.push(...action.payload.travels);
			state.skip = action.payload.skip;
			state.hasMore = action.payload.hasMore;
		},
		putTravelsSkipRequestFailed: (state, action) => {
			state.loading.skip = false;
			state.errors.skip = action.payload;
		},
		sightRequested: state => {
			state.loading.sight = true;
		},
		sightReceived: (state, action) => {
			state.sight = action.payload;
			state.loading.sight = false;
			state.errors.sight = null;
		},
		sightRequestFailed: (state, action) => {
			state.loading.sight = false;
			state.errors.sight = action.payload;
		},
		sightsRequested: state => {
			state.loading.sights = true;
		},
		sightsReceived: (state, action) => {
			state.sights = action.payload;
			state.loading.sights = false;
			state.errors.sights = null;
		},
		sightsRequestFailed: (state, action) => {
			state.loading.sights = false;
			state.errors.sights = action.payload;
		},
		hashtagsRequested: state => {
			state.loading.hashtags = true;
		},
		hashtagsReceived: (state, action) => {
			state.hashtags = action.payload;
			state.loading.hashtags = false;
			state.errors.hashtags = null;
		},
		hashtagsRequestFailed: (state, action) => {
			state.loading.hashtags = false;
			state.errors.hashtags = action.payload;
		},
	},
});

const { actions, reducer: MapReducer } = MapSlice;

export const {
	travelsRequested,
	travelsReceived,
	travelsRequestFailed,
	putTravelsSkipRequested,
	putTravelsSkip,
	putTravelsSkipRequestFailed,
	sightRequested,
	sightReceived,
	sightRequestFailed,
	sightsRequested,
	sightsReceived,
	sightsRequestFailed,
	hashtagsRequested,
	hashtagsReceived,
	hashtagsRequestFailed
} = actions;

export { MapReducer };
