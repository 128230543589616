import React, { memo } from 'react';
import { Typography } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import TravelsCard from './TravelsCard';
import { getDays } from './../../../Helper';
import { useSelector } from 'react-redux';
import { Loader } from './../../common/Loader/Loader';

const { Title } = Typography;

const TravelsList = ({ travels, duration, difficulty, hash, isLoggedIn, hasMore, fetchMore, addToFavorite }) => {
	let daysDuration = getDays(duration);
	const { user } = useSelector(state => state.UserPage);
	const favorites = user && user.favorites ? user.favorites : [];
	return (
		<InfiniteScroll
			next={() => fetchMore()}
			hasMore={hasMore}
			loader={
				<div className='center-content'>
					<Loader />
				</div>
			}
			endMessage={
				<p style={{ textAlign: 'center' }}>
					<b>You have seen it all!</b>
				</p>
			}
			dataLength={travels.length}
			className='travels-container'
			style={{ overflow: 'hidden' }}>
			{hash !== '' && (
				<Title level={2} className='travels-hashtag'>
					{hash}
				</Title>
			)}
			{travels.map(travel => {
				return (travel.daysNumber && daysDuration.includes(+travel.daysNumber) && travel.difficulty == difficulty) || (duration === 'All' && travel.difficulty == difficulty) ? (
					<TravelsCard travel={travel} favorites={favorites} isLoggedIn={isLoggedIn} addToFavorite={addToFavorite} key={travel.identifier} />
				) : (
					false
				);
			})}
		</InfiniteScroll>
	);
};

export default memo(TravelsList);
