import React, { Fragment, useState } from 'react';
import {connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tooltip, Button, Modal, Col } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';

import * as TravelsAndSightsActions from '../../../../state/actions/TravelsAndSightsActions';
import { openNotification } from '../../../common/Notification/Notification';

const ChangeSightsButtons = props => {
	const { sight = '', day = -1, identifier = '', allDays = -1, TravelsAndSightsActions } = props;
	const [visibleModal, setVisibleModal] = useState(false);

	const showModal = () => {
		setVisibleModal(true);
	};

	const cancelModal = () => {
		setVisibleModal(false);
	};

	const move = move => {
		console.log(day, sight, allDays, move, props);
		if (move === 'next' && day < allDays) {
			TravelsAndSightsActions.deleteSight(null, sight, identifier, day);
			TravelsAndSightsActions.addSights(sight, -1, day);
			openNotification(
				<span>
					<span style={{ color: '#00008B' }}>{sight.title}</span> was moved to the last position of the next day.
				</span>
			);
		} else if (move === 'prev' && day !== 1) {
			TravelsAndSightsActions.deleteSight(null, sight, identifier, day);
			TravelsAndSightsActions.addSights(sight, -1, day - 2);
			openNotification(
				<span>
					<span style={{ color: '#00008B' }}>{sight.title}</span> was moved to the last position of the previous day.
				</span>
			);
		} else if (move === 'right' || move === 'left') {
			TravelsAndSightsActions.moveSight(sight, identifier, day, move);
		} else {
			openNotification('Sorry. Not possible.', 'error');
		}
	};

	const delSight = () => {
		TravelsAndSightsActions.deleteSight(null, sight, identifier, day);
		openNotification(
			<span>
				<span style={{ color: '#00008B' }}>{sight.title}</span> was deleted
			</span>,
			'warning'
		);
	};

	return (
		<Fragment>
			<Col className='days-card_options'>
				<Tooltip placement={'bottom'} title={'Move up'}>
					<span aria-hidden='true' className={'moveSight'} onClick={() => move('left')}>
						<ArrowUpOutlined />
					</span>
				</Tooltip>
				<Tooltip placement={'bottom'} title={'Move down'}>
					<span aria-hidden='true' className={'moveSight'} onClick={() => move('right')}>
						<ArrowDownOutlined />
					</span>
				</Tooltip>
				<Tooltip placement={'bottom'} title={'Move to the previous day'}>
					<span aria-hidden='true' className={'moveSight'} onClick={() => move('prev')}>
						<ArrowLeftOutlined />
					</span>
				</Tooltip>
				<Tooltip placement={'bottom'} title={'Move to the next day'}>
					<span aria-hidden='true' className={'moveSight'} onClick={() => move('next')}>
						<ArrowRightOutlined />
					</span>
				</Tooltip>
				<Tooltip placement={'bottom'} title={'Delete'}>
					<span aria-hidden='true' className={'moveSight'} onClick={showModal}>
						<CloseOutlined />
					</span>
				</Tooltip>
			</Col>

			<Modal visible={visibleModal} centered className='travel-modify' onCancel={cancelModal} style={{ textAlign: 'center' }} footer={null}>
				<h2 style={{ marginTop: '1vh' }}>Are you sure you want to delete this sight from your travel?</h2>
				<Button onClick={delSight} style={{ marginRight: '1vw' }}>
					Yes
				</Button>
				<Button onClick={cancelModal} style={{ marginLeft: '1vw' }}>
					No
				</Button>
			</Modal>
		</Fragment>
	);
};

function mapStateToProps(state) {
	return {
		TravelInfo: state.TravelInfo,
		travel: state.TravelInfo.travel,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		TravelsAndSightsActions: bindActionCreators(TravelsAndSightsActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSightsButtons);
