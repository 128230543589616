import React from 'react';
import { Button } from 'antd';
import Badge from '../common/Badge';

const ButtonGroup = Button.Group;

const ButtonIntensity = ({ difficulties, difficulty, identifier, day = '',  }) => {
	return (
		<ButtonGroup className='header-intensity'>
			{difficulties.map(d => (
				<Badge
					key={`intensity-${d}`}
					intensity={d}
					type={d === difficulty ? 'green' : 'grey'}
					pathname={`/trip/${identifier}/${d}/${day && `day/${day}/`}`}
					className={d === difficulty ? 'header-intensity_primary' : 'header-intensity_default'}
				/>
			))}
		</ButtonGroup>
	);
};

export default ButtonIntensity;
