export function validator(data, config) {
	const errors = {};
	function validate(validateMethod, value, config) {
		let statusValidate;
		switch (validateMethod) {
			case 'isEmail': {
				const emailRegExp = /^\S+@\S+\.\S+$/g;
				statusValidate = !emailRegExp.test(value);
				break;
			}
			case 'isCapitalSymbol': {
				const capitalRegExp = /[A-Z]+/g;
				statusValidate = !capitalRegExp.test(value);
				break;
			}
			case 'isContainDigit': {
				const digitRegExp = /\d+/g;
				statusValidate = !digitRegExp.test(value);
				break;
			}
			case 'min': {
				statusValidate = value.length < config.value;
				break;
			}
			default:
				break;
		}
		if (statusValidate) return config.message;
	}

	if (data) {
		Object.keys(config).map(fieldName => {
			const error = validate(fieldName, data, config[fieldName]);
			if (error && !errors[fieldName]) {
				errors[fieldName] = error;
			}
		});
	}
	return errors;
}
