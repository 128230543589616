import React, { useEffect, useState, memo } from 'react';
import { Row } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router';

import MapTitle from './MapTitle';
import MapDrawer from './MapDrawer';
import MapFilters from './MapFilters';
import MapHashtags from './MapHashtags';
import { checkLatLong } from './../../../helpers/_Functions';
import { useSelector, useDispatch } from 'react-redux';
import { loadTravels } from './../../../state/actions/MapActions';

const MapHeader = ({ sight, selectedHashtags, hashtags, loading }) => {
	const { identifier, distance, duration = 'All' } = useParams();
	const {label} = useSelector(state => state.Search.location);
	const { hash } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [hashtagsOpen, setHashtagsOpen] = useState(false);
	const [days, setDays] = useState('');
	const [menuOpen, setMenuOpen] = useState(false);

	const getAllHashtags = () => {
		if (identifier) {
			if (identifier.includes(';')) {
				const { lat, long } = checkLatLong(identifier);
				if (lat && long) {
					dispatch(loadTravels({ lat, long, days: duration, hash, distance, getHashtag: true, limit: 100 }));
				}
			} else {
				dispatch(loadTravels({ sightIdentifier: identifier, days: duration, hash, getHashtag: true, skip: 0, limit: 100 }));
			}
		} else {
			dispatch(loadTravels({ days: duration, hash, distance, getHashtag: true, skip: 0, limit: 100 }));
		}
	};

	const handleSelectHashtag = hashtag => {
		const hashtags = selectedHashtags.includes(hashtag) ? selectedHashtags.filter(hash => hash !== hashtag) : [...selectedHashtags, hashtag];
		navigate({ hash: hashtags.join('#') });
	};

	const handleSelectDuration = day => {
		const duration = day === 'All' ? '' : day;
		const url = `/map${duration && `/${duration}`}/itineraries${identifier ? `/${identifier}` : ''}/${distance ? `${distance}/` : ''}${hash && `${hash}`}`;
		navigate(url);
	};

	const clearFilters = () => {
		navigate(`/map/itineraries${identifier ? `/${identifier}` : '/'}${distance ? `/${distance}/` : ''}`);
	};

	const clearAll = () => {
		navigate('/map/itineraries/');
	};

	const menuToggle = state => {
		setMenuOpen(state.isOpen);
	};

	const onHashtagsToggle = isOpen => setHashtagsOpen(isOpen);

	useEffect(() => {
		if (duration) {
			setDays(duration);
		} else {
			setDays('All');
		}
	}, [duration]);

	useEffect(() => {
		setHashtagsOpen(false);
	}, [identifier, distance, duration]);
console.log(loading)
	return (
		<Row className='explore-header'>
			<MapTitle sight={sight} location={label} distance={distance} />
			<MapDrawer menuOpen={menuOpen} days={days} selectedHashtags={selectedHashtags} menuToggle={menuToggle} clearAll={clearAll}>
				<Row className='explore-filters'>
					<MapFilters days={days} selectedHashtags={selectedHashtags} menuOpen={menuOpen} clearAll={clearAll} clearFilters={clearFilters} handleSelectDuration={handleSelectDuration} />
					<MapHashtags
						hashtags={!hashtagsOpen ? hashtags.slice(0, 8) : hashtags}
						selectedHashtags={selectedHashtags}
						hashtagsOpen={hashtagsOpen}
						loading={loading.hashtags}
						handleSelectHashtag={handleSelectHashtag}
						onHashtagsToggle={onHashtagsToggle}
						getAllHashtags={getAllHashtags}
					/>
				</Row>
			</MapDrawer>
		</Row>
	);
};

export default memo(MapHeader);
