// IMPORT PACKAGES
import React from 'react';
import { Provider } from 'react-redux';
// IMPORT COMPONENTS
import AppRouter from '../routers/AppRouter';

// COMPONENT
class Root extends React.Component {
    render(){
        const { store, history } = this.props;
        return(
            <Provider store={store}>
                <AppRouter history={history}/>
            </Provider>
        );
    }
}

export default Root;