// IMPORT PACKAGES
import React from 'react';
// IMPORT STORE
import createAppStore, { history } from './state/stores/AppStore';
// IMPORT COMPONENTS
import Root from './containers/Root';

// COMPONENT
const App = () => <Root store={createAppStore()} history={history} />;

export default App;
