import React from 'react';
import { Col, Typography, List } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { ImageFinder } from '../../../Helper';
import MoveControlButtons from './MoveControlButtons';

const TravelCard = props => {
	const { Title, Text } = Typography;
	const { objSights, day, days, identifier, sight, index, sights } = props;
	const { pathname } = useLocation();

	return (
		<List.Item className='days-card' key={`${sight}-${index}`} name={`${objSights[sight].identifier}`}>
			{pathname.includes('edit') && <MoveControlButtons sights={sights} sight={sight} day={day} identifier={identifier} allDays={days} />}
			<Col className='days-card__image'>
				<Link to={`/trip/${identifier}/sight/${objSights[sight].identifier}/`} className='days-card_img'>
					<ImageFinder
						alt={sight}
						src={objSights[sight].images && objSights[sight].images.length && objSights[sight].images[0].hasOwnProperty('name') ? objSights[sight].images[0].name : 'default-img.jpg'}
						className='days-card_img'
					/>
				</Link>
			</Col>
			<Col className='days-card_info'>
				<Link to={`/trip/${identifier}/sight/${objSights[sight].identifier}/`}>
					<Title level={2} className='days-card_info-title'>
						{objSights[sight].title}
					</Title>
				</Link>
				<Text className='days-card_info-subtitle'>{objSights[sight].subtitle}</Text>
				<a href={`https://www.google.com/maps/place/${objSights[sight].location[1]},${objSights[sight].location[0]}`} target='_blank' rel='nofollow noopener noreferrer'>
					<span className='material-icons days-card_marker'>place</span>
					<Text className='days-card_info-subtitle_link'>
						{objSights[sight].location[1]}, {objSights[sight].location[0]}
					</Text>
				</a>
				<Text className='days-card_info-description'>{objSights[sight].description}</Text>
			</Col>
		</List.Item>
	);
};

export default TravelCard;
