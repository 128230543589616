import React from 'react';
import { Col, Button, Row, Select, Tooltip } from 'antd';
import { CalendarOutlined, ClearOutlined, FilterOutlined, FilterFilled } from '@ant-design/icons';
import { DAYS } from '../../../constants/Duration';
import { checkDay } from '../../../helpers/_Functions';

const { Option } = Select;

const MapFilters = props => {
	const { days, selectedHashtags, menuOpen, clearAll, clearFilters, handleSelectDuration } = props;
	return (
		<>
			<Col className='explore-filters__duration explore-duration'>
				<Select
					className='explore-duration__select'
					value={
						days ? (
							checkDay(days)
						) : (
							<span className='explore-duration__select-placeholder'>
								<CalendarOutlined style={{ textAlign: 'left' }} />
								<p>Duration</p>
							</span>
						)
					}
					onChange={handleSelectDuration}>
					{DAYS.map((day, index) => {
						return (
							<Option key={`day-${index}`} value={day}>
								{checkDay(day)}
							</Option>
						);
					})}
				</Select>
			</Col>

			{!menuOpen && (
				<Col className='explore-filters__clear explore-filter'>
					<Tooltip title='Clear Location'>
						<Button className='explore-filter__btn' onClick={clearAll}>
							<ClearOutlined />
						</Button>
					</Tooltip>
				</Col>
			)}

			{!menuOpen && (
				<Col className='explore-filters__filter explore-filter'>
					<Tooltip title='Clear Filters'>
						<Button className='explore-filter__btn' onClick={clearFilters}>
							{selectedHashtags.length || (days !== 'All' && days) ? <FilterFilled /> : <FilterOutlined />}
						</Button>
					</Tooltip>
				</Col>
			)}
		</>
	);
};

export default MapFilters;
