import React, { memo } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Typography, Row, Col, Rate, Progress } from 'antd';
import { GetIcon } from '../../../Helper';
const { Title, Text } = Typography;

const SightInfo = () => {
	const { identifier = '' } = useParams();
	const { sight, travel = {} } = useSelector(state => state.SightPage);
	console.log(travel)
	return (
		<Row className='sight-header__info-content'>
			<Row>
				{sight.trailDetails && <span className='sight-header__rate'>{sight.trailDetails.scenery}.0</span>}
				<Title level={1} className='sight-header__title'>
					{sight.title}
				</Title>
				<Text className='sight-header__subtitle'>{sight.subtitle}</Text>
				{identifier && travel.hasOwnProperty('title') && (
					<Text className='sight-header__trip'>
						one of the sights of the trip
						<a href={`/trip/${travel.identifier}`} className='sight-header__trip-title'>
							{travel.title}
						</a>
					</Text>
				)}
			</Row>
			{sight.trailDetails && (
				<Row className='sight-header__about'>
					<Col lg={6} md={6} sm={6} xs={6}>
						<Title level={2}>Difficulty</Title>
						<Col className='sight-header__about-difficulty'>
							<Progress percent={sight.trailDetails.difficulty * 20} size='small' strokeColor={'#0C4895'} />
						</Col>
					</Col>
					<Col lg={6} md={6} sm={6} xs={6}>
						<Title level={2}>Distance</Title>
						<Text className='sight-header__about-distance'>{sight.trailDetails.distance}</Text>
					</Col>
					<Col lg={6} md={6} sm={6} xs={6}>
						<Title level={2}>Elevation</Title>
						<Col className='sight-header__about-elevation'>
							<Rate
								disabled
								character={
									<span className='material-icons' style={{ fontSize: '16px' }}>
										directions_walk
									</span>
								}
								defaultValue={Number(sight.trailDetails.elevation)}
							/>
						</Col>
					</Col>
					<Col lg={6} md={6} sm={6} xs={6}>
						<Title level={2}>Crowd</Title>
						<Col className='sight-header__about-crowd'>
							<Rate
								disabled
								character={
									<span className='material-icons' style={{ fontSize: '16px' }}>
										person
									</span>
								}
								defaultValue={Number(sight.trailDetails.crowd)}
							/>
						</Col>
					</Col>
				</Row>
			)}
			<Row className='sight-header__info-container'>
				<Col className='sight-header__link'>
					<a href={`https://www.1000viewpoints.com/location/${sight.location[0]};${sight.location[1]}/5`} target='_blank' rel='nofollow noopener noreferrer'>
						<GetIcon src='gallery.svg' className='sight-header__icon' />
						<Title level={2} className={'all-pics'}>
							See more pics
						</Title>
					</a>
				</Col>

				<Col className='sight-header__text'>
					<GetIcon src='marker.svg' className='sight-header__icon' />
					<Title level={2}>{sight.address}</Title>
				</Col>

				<Col className='sight-header__link'>
					<a href={`https://www.google.com/maps/place/${sight.location[1]},${sight.location[0]}`} target='_blank' rel='nofollow noopener noreferrer'>
						<GetIcon src='direction.svg' className='sight-header__icon' />
						<Title level={2}>
							{sight.location[0]}, {sight.location[1]}
						</Title>
					</a>
				</Col>

				{sight.usefulResources && (
					<Col className='sight-header__link-resources'>
						<GetIcon src='resources.svg' className='sight-header__icon' />
						<Title level={2}>Useful resources: </Title>
						{sight.usefulResources.map((element, index) => {
							let url = element.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];
							if (url) {
								return (
									<span key={index}>
										<a href={element} rel='nofollow noopener noreferrer' target='_blank'>
											{url}
										</a>
										{sight.usefulResources.length - 1 !== index ? ', ' : false}
									</span>
								);
							}
						})}
					</Col>
				)}
			</Row>
		</Row>
	);
};

export default memo(SightInfo);
