import { useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-routing-machine';
import 'leaflet-extra-markers/src/assets/css/leaflet.extra-markers.css';
import { scroller } from 'react-scroll';
import errorsService from './../../../services/errors.service';
import { useLocation } from 'react-router-dom';
import { openNotification } from './../../common/Notification/Notification';

const Routing = ({ waypoint, allRoutes, routeSights, sights, handleClick, handleChangeTripRoute, day = 'All' }) => {
	const [route, setRoute] = useState({});
	const map = useMap();
	const { pathname } = useLocation();
	const newAllRoutes = useMemo(
		() =>
			waypoint.reduce(
				(p, c) => {
					if (p[p.length - 1].length === 25) {
						p.push([p[p.length - 1][p[p.length - 1].length - 1]]);
					}

					p[p.length - 1].push(c);
					return p;
				},
				[[]]
			),
		[waypoint]
	);

	const findMissedSight = ({ index, array }) => {
		let body = {
			site: 'beforevoyage',
			page: pathname,
			log: `Error on ${pathname} info: error on sight. Not found sight in sights. Day: ${day}. Sight identifier: "${array[index]}" index: "${index}"`,
		};
		errorsService.putError(body, pathname);
		const message = day === 'All' ? 'Sorry, trip is updating!' : 'Sorry, day is updating!';
		openNotification(message, 'info');
	};

	useEffect(() => {
		if (!map) return;

		if (waypoint.indexOf(undefined) !== -1) {
			return findMissedSight({ index: waypoint.indexOf(undefined), array: routeSights });
		}

		const routingControl = newAllRoutes.map((routes, index) => {
			const newIndex = newAllRoutes.slice(0, index).reduce((acc, arr) => acc + arr.length, 0);
			return L.Routing.control({
				show: false,
				waypoints: routes,
				addWaypoints: false,
				// draggableWaypoints: false,
				router: L.Routing.mapbox('pk.eyJ1Ijoidmhham1payIsImEiOiJjam90bGJzMmMxMmh3M2tybmljb2o4YmtzIn0.qU5_ExGJNUGpTTtdpZ1X0w'),
				lineOptions: {
					styles: [
						{
							color: 'blue',
							opacity: '0.7',
							weight: 4,
						},
					],
				},
				createMarker: function (i, wp) {
					const sight = sights.find(el => el.location[0] === wp.latLng.lng && (newIndex + i === newIndex && newIndex !== 0 ? null : routeSights[newIndex + i - index]));

					if (sight) {
						const title = `${sight.title} ${sight?.day ? `Day ${sight.day}` : ''}`;
						const number = routeSights.indexOf(sight.identifier);
						return L.marker(wp.latLng, {
							title: title,
							click: function () {
								handleClick(sight, false);
								scroller.scrollTo(`${sight.identifier}`, {
									duration: 1500,
									smooth: true,
									containerId: 'travel-sights',
								});
							},
							icon: !sight?.icon
								? L.ExtraMarkers.icon({
										icon: 'fa-number',
										number: number + 1,
										markerColor: 'blue',
								  })
								: L.ExtraMarkers.icon({
										shape: 'circle',
										prefix: 'fas',
										iconColor: '#fff',
										extraClasses: 'add-marker',
										icon: sight.icon,
										markerColor: 'blue',
								  }),
						}).on('click', function () {
							handleClick(sight, false);
							scroller.scrollTo(`${sight.identifier}`, {
								duration: 1500,
								smooth: true,
								containerId: 'travel-sights',
							});
						});
					}
				},
			})
				.on('routesfound', function (e) {
					handleChangeTripRoute(e.routes[0].summary);
				})
				.addTo(map);
		});

		// console.log(map, routingControl, allRoutes);

		// const boundsVersion = L.Routing.control({
		// 	waypoints: waypoint
		// });
		// boundsVersion.on('routeselected', function(e){
		// 	let line = L.Routing.line(e.route);
		// 	console.log(e.route)
		// 	// console.log(line.getBounds())
		// });

		// routingControl.map(control => control.on('routeselected', function(e){
		// 	let line = L.Routing.line(e.route);
		// 	// console.log(e.route)
		// 	// console.log(line.getBounds())
		// }));
		map.fitBounds(waypoint)
		// map.flyToBounds(waypoint)
		// console.log(map.getCenter())
		return () => {
			routingControl.map(control => map.removeControl(control));
		};
	}, [map, routeSights, waypoint]);

	return null;
};

export default Routing;

// const routingControl = L.Routing.control({
// 	waypoints: waypoint,
// 	show: false,
// 	// router: L.Routing.mapbox('pk.eyJ1Ijoidmhham1payIsImEiOiJjam90bGJzMmMxMmh3M2tybmljb2o4YmtzIn0.qU5_ExGJNUGpTTtdpZ1X0w'),
// 	lineOptions: {
// 		styles: [
// 			{
// 				color: 'blue',
// 				opacity: '0.7',
// 				weight: 4,
// 			},
// 		],
// 	},
// 	addWaypoints: false,
// 	draggableWaypoints: false,
// 	fitSelectedRoutes: true,
// 	createMarker: function (i, wp) {
// 		const sight = sights.find(el => el.identifier === routeSights[i]);
// 		const title = `${sight.title} ${sight?.day ? `Day ${sight.day}` : ''}`;
// 		if (sight) {
// 			return L.marker(wp.latLng, {
// 				title: title,
// 				click: function () {
// 					handleClick(sight, false);
// 					scroller.scrollTo(`${sight.identifier}`, {
// 						duration: 1500,
// 						smooth: true,
// 						containerId: 'travel-sights',
// 					});
// 				},
// 				icon: !sight?.icon
// 					? L.ExtraMarkers.icon({
// 							icon: 'fa-number',
// 							number: i + 1,
// 							markerColor: 'blue',
// 					  })
// 					: L.ExtraMarkers.icon({
// 							shape: 'circle',
// 							prefix: 'fas',
// 							iconColor: '#fff',
// 							extraClasses: 'add-marker',
// 							icon: sight.icon,
// 							markerColor: 'blue',
// 					  }),
// 			}).on('click', function () {
// 				handleClick(sight, false);
// 				scroller.scrollTo(`${sight.identifier}`, {
// 					duration: 1500,
// 					smooth: true,
// 					containerId: 'travel-sights',
// 				});
// 			});
// 		}
// 	},
// })
// 	.on('routesfound', function (e) {
// 		console.log(e);
// 		handleChangeTripRoute(e.routes[0].summary);
// 	})
// 	.addTo(map);
