import React, { useState, useEffect, memo } from 'react';
import { Row, Col, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';

import SearchLocation from '../../../ui/Search/SearchLocation';
import { GetIcon } from '../../../../Helper';
import { checkControlExisting } from '../../../../helpers/_Functions';
import { getControl } from '../../../../state/reducers/SearchReducer';
import { getSearchInfo } from './../../../../state/reducers/SearchReducer';
import { deleteSearchControl } from './../../../../state/actions/SearchAction';
import { deleteSight } from '../../../../state/actions/CustomizeTripActions';

const { Text } = Typography;

const TravelModifyCar = props => {
	const {
		identifier = '',
		sights = [],
		routes = [],
		day = '',
		type = 'startDay',
		updateTravelControls,
		addToSights,
	} = props;

	const dispatch = useDispatch();
	const car = useSelector(getControl('car'));
	const Search = useSelector(getSearchInfo());

	const [carInfo, setCarInfo] = useState('');
	const [showCar, setShowCar] = useState(false);

	const index = type === 'startDay' ? 
											checkControlExisting(day, 'start', Search) && checkControlExisting(day, 'airport', Search) ? 2 
											: checkControlExisting(day, 'start', Search) || checkControlExisting(day, 'airport', Search) ? 1 : 0
										: 
											checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'finish', Search) ? routes[day - 1].sights.length - 2
											: checkControlExisting(day, 'airport', Search) || checkControlExisting(day, 'finish', Search) ? routes[day - 1].sights.length - 1 : routes[day - 1].sights.length;

	const deleteCar = () => {
		dispatch(deleteSight({identifier: carInfo.label, day}));
		dispatch(deleteSearchControl({identifier: carInfo.label, type: 'car', day}));
		setShowCar(false);
		setCarInfo('');
	};

	useEffect(() => {
		if (car.length) {
			let currCar = car.find(item => item.identifier === identifier && item.day === day);
			if (currCar) {
				if (!sights.find(el => el.title === currCar.data.label && el.day === day)) {
					setCarInfo(currCar.data);
					setShowCar(true);
					addToSights({obj: currCar, type: 'car', icon: 'fa-car-side', index});
				}
			}
		}
	}, [car]);

	useEffect(() => {
		if (car.length && !car.find(el => el.day === day)) {
			setCarInfo('');
			setShowCar(false);
		} else {
			let currCar = car.find(item => item.identifier === identifier && item.day === day);
			if (currCar) {
				setCarInfo(currCar.data);
				setShowCar(true);
			}
		}
	}, [day]);

	return (
		<Row className='modify-btns'>
			<Col lg={4} xs={4} className='modify-icon'>
				<GetIcon src='car.png' />
				<Text className='modify-title'>Car rent</Text>
			</Col>
			<Col lg={20} xs={20} className='modify-text'>
				{!showCar && (
					<div style={{ padding: '0' }}>
						<SearchLocation
							controlType='car'
						/>
					</div>
				)}

				{showCar && carInfo && (
					<Col lg={20} xs={20}>
						<h3 className={'modifyInfo'}>{carInfo.text}</h3>
						<a href={`https://www.google.com/maps/place/${carInfo.y},${carInfo.x}`} className='modify-link' target='_blank' rel='nofollow noopener noreferrer'>
							<span className='material-icons modify-link__marker'>place</span>
							<Text className='modify-link__text'>
								{carInfo.y}, {carInfo.x}
							</Text>
						</a>
					</Col>
				)}

				{showCar && carInfo && (
					<Col lg={4} xs={4} onClick={deleteCar} className={'closeModify'}>
						<CloseOutlined />
					</Col>
				)}

				{!showCar && (
					<Col lg={4} xs={4} 
						onClick={() => 
							updateTravelControls({ car: day })
						} 
						aria-hidden='true' 
						className={'closeModify'}
					>
						<CloseOutlined />
					</Col>
				)}
			</Col>
		</Row>
	);
};

export default memo(TravelModifyCar);
