import React, { useState } from 'react';
import { Select, Button, Typography, Row, Space } from 'antd';
import { Link } from 'react-router-dom';

import { dataSearch } from '../../../helpers/search';
import { FileSearchOutlined, CloseCircleTwoTone, SearchOutlined } from '@ant-design/icons';

const { Option, OptGroup } = Select;
const { Text } = Typography;

const FIND = (
	<>
		<FileSearchOutlined style={{ color: '#1890ff' }} />
		Find Trip and Sights
	</>
);
const NOTFOUND = (
	<>
		<CloseCircleTwoTone twoToneColor='#eb2f96' />
		No results found
	</>
);

const HeaderSearch = props => {
	const { className = 'header-search', placeholder = 'Search' } = props;

	const [loadingSearch, setLoadingSearch] = useState(false);
	const [value, setValue] = useState(undefined);
	const [data, setData] = useState({});
	const [fetching, setFetching] = useState(FIND);

	const handleSearch = async value => {
		setValue(value);
		setLoadingSearch(true);
		if (value && value.length >= 3) {
			const { trips, sights, hashtags } = await dataSearch(value);
			setData({
				trips,
				sights,
				hashtags,
			});
			if (!trips.length && !sights.length && !Object.keys(hashtags).length) {
				setFetching(NOTFOUND);
			}
			setLoadingSearch(false);
		} else {
			setData({});
			setFetching(FIND);
		}
	};

	return (
		<Row className={className}>
			<Space className={`${className}-btn-group`}>
				<Select
					className={`${className}-search-select`}
					popupClassName={`${className}-search-dropdown`}
					showSearch
					searchValue={value}
					placeholder={placeholder}
					defaultActiveFirstOption={false}
					showArrow={false}
					filterOption={false}
					onSearch={handleSearch}
					loading={loadingSearch}
					notFoundContent={fetching}>
					{data?.trips && (
						<OptGroup label='Trips'>
							{data.trips.map((trip, i) => (
								<Option key={`${i}-trip-${trip.identifier}`} value={`${i}-/trip/${trip.identifier}/moderate/`}>
									<Link className='header-search-link' to={`/trip/${trip.identifier}/moderate/`} title={trip.title}>
										{trip.title}
									</Link>
								</Option>
							))}
						</OptGroup>
					)}

					{data?.sights && (
						<OptGroup label='Sights'>
							{data.sights.map((sight, i) => {
								return (
									<Option key={`${i}-sight-${sight.identifier}`} value={`${i}-/sight/${sight.identifier}/`}>
										<Link to={`/sight/${sight.identifier}/`} className='header-search-link' title={sight.title}>
											{sight.title}
										</Link>
									</Option>
								);
							})}
						</OptGroup>
					)}

					{data?.hashtags &&
						Object.keys(data?.hashtags).map(key => (
							<OptGroup
								key={`OptGroupUniqueTravelByTags-${key}`}
								label={
									<span>
										<Text strong> # {key}</Text>
									</span>
								}>
								{data?.hashtags[key].map((travelTag, i) => {
									if (travelTag.difficulty === 'moderate') {
										return (
											<Select.Option key={`${i}-tripTag-${travelTag.identifier}`} value={`${i}-/trip/${travelTag.identifier}`}>
												<Link to={`/trip/${travelTag.identifier}/${travelTag.difficulty}/`} className='header-search-link' title={travelTag.title}>
													{travelTag.title}
												</Link>
											</Select.Option>
										);
									}
								})}
							</OptGroup>
						))}
				</Select>
				<Button type='primary' icon={<SearchOutlined />} />
			</Space>
		</Row>
	);
};

export default HeaderSearch;
