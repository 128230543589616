import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { Row, Col, Typography } from 'antd';
import { connect } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';

import SearchLocation from '../../../ui/Search/SearchLocation';
import * as TravelInfoActions from '../../../../state/actions/TravelInfoActions';
import { GetIcon } from '../../../../Helper';
import { checkControlExisting } from '../../../../helpers/_Functions';

const { Text } = Typography;

const TravelModifyCar = props => {
	const {
		travel: {identifier } = {},
		sights = [],
		car = [],
		routes = [],
		day = '',
		type = 'startDay',
		updateTravelControls,
		Search,
		addToSights,
		actions,
	} = props;

	const [carInfo, setCarInfo] = useState('');
	const [showCar, setShowCar] = useState(false);

	const index = type === 'startDay' ? 
											checkControlExisting(day, 'start', Search) && checkControlExisting(day, 'airport', Search) ? 2 
											: checkControlExisting(day, 'start', Search) || checkControlExisting(day, 'airport', Search) ? 1 : 0
										: 
											checkControlExisting(day, 'airport', Search) && checkControlExisting(day, 'finish', Search) ? routes[day - 1].sights.length - 2
											: checkControlExisting(day, 'airport', Search) || checkControlExisting(day, 'finish', Search) ? routes[day - 1].sights.length - 1 : routes[day - 1].sights.length;

	const deleteCar = () => {
		actions.deleteSight({identifier: carInfo.label, type: 'car', day});
		setShowCar(false);
		setCarInfo('');
	};

	useEffect(() => {
		if (car.length) {
			let currCar = car.find(item => item.identifier === identifier && item.day === day);
			if (currCar) {
				if (!sights.find(el => el.title === currCar.data.label && el.day === day)) {
					setCarInfo(currCar.data);
					setShowCar(true);
					addToSights({obj: currCar, type: 'car', icon: 'fa-car-side', index});
				}
			}
		}
	}, [car]);

	useEffect(() => {
		if (car.length && !car.find(el => el.day === day)) {
			setCarInfo('');
			setShowCar(false);
		} else {
			let currCar = car.find(item => item.identifier === identifier && item.day === day);
			if (currCar) {
				setCarInfo(currCar.data);
				setShowCar(true);
			}
		}
	}, [day]);

	return (
		<Row className='modify-btns'>
			<Col lg={4} xs={4} className='modify-icon'>
				<GetIcon src='car.png' />
				<Text className='modify-title'>Car rent</Text>
			</Col>
			<Col lg={20} xs={20} className='modify-text'>
				{!showCar && (
					<div style={{ padding: '0' }}>
						<SearchLocation
							searchType='car'
						/>
					</div>
				)}

				{showCar && carInfo && (
					<Col lg={20} xs={20}>
						<h3 className={'modifyInfo'}>{carInfo.text}</h3>
						<a href={`https://www.google.com/maps/place/${carInfo.y},${carInfo.x}`} className='modify-link' target='_blank' rel='nofollow noopener noreferrer'>
							<span className='material-icons modify-link__marker'>place</span>
							<Text className='modify-link__text'>
								{carInfo.y}, {carInfo.x}
							</Text>
						</a>
					</Col>
				)}

				{showCar && carInfo && (
					<Col lg={4} xs={4} onClick={deleteCar} className={'closeModify'}>
						<CloseOutlined />
					</Col>
				)}

				{!showCar && (
					<Col lg={4} xs={4} 
						onClick={() => 
							updateTravelControls({ car: day })
						} 
						aria-hidden='true' 
						className={'closeModify'}
					>
						<CloseOutlined />
					</Col>
				)}
			</Col>
		</Row>
	);
};

function mapStateToProps(state) {
	return {
		car: state.Search.car,
		routes: state.TravelInfo.routes,
		travel: state.TravelInfo.travel,
		sights: state.TravelInfo.sights,
		Search: state.Search,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(TravelInfoActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelModifyCar);
