import httpService from './http.service';

const searchEndpoint = 'search?query=';

const searchService = {
	get: async (query) => {
		const { data } = await httpService.get(`${searchEndpoint}${query}`);
		return data;
	}

};
export default searchService;