import React, { useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Menu } from 'antd';
import { UnorderedListOutlined, UserOutlined, HeartOutlined, ScheduleOutlined, ContainerOutlined, HomeOutlined, LogoutOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { Link, Outlet, useLocation } from 'react-router-dom';
// import HeaderSearch from './../../ui/Search/HeaderSearch';
import { logOut } from '../../../state/actions/AuthActions';

const { Content, Sider } = Layout;

const UserContent = () => {
	const { id, type } = useParams();
	const {pathname} = useLocation();
	console.log(type, pathname)
	const { user } = useSelector(state => state.UserPage) || {};
	const [collapsed, setCollapsed] = useState(true);
	const dispatch = useDispatch();
	const onCollapse = () => {
		setCollapsed(prev => !prev);
	};

	function getItem(label, key, icon, children, type) {
		return {
			key,
			icon,
			children,
			label,
			type,
		};
	}

	const handleLogOut = () => dispatch(logOut());

	const items = [
		getItem(<Link to={`/user/${id}/`}>Account</Link>, 'user', <UserOutlined />),
		getItem(<Link to={`/user/${id}/trips`}>My Trips</Link>, 'trips', <UnorderedListOutlined />),
		getItem(<Link to={`/user/${id}/favorites`}>Wish List</Link>, 'favorites', <HeartOutlined />),
		getItem(<Link to={`/user/${id}/schedule`}>Schedule</Link>, 'schedule', <ScheduleOutlined />),
		getItem(<Link to={`/user/${id}/tickets`}>Tickets</Link>, 'tickets', <ContainerOutlined />),
		getItem(<Link to={`/user/${id}/hotels`}>Hotels</Link>, 'hotels', <HomeOutlined />),
		getItem(
			<Link to='/login' onClick={handleLogOut}>
				Log Out
			</Link>,
			null,
			<LogoutOutlined />,
		),
	];

	return (
		<Layout className='user-container'>
			{id === user?._id ? (
				<>
					<Sider collapsible collapsed={collapsed} onCollapse={onCollapse} theme='light'>
						<Menu theme='light' items={items} defaultSelectedKeys={type || 'user'} />
					</Sider>
					<Content className='user-content'>
						{/* <Row className='user-header'>
							<Col className='user-header__search'>
								<HeaderSearch className='header-search' />
							</Col>

							<Col className='user-header__menu'>
								<Col className='user-header__item'>
									<Icon type='bell' />
								</Col>
								<Col className='user-header__item'>
									<Icon type='heart' />
								</Col>
							</Col>

							<Col className='user-header__user'>
								<Dropdown overlay={menu} trigger={['click']}>
									<a onClick={e => e.preventDefault()}>
										{user.name}
										<DownOutlined />
									</a>
								</Dropdown>
							</Col>
						</Row> */}

						<Outlet />
					</Content>
				</>
			) : (
				<h1>User not found</h1>
			)}
		</Layout>
	);
};

export default memo(UserContent);
