import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser, loadTrips } from './../../../state/actions/UserActions';

const AppLoader = ({ children }) => {
	const dispatch = useDispatch();
	const { isLoggedIn } = useSelector(state => state.UserPage);
	useEffect(() => {

		if (isLoggedIn) {
			dispatch(loadUser());
			dispatch(loadTrips());
		}
	}, [isLoggedIn]);

	return children;
};

export default AppLoader;
