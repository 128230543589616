import React, { useMemo, useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Descriptions, Form, Input, Button, Select, Col, Row, Modal, Popover, Tooltip } from 'antd';
// import * as UserActions from '../../../state/actions/UserActions';
import { getMonthName } from '../../../../helpers/_Functions';
// import * as SearchActions from '../../../state/actions/SearchAction';
import { getSearchFromMapBox } from '../../../../libs/mapbox';
import { addUserCountry, addUserLocation, updateUser } from '../../../../state/actions/UserActions';
import { CalendarOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Settings from './Settings';
// import Icon from '@ant-design/icons';

const AccountContent = props => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	// const { user, trips = [],} = props;
	// const [edit, setEdit] = useState(false);
	const dispatch = useDispatch();
	const {
		user,
		trips = [],
		auth: { userId },
	} = useSelector(state => state.UserPage);
	useEffect(() => {
		if (user.location) {
			dispatch(addUserCountry({ lat: user.location.lat, long: user.location.long }));
		}
	}, [user.location]);

	const handleSubmit = values => {
		console.log(values);
		navigate(`/user/${userId}/`)
		dispatch(updateUser(values))
	};

	const getSightsCount = () => {
		return trips.reduce((curr, next) => curr + next.travel.route.reduce((curr, next) => curr + next.sights.length, 0), 0);
	};

	const sightsCount = useMemo(() => getSightsCount(), [trips]);
	console.log(sightsCount);

	const success = ({ coords }) => {
		dispatch(addUserLocation({ lat: coords.latitude, long: coords.longitude }));
		// const [data] = await getSearchFromMapBox(coords.latitude, coords.longitude);
		// console.log(data)

		// SearchActions.setSearchLocation(coords.latitude, coords.longitude, 'start', identifier, day);
	};
	const getLocation = () => {
		navigator.geolocation.getCurrentPosition(success);
	};
	console.log(userId, user);
	return (
		<Layout>
			{!pathname.includes('settings') ? (
				<>
					<Row type='flex'>
						<Col className='account-info'>
							<Descriptions
								title={
									<h5 className='account-title'>
										Personal Information
										<Link to={`/user/${userId}/settings`}>edit</Link>
									</h5>
								}
								layout='vertical'>
								<Descriptions.Item label='Name'>{user.name}</Descriptions.Item>
								<Descriptions.Item label='e-mail'>{user.email}</Descriptions.Item>
							</Descriptions>
						</Col>
						<Col className='account-intro'>
							<h5 className='account-title'>Intro</h5>

							<span>
								<CalendarOutlined /> Joined in {getMonthName(new Date(Number(user.createdAt)).getMonth())} {new Date(Number(user.createdAt)).getFullYear()}
							</span>
							<span>
								<Tooltip title='Change current location'>
									{/* <span onClick={getLocation} aria-hidden="true"> */}
									<Button icon={<span className='material-icons'>place</span>} onClick={getLocation} />
									{/* <Icon component={<span className='material-icons'>place</span>}  onClick={getLocation} /> */}
									{/* </span> */}
								</Tooltip>
								{user.location ? (
									<span>{user.country}</span>
								) : (
									<span className='account-location' onClick={getLocation} aria-hidden='true'>
										Add current location
									</span>
								)}
							</span>
						</Col>
					</Row>
					<Row>
						<Col className='account-activity'>
							<h5 className='account-title account-activity__title'>Activity</h5>
							<Col className='account-activity__card'>
								<p className='account-activity__card-title'>Trips</p>
								<p className='account-activity__card-text'>{trips.length}</p>
							</Col>
							<Col className='account-activity__card'>
								<p className='account-activity__card-title'>Sights</p>
								<p className='account-activity__card-text'>{sightsCount}</p>
							</Col>
						</Col>
					</Row>
				</>
			) : (
				<Settings />
			)}
		</Layout>
	);
};

export default memo(AccountContent);
