import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Typography, Row, Badge, Col, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { GetIcon } from '../../../../Helper';

import { changeTitle, checkDay, getColor } from '../../../../helpers/_Functions';
const { Title, Text } = Typography;

const HotelsContent = () => {
	const { hotels = [] } = useSelector(state => state.UserPage.user);
	console.log(hotels)
	return (
		<Layout className=''>
			<Title level={1}>Hotels</Title>
			{hotels !== null && hotels.length ? (
				<Row>
					{hotels.map(({ title, identifier, difficulty, hotels }) => (
						<Row className='hotels-cards' key={`cards-${identifier}`}>
							<Link to={`/trip/edit/${identifier}/${difficulty}/day/1/`} className='hotels-cards__trip'>
								<Title className='hotels-cards__trip-title' level={3}>
									{title}
								</Title>
								<Tag color={getColor(difficulty)}>{difficulty}</Tag>
							</Link>
							<Row className='hotels-card__hotels' gutter={[16,16]}>
								{hotels?.map(hotel => (
									<Col key={`hotel-${hotel.title}`} span={12}>
										<Badge.Ribbon text={checkDay(hotel.day)} className='hotels-card__hotel-badge'>
											<Link to={`/trip/edit/${identifier}/${difficulty}/day/${hotel.day}/`}>
												<Col className='hotels-card' key={identifier + hotel + hotel.day}>
													<Col lg={6} xs={6}>
														<GetIcon src='hotel.png' />
													</Col>
													<Col lg={18} xs={18}>
														<Title level={2} className='hotels-card__title'>
															{hotel.title}
														</Title>
														<a
															href={`https://www.google.com/maps/place/${hotel.location[1]},${hotel.location[0]}`}
															className='modify-link'
															target='_blank'
															rel='nofollow noopener noreferrer'>
															<span className='material-icons modify-link__marker'>place</span>
															<Text className='modify-link__text'>
																{hotel.location[1]}, {hotel.location[0]}
															</Text>
														</a>
													</Col>
												</Col>
											</Link>
										</Badge.Ribbon>
									</Col>
								))}
							</Row>
						</Row>
					))}
				</Row>
			) : (
				<Title level={1}>Hotels list is empty</Title>
			)}
		</Layout>
	);
};

export default HotelsContent;
