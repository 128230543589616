import React, { useState } from 'react';
import { Dropdown, Tooltip, Space, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ShareAltOutlined } from '@ant-design/icons';
import { controls } from './../../../../constants/Constants';
import TravelShareLink from './../TravelShare/TravelShareLink';
import {
	FacebookShareButton,
	RedditShareButton,
	TelegramShareButton,
	TwitterShareButton,
	ViberShareButton,
	WhatsappShareButton,
	EmailIcon,
	FacebookIcon,
	RedditIcon,
	TelegramIcon,
	TwitterIcon,
	ViberIcon,
	WhatsappIcon,
} from 'react-share';
import { shareURLChanged } from '../../../../state/reducers/CustomizeTripReducer';
import { useLocation } from 'react-router-dom';

const Share = () => {
	const dispatch = useDispatch();
	const { travel, shareURL } = useSelector(state => state.CustomizeTripPage);
	const Search = useSelector(state => state.Search);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const handleChangeVisible = visibility => setIsModalVisible(visibility);
	const { pathname } = useLocation();
	const subtitleH2 = travel.subtitle.length ? `${travel.subtitle}. Day-by-day itinerary. Details and routes. ` : 'Day-by-day itinerary. Details and routes. ';
	const titleH1 = travel.title.length ? travel.title : 'Travel';
	const shareTitle = `${subtitleH2}`;
	const shareBody = `${subtitleH2} Link:`;

	const getShareUrl = () => {
		const filterUrl = [];
		controls.map(
			element =>
				Search[element] &&
				Search[element].forEach(item => {
					const index = filterUrl.findIndex(el => el.day === item.day);
					if (filterUrl && filterUrl.find(el => el.day === item.day)) {
						filterUrl[index][element] = { text: item.data.text, x: item.data.x, y: item.data.y };
					} else {
						filterUrl.push({ day: item.day, [element]: { text: item.data.text, x: item.data.x, y: item.data.y } });
					}
				}),
		);
		console.log(filterUrl);
		filterUrl.length
			? dispatch(shareURLChanged(`https://www.before.voyage${pathname}#${encodeURIComponent(JSON.stringify(filterUrl))}`))
			: dispatch(shareURLChanged(`https://www.before.voyage${pathname}`));
	};

	const items = [
		{
			key: '1',
			label: (
				<Tooltip title='Get link'>
					<Button
						onClick={() => {
							handleChangeVisible(true);
							getShareUrl();
						}}>
						<span className='material-icons link-btn'>link</span>
					</Button>
				</Tooltip>
			),
		},
		{
			key: '2',
			label: (
				<a href={`mailto:before.voyage@gmail.com?subject=${titleH1}&body=${shareBody + ' ' + shareURL}`} target='_blank' rel='nofollow noopener noreferrer'>
					<EmailIcon size={38} />
				</a>
			),
		},
		{
			key: '3',
			label: (
				<FacebookShareButton url={shareURL} quote={shareTitle} hashtag='travel, trip, before_voyage'>
					<FacebookIcon size={38} />
				</FacebookShareButton>
			),
		},
		{
			key: '4',
			label: (
				<TwitterShareButton url={shareURL} title={shareTitle} hashtags={['travel', 'trip', 'before_voyage']}>
					<TwitterIcon size={38} />
				</TwitterShareButton>
			),
		},
		{
			key: '5',
			label: (
				<TelegramShareButton url={shareURL} title={shareTitle}>
					<TelegramIcon size={38} />
				</TelegramShareButton>
			),
		},
		{
			key: '6',
			label: (
				<WhatsappShareButton url={shareURL} title={shareTitle} separator=':: '>
					<WhatsappIcon size={38} />
				</WhatsappShareButton>
			),
		},
		{
			key: '7',
			label: (
				<ViberShareButton url={shareURL} title={shareTitle}>
					<ViberIcon size={38} />
				</ViberShareButton>
			),
		},
		{
			key: '8',
			label: (
				<RedditShareButton url={shareURL} title={shareTitle} windowWidth={660} windowHeight={460}>
					<RedditIcon size={38} />
				</RedditShareButton>
			),
		},
	];

	return (
		<>
			<Dropdown menu={{ items }} trigger={['click']} className='btn-share'>
				<Tooltip title='Share trip'>
					<ShareAltOutlined size={35} />
				</Tooltip>
			</Dropdown>
			<TravelShareLink isModalVisible={isModalVisible} shareUrl={shareURL} onChangeVisible={handleChangeVisible} />
		</>
	);
};

export default Share;
