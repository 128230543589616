import React from 'react';
import { Row, Col, Typography} from 'antd';
import { MailFilled } from '@ant-design/icons';
import './Footer.sass';

const { Title } = Typography;

const Footer = () => {
    return (
        <footer className="footer-container">
            <Row>
                <Col lg={6} md={6} sm={12} xs={4} className="footer-container_home">
                    <Title level={4}> <a href='/'>Home</a></Title>
                </Col>

                <Col lg={6} md={6} sm={12} xs={8} className="footer-container_sitemap">
                    <Title level={4}> <a href='/sitemap.html'>Sitemap</a></Title>
                </Col>

                <Col lg={6} md={6} sm={12} xs={12} className="footer-container_terms">
                    <Title level={4}><a href='/Terms-And-Conditions'>Terms and conditions</a></Title>
                </Col>

                <Col lg={6} md={6} sm={12} xs={24} className="footer-container_mail">
                    <Title level={4}><MailFilled />before.voyage@gmail.com</Title>
                </Col>
            </Row>
        </footer>
    );
};

export default Footer;
