import localStorageService from './../../services/localStorage.service';
import { showMessage } from './HelperActions';
import userService from './../../services/user.service';
import travelService from './../../services/travel.service';
import { nanoid } from 'nanoid';
import { getSearchFromMapBox } from '../../libs/mapbox';
import {
	userTripsReceived,
	userReceived,
	userRequested,
	userFavoritesReceived,
	userFavoritesFailed,
	userFavoriteTravelsReceived,
	userTripAdded,
	userTripUpdated,
	userTripDeleted,
	userTripsRequested,
	userTripsRequestFailed,
	userRequestFailed,
	userHotelAdded,
	userLocationAdded,
	userCountryAdded,
	userInfoUpdated,
} from '../reducers/UserReducer';
import { openNotification } from './../../components/common/Notification/Notification';

export const loadUser = () => async dispatch => {
	dispatch(userRequested());
	try {
		const data = await userService.getCurrentUser();
		if (data) {
			dispatch(userReceived(data));
		} else {
			dispatch(userRequestFailed(data));
		}
	} catch ({ message }) {
		dispatch(userRequestFailed(message));
	}
};

export const updateUser = payload => async dispatch => {
	try {
		const data = await userService.update(payload);
		console.log(data);
		dispatch(userInfoUpdated(data));
		// dispatch({ type: GET_USER_INFO, payload: data });
	} catch (error) {
		const { code, message } = error.response.data.error;
		dispatch(showMessage({ identifier: 'update', message }));
	}
};

export const loadFavoritesTravels = payload => async dispatch => {
	try {
		const data = await Promise.all(
			payload.map(async element => {
				const params = { filter: { identifier: element.identifier, difficulty: element.difficulty }, sort: { rating: -1, identifier: 1 } };
				const [data] = await travelService.get(params);
				return data;
			}),
		);
		dispatch(userFavoriteTravelsReceived(data));
	} catch (error) {
		dispatch(userFavoritesFailed('error in get favorites'));
	}
};

export const addToFavorites = travel => async (dispatch, getState) => {
	const { favorites = [] } = getState().UserPage.user;

	const travelExist = (favorites && favorites.find(favoriteTravel => favoriteTravel.identifier === travel.identifier && favoriteTravel.difficulty === travel.difficulty)) || false;

	try {
		const newFavorites = travelExist
			? favorites.filter(favoriteTravel => !(favoriteTravel.identifier === travel.identifier && favoriteTravel.difficulty === travel.difficulty))
			: favorites
			? [...favorites, travel]
			: [travel];
		const { favorites: data } = await userService.addToFavorites({ favorites: newFavorites });

		dispatch(userFavoritesReceived(data));
	} catch (error) {
		dispatch(userFavoritesFailed('error in favorites'));
	}
};

export const addUserHotel = data => async (dispatch, getState) => {
	const newData2 = {
		identifier: data.identifier,
		difficulty: data.difficulty,
		title: data.title,
		daysNumber: data.daysNumber,
		hotels: [data.hotel],
	};
	const { hotels = [] } = getState().UserPage.user;
	const tripExisting = hotels && hotels.find(({ identifier, difficulty }) => identifier === data.identifier && difficulty === data.difficulty);
	// // const travelExist = (hotels && hotels.find(favoriteTravel => favoriteTravel.identifier === hotel.identifier && favoriteTravel.difficulty === hotel.difficulty)) || false;
	const hotelExist = tripExisting && tripExisting.hotels.find(({ title, day }) => data.hotel.day === day && title === data.hotel.title);
	console.log(tripExisting, hotelExist, hotels);
	const newData =
		tripExisting || hotelExist
			? hotels.map(item => {
					if (item.identifier === data.identifier && item.difficulty === data.difficulty) {
						if (hotelExist) {
							console.log(
								hotelExist,
								item.hotels.filter(({ day, title }) => !(data.hotel.day === day && title === data.hotel.title)),
							);
							return { ...item, hotels: item.hotels.filter(({ day, title }) => !(data.hotel.day === day && title === data.hotel.title)) };
						}
						return { ...item, hotels: [...item.hotels, data.hotel] };
					}
					return item;
			  })
			: hotels.length
			? [...hotels, newData2]
			: [newData2];
	console.log(newData);
	try {
		const data2 = await userService.addHotel({ hotels: newData });
		console.log(data2);
		dispatch(userHotelAdded(data2.hotels));
	} catch ({ message }) {
		console.log(message);
	}
};

export const loadTrips = () => async dispatch => {
	dispatch(userTripsRequested());
	try {
		const data = await userService.getTrips(localStorageService.getUserId());
		dispatch(userTripsReceived(data));
	} catch ({ message }) {
		dispatch(userTripsRequestFailed(message));
	}
};

export const saveTrip =
	(data, message = 'Trip saved!') =>
	async (dispatch, getState) => {
		const { travel } = data;
		const { _id } = getState().UserPage.user;
		const { trips = [] } = getState().UserPage;
		try {
			const trip = trips.length && trips.find(({ travel: trip }) => trip.identifier === travel.identifier && trip.difficulty === travel.difficulty);
			if (!trip) {
				const newTrip = {
					...data,
					created_at: Date.now(),
					userId: _id,
					_id: nanoid(),
				};
				const content = await userService.addTrip(newTrip);

				dispatch(userTripAdded(content));
				openNotification(message);
				// dispatch(travelSaved('Trip saved!'));
				// dispatch(showMessage({ identifier: 'trip', message: 'Trip saved!' }));
			} else {
				const content = await userService.updateTrip({ ...trip, ...data });
				dispatch(userTripUpdated(content));
				openNotification(message);
				// dispatch(travelSaved('Trip updated!'));
				// dispatch(showMessage({ identifier: 'trip', message: 'Trip updated!' }));
			}
		} catch (error) {
			console.log(error);
		}
	};

export const deleteTripHotels = (identifier, difficulty, days) => async (dispatch, getState) => {
	const { hotels } = getState().UserPage.user;
	try {
		const newHotels = hotels.filter(item => !(item.identifier === identifier && item.difficulty === difficulty && item.daysNumber === days));
		console.log(newHotels, hotels);
		// const trip = trips.find(({ userId }) => userId === id) || {};
		// console.log(trip); ---> {}
		// const trip
		const data = await userService.updateTripHotels({ hotels: newHotels}); // ---> [{}, {}]
		dispatch(userHotelAdded(data))
	} catch (e) {
		console.log(e);
	}
};

export const deleteTrip = (id, identifier, difficulty, days) => async (dispatch, getState) => {
	// const trips = getState().UserPage.trips;
	try {
		// const trip = trips.find(({ userId }) => userId === id) || {};
		// console.log(trip); ---> {}
		const data = await userService.deleteTrip(id);
		if (data === null) {
			dispatch(userTripDeleted(id));
			dispatch(deleteTripHotels(identifier, difficulty, days));
		}
	} catch (error) {
		console.log(error);
	}
};

export const addUserLocation = location => async dispatch => {
	try {
		const status = await userService.addLocation({ location });
		if (status === 200) {
			dispatch(userLocationAdded(location));
		}
	} catch (error) {
		console.log(error);
	}
};

export const addUserCountry = location => async dispatch => {
	try {
		console.log(location);
		const [data] = await getSearchFromMapBox(location.lat, location.long);
		console.log(data);
		dispatch(userCountryAdded(data.label));
	} catch (error) {
		console.log(error);
	}
};
