import React from 'react';
import { Col, Typography } from 'antd';
import { informationsTabs } from '../../../constants/Information';

const { Title, Text } = Typography;

const TravelsInformation = () => {
	return (
		<>
			{informationsTabs.map(({ number, text }, key) => (
				<Col lg={6} md={6} xs={12} sm={12} style={{ textAlign: 'center' }} key={`tabs-${key}`}>
					<p>{number}</p>
					<Title level={3}>{text}</Title>
					<Text className='zeropage-informations__tabs-subtitle'>{number}</Text>
				</Col>
			))}
		</>
	);
};

export default TravelsInformation;
