import React from 'react';
import {
    GET_TRAVELS_FOR_TRAVELINFO, GET_TRAVEL_FOR_TRAVELINFO, GET_SIGHTS_FOR_TRAVELINFO, GET_MORE_SIGHTS_NEAR_TRAVELINFO, DELETE_MORE_SIGHTS_NEAR_TRAVELINFO, ADD_SIGHT_TRAVELINFO, GET_ROUTE_TRAVELINFO, DELETE_SIGHT_TRAVELINFO,
    GET_TRAVELINFO_FULFILLED, ADD_SIGHTS, DELETE_SIGHT, MOVE_SIGHT, CHANGE_ROUTE_FULFILLED
} from '../../constants/ActionTypes';
import { deepClone } from '../../helpers/_Object';
import {openNotification} from '../../components/common/Notification/Notification';


// INITIALIZE STATE
const initialState = {
    travel: [],
    travels: [],
    sights: [],
    routes: [],
    sightsNear: [],
};

// REDUCER
export const TravelInfoReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_TRAVELS_FOR_TRAVELINFO:
            return {
                ...state,
                travels: action.payload
            };
        case GET_TRAVEL_FOR_TRAVELINFO:
            return {
                ...state,
                travel: action.payload
            };
        case GET_SIGHTS_FOR_TRAVELINFO:
            return {
                ...state,
                sights: action.payload.sights,
                routes: action.payload.routes
            };
        case GET_ROUTE_TRAVELINFO: 
            return {
                ...state,
                routes: action.payload
            };
        case GET_MORE_SIGHTS_NEAR_TRAVELINFO:
            return {
                ...state,
                sightsNear: [...state.sightsNear, action.payload]
            };
        case DELETE_MORE_SIGHTS_NEAR_TRAVELINFO:
            return {
                ...state,
                sightsNear: state.sightsNear.filter(item => (item.day !== action.payload.day && item.identifier === action.payload.identifier))
            };
        case ADD_SIGHT_TRAVELINFO:{
            const {
                sight,
                travel,
                routes
            } = action.payload;
            return {
                ...state,
                travel,
                routes,
                sights: [...state.sights, sight],
            };
        }
        case DELETE_SIGHT_TRAVELINFO: {
            const {
                routes,
                travel,
                day,
                identifier
            } = action.payload;
            console.log(action.payload);
            return {
                ...state,
                sights: state.sights.filter(sight => (sight.identifier !== identifier && sight?.day !== day)),
                routes,
                travel
            };
        }






        case GET_TRAVELINFO_FULFILLED: {
            let newState = deepClone(state);
            newState = {...action.payload};
            return newState;
        }

        case ADD_SIGHTS: {
            console.log('ADD_SIGHTS', action.payload);
            let newState = deepClone(state);
            let {
                sights,
                travels,
                travel,
                travelInfo
            } = newState;
            let {
                add = {},
                index = 0,
                day = 0,
            } = action.payload;
            console.log(newState)
            // let flag = day === -1;
            // if (index === -1) {
            //     index = sights.length;
            // }
            sights.push(add);
            travels.forEach(item => {
                if(Number(item.daysNumber) === day){
                    item.route[day-1].sights.splice(index, 0, add.identifier);
                }
            });
            if(index === -1){
                travel.route[day-1].sights.push(add.identifier);
                let currPos = travel.route[day-1].sights.length-1;
                travelInfo.route[day-1].sights.splice(currPos, 0, add.identifier);
            } else {
                travelInfo.route[day-1].sights.splice(index, 0, add.identifier);
            }
            // let del = sights.findIndex(item => item.identifier === add.identifier && item.day === add.day);
            // console.log(del)
            // if (del !== -1) {
            //     sights.push(add);
            // }else{
            //     sights.push(add);
            // }
            
            // travels.forEach(item => {
            //     if (day === -1) {
            //         day = item.route.length - 1;
            //     }
            //     let prev = item.route[day-1].sights.findIndex(sight => sight === add.identifier);
            //     if (prev !== -1) {
            //         item.route[day-1].sights.splice(prev, 1);
            //     }
            // });
            // travels.forEach((item) => {
            //     if (day === -1) {
            //         day = item.route.length - 1;
            //     }
            //     item.route[day-1].sights.splice(index, 0, add.identifier);
            //     if (flag) {
            //       day = -1;
            //     }
            // });
            // console.log(index)
            // index === -1 && travel.route[day-1].sights.splice(index, 0, add.identifier); // addSight
            // travelInfo.route[day-1].sights.splice(index, 0, add.identifier);
            
            // newState.travelInfo.route[day-1].sights.splice(index, 0, add.identifier);
            // newState.travel.route[day-1].legs.splice(index, 0, {distance});
            // nextDayDistance && newState.travelInfo.route[day] ? newTravel.route[day].legs.splice(0, 1, {distance: nextDayDistance}) : null;
            // console.log(nextDayDistance)
            //replace  nextDayDistance && newTravel.route[day] ? newTravel.route[day].legs.splice(0, 1, {distance})
            // newState.travel = newTravel;
            // newState.travels = newTravels;
            console.log('newState end', newState);
            return newState;
        }

        case CHANGE_ROUTE_FULFILLED: {
            console.log('change route', action.payload);
            let newState = deepClone(state);
            newState.travelInfo.route = action.payload;
            return {
                ...state,
                travelInfo: {...state.travelInfo, route: action.payload}
            }
        }

        case DELETE_SIGHT: {
            console.log('DELETE_SIGHT', action.payload);
            let newState = deepClone(state);
            let {
                type,
                identifier,
                day: currDay
            } = action.payload;

            let indexElSights = newState.sights.findIndex(item => item.subtitle === type && item.day === currDay);
           
            if (indexElSights !== -1) {
                newState.sights.splice(indexElSights, 1);
            }
            
            let indexElTravel = newState.travel.route[currDay-1].sights.findIndex(item => item === identifier);// del2 - index of element in array
            if(indexElTravel !== -1){
                newState.travel.route[currDay-1].sights.splice(indexElTravel, 1);
                // newState.travel.route[currDay-1].legs.splice(del2, 1);
            }

            const indexElInfoTravel = newState.travelInfo.route[currDay-1].sights.findIndex(item => item === identifier);
            if(indexElInfoTravel !== -1){
                newState.travelInfo.route[currDay-1].sights.splice(indexElInfoTravel, 1);

            }
        
            newState.travels.forEach(travel => {
               travel.route.forEach(day => {
                   let del = day.sights.findIndex(item => item === identifier);
                   if (del !== -1) {
                       day.sights.splice(del ,1);
                   }
               });
            });

            return newState;
        }
        case MOVE_SIGHT: {
            console.log('MOVE_SIGHT', action.payload);
            let newState = deepClone(state);
            let {
                move = '',
                day = -1,
                identifier = '',
            } = action.payload;
            if (move === 'left' || move === 'right') {
                let changeSightIndex = newState.travel.route[day - 1].sights.findIndex(item => item === identifier);
                // const changeSightIndetTravelInfo = newState.travelInfo.route[day - 1].sights.findIndex(item => item === identifier);
                console.log(changeSightIndex, move)
                if (changeSightIndex !== 0 && move === 'left') {
                    [newState.travel.route[day - 1].sights[changeSightIndex - 1], newState.travel.route[day - 1].sights[changeSightIndex]] =
                        [newState.travel.route[day - 1].sights[changeSightIndex], newState.travel.route[day - 1].sights[changeSightIndex - 1]];

                    // [newState.travelInfo.route[day-1].sights[changeSightIndetTravelInfo - 1], newState.travelInfo.route[day-1].sights[changeSightIndetTravelInfo]] =
                    //     [newState.travelInfo.route[day - 1].sights[changeSightIndetTravelInfo], newState.travelInfo.route[day - 1].sights[changeSightIndetTravelInfo - 1]]
                    openNotification(<span><span style={{color: '#00008B'}}>{newState.travel.route[day - 1].sights[changeSightIndex - 1]}</span> was moved earlier in the day.</span>);
                } else if (changeSightIndex !== newState.travel.route[day - 1].sights.length - 1 && move === 'right') {
                    [newState.travel.route[day - 1].sights[changeSightIndex], newState.travel.route[day - 1].sights[changeSightIndex + 1]] =
                        [newState.travel.route[day - 1].sights[changeSightIndex + 1], newState.travel.route[day - 1].sights[changeSightIndex]];

                    // [newState.travelInfo.route[day - 1].sights[changeSightIndetTravelInfo], newState.travelInfo.route[day - 1].sights[changeSightIndetTravelInfo + 1]] =
                    //     [newState.travelInfo.route[day - 1].sights[changeSightIndetTravelInfo + 1], newState.travelInfo.route[day - 1].sights[changeSightIndetTravelInfo]];
                    openNotification(<span><span style={{color: '#00008B'}}>{newState.travel.route[day - 1].sights[changeSightIndex + 1]}</span> was moved later in the day.</span>);
                } else {
                    openNotification('Sorry. Not possible.', 'error');
                }

                newState.travels.forEach(travel => {
                    let changeSightIndex = travel.route[day - 1].sights.findIndex(item => item === identifier);
                    if (changeSightIndex !== 0 && move === 'left') {
                        [travel.route[day - 1].sights[changeSightIndex - 1], travel.route[day - 1].sights[changeSightIndex]] =
                            [travel.route[day - 1].sights[changeSightIndex], travel.route[day - 1].sights[changeSightIndex - 1]];
                    } else if (changeSightIndex !== travel.route[day - 1].sights.length && travel.route[day - 1].sights[changeSightIndex] !== undefined && travel.route[day - 1].sights[changeSightIndex + 1] !== undefined && move === 'right') {
                        [travel.route[day - 1].sights[changeSightIndex], travel.route[day - 1].sights[changeSightIndex + 1]] =
                            [travel.route[day - 1].sights[changeSightIndex + 1], travel.route[day - 1].sights[changeSightIndex]];

                    }
                });
            }

            return newState;
        }
        // case GET_ROUTE_INFO: {
        //     console.log(action.payload)
        //     return {
        //         ...state,
        //         route: action.payload
        //     };
        // }

        default:
            return state;
    }
};
