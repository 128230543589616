import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Row, Col, Button, Typography } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import './ZeroPage.sass';

import { GetIcon } from '../../../Helper';
import BurgerMenu from '../../ui/Header/BurgerMenu';
import SearchForm from '../../ui/Search/SearchForm';
import Footer from '../../ui/Footer/Footer';
import { hashtagsWords, popularCities, recommendedTab } from '../../../constants/Information';
import { Loader } from '../../common/Loader/Loader';
import { loadCitiesTrips, loadHashtags, loadPopularDayTrips, loadRecommendedTrips } from './../../../state/actions/ZeroPageAction';

import HeaderSearch from '../../ui/Search/HeaderSearch';
import Logo from '../../../img/logo.svg';
import HashtagsList from '../../ui/HashtagsList';
import PageHelmet from '../../ui/hoc/PageHelmet';

import RecommendedTravels from './RecommendedTravels';
import TravelsInformation from './TravelsInformation';
import PopularTravels from './PopularTravels';
import CitiesTravels from './CitiesTravels';

const { Title, Text } = Typography;
const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	arrows: true,
	slidesPerRow: 3,
	initialSlide: 0,
	slidesToScroll: 1,
	nextArrow: <RightOutlined />,
	prevArrow: <LeftOutlined />,
	appendDots: function customDots (dots) {
		return (
			<div>
				<ul className='popular-dots'> {dots} </ul>
			</div>
		);
	},
	responsive: [
		{
			breakpoint: 480,
			settings: {
				slidesPerRow: 1,
				slidesToScroll: 1,
			},
		},
	],
};
const settingsCardList = {
	...settings,
	slidesPerRow: 1,
	dots: false,
};

const title = 'Itineraries - Find Popular Trips in North America.';
const description = 'Search for Popular Itineraries and Roadmaps in US and Canada - Choose the Best Trip Among Thousands of Options. Get Your Complete Travel Plan.';

function ZeroPage (props) {
	const { popularDayTrips, hashtags, recommendedTrips, citiesTrips, errors = {}, loading = {} } = useSelector(state => state.ZeroPage);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [menuOpen, setMenuOpen] = useState(false);
	const [hashtagLoading, setHashtagLoading] = useState(false);
	const [hashtagsList, setHashtags] = useState(hashtagsWords);
	const [hashtagsOpen, setHashtagsOpen] = useState(true);

	const handleStateChange = state => setMenuOpen(state.isOpen);

	const toggleMenu = () => setMenuOpen(!menuOpen);

	const changeRecommendedTravels = (key = 0) => {
		const type = recommendedTab[key];
		dispatch(loadRecommendedTrips(type));
	};

	const changeCitiesTravels = (key = 0) => {
		const city = popularCities[key].replace(/ +/g, '').trim();
		dispatch(loadCitiesTrips(city));
	};

	const getAllHashtags = () => {
		dispatch(loadHashtags());
		setHashtagsOpen(false);
		setHashtagLoading(true);
	};

	const redirect = () => {
		navigate('/map/itineraries/');
	};

	useEffect(() => {
		setHashtags(items => [...items, ...hashtags]);
		setHashtagLoading(false);
	}, [hashtags]);

	useEffect(() => {
		if (!recommendedTrips.length) {
			changeCitiesTravels();
			changeRecommendedTravels();
			dispatch(loadPopularDayTrips());
			setHashtags(hashtagsWords);
		}
	}, []);

	return (
		<PageHelmet title={title} description={description} canonicalUrl='/'>
			<header className='zeropage-header'>
				<BurgerMenu
					pageWrapId='page-wrap'
					outerContainerId='header'
					menuOpen={menuOpen}
					handleStateChange={handleStateChange}
					toggleMenu={toggleMenu}
					burgerMenuClass='burger-menu burger-menu__zeropage'
					{...props}
				/>
				<Row className='zeropage-header__content'>
					<Col className='zeropage-header__titles' xs={{ order: 1 }} md={{ order: 1 }} lg={{ order: 1, span: 24 }}>
						<Row className='logo zeropage-header__logo'>
							<img src={Logo} alt='before.voyage' className='zeropage-header__logo-img' />
						</Row>
						<Title level={1} className='zeropage-header__title'>
							Best ITINERARIES around the USA and Canada
							<Text>Hundreds of trips, must-see sights and viewpoints. Roadmaps.</Text>
						</Title>
					</Col>

					<Col className='zeropage-choice__btn' xs={{ order: 2, span: 24 }} md={{ order: 2 }} lg={{ order: 2, span: 4 }}>
						<Button type='primary' onClick={redirect}>
							Explore Map
						</Button>
					</Col>

					<Col className='zeropage-form' xs={{ order: 5, span: 24 }} md={{ order: 2 }} lg={{ order: 3, span: 12 }}>
						<SearchForm />
					</Col>

					<Col className='zeropage-header__subtitle' xs={{ order: 4 }} md={{ order: 3 }} lg={{ order: 4 }} xl={{ order: 4 }}>
						<Text className='zeropage-header__subtitle-text'>Detailed itineraries, well-tried and recommended by experienced travelers.</Text>
					</Col>

					<Col className='zeropage-recommended' xs={{ order: 3 }} md={{ order: 4 }} lg={{ order: 5 }}>
						<RecommendedTravels
							travels={recommendedTrips}
							loading={loading.recommendedTrips}
							message={errors.recommendedTrips}
							settings={settings}
							changeRecommendedTravels={changeRecommendedTravels}
						/>
					</Col>
				</Row>
			</header>

			<main className='zeropage-content'>
				<Row className='zeropage-informations'>
					<Title level={2} className='zeropage-title'>
						Plan your trip easily
					</Title>
					<Text className='zeropage-text'>Get maps, tips and recommendations, options of accommodations and airports.</Text>
					<Row className='zeropage-informations__tabs'>
						<TravelsInformation />
						<GetIcon src='bck-img.png' className='zeropage-informations__tabs-img' />
					</Row>
				</Row>

				<Row className='zeropage-daytravels'>
					<Title level={2} className='zeropage-title'>
						Travel plans for 1 day, a week-end, a week, a fortnight (and more options)
					</Title>
					<Text className='zeropage-text'>No matter how much time you have, we have travel plans for you.</Text>
					<PopularTravels loading={loading.popularDayTrips} travels={popularDayTrips} settings={settingsCardList} message={errors.popularDayTrips} />
				</Row>

				<Row className='zeropage-search'>
					<Title level={2} className='zeropage-title'>
						Search for a trip by a key word, a state/province, a geographical object, a national/state/provincial park, a sight, a landmark, etc.
					</Title>
					<HeaderSearch placeholder='e.g. California, Great Canyon, Lake Huron, ocean, glaciers, wildlife, etc.' className='zeropage-search__form' />
				</Row>

				<Row className='zeropage-popular'>
					<Title level={2} className='zeropage-title'>
						Browse through recommended trips within the chosen state/province
					</Title>
					<CitiesTravels travels={citiesTrips} message={errors.citiesTrips} loading={loading.citiesTrips} settings={settings} changeCitiesTravels={changeCitiesTravels} />
				</Row>

				<Row className='zeropage-hashtags'>
					<Title level={2} className='zeropage-title'>
						Keywords
					</Title>
					<Col>
						<HashtagsList hashtags={hashtagsList} className='zeropage-hashtag' />
						{hashtagLoading && <Loader />}
						{hashtagsOpen && !hashtags.length ? <Button onClick={getAllHashtags}>See all</Button> : false}
					</Col>
				</Row>
			</main>
			<Footer />
		</PageHelmet>
	);
}

export default ZeroPage;
