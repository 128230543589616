import { createSlice } from '@reduxjs/toolkit';

// INITIALIZE STATE
const initialState = {
	popularDayTrips: [],
	recommendedTrips: [],
	citiesTrips: [],
	hashtags: [],
	loading: {},
	errors: {},
};

const ZeroPageSlice = createSlice({
	name: 'ZeroPage',
	initialState,
	reducers: {
		popularDayTripsRequested: state => {
			state.loading.popularDayTrips = true;
		},
		popularDayTripsReceived: (state, action) => {
			state.loading.popularDayTrips = false;
			state.errors.popularDayTrips = null;
			state.popularDayTrips = Object.assign({}, ...action.payload.map(obj => obj));
		},
		popularDayTripsFailed: (state, action) => {
			state.loading.popularDayTrips = false;
			state.errors.popularDayTrips = action.payload;
		},
		citiesTripsRequested: state => {
			state.loading.citiesTrips = true;
		},
		citiesTripsReceived: (state, action) => {
			state.loading.citiesTrips = false;
			state.errors.citiesTrips = null;
			state.citiesTrips = action.payload;
		},
		citiesTripsFailed: (state, action) => {
			state.loading.citiesTrips = false;
			state.errors.citiesTrips = action.payload;
		},
		recommendedTripsRequested: state => {
			state.loading.recommendedTrips = true;
		},
		recommendedTripsReceived: (state, action) => {
			state.loading.recommendedTrips = false;
			state.errors.recommendedTrips = null;
			state.recommendedTrips = action.payload;
		},
		recommendedTripsFailed: (state, action) => {
			state.loading.recommendedTrips = false;
			state.errors.recommendedTrips = action.payload;
		},
		hashtagsRequested: state => {
			state.loading.hashtags = true;
		},
		hashtagsReceived: (state, action) => {
			state.loading.hashtags = false;
			state.errors.hashtags = null;
			state.hashtags = action.payload;
		},
		hashtagsFailed: (state, action) => {
			state.loading.hashtags = false;
			state.errors.hashtags = action.payload;
		},
	},
});

const { reducer: ZeroPageReducer, actions } = ZeroPageSlice;

export const {
	popularDayTripsRequested,
	popularDayTripsReceived,
	popularDayTripsFailed,
	citiesTripsRequested,
	citiesTripsReceived,
	citiesTripsFailed,
	recommendedTripsRequested,
	recommendedTripsReceived,
	recommendedTripsFailed,
	hashtagsRequested,
	hashtagsReceived,
	hashtagsFailed,
} = actions;
export { ZeroPageReducer };

// export const ZeroPageReducer = (state = initialState, action) => {
// 	switch (action.type) {
// 		case GET_POPULAR_DAY_TRIPS: {
//             let newArr = Object.assign({}, ...action.payload.map(obj => obj));
// 			return {
// 				...state,
// 				travels: newArr,
// 			};
// 		}
// 		case GET_ZEROPAGE_CITIES_TRAVELS:
// 			return {
// 				...state,
// 				citiesTravels: action.payload,
// 			};
// 		case GET_ZEROPAGE_RECOMMENDED_TRAVELS:
// 			return {
// 				...state,
// 				recommendedTravels: action.payload,
// 			};
// 		case GET_HASHTAGS:
// 			return {
// 				...state,
// 				hashtags: action.payload,
// 			};
// 		default:
// 			return state;
// 	}
// };
