import React from 'react';
import { useSelector } from 'react-redux';
import './UserContainer.sass';
import PageHelmet from '../../components/ui/hoc/PageHelmet';
import UserContent from './../../components/pages/UserPage/UserContent';
import Header from '../../components/ui/Header/Header';
import AuthContainer from '../Auth/AuthContainer';
import { Loader } from './../../components/common/Loader/Loader';

const UserContainer = () => {
	const { loading, isLoggedIn } = useSelector(state => state.UserPage);

	const title = 'title';
	const description = 'description';
	// console.log(isLoggedIn, loading.hasOwnProperty('user') && !loading.user, loading)
	return (
		<div className='user'>
			<PageHelmet title={title} description={description}>
				<Header />
				{isLoggedIn ? (loading.hasOwnProperty('user') && !loading.user ? <UserContent /> : <Loader />) : <AuthContainer />}
			</PageHelmet>
		</div>
	);
};

export default UserContainer;
