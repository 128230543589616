import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router';
import { Row, Col, Button, Affix, Dropdown, Typography, Tooltip, DatePicker, Popover, Checkbox } from 'antd';
import BreadcrumbComponent from '../../ui/Breadcrumb/Breadcrumb';
import { ShareAltOutlined, SaveOutlined, CalendarTwoTone, SettingOutlined, FullscreenOutlined, UnorderedListOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { animateScroll as scroll } from 'react-scroll';

import { getRoutes, getSights, getSightsNear, getTravel, getTravels } from '../../../state/reducers/CustomizeTripReducer';
import { changeTitle, secondsToHms, checkDay } from '../../../helpers/_Functions';
import ButtonIntensity from '../../ui/ButtonIntensity';
import { WindowSize } from './../../../helpers/WindowSize';
import DaysTabs from '../../ui/DaysTabs/DaysTabs';
import ErrorBoundaryTravelMap from './../../ErrorBoundary/ErrorBoundaryTravelMap';
import { addUserTripDate, saveTrip } from '../../../state/actions/UserActions';
import { GetIcon } from '../../../Helper';
import TravelShare from './TravelShare/TravelShare';
import TravelShareLink from './TravelShare/TravelShareLink';
import TravelControls from './TravelControls';
// import TravelModifyControls from './TravelModify/TravelModifyControls';
import { putSearchControl } from '../../../state/actions/SearchAction';
import TravelCards from '../../ui/TravelCards/TravelCards';
// import TravelModalCalendar from './TravelCalendar/TravelModalCalendar';
import debounce from 'lodash.debounce';
import useIsFirstRender from './../../hooks/useIsFirstRender';
import TripPlanner from './TripPlanner';
import moment from 'moment';
import { getUserTrip } from '../../../state/reducers/UserReducer';
import FullScreenCustomizeTripContent from './FullScreenCustomizeTripContent/FullScreenCustomizeTripContent';

import { DEBOUNCE_SAVE_DELAY_MS } from '../../../constants/Constants';
import PicsContribution from './PicsContribution';
import Share from './HeaderSettings/Share';
import Settings from './HeaderSettings/Settings';
import TravelMap from './TravelMap';

const { Title, Text } = Typography;
const ButtonGroup = Button.Group;
const { RangePicker } = DatePicker;

const CustomizeTripContent = ({ fullScreen, handleChangeScreen }) => {
	const { identifier, difficulty, type: day = '' } = useParams();
	const { hash, pathname } = useLocation();
	const navigate = useNavigate();
	const { width } = WindowSize();
	const dispatch = useDispatch();

	const { travel, travels, routes, sights, sightsNear } = useSelector(state => state.CustomizeTripPage);
	const { date = Date.now(), settings = {} } = useSelector(getUserTrip(identifier, difficulty, travel.daysNumber));

	const Search = useSelector(state => state.Search);
	const [container, setContainer] = useState(null);
	const [tab, setTab] = useState('List');
	// const [shareUrl, setShareUrl] = useState(`https://www.before.voyage${pathname}`);
	const [travelControls, setTravelControls] = useState({});
	// const [isModalVisible, setIsModalVisible] = useState(false);

	// const [isCalendarVisible, setCalendarVisible] = useState(false);
	const [tripRoute, setTripRoute] = useState({ totalDistance: 0, totalTime: 0 });
	const [routeLoading, setRouteLoading] = useState(true);

	const route = useMemo(() => (day === 'All' ? routes.find(route => route.daysOrder === day) : travel.route.find(route => route.daysOrder === day)), [day, routes, travel]);

	const difficulties = useMemo(() => travels.map(travel => travel.difficulty), [travels]);
	const routeSights = useMemo(() => routes.find(route => route.daysOrder === day).sights, [routes, day]);
	const allRoutesSights = useMemo(() => routes.find(route => route.daysOrder === 'All').sights, [routes]);
	const isFirst = useIsFirstRender();

	// need optimization
	const objSights = [];
	sights.map(sight => {
		objSights[sight.identifier] = sight;
	});
	// need optimization
	const changeRouteOnTab = activeKey => {
		scroll.scrollToTop({
			duration: 1500,
			smooth: true,
			containerId: 'travel-sights',
		});
		navigate(`/trip/edit/${travel.identifier}/${travel.difficulty}/day/${activeKey}/`);
		setRouteLoading(true);
	};

	const handleChangeTripRoute = route => {
		setTripRoute(prev => ({ totalDistance: prev.totalDistance + route.totalDistance, totalTime: prev.totalTime + route.totalTime }));
	};

	const handleChangeTab = type => setTab(type);

	const onChangeDate = (_, dateString) => {
		if (dateString) {
			dispatch(saveTrip({ travel, Search, routes, date: dateString, settings }));
		}
	};

	const saveTrips = () => {
		dispatch(saveTrip({ travel, routes, Search }));
	};

	const updateTravelControls = value => {
		const controls = ['airport', 'car', 'hotel', 'start', 'finish'];

		controls.forEach(control => {
			Object.keys(value).forEach(item => {
				if (item === control) {
					setTravelControls(prev => ({
						...prev,
						[control]:
							prev[control]?.includes(value[control]) ? prev[control].filter(el => el !== value[control]) : prev[control] ? [...prev[control], value[control]] : [value[control]],
					}));
				}
			});
		});
	};

	const getLinkToGoogle = (prev, curr) => {
		prev = sights.find(sight => sight.identifier === prev);
		curr = sights.find(sight => sight.identifier === curr);
		if (prev === undefined) {
			const prevDaySight = travel.route[day - 2].sights[travel.route[day - 2].sights.length - 1];
			prev = sights.find(sight => sight.identifier === prevDaySight);
		}
		if (prev && curr) {
			const route = `${prev.location[1]},+${prev.location[0]}/${curr.location[1]},+${curr.location[0]}`;
			window.open('https://www.google.com.ua/maps/dir/' + route, '_blank');
		}
	};

	const getRoute = () => {
		const route = [];
		travel.route[Number(day) - 1].sights.map((el, index) => {
			sights.filter(sight => {
				if (sight.identifier === el && sight.hasOwnProperty('images')) {
					if (index === 1) {
						route.push(`${sight.location[1]},${sight.location[0]}/`);
					} else if (index < 10 || index === travel.route[Number(day) - 1].sights.length - 1) {
						route.push(`${sight.location[1]},+${sight.location[0]}/`);
					}
				}
			});
		});

		window.open('https://www.google.com.ua/maps/dir/' + route.join(''), '_blank');
	};
	const getDataFromUrl = url => {
		const data = JSON.parse(decodeURIComponent(url));
		const controls = ['airport', 'car', 'hotel', 'start', 'finish'];
		controls.map(control =>
			data.map(el => {
				if (Object.prototype.hasOwnProperty.call(el, control)) {
					updateTravelControls({ [control]: el.day });
					setTravelControls(prev => ({ ...prev, [control]: true }));
					dispatch(
						putSearchControl({
							data: { label: el[control].text, text: el[control].text, x: el[control].x, y: el[control].y },
							searchType: control,
							identifier,
							day: el.day,
							url: true,
						}),
					);
				}
			}),
		);
	};

	const travelDistance = `${Math.floor(travel.distance / 1000)} km | ${Math.floor(travel.distance / 1609.344)} mi`;
	const dayDistance = day !== 'All' && `~${(tripRoute.totalDistance / 1000).toFixed(2)} km | ${(tripRoute.totalDistance / 1609.344).toFixed(2)} mi ~${secondsToHms(tripRoute.totalTime)}`;

	const disabledDate = current => {
		// Can not select days before today and today
		return current && current < moment().endOf('day');
	};

	useEffect(() => {
		if (hash) {
			const newHash = hash.split('#').pop().trim();
			getDataFromUrl(newHash);
		}
	}, [hash]);

	useEffect(() => setTripRoute({ totalDistance: 0, totalTime: 0 }), [day]);

	useEffect(() => {
		const controls = ['airport', 'car', 'hotel', 'start', 'finish'];

		controls.map(control => {
			if (Object.prototype.hasOwnProperty.call(Search, control) && Search[control]) {
				Search[control].map(item => {
					if (item.identifier === identifier && item.difficulty === difficulty) {
						updateTravelControls({ [control]: item.day });
					}
				});
			}
		});
	}, []);

	const saveData = useCallback(newData => {
		const message = 'Trip auto-saved.';
		dispatch(saveTrip(newData, message));
	}, []);

	const debouncedSave = useCallback(
		debounce(async newData => {
			saveData(newData);
		}, DEBOUNCE_SAVE_DELAY_MS),
		[],
	);

	useEffect(() => {
		if (!isFirst) {
			// autosaveTrip({ travel, routes, Search, settings: { autoSave: true } });
			debouncedSave({ travel, routes, Search, settings: { autoSave: true } });
		}
	}, [routes]);

	useEffect(() => {
		if (route) {
			setRouteLoading(false);
		}
	}, [route]);
	
	useEffect(() => {
		
	}, [])

	console.log(routeLoading);
	return (
		<>
			{!fullScreen ? (
				<>
					<Row className='travel-header'>
						<BreadcrumbComponent travel={travel} day={day} />
						<Row>
							<Col lg={12} md={12} sm={24} xs={24}>
								<Col className='travel-header__subinfo'>
									<Text>Day-by-day itinerary. Details and routes.</Text>
									<ButtonIntensity difficulties={difficulties} difficulty={difficulty} identifier={travel.identifier} day={day} />
								</Col>
								<Col className='travel-header__titles'>
									<Title level={1} className='travel-header__title'>
										{changeTitle(travel.title)}
									</Title>
									<Text className='card travel-header__day'>{checkDay(travel.daysNumber)}</Text>
								</Col>

								<Text className='travel-header__subtitle'>{travel.subtitle}</Text>
							</Col>

							<Col lg={12} md={12} sm={24} xs={24} className='travel-header__info'>
								<Col className='travel-header__calendar'>
									<Typography.Text className='travel-header__calendar-text'>
										<span className='material-icons travel-header__calendar-icon'>outlined_flag</span>Select start trip day:
									</Typography.Text>
									{/* <RangePicker
										defaultValue={[moment(date), moment(moment(date).add(travel.daysNumber - 1, 'days'))]}
										disabled={[false, true]}
										onChange={onChangeDate}
										disabledDate={disabledDate}
									/> */}
									<DatePicker onChange={onChangeDate} defaultValue={moment(date)} disabledDate={disabledDate} />
									<Col>
										<Typography.Text className='travel-header__calendar-text'>
											<span className='material-icons travel-header__calendar-icon'>check_circle_outline</span>Finish at:{' '}
											{moment(date)
												.add(travel.daysNumber - 1, 'days')
												.format('YYYY-MM-DD')}
										</Typography.Text>
									</Col>
								</Col>
								<Col className='travel-header__info-distance'>
									<Col className='card travel-header__info-distance__trip'>
										<Text className='travel-header__info-title'>
											<i className='material-icons'>route</i> Trip Distance:
										</Text>
										<Text className='travel-header__info-subtitle'>{travelDistance}</Text>
									</Col>
									{day !== 'All' && (
										<Col className='card travel-header__info-distance__day'>
											<Text className='travel-header__info-title'>
												<i className='material-icons'>drive_eta</i> Day {day} Distance:
											</Text>
											<Text className='travel-header__info-subtitle'>{dayDistance}</Text>
										</Col>
									)}
								</Col>
								<Col className={`${!settings.autoSave ? 'save-options' : ''} travel-header__options`}>
									<Share />
									<Settings />
									<Tooltip title='Full screen Moderate Trip'>
										<Button className='btn-options' icon={<FullscreenOutlined size={35} />} onClick={handleChangeScreen} />
									</Tooltip>
									{!settings.autoSave && (
										<Tooltip title='Save trip'>
											<Button onClick={saveTrips} className='btn-save travel-header__btn-save'>
												<SaveOutlined size={35} />
											</Button>
										</Tooltip>
									)}
								</Col>
							</Col>
						</Row>
					</Row>

					<main className='travel-content'>
						{width < 769 && (
							<ButtonGroup className='days-menu--mobile'>
								<Col xs={12}>
									<Button className='days-menu__button' onClick={() => handleChangeTab('List')}>
										<UnorderedListOutlined className={tab === 'List' ? 'primary' : 'default'} />
										<span className={`days-menu__button-text ${tab === 'List' ? 'primary' : 'default'}`}>List</span>
									</Button>
								</Col>
								<Col xs={12}>
									<Button className='days-menu__button' onClick={() => handleChangeTab('Map')}>
										<EnvironmentOutlined className={tab === 'Map' ? 'primary' : 'default'} />
										<span className={`days-menu__button-text ${tab === 'Map' ? 'primary' : 'default'}`}>Map</span>
									</Button>
								</Col>
							</ButtonGroup>
						)}
						{(tab === 'List' || width > 769) && (
							<Row className='travel-sights'>
								{day !== 'All' && (
									<Row className='travel-settings'>
										<Row className='travel-controls'>
											{/* <Affix offsetTop={50}> */}
											<TravelControls
												day={day}
												identifier={identifier}
												travel={travel}
												routes={routes}
												sightsNear={sightsNear}
												sights={sights}
												travelControls={travelControls}
												width={width}
												updateTravelControls={updateTravelControls}
												handleChangeTab={handleChangeTab}
											/>
											{/* </Affix> */}
										</Row>
										<Col className='travel-settings__controls'>
											<Tooltip title='Routes on google.maps'>
												<Button
													className='travel-header__btn-map'
													shape='circle'
													onClick={getRoute}
													icon={<GetIcon src='google-map.svg' className='travel-header__btn-map__icon' />}
												/>
											</Tooltip>
											{/* <Tooltip title='Trip dates'>
										<Button className='travel-header__btn-calendar' shape='circle' onClick={() => onChangeCalendarVisible(true)} icon={<CalendarTwoTone />} />
									</Tooltip> */}
										</Col>
									</Row>
								)}

								<Row className='travel-sights__content' id='travel-sights'>
									{tab !== 'Map' && (
										<Col xxl={2} lg={5} md={5} sm={24} xs={24} className='travel-sights__days'>
											<DaysTabs routes={travel.route} changeRouteOnTab={changeRouteOnTab} date={date} />
										</Col>
									)}

									<Col className='travel-sights__cards'>
										<Row>
											{day === 'All' ? (
												routes.slice(0, -1).map(route => (
													<div key={route.daysOrder} className='travel-sights__cards-day' ref={setContainer}>
														<Affix target={() => container}>
															<h5>Day {route.daysOrder}</h5>
														</Affix>
														<TravelCards
															routes={routes}
															objSights={objSights}
															day={route.daysOrder}
															route={route}
															identifier={identifier}
															routeLoading={routeLoading}
															getLinkToGoogle={getLinkToGoogle}
														/>
													</div>
												))
											) : (
												<TripPlanner
													routeSights={routeSights}
													travelControls={travelControls}
													getLinkToGoogle={getLinkToGoogle}
													objSights={objSights}
													updateTravelControls={updateTravelControls}
													day={day}
												/>
											)}
										</Row>
										<PicsContribution objSights={objSights} route={route} />
									</Col>
								</Row>
							</Row>
						)}
						{(tab === 'Map' || width > 769) && routeSights && (
							<ErrorBoundaryTravelMap page='TravelMap'>
								<TravelMap
									handleChangeTripRoute={handleChangeTripRoute}
									className='travel-map'
									sights={sights}
									sightsNear={sightsNear}
									routeSights={routeSights}
									day={day}
									identifier={identifier}
									allRoutesSights={allRoutesSights}
									// waypoint={waypoint}
								/>
							</ErrorBoundaryTravelMap>
						)}
						{/* <TravelShareLink isModalVisible={isModalVisible} shareUrl={shareUrl} onChangeVisible={handleChangeVisible} /> */}
						{/* <TravelModalCalendar isCalendarVisible={isCalendarVisible} onChangeCalendarVisible={onChangeCalendarVisible} /> */}
					</main>
				</>
			) : (
				<FullScreenCustomizeTripContent
					routeSights={routeSights}
					objSights={objSights}
					getLinkToGoogle={getLinkToGoogle}
					date={date}
					route={route}
					travelDistance={travelDistance}
					dayDistance={dayDistance}
					handleChangeScreen={handleChangeScreen}
					changeRouteOnTab={changeRouteOnTab}
					travelControls={travelControls}
					updateTravelControls={updateTravelControls}
					handleChangeTab={handleChangeTab}
					allRoutesSights={allRoutesSights}
					handleChangeTripRoute={handleChangeTripRoute}
				/>
			)}
		</>
	);
};

export default CustomizeTripContent;
