import localStorageService from './localStorage.service';
import configFile from '../config.json';
import authService from './auth.service';
import axios from 'axios';

const http = axios.create({
	baseURL: configFile.apiEndpoint_FireBase,
});

http.interceptors.request.use(
	async function (config) {
		if (configFile.isFireBase) {
			const containSlash = /\/$/gi.test(config.url);
			config.url = (containSlash ? config.url.slice(0, -1) : config.url) + '.json';
			const expiresDate = localStorageService.getTokenExpiresDate();
			const refreshToken = localStorageService.getRefreshToken();
			if (refreshToken && expiresDate < Date.now()) {
				const data = await authService.refresh();

				localStorageService.setTokens({
					refreshToken: data.refresh_token,
					idToken: data.id_token,
					expiresIn: data.expires_in,
					localId: data.user_id,
				});
			}
			const accessToken = localStorageService.getAccessToken();
			if (accessToken) {
				config.params = { ...config.params, auth: accessToken };
			}
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	},
);

const userEndpoint = 'users/';
const tripsEndpoint = 'trips/';

const userService = {
	get: async () => {
		const { data } = await http.get(userEndpoint);
		return data;
	},
	create: async payload => {
		const { data } = await http.put(userEndpoint + payload._id, payload);
		return data;
	},
	getCurrentUser: async () => {
		const { data } = await http.get(userEndpoint + localStorageService.getUserId());
		return data;
	},
	update: async payload => {
		const { data } = await http.patch(userEndpoint + localStorageService.getUserId(), payload);
		return data;
	},
	addToFavorites: async payload => {
		const { data } = await http.patch(userEndpoint + localStorageService.getUserId(), payload);
		return data;
	},
	addHotel: async payload => {
		const { data } = await http.patch(userEndpoint + localStorageService.getUserId(), payload);
		return data;
	},
	getTrips: async id => {
		const { data } = await http.get(tripsEndpoint, {
			params: {
				orderBy: '"userId"',
				equalTo: `"${id}"`,
			},
		});
		return [...Object.values(data)];
	},
	addTrip: async payload => {
		const { data } = await http.put(tripsEndpoint + payload._id, payload);
		return data;
	},
	updateTrip: async payload => {
		const { data } = await http.patch(tripsEndpoint + payload._id, payload);
		return data;
	},
	deleteTrip: async id => {
		console.log(id);
		const { data } = await http.delete(tripsEndpoint + id);
		return data;
	},
	updateTripHotels: async payload => {
		const { data } = await http.patch(userEndpoint + localStorageService.getUserId(), payload);
        console.log(data)
		return data.hotels;
	},
	addLocation: async payload => {
		const { status } = await http.patch(userEndpoint + localStorageService.getUserId(), payload);
		return status;
	},
};
export default userService;
