// import {
import { createSlice } from '@reduxjs/toolkit';
//     GET_TRAVELS_FOR_SIGHT,
//     GET_SIGHT_INFO,
//     GET_SIGHTS_NEAR,
//     GET_SIGHT_TRAVEL
// } from '../../constants/ActionTypes';

// INITIALIZE STATE
const initialState = {
    sight: {},
    travels: [],
    sights: [],
    travel: {},
    loading: {},
    errors: {}
};

const SightSlice = createSlice({
    name: 'SightPage',
    initialState,
    reducers: {
        sightRequested: state => {
            state.loading.sight = true;
        },
        sightReceived: (state, action) => {
            state.sight = action.payload;
            state.loading.sight = false;
            state.errors.sight = null;
        },
        sightRequestFailed: (state, action) => {
            state.loading.sight = false;
            state.errors.sight = action.payload;
        },
        travelSightRequested: state => {
            state.loading.travel = true;
        },
        travelSightReceived: (state, action) => {
            state.loading.travel = false;
            state.travel = action.payload;
            state.errors.travel = null;
        },
        travelSightRequestFailed: (state, action) => {
            state.loading.travel = false;
            state.errors.travel = action.payload;
        },
        travelsForSightRequested: state => {
            state.loading.travels = true;
        },
        travelsForSightReceived: (state, action) => {
            state.loading.travels = false;
            state.travels = action.payload;
            state.errors.travels = null;
        },
        travelsForSightRequestFailed: (state, action) => {
            state.loading.travels = false;
            state.errors.travels = action.payload;
        },
        sightsNearRequested: state => {
            state.loading.sights = true;
        },
        sightsNearReceived: (state, action) => {
            state.loading.sights = false;
            state.sights = action.payload;
            state.errors.sights = null;
        },
        sightsNearRequestFailed: (state, action) => {
            state.loading.sights = false;
            state.errors.sights = action.payload;
        },
    }
});

const {actions, reducer: SightReducer} = SightSlice;

export const {
    sightRequested,
    sightReceived,
    sightRequestFailed,
    travelSightRequested,
    travelSightReceived,
    travelSightRequestFailed,
    travelsForSightRequested,
    travelsForSightReceived,
    travelsForSightRequestFailed,
    sightsNearRequested,
    sightsNearReceived,
    sightsNearRequestFailed
} = actions;

export {SightReducer};