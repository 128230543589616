import React, { Fragment, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, Button, Modal, Col } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, ConsoleSqlOutlined } from '@ant-design/icons';

// import * as TravelsAndSightsActions from '../../../../state/actions/TravelsAndSightsActions';
import { openNotification } from '../../../components/common/Notification/Notification';
import { deleteSight, moveSight } from '../../../state/actions/CustomizeTripActions';

const MoveControlButtons = props => {
	const { sight = '', day = -1, identifier = '', allDays = -1, sights = [] } = props;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const dispatch = useDispatch();
	const showModal = () => {
		setIsModalOpen(true);
	};

	const cancelModal = () => {
		setIsModalOpen(false);
	};

	const move = move => {
		// console.log(day, sight, allDays,  move, props);
		const sightIndex = sights.findIndex(el => el === sight);
		if (move === 'up' || move === 'down') {
			if (sightIndex === 0 && day !== 1) {
				// dispatch(deleteSight({identifier: sight, day, index: -1}));
			}
			dispatch(moveSight({ sight, identifier, day, move }));
			move === 'up' &&
				openNotification(
					<span>
						<span style={{ color: '#00008B' }}>{sight}</span> was moved earlier in the day.
					</span>,
				);
			move === 'down' &&
				openNotification(
					<span>
						<span style={{ color: '#00008B' }}>{sight}</span> was moved later in the day.
					</span>,
				);
		} else if (move === 'next' && day < allDays) {
			dispatch(moveSight({ sight, identifier, day, move }));
			// dispatch(deleteSight({identifier, day, index: -1}));
			// dispatch(addSight({sight, index: 0, day}));
			openNotification(
				<span>
					<span style={{ color: '#00008B' }}>{sight.title}</span> was moved to the last position of the next day.
				</span>,
			);
		} else if (move === 'prev' && Number(day) !== 1) {
			dispatch(moveSight({ sight, identifier, day, move }));
			openNotification(
				<span>
					<span style={{ color: '#00008B' }}>{sight.title}</span> was moved to the last position of the previous day.
				</span>,
			);
		} else {
			openNotification('Sorry. Not possible.', 'error');
		}
		// if (move === 'next' && day < allDays) {
		// 	dispatch(deleteSight({identifier, day}));
		// 	dispatch(addSight({sight, index: 0, day}));
		// 	openNotification(
		// 		<span>
		// 			<span style={{ color: '#00008B' }}>{sight.title}</span> was moved to the last position of the next day.
		// 		</span>
		// 	);
		// } else if (move === 'prev' && Number(day) !== 1) {
		// 	dispatch(addSight({sight, index: 0, day}));
		// 	dispatch(deleteSight({identifier, day}));
		// 	openNotification(
		// 		<span>
		// 			<span style={{ color: '#00008B' }}>{sight.title}</span> was moved to the last position of the previous day.
		// 		</span>
		// 	);
		// } else if ((move === 'up' || move === 'down') ) {
		// 	console.log()
		// 	dispatch(moveSight({sight, identifier, day, move}));
		// 	// move === 'left' && openNotification(<span><span style={{color: '#00008B'}}>{sight}</span> was moved earlier in the day.</span>);
		// 	// move === 'right' && openNotification(<span><span style={{color: '#00008B'}}>{sight}</span> was moved later in the day.</span>);
		// } else {
		// 	openNotification('Sorry. Not possible.', 'error');
		// }
	};

	const delSight = () => {
		dispatch(deleteSight(null, sight, identifier, day));
		openNotification(
			<span>
				<span style={{ color: '#00008B' }}>{sight.title}</span> was deleted
			</span>,
			'warning',
		);
	};

	return (
		<Fragment>
			<Col className='days-card_options'>
				<Tooltip placement={'bottom'} title={'Move up'}>
					<span aria-hidden='true' className={'moveSight'} onClick={() => move('up')}>
						<ArrowUpOutlined />
					</span>
				</Tooltip>
				<Tooltip placement={'bottom'} title={'Move down'}>
					<span aria-hidden='true' className={'moveSight'} onClick={() => move('down')}>
						<ArrowDownOutlined />
					</span>
				</Tooltip>
				<Tooltip placement={'bottom'} title={'Move to the previous day'}>
					<span aria-hidden='true' className={'moveSight'} onClick={() => move('prev')}>
						<ArrowLeftOutlined />
					</span>
				</Tooltip>
				<Tooltip placement={'bottom'} title={'Move to the next day'}>
					<span aria-hidden='true' className={'moveSight'} onClick={() => move('next')}>
						<ArrowRightOutlined />
					</span>
				</Tooltip>
				<Tooltip placement={'bottom'} title={'Delete'}>
					<span aria-hidden='true' className={'moveSight'} onClick={showModal}>
						<CloseOutlined />
					</span>
				</Tooltip>
			</Col>

			<Modal open={isModalOpen} centered className='travel-modify' onCancel={cancelModal} style={{ textAlign: 'center' }} footer={null}>
				<h2 style={{ marginTop: '1vh' }}>Are you sure you want to delete this sight from your travel?</h2>
				<Button onClick={delSight} style={{ marginRight: '1vw' }}>
					Yes
				</Button>
				<Button onClick={cancelModal} style={{ marginLeft: '1vw' }}>
					No
				</Button>
			</Modal>
		</Fragment>
	);
};

export default memo(MoveControlButtons);
