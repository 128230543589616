import React, { useState } from 'react';
import { Row, Button, Col } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import TravelControls from '../TravelControls';
import TravelCards from './../../TravelInfoPage/TravelCards/TravelCards';
import TripPlanner from '../TripPlanner';
import DaysTabs from './../../../ui/DaysTabs/DaysTabs';
import PicsContribution from '../PicsContribution';

const CollapseSights = ({
	day,
	visible,
	handleChangeVisible,
	identifier,
	travel,
	routes,
	changeRouteOnTab,
	date,
	sightsNear,
	objSights,
	getLinkToGoogle,
	routeSights,
	sights,
	travelControls,
	width,
	route,
	updateTravelControls,
	handleChangeTab,
}) => {
	
	return (
		<Row className={`moderate-content__sights${visible ? '-full' : ''}`}>
			<Col className={`moderate-content__days${visible ? '-full' : ''}`} xxl={2} lg={5} md={5} sm={24} xs={24}>
				<Col className='moderate-content__days-tabs'>
					<DaysTabs routes={travel.route} changeRouteOnTab={changeRouteOnTab} date={date} />
				</Col>
				{!visible && <Button className='moderate-content__btn' onClick={handleChangeVisible} icon={<CaretRightOutlined />} />}
			</Col>

			<Col className={visible ? 'moderate-content__show' : 'moderate-content__hidden'} xxl={22} lg={19} md={19} sm={24} xs={24}>
				<Row>
					<TravelControls
						day={day}
						identifier={identifier}
						travel={travel}
						routes={routes}
						sightsNear={sightsNear}
						sights={sights}
						travelControls={travelControls}
						width={width}
						updateTravelControls={updateTravelControls}
						handleChangeTab={handleChangeTab}
					/>
					{visible && <Button className='moderate-content__btn' onClick={handleChangeVisible} icon={<CaretLeftOutlined />} />}
				</Row>
				<Col className='moderate-content__cards'>
					{day === 'All' ? (
						routes.slice(0, -1).map(route => (
							<div key={route.daysOrder} className='travel-sights__cards-day'>
								{/* <Affix target={() => container}>
										<h5>Day {route.daysOrder}</h5>
									</Affix> */}
								<TravelCards
									routes={routes}
									objSights={objSights}
									day={route.daysOrder}
									route={route}
									identifier={identifier}
									// routeLoading={routeLoading}
									getLinkToGoogle={getLinkToGoogle}
								/>
							</div>
						))
					) : (
						<TripPlanner
							routeSights={routeSights}
							travelControls={travelControls}
							getLinkToGoogle={getLinkToGoogle}
							objSights={objSights}
							updateTravelControls={updateTravelControls}
							day={day}
						/>
					)}

					<PicsContribution objSights={objSights} route={route}/>
				</Col>
			</Col>
		</Row>
	);
};

export default CollapseSights;
