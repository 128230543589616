// IMPORT PACKAGE REFERENCES
// import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

// IMPORT MIDDLEWARE
// import promiseMiddleware from 'redux-promise-middleware';

// IMPORT REDUCERS
import AppReducer from '../reducers/AppReducer';
import { configureStore } from '@reduxjs/toolkit';

// CONFIGURE STORE
export const history = createBrowserHistory();

const createAppStore = () => {
	return configureStore({
		reducer: AppReducer(history),
		devTools: process.env.NODE_ENV !== 'production',
		middleware: getDefaultMiddleware => getDefaultMiddleware().concat(routerMiddleware(history)),
	});
};
export default createAppStore;

// export default function createAppStore(initialState = {}){
//     const store = createStore(AppReducer(history), initialState, applyMiddleware(thunk, routerMiddleware(history), promiseMiddleware()));
//     return store;
// }
