import React, { useState, useEffect, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import SearchLocation from '../../../ui/Search/SearchLocation';
import { GetIcon } from '../../../../Helper';
import { checkControlExisting, distanceMi } from '../../../../helpers/_Functions';
import { getControl, getSearchInfo, searchControlAdded } from '../../../../state/reducers/SearchReducer';
import { deleteSight } from '../../../../state/actions/CustomizeTripActions';
import { deleteSearchControl, putSearchControl } from './../../../../state/actions/SearchAction';

const { Text } = Typography;

const TravelModifyAirport = props => {
	const { travel: { identifier, difficulty, airports, route } = {}, sights = [], routes = [], day = '', type = 'startDay', updateTravelControls, addToSights } = props;

	const dispatch = useDispatch();
	const airport = useSelector(getControl('airport'));
	const Search = useSelector(getSearchInfo());

	const [airportInfo, setAirportInfo] = useState('');
	const [showAirport, setShowAirport] = useState(false);

	const index =
		type === 'startDay' ? (checkControlExisting(day, 'start', Search) ? 1 : 0) : checkControlExisting(day, 'finish', Search) ? routes[day - 1].sights.length - 1 : routes[day - 1].sights.length;
	const lastSight = sights.find(sight => sight.identifier === route[day - 1].sights[route[day - 1].sights.length - 1]);

	const currAirport = useMemo(() => airport.find(item => item.identifier === identifier && item.day === day && item.difficulty === difficulty), [airport]);

	const addAirport = airport => {
		dispatch(searchControlAdded({ data: { label: airport.label, text: airport.text, x: airport.x, y: airport.y }, controlType: 'airport', identifier, day, difficulty }));
	};

	const deleteAirport = () => {
		dispatch(deleteSight({ identifier: airportInfo.label, day }));
		dispatch(deleteSearchControl({ type: 'airport', identifier: airportInfo.label, day, difficulty }));
		setAirportInfo('');
		setShowAirport(false);
	};

	useEffect(() => {
		if (airport.length) {
			// const currAirport = airport.find(item => item.identifier === identifier && item.day === day);
			if (currAirport) {
				if (!sights.find(el => el.title === currAirport.data.label && el.day === day)) {
					setAirportInfo(currAirport.data);
					setShowAirport(true);
					addToSights({ obj: currAirport, type: 'airport', icon: 'fa-plane', index });
				}
			}
		}
	}, [airport]);

	useEffect(() => {
		if (airport.length && !airport.find(el => el.day === day)) {
			setAirportInfo('');
			setShowAirport(false);
		} else {
			// const currAirport = airport.find(item => item.identifier === identifier && item.day === day);
			if (currAirport) {
				setAirportInfo(currAirport.data);
				setShowAirport(true);
			}
		}
	}, [day]);

	return (
		<Row className='modify-btns'>
			<Col lg={4} xs={4} className='modify-icon'>
				<GetIcon src='airport.png' />
				<Text className='modify-title'>Airport</Text>
			</Col>
			<Col lg={20} xs={20} className='modify-text'>
				{!showAirport && (
					<>
						{airports.map((item, key) => (
							<Button className={'addOptionModify'} key={key} onClick={() => addAirport({ text: item.title, label: item.title, x: item.location[0], y: item.location[1] })}>
								{item.title}
							</Button>
						))}
						<div style={{ padding: '0' }}>
							<SearchLocation controlType='airport' searchCategory='airport' />
						</div>
					</>
				)}

				{showAirport && airportInfo && (
					<Col lg={20} xs={20}>
						<h3 className={'modifyInfo'}>{airportInfo.text}</h3>
						<a href={`https://www.google.com/maps/place/${airportInfo.y},${airportInfo.x}`} className='modify-link' target='_blank' rel='nofollow noopener noreferrer'>
							<span className='material-icons modify-link__marker'>place</span>
							<Text className='modify-link__text'>
								{airportInfo.y}, {airportInfo.x}
							</Text>
						</a>
						<span className='modify-distance'>
							<b>
								~ {distanceMi([airportInfo.x, airportInfo.y], lastSight.location)} mi/ ~ {Math.round(distanceMi([airportInfo.x, airportInfo.y], lastSight.location) * 1.609)} km
							</b>{' '}
							to {lastSight.title}, the last sight of Day {day}
						</span>
					</Col>
				)}

				{showAirport && airportInfo && (
					<Col lg={4} xs={4} onClick={deleteAirport} className={'closeModify'}>
						<CloseOutlined />
					</Col>
				)}

				{!showAirport && (
					<Col lg={4} xs={4} onClick={() => updateTravelControls({ airport: day })} aria-hidden='true' className={'closeModify'}>
						<CloseOutlined />
					</Col>
				)}
			</Col>
		</Row>
	);
};

export default memo(TravelModifyAirport);
