import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Select, Button, Icon } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';

// import * as SearchActions from '../../../state/actions/SearchAction';
import SearchLocation from './SearchLocation';
import { DAYS } from '../../../constants/Duration';
import { checkDay } from '../../../helpers/_Functions';
import { putSearchDuration } from '../../../state/reducers/SearchReducer';

const { Option } = Select;

const SearchForm = () => {
	// const { Search: { item: { x: long = '', y: lat = '', label = '' } = {} } = {} } = props;
	const { location: { x: long = '', y: lat = '', label = '' } = {}, duration, error } = useSelector(state => state.Search);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleChange = value => {
		console.log(value, 'put search duration value');
		dispatch(putSearchDuration(value));
	};

	const handleSubmit = () => {
		const urlDay = DAYS.includes(duration) ? duration : 'All';
		if (label && !error) {
			let urlLabel = label.length && label.trim().replace(/ /g, '+').replace(/,\+/g, ';+').replace(/,\t\t/g, ';+');

			const url = urlLabel ? `/trips/${urlDay}/${lat};${long}/${urlLabel}/` : `/trips/${urlDay}/${lat};${long}/`;
			navigate(url);
		} else {
			navigate(`/trips/${urlDay}/`);
		}
	};

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
			lg: { span: 24 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 24 },
			lg: { span: 24 },
		},
	};

	return (
		<>
			<Form {...formItemLayout} onFinish={handleSubmit} layout='vertical' className='search-form'>
				<Form.Item className='search-form__duration'>
					<Select
						placeholder={
							<span className='search-form__item-placeholder'>
								<CalendarOutlined  />
								<p>Duration</p>
							</span>
						}
						onChange={handleChange}>
						{DAYS.map((day, index) => {
							return (
								<Option key={`day-${index}`} value={day}>
									{checkDay(day)}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item className='search-form__search'>
					<SearchLocation />
				</Form.Item>
				<Form.Item className='search-form__item'>
					<Button type='primary' htmlType='submit' className='search-form__btn'>
						Choose
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default memo(SearchForm);
