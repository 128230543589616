import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import OverviewHeader from './OverviewHeader';
import QuickMenu from './QuickMenu';
import OverviewGallery from './OverviewGallery';
import OverviewMap from './OverviewMap';
import DayByDay from './DayByDay';
import TipsRecommendations from './TipsRecommendations';
import OverviewStay from './OverviewStay';
import OverviewAirports from './OverviewAirports';
import ReviewsInfo from './../../ui/Reviews/ReviewsInfo';
import OverviewAlternatives from './OverviewAlternatives';
import ErrorBoundaryTravel from './../../ErrorBoundary/ErrorBoundaryTravel';

const TravelOverviewContent = () => {
	const { travel, travels, sights } = useSelector(state => state.TravelOverviewPage);
	return (
		<main className='overview-content'>
			<OverviewHeader travel={travel} travels={travels} />
			<QuickMenu />
			<ErrorBoundaryTravel page='OverviewGallery'>
				<OverviewGallery />
			</ErrorBoundaryTravel>
			{(sights.length && <OverviewMap />) || null}
			<DayByDay travel={travel} />
			<TipsRecommendations travel={travel}/>
			<ErrorBoundaryTravel page='OverviewStay'>
				
				{travel.stay && sights.length && <OverviewStay />}
			</ErrorBoundaryTravel>
			<ErrorBoundaryTravel page='OverviewAirports'>{travel.airports && sights.length && <OverviewAirports />}</ErrorBoundaryTravel>
			<ErrorBoundaryTravel page='OverviewReviews'>
				<ReviewsInfo type='TravelOverviewPage' sights={sights} />
			</ErrorBoundaryTravel>
			<ErrorBoundaryTravel page='OverviewAlternatives'>{<OverviewAlternatives />}</ErrorBoundaryTravel>
		</main>
	);
};

export default memo(TravelOverviewContent);
