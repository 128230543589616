import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Layout, Typography, Col } from 'antd';
import './AuthContainer.sass';
import LoginFormPage from '../../components/pages/AuthPage/LoginForm';
import RegisterFormPage from './../../components/pages/AuthPage/RegisterForm';
import Header from './../../components/ui/Header/Header';
import ResetFormPage from './../../components/pages/AuthPage/ResetForm';
import { loginGoogle, loginFacebook, login, signUp } from '../../state/actions/AuthActions';
import { hideMessage } from '../../state/reducers/UserReducer';

const { Content } = Layout;
const { Title } = Typography;

const AuthContainer = () => {
	const { pathname } = useLocation();
	const [resetPassword, setResetPassword] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const { auth, message } = useSelector(state => state.UserPage);
	const dispatch = useDispatch();
	console.log(location);
	const handleResetPassword = type => {
		setResetPassword(type);
	};
	const redirect = location.state ? location.state.from.pathname : `/user/${auth?.userId}`;
	const googleSignIn = () => {
		dispatch(loginGoogle());
	};

	const facebookSignIn = () => {
		dispatch(loginFacebook());
	};

	const handleLogin = values => dispatch(login(values));

	const handleSignUp = values => dispatch(signUp(values));

	useEffect(() => {
		if (auth?.userId) {
			navigate(redirect);
		}
	}, [auth]);

	useEffect(() => {
		if (message) {
			dispatch(hideMessage());
		}
	}, [location]);

	// useEffect(() => {
	// 	if (error) {
	// 		openNotification(error);
	// 	}
	// }, [error]);

	return (
		<Layout className='auth'>
			<Header />
			<Content className='auth-form'>
				<Col className='auth-container'>
					<Title level={1} className='auth-title'>
						{pathname.includes('login') ? (resetPassword ? 'Password reset' : 'Sign in') : 'Create an account'}
					</Title>
					{pathname.includes('login') ? (
						resetPassword ? (
							<>
								<span className='auth-subtitle'>Enter your email address and we'll send you a link to reset your before.voyage password.</span>
								<ResetFormPage handleResetPassword={handleResetPassword} />
							</>
						) : (
							<>
								<span className='auth-subtitle'>
									New user? <Link to='/registration'> Create an account</Link>
								</span>
								<LoginFormPage handleLogin={handleLogin} handleResetPassword={handleResetPassword} googleSignIn={googleSignIn} facebookSignIn={facebookSignIn} />
							</>
						)
					) : (
						<>
							<span className='auth-subtitle'>
								Already have an account? <Link to='/login'>Sign in</Link>
							</span>
							<RegisterFormPage handleSignUp={handleSignUp} googleSignIn={googleSignIn} facebookSignIn={facebookSignIn} />
						</>
					)}
				</Col>
			</Content>
		</Layout>
	);
};

export default AuthContainer;
