import reviewService from './../../services/review.service';
import { reviewsReceived, reviewsRequested, reviewsRequestFailed, setReview } from '../reducers/ReviewReducer';

export const loadReviews = (identifier, type) => async dispatch => {
	dispatch(reviewsRequested());
	try {
		// dispatch(showLoader(`reviews_${type}`));
		const [data] = await reviewService.get(identifier);
		dispatch(reviewsReceived(data || {}));

		// dispatch({ type: GET_REVIEWS, payload: data || {} });
		// dispatch(hideLoader(`reviews_${type}`));
		
	} catch ({message}) {
		dispatch(reviewsRequestFailed(message));
		// dispatch(showMessage({ identifier: `reviews_${type}`, message: e.message }));
		// dispatch(hideLoader(`reviews_${type}`));
	}
};

export const setReviewInfo = (data, type) => async (dispatch) => {
	// const { message } = getState().Helper;
	// dispatch()
	try {
		// if (message.setReview) {
		// 	dispatch(hideMessage({identifier: `setReview_${type}` }));
		// }
		// dispatch(showLoader(`setReview_${type}`));
		const result = await reviewService.setReview(data);
		if (result.status && result.status === 200) {
			// dispatch(hideLoader(`setReview_${type}`));
			// dispatch({ type: SET_REVIEW, payload: true });
			dispatch(setReview(true));
			dispatch(loadReviews(data.objectId, type));
		}
	} catch (error) {
		dispatch(setReview(false));
		// dispatch({ type: SET_REVIEW, payload: false });
		// dispatch(showMessage({ identifier: `setReview_${type}`, message: error.message }));
		// dispatch(hideLoader(`setReview_${type}`));
	}
};
