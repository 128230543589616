import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Col, Button, Tooltip } from 'antd';
import { ClearOutlined, FilterOutlined, FilterFilled } from '@ant-design/icons';
import { WindowSize } from '../../../helpers/WindowSize';

const MapDrawer = ({ menuOpen, days, selectedHashtags, menuToggle, clearAll, children }) => {
	const { width } = WindowSize();

	if (width < 769) {
		return (
			<div className='explore-drawer'>
				<Col className='explore-filters__clear explore-filter'>
					<Tooltip title='Clear Location'>
						<Button className='explore-filter__btn' onClick={clearAll}>
							<ClearOutlined />
						</Button>
					</Tooltip>
				</Col>
				<Col className='explore-drawer__menu'>
					<Menu
						right
						isOpen={menuOpen}
						customBurgerIcon={selectedHashtags.length || (days !== 'All' && days) ? <div className='explore-drawer__menu-btn'><FilterFilled /></div> : <div className='explore-drawer__menu-btn'><FilterOutlined /></div>}
						onStateChange={menuToggle}>
						{children}
					</Menu>
				</Col>
			</div>
		);
	}
	return children;
};

export default MapDrawer;
