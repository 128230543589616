import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'antd';

import Header from './../../components/ui/Header/Header';
import PageHelmet from './../../components/ui/hoc/PageHelmet';
import CustomizeTripContent from './../../components/pages/CustomizeTripPage/CustomizeTripContent';
import { Loader } from './../../components/common/Loader/Loader';
import { loadUserTravel } from '../../state/actions/CustomizeTripActions';
import '../TravelInfo/TravelContainer.sass';
import { shareURLChanged, travelReset } from '../../state/reducers/CustomizeTripReducer';
import useIsFirstRender from './../../components/hooks/useIsFirstRender';
import ErrorMessage from '../../components/common/Message/Message';
import { useLocation } from 'react-router-dom';

const CustomizeTripContainer = () => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { identifier, difficulty } = useParams();
	// const isFirstRender = useIsFirstRender();
	const { travel, isLoading, errors: {travel: travelError = ''} } = useSelector(state => state.CustomizeTripPage);
	const { user: userLoading = true } = useSelector(state => state.UserPage.loading);
	const [fullScreen, setFullScreen] = useState(false);
	// console.log(isFirstRender, difficulty, identifier, userLoading, 'first render', travel);
	// console.log(error)
	useEffect(() => {
		if (!(travel.identifier === identifier && travel.difficulty === difficulty)) {
			if (!userLoading) {
				dispatch(loadUserTravel(identifier, difficulty));
			}
		}
		dispatch(shareURLChanged(`https://www.before.voyage${pathname}`));
		// dispatch(travelReset());
	}, [identifier, difficulty, dispatch, userLoading]);
	const handleChangeScreen = () => setFullScreen(prev => !prev);
	const title = travel.title ? `${travel.title}. Detailed Travel Plan. Day-By-Day Planning.` : 'Detailed Travel Plan. Day-By-Day Planning.';
	const description = travel.title ? `${travel.title}. Itinerary, Routes, Sights, Roadmaps, Trails, Useful Information, Recommendations, Tips, Photos` : 'Itinerary, Routes, Sights, Roadmaps, Trails, Useful Information, Recommendations, Tips, Photos';
	const imgTravel = travel.imageTitle ? `https://www.before.voyage/external/img/${travel.imageTitle}` : 'https://www.before.voyage/img/default.svg';

	const LoadingJSX = isLoading ? <Loader /> : travelError && <ErrorMessage text={travelError} />;
	// console.log(LoadingJSX, isLoading, travelError)
	return (
		<Layout className='travel-page'>
			<PageHelmet title={title} description={description} image={imgTravel}>
				{!fullScreen && <Header />}
				{!isLoading && Object.prototype.hasOwnProperty.call(travel, 'title') ? <CustomizeTripContent fullScreen={fullScreen} handleChangeScreen={handleChangeScreen} /> : LoadingJSX}
			</PageHelmet>
		</Layout>
	);
};

export default CustomizeTripContainer;
