import { createSlice } from '@reduxjs/toolkit';
//     GET_TRAVELS,
// 	PUT_TRAVELS_SKIP
// } from '../../constants/ActionTypes';

// INITIALIZE STATE
const initialState = {
	travels: [],
	skip: 0,
	hasMore: true,
	loading: {},
	errors: {},
};

// REDUCER
// export const TravelsReducer = (state = initialState, action) => {
//     switch(action.type) {
//         case GET_TRAVELS: {
// 			return {
// 				...state,
// 				travels: action.payload.travels,
// 				skip: action.payload.skip,
// 				hasMore: action.payload.hasMore
// 			};
// 		}

// 		case PUT_TRAVELS_SKIP:
// 			return {
// 				...state,
// 				travels: [ ...state.travels, ...action.payload.travels],
// 				skip: action.payload.skip,
// 				hasMore: action.payload.hasMore
// 			};

//         default:
//             return state;
//     }
// };

const travelsSlice = createSlice({
	name: 'TravelsPage',
	initialState,
	reducers: {
		travelsRequested: state => {
			state.loading.travels = true;
		},
		travelsReceived: (state, action) => {
			state.travels = action.payload.travels;
			state.skip = action.payload.skip;
			state.hasMore = action.payload.hasMore;
			state.loading.travels = false;
			state.errors.travels = null;
		},
		travelsRequestFailed: (state, action) => {
			state.loading.travels = false;
			state.errors.travels = action.payload;
		},
		putTravelsSkipRequested: state => {
			state.loading.skip = true;
		},
		putTravelsSkip: (state, action) => {
			state.loading.skip = false;
			state.travels.push(...action.payload.travels);
			state.skip = action.payload.skip;
			state.hasMore = action.payload.hasMore;
		},
		putTravelsSkipRequestFailed: (state, action) => {
			state.loading.skip = false;
			state.errors.skip = action.payload;
		},
	},
});

const { reducer: TravelsReducer, actions } = travelsSlice;

export const { travelsRequested, travelsReceived, travelsRequestFailed, putTravelsSkipRequested, putTravelsSkip, putTravelsSkipRequestFailed } = actions;
export { TravelsReducer };
