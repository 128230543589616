import React, { useState, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import { Typography, Row, Col, Button } from 'antd';
import { ExtraMarkers } from 'leaflet-extra-markers'; //need for icon
import 'leaflet-extra-markers/src/assets/css/leaflet.extra-markers.css';
import { StickyContainer, Sticky } from 'react-sticky';

import { ImageFinder } from '../../../Helper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Map from './../../ui/hoc/Map/Map';

const { Title, Text } = Typography;

let settingsSwiper = {
	freeMode: true,
	pagination: { clickable: true },
	className: 'trip-swiper',
	breakpoints: {
		320: {
			slidesPerView: 5,
		},
		480: {
			slidesPerView: 5,
		},
		640: {
			slidesPerView: 25,
			spaceBetween: 2,
		},
	},
};

const OverviewMap = () => {
	const { travel = {}, routes = [], sights = [] } = useSelector(state => state.TravelOverviewPage);
	const [currDay, setCurrDay] = useState('All');

	const distance = currDay === 'All' ? travel.distance : routes[Number(currDay) - 1].route.distance;
	const currentRouteDistance = useMemo(
		() => (currDay === 'All' ? `${(distance / 1000).toFixed(2)} km | ${(distance / 1609.344).toFixed(2)} mi` : `${(distance / 1000).toFixed(2)} km | ${(distance / 1609.344).toFixed(2)} mi`),
		[currDay, distance]
	);

	const ButtonTabsJSX = (
		<Swiper {...settingsSwiper}>
			<SwiperSlide>
				<Button
					size='small'
					className={currDay === 'All' ? 'overview-btn--primary' : 'overview-btn--default'}
					type={currDay === 'All' ? 'primary' : 'default'}
					onClick={() => changeCurrDay('All')}>
					All Days
				</Button>
			</SwiperSlide>
			{travel.route.map(route => (
				<SwiperSlide key={route.daysOrder}>
					<Button
						size='small'
						className={currDay === route.daysOrder ? 'overview-btn--primary' : 'overview-btn--default'}
						type={currDay === route.daysOrder ? 'primary' : 'default'}
						onClick={() => changeCurrDay(route.daysOrder)}>
						Day {route.daysOrder}
					</Button>
				</SwiperSlide>
			))}
		</Swiper>
	);

	const mapSights = useMemo(
		() =>
			currDay !== 'All'
				? travel.route[Number(currDay) - 1].sights.map(sight => sights.find(s => s.identifier === sight)).filter(Boolean)
				: routes[routes.length - 1].sights.map(sight => sights.find(s => s.identifier === sight)).filter(Boolean),
		[currDay]
	);
	const changeCurrDay = day => setCurrDay(day);
	const bounds = useMemo(() => mapSights.map(s => [s.location[1], s.location[0]]), [mapSights]);
	return (
		mapSights.length && (
			<div className='overview-roadmap'>
				<StickyContainer>
					<Row className='overview-roadmap__header'>
						<Sticky>
							{({ style }) => (
								<div style={style} className='overview-roadmap__header-sticky'>
									<Title level={2} className='overview-title'>
										Roadmap
									</Title>
								</div>
							)}
						</Sticky>
					</Row>
					<Row className='overview-roadmap__content'>
						<Row className='overview-roadmap__content-btns'>
							<Text className='overview-roadmap__distance'>{currentRouteDistance}</Text>
							{ButtonTabsJSX}
						</Row>
						<Map className='overview-roadmap__content-map' bounds={bounds}>
							<Col className='overview-roadmap__content-link'>
								<a href={`/trip/${travel.identifier}/${travel.difficulty}/day/${currDay !== 'All' ? currDay : 1}`}>
									<Text className='overview-roadmap__content-link-text'>See a detailed itinerary.</Text>
								</a>
							</Col>
							{mapSights.map((sight, index) => (
								<Marker
									position={[sight.location[1], sight.location[0]]}
									key={`${sight.identifier}_marker`}
									title={sight.title}
									riseOnHover='true'
									icon={L.ExtraMarkers.icon({
										icon: 'fa-number',
										number: index + 1,
										markerColor: 'blue',
									})}>
									<Popup className='popup'>
										<Row className='popup__container'>
											<Col className='popup__container-img' lg={6} md={6} sm={6} xs={6}>
												<a href={`/trip/${travel.identifier}/sight/${sight.identifier}`}>
													<ImageFinder src={sight.images[0].name} className='popup-img' />
												</a>
											</Col>
											<Col className='popup__content' lg={18} md={18} sm={18} xs={18}>
												<a href={`/trip/${travel.identifier}/sight/${sight.identifier}`}>
													<Title level={3} className='popup-title'>
														{sight.title}
													</Title>
												</a>

												<Text className='popup-text'>{sight.subtitle}</Text>
												<a
													href={`https://www.google.com/maps/place/${sight.location[1]},${sight.location[0]}`}
													className='popup__container-link'
													target='_blank'
													rel='nofollow noopener noreferrer'>
													<span className='material-icons popup_marker'>place</span>
													<Text className='popup-text_link'>
														{sight.location[0]}, {sight.location[1]}
													</Text>
												</a>
											</Col>
										</Row>
									</Popup>
								</Marker>
							))}
						</Map>
					</Row>
				</StickyContainer>
			</div>
		)
	);
};

export default memo(OverviewMap);
