import React, {memo} from 'react';
import {useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Carousel, Row, Col, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ImageFinder } from '../../../Helper';

const { Title, Text } = Typography;
let settings = {
	className: 'center',
	draggable: true,
	centerMode: true,
	infinite: true,
	slidesToShow: 3,
	lazyLoad: true,
	swipeToSlide: true,
	speed: 500,
	dots: false,
	focusOnSelect: true,
	arrows: true,
	nextArrow: <RightOutlined />,
	prevArrow: <LeftOutlined />,
	responsive: [
		{
			breakpoint: 480,
			settings: {
				arrows: true,
				slidesToShow: 1,
			},
		},
	],
};
const OverviewGallery = () => {
	const { travel = {}, sights = [] } = useSelector(state => state.TravelOverviewPage);

	return (
		<div className='overview-photos'>
			<Carousel {...settings}>
				{travel.route.map(trip =>
					trip.sights.map((sightIdentifier, index) =>
						sights.map(
							sight =>
								sight.identifier === sightIdentifier && (
									<Col className={'overview-photos__carousel-item'} key={sightIdentifier}>
										<Col className='overview-photos__carousel-info'>
											<Text>
												<span className='material-icons'>event</span>Day {trip.daysOrder}
											</Text>
											<Text>
												<span className='material-icons '>run_circle</span>Sight {index + 1}
											</Text>
										</Col>
										<ImageFinder alt={sight.title} title={sight.title} src={sight.images[0].name} className='overview-photos__img' />
										<Link className='overview-photos__carousel-link' to={`/trip/${travel.identifier}/sight/${sight.identifier}`}>
											<Title level={4}>{sight.title}</Title>
											<Text>{sight.subtitle}</Text>
										</Link>
									</Col>
								)
						)
					)
				)}
			</Carousel>

			<a className='overview-photos__link' href={`/trip/${travel.identifier}/${travel.difficulty}/day/1`}>
				<span className='material-icons'>follow_the_signs</span>
				<span>See a trip&apos;s detailed itinerary and a roadmap</span>
			</a>

			{travel.hashtags.length && (
				<Row className='hashtags overview-hashtags'>
					{travel.hashtags.map((item, key) => (
						<Link to={{ pathname: '/trips/All', hash: `${item}` }} key={key}>
							#{item}
						</Link>
					))}
				</Row>
			)}
			{travel.description && <Row className='overview-description' dangerouslySetInnerHTML={{ __html: travel.description }} />}
		</div>
	);
};


export default memo(OverviewGallery);
