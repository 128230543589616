import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useParams, useLocation } from 'react-router';
import { Row, Col, Button,  Select } from 'antd';
import SearchLocation from '../../ui/Search/SearchLocation';
import { DAYS } from '../../../constants/Duration';
import { INTENSITY } from '../../../constants/Information';
import { checkDay } from '../../../helpers/_Functions';
import { ControlOutlined, EnvironmentOutlined, CalendarOutlined } from '@ant-design/icons';
import { putSearchDifficulty } from '../../../state/reducers/SearchReducer';

const ButtonGroup = Button.Group;
const { Option } = Select;

const TravelsFilter = () => {
	const { days, identifier } = useParams();
	const { hash } = useLocation();
	const { location: { label = '' } = {}, duration, difficulty } = useSelector(state => state.Search);
    const dispatch = useDispatch();
	const travelsIntensities = [];

	const changeIntensity = (intensityType = '') => {
		if (intensityType !== '' && intensityType !== difficulty) {
			dispatch(putSearchDifficulty(intensityType));
		}
	};

	if (INTENSITY.length) {
		INTENSITY.forEach(element => {
			travelsIntensities.push(element);
		});
	}

	const ButtonIntensity = (
		<Select className='travels-filters__intensity-select' onSelect={(e) => changeIntensity(e)} defaultValue={difficulty} style={{ width: 120 }}>
			{travelsIntensities.length
				? travelsIntensities.map(elem => {
						return (
							<Option value={elem} key={`button-${elem}`}>
								{elem}
							</Option>
						);
				  })
				: false}
		</Select>
	);

	let urlLabel = label.length && label.trim().replace(/ /g, '+').replace(/,\+/g, ';+').replace(/,\t\t/g, ';+') + '/';

	return (
		<Row className='travels-options'>
			<Col className='travels-filters__duration' xs={{ order: 2 }} sm={{ order: 2 }} md={{ order: 2 }} lg={{ order: 2 }}>
				<span className='travels-filters__title'>
					<CalendarOutlined /> Duration:
				</span>
				<ButtonGroup className='travels-filters__days'>
					{DAYS.map((day, index) => {
						return (
							<Link
								className={day === duration ? 'travels-options__link' : ''}
								key={`days-${index}`}
								to={{ pathname: `${identifier ? `/trips/${day}/${identifier}/${urlLabel}` : `/trips/${day}/`}`, hash: `${hash}` }}>
								<Button type={day === days ? 'primary btn-active' : 'default btn-default'}>{checkDay(day)}</Button>
							</Link>
						);
					})}
				</ButtonGroup>
			</Col>
			<Col className='travels-filters' xs={{ order: 1, span: 12 }} sm={{ order: 1, span: 12 }} md={{ order: 2, span: 12 }} lg={{ order: 2, span: 12 }}>
				<Col className='travels-filters__intensity'>
					<span className='travels-filters__title'>
						<ControlOutlined />
						Intensity:
					</span>
					{ButtonIntensity}
				</Col>

				<Col className='travels-filters__search' xs={{ order: 1, span: 12 }} sm={{ order: 1, span: 12 }} md={{ order: 2, span: 12 }} lg={{ order: 2, span: 12 }}>
					<span className='travels-filters__title'>
						<EnvironmentOutlined /> Location:
					</span>
					<SearchLocation className='travels-filters__search-select' />
				</Col>
			</Col>
		</Row>
	);
};

export default memo(TravelsFilter);
