import httpService from './http.service';

const travelEndpoint = 'travel/';

const travelService = {
	get: async ({ filter, sort, limit, skip }) => {
		const { data } = await httpService.get(travelEndpoint, {
			params: {
				$filter: filter,
				$sort: sort,
				$limit: limit,
				$skip: skip,
			},
		});
		return data;
	},
	getTravels: async ({ skip, limit, hashtag, daysNumber, difficulty, sort, route }) => {
		const {data} = await httpService.get(travelEndpoint, {
			params: {
				$filter: {
					difficulty,
					daysNumber,
					hashtags: hashtag,
					route,
				},
				$sort: sort,
				$limit: limit,
				$skip: skip,
			},
		});
		return data;
	},
	getTravelsForSight: async ({ identifier, sight, difficulty, sort, limit }) => {
		const { data } = await httpService.get(travelEndpoint, {
			params: {
				$filter: {
					identifier: {
						$ne: identifier,
					},
					'route.sights': sight,
					difficulty,
				},
				$sort: sort,
				$limit: limit,
			},
		});
		return data;
	},
	getTravelsWithSights: async ({ sights, difficulty, identifier, limit, sort }) => {
		const { data } = await httpService.get(travelEndpoint, {
			params: {
				$filter: {
					identifier: {
						$ne: identifier,
					},
					route: {
						$elemMatch: {
							sights: {
								$in: sights,
							},
						},
					},
					difficulty,
				},
				$limit: limit,
				$sort: sort,
			},
		});
		return data;
	},
};
export default travelService;
