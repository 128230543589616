// IMPORT PACKAGE REFERENCES
import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

// IMPORT REDUCERS
import { ZeroPageReducer } from './ZeroPageReducer';
// import { TravelsAndSightsReducer } from './TravelsAndSightsReducer';
import { TravelsReducer } from './TravelsReducer';
import { LocationReducer } from './LocationReducer';
import { TravelInfoReducer } from './TravelInfoReducer';
import { SearchReducer } from './SearchReducer';
import { TravelOverviewReducer } from './TravelOverviewReducer';
import { SightReducer } from './SightReducer';
import { MapReducer } from './MapReducer';
import { HelperReducer } from './HelperReducer';
import { ReviewReducer } from './ReviewReducer';
import { UserReducer } from './UserReducer';
import { CustomizeTripReducer } from './CustomizeTripReducer';
import { TravelReducer } from './TravelReducer';

// EXPORT APP REDUCER
const AppReducer = history =>
	combineReducers({
		router: connectRouter(history),
		ZeroPage: ZeroPageReducer,
		TravelsPage: TravelsReducer,
		TravelPage: TravelReducer,
        TravelOverviewPage: TravelOverviewReducer,
		SightPage: SightReducer,
		Location: LocationReducer,
		TravelInfo: TravelInfoReducer,
		Search: SearchReducer,
		MapPage: MapReducer,
		Helper: HelperReducer,
		reviews: ReviewReducer,
		UserPage: UserReducer,
		CustomizeTripPage: CustomizeTripReducer,
	});

export default AppReducer;
