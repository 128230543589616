import axios from 'axios';
import configFile from '../config.json';
import localStorageService from './localStorage.service';

const httpAuth = axios.create({
	baseURL: 'https://identitytoolkit.googleapis.com/v1/',
	params: {
		key: configFile.REACT_APP_FIREBASE_KEY,
	},
});

const authService = {
	register: async ({ email, password }) => {
		const { data } = await httpAuth.post('accounts:signUp', {
			email,
			password,
			returnSecureToken: true,
		});
		return data;
	},
	login: async ({ email, password }) => {
		const { data } = await httpAuth.post('accounts:signInWithPassword', {
			email,
			password,
			returnSecureToken: true,
		});
		return data;
	},
	refresh: async () => {
		const { data } = await httpAuth.post('token', {
			grant_type: 'refresh_token',
			refresh_token: localStorageService.getRefreshToken(),
		});
		return data;
	},
	reset: async ({ email }) => {
		const data = await httpAuth.post('accounts:sendOobCode', {
			email,
			requestType: 'PASSWORD_RESET',
		});
		return data;
	},
};

export default authService;
